import React from "react";
import { Select } from "components/_inputs/Select";
import { SUPPORTED_WEBSITE_LANGUAGES } from "assets/constants/supportedWebsiteLanguages";
import { LanguageCode } from "@explorance/mly-types";
import { ButtonSize } from "ts/enums/button";
import { Text } from "components/Text";

type Props = {
  currentLanguage: LanguageCode;
  handleSelectLanguage: (lang: LanguageCode) => void;
};

export const LanguageSelect = ({ currentLanguage, handleSelectLanguage }: Props) => {
  const getSelectOption = (languageCode: LanguageCode) => {
    return {
      label: <Text resource={`languageCode.${languageCode}.userLanguageName`} />,
      value: languageCode,
    };
  };

  return (
    <Select
      selectedOption={getSelectOption(currentLanguage)}
      options={SUPPORTED_WEBSITE_LANGUAGES.map((lc) => getSelectOption(lc))}
      buttonSize={ButtonSize.md}
      buttonStyle={{
        position: "relative",
        fontWeight: "normal",
        fontSize: "15px",
        width: "100%",
      }}
      dropdownPosition={{ right: 0 }}
      dropdownWidth="100%"
      flex="1 0 auto"
      handleChange={handleSelectLanguage}
    />
  );
};
