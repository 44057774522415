import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useAppSelector, useAppDispatch } from "store";
import { StyledDropdownTransition } from "components/DropdownMenu/StyledDropdownTransition";
import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { NumberInput } from "components/_inputs/NumberInput";

import useClickOutside from "hooks/useClickOutside";
import { validateEmail } from "utils/validation";
import { getLicencePeriodById, updateActiveLicencePeriod } from "services/licensing";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { ErrorType, RoleType } from "@explorance/mly-types";
import { showToastError } from "store/toast/toastSlice";
import { Text } from "components/Text";

const COMMENTS_LIMIT_INPUT_RANGE = { min: 1, max: 9999999 };
const USERS_LIMIT_INPUT_RANGE = { min: 1, max: 99999 };
const EMAIL_NOTIFICATION_INPUT_RANGE = { min: 1, max: 100 };

type Props = {
  periodId: number;
};

export const QuotaMenu = ({ periodId }: Props) => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const [showQuotaDropdown, setShowQuotaDropdown] = useState<boolean>(false);
  const [commentsLimitInputValue, setCommentsLimitInputValue] = useState<number>(null);
  const [userLimitInputValue, setUserLimitInputValue] = useState<number>(null);
  const [emailNotificationThresholdInputValue, setEmailNotificationThresholdInputValue] =
    useState<number>(null);
  const [emailToNotifyInputValue, setEmailToNotifyInputValue] = useState<string>("");
  const [isEmailError, setIsEmailError] = useState<boolean>(false);
  const [isActivePeriod, setIsActivePeriod] = useState<boolean>(false);
  const isInputInactive = currentUser.roleType === RoleType.Admin || !isActivePeriod;
  const quotaMenuRef = useClickOutside(() => setShowQuotaDropdown(false));

  const handleSave = async () => {
    const isEmailInputEmpty = !emailToNotifyInputValue;
    const isEmailValid = isEmailInputEmpty || validateEmail(emailToNotifyInputValue);
    setIsEmailError(!isEmailValid);
    if (!isEmailValid) return;

    try {
      const requestBody =
        currentUser.roleType === RoleType.SuperAdmin
          ? {
              commentLimit: commentsLimitInputValue,
              userLimit: userLimitInputValue,
              notificationQuota: emailNotificationThresholdInputValue,
              notificationEmail: isEmailInputEmpty ? null : [emailToNotifyInputValue],
            }
          : {
              notificationQuota: emailNotificationThresholdInputValue,
              notificationEmail: isEmailInputEmpty ? null : [emailToNotifyInputValue],
            };

      await updateActiveLicencePeriod(requestBody);
      setShowQuotaDropdown(false);
    } catch (error: any) {
      dispatch(
        showToastError(
          error.response?.data?.details?.errorType === ErrorType.UsersLimitReached
            ? {
                message: {
                  titleKey: "licensing.quotaMenu.error.belowExistingUserCount",
                  args: [{ titleKeyArg: error.response.data.details.count }],
                },
              }
            : "toast.defaultError"
        )
      );
    }
  };

  // Fetching the current period's quota values
  useEffect(() => {
    if (showQuotaDropdown && periodId) {
      getLicencePeriodById(periodId).then(({ data }) => {
        setUserLimitInputValue(data?.userLimit);
        setCommentsLimitInputValue(data.commentLimit);
        setEmailNotificationThresholdInputValue(data.notificationQuota);
        setEmailToNotifyInputValue(data.notificationEmail[0]);
        setIsActivePeriod(data.isActive);
      });
      setIsEmailError(false);
    }
  }, [showQuotaDropdown, periodId]); // eslint-disable-line

  const inputStyling = {
    height: "34px",
    margin: "0 6px",
    border: `1px solid ${Color.sky50}`,
    borderRadius: "2px",
    padding: "0 6px",
    color: `${Color.gray50}`,
    width: "96px",
  };

  return (
    <StyledQuotaMenu ref={quotaMenuRef}>
      <Button
        variant={ButtonVariant.secondary}
        size={ButtonSize.md}
        onClick={() => setShowQuotaDropdown((show) => !show)}
      >
        <span>
          <Text resource="licensing.quotaMenu.button" />
        </span>
        <Icon type={IconType.chevronRight} size={14} style={{ marginLeft: "6px" }} />
      </Button>
      <StyledQuotaDropdown show={showQuotaDropdown}>
        <h3>
          <Text resource="licensing.quotaMenu.header" />
        </h3>
        <>
          <StyledQuotaInputField>
            <span>
              <Text resource="licensing.quotaMenu.commentSentenceField.label1" />
            </span>
            <NumberInput
              value={commentsLimitInputValue}
              minimum={COMMENTS_LIMIT_INPUT_RANGE.min}
              maximum={COMMENTS_LIMIT_INPUT_RANGE.max}
              disabled={isInputInactive}
              styling={inputStyling}
              handleChange={setCommentsLimitInputValue}
            />
            <span>
              <Text resource="licensing.quotaMenu.commentSentenceField.label2" />
            </span>
          </StyledQuotaInputField>
          <StyledQuotaInputField>
            <span>
              <Text resource="licensing.quotaMenu.userQuotaField.label1" />
            </span>
            <NumberInput
              value={userLimitInputValue}
              minimum={USERS_LIMIT_INPUT_RANGE.min}
              maximum={USERS_LIMIT_INPUT_RANGE.max}
              allowNull
              disabled={isInputInactive}
              styling={inputStyling}
              handleChange={setUserLimitInputValue}
            />
            <span>
              <Text resource="licensing.quotaMenu.userQuotaField.label2" />
            </span>
          </StyledQuotaInputField>

          <StyledHorizontalSeparator />
        </>
        <StyledQuotaInputField>
          <span>
            <Text resource="licensing.quotaMenu.emailNotificationQuotaField.label1" />
          </span>
          <NumberInput
            value={emailNotificationThresholdInputValue}
            minimum={EMAIL_NOTIFICATION_INPUT_RANGE.min}
            maximum={EMAIL_NOTIFICATION_INPUT_RANGE.max}
            styling={{
              ...inputStyling,
              width: "40px",
            }}
            disabled={!isActivePeriod}
            handleChange={setEmailNotificationThresholdInputValue}
          />
          <span>
            <Text resource="licensing.quotaMenu.emailNotificationQuotaField.label2" />
          </span>
        </StyledQuotaInputField>
        {isEmailError && (
          <StyledEmailError>
            <Icon
              type={IconType.warning}
              color={Color.red50}
              size={16}
              style={{ marginRight: 5 }}
            />
            <Text resource="licensing.quotaMenu.emailField.error" />
          </StyledEmailError>
        )}
        <StyledQuotaInputField>
          <Text resource="licensing.quotaMenu.emailField.label" />
          <StyledEmailInput
            value={emailToNotifyInputValue}
            onChange={(e) => setEmailToNotifyInputValue(e.target.value)}
            isError={isEmailError}
            style={inputStyling}
            disabled={!isActivePeriod}
          />
        </StyledQuotaInputField>
        <StyledSubmitButtonsContainer>
          <Button
            size={ButtonSize.sm}
            style={{ marginRight: 12 }}
            variant={ButtonVariant.light}
            onClick={() => setShowQuotaDropdown(false)}
          >
            <Text resource="button.cancel" />
          </Button>
          <Button size={ButtonSize.sm} onClick={handleSave} disabled={!isActivePeriod}>
            <Text resource="button.save" />
          </Button>
        </StyledSubmitButtonsContainer>
      </StyledQuotaDropdown>
    </StyledQuotaMenu>
  );
};

const StyledQuotaMenu = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  h3 {
    margin: 2px 0 12px 0;
  }
`;

const StyledQuotaDropdown = styled(StyledDropdownTransition)<{ show: boolean }>`
  position: absolute;
  right: 0;
  top: 100%;
  background-color: ${Color.white};
  box-shadow: 0px 3px 6px #00000029;
  padding: 12px 16px;
  border-radius: 5px;
  width: 422px;

  * {
    font-size: 15px;
  }
`;

const StyledQuotaInputField = styled.div`
  padding-bottom: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > * {
    display: inline;
    font-size: 15px;
  }
`;

const StyledEmailInput = styled.input<{ isError: boolean }>`
  flex-grow: 1;
  ${({ isError }) =>
    isError &&
    css`
      border: 1px solid ${Color.red50} !important;
      background-color: ${Color.red10};
    `}
`;

const StyledEmailError = styled.div`
  display: flex;
  align-items: center;
  color: ${Color.red50};
  margin-bottom: 8px;
`;

const StyledHorizontalSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Color.sky50};
  margin-bottom: 12px;
`;

const StyledSubmitButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
