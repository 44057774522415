import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
  useCallback,
  useRef,
} from "react";
import { useParams, useHistory } from "react-router-dom";
import { useAppSelector } from "store";
import { UserDemographic } from "../UserProfileContext";
import { AnalysisContext } from "context/AnalysisContext";

import { getAllUserDemographics } from "services/users";
import { isAnyAdmin } from "utils/isAnyAdmin";
import { useResource } from "hooks/useResource";
import { typeDataToUserDemographics } from "utils/userDemographics";
import {
  GetRecipientsGroupsParams,
  GetRecipientsUsersParams,
  GetShareableUsersParams,
  getRecipientsGroups,
  getRecipientsUsers,
  getSharingDraft,
  getShareableUsers,
  updateSharingDraft,
  addUsersToNewSharingDraft,
  removeUsersFromNewSharingDraft,
  getSharingDraftUsers,
} from "services/analysis/sharing";
import { getSharingMethodFromView } from "utils/getSharingMethodFromSharingView";
import { getInitialState } from "./getInitialState";
import {
  getSharedUsersFromApiData,
  getVisibleCommentDemsForFetch,
  getVisibleCommentDemsFromData,
  getVisibleDemFiltersFromData,
} from "./helpers";
import { getDemographicsByAnalysisId } from "services/analysis";
import { PILLS_LIMITS } from "assets/constants/sharingDraftLimits";

import { DemographicFilter, DemographicFilterHeader } from "ts/filters/demographicFilter";
import {
  CustomConditionDemographics,
  RecipientGroupsApiData,
  SharingUser,
  SharingUserDemographic,
  SharingUsersApiData,
} from "ts/sharing";
import {
  AnalysisStatus,
  FileUploadStatus,
  PermissionLevel,
  RoleType,
  ShareStatus,
  SharingCommentExplorerAccess,
  SharingDashboardAccess,
  SharingOverviewAccess,
  SharingTopicExplorerAccess,
} from "@explorance/mly-types";
import { SharingView, ShareStep, DefaultWidgets, ShareByOption } from "ts/enums/sharing";
import { SelectOption } from "ts/select";

const SharingContext = createContext({} as SharingContextReturnType);

type UpdateSharingStateOptions = {
  save?: boolean;
};

type SharingContextReturnType = {
  sharingState: SharingContextState;
  updateSharingState: (property: string, value: any, options?: UpdateSharingStateOptions) => void;
  refetchSharedGroupsData: (params: GetRecipientsGroupsParams) => void;
  refetchSharedUsersData: (params: GetRecipientsUsersParams) => void;
  refetchSharingUsersData: (
    params: GetShareableUsersParams,
    demographics: SharingUserDemographic[]
  ) => void;
  handleUserSharingDraft: (userIds: number[], isSelected: boolean) => void;
  removeAllUsersFromDraft: () => void;
  validateUsersShown: (arr: SharingUser[]) => SharingUser[];
  updateSelectedUsers: () => void;
};

export type SharingContextState = {
  isGroupsApiDataLoading: boolean;
  isSharedUsersApiDataLoading: boolean;
  groupsApiData: RecipientGroupsApiData;
  sharedUsersApiData: SharingUsersApiData;
  shareableUsersApiData: SharingUsersApiData;
  selectedUsers: SharingUser[];
  selectedUsersCount: number;
  step: ShareStep;
  allUserDemographics: UserDemographic[];
  selectedUserDemographicFilters: UserDemographic[];
  view: SharingView;
  pillsPage: number;
  shareBy: SelectOption;
  shareByDropdownOptions: SelectOption[];
  permissionFields: {
    groupName?: string;
    permissionLevel: PermissionLevel;
    dashboardAccess: SharingDashboardAccess;
    topicExplorerAccess: SharingTopicExplorerAccess;
    commentExplorerAccess: SharingCommentExplorerAccess;
    defaultWidgets: DefaultWidgets;
    allowExportData: boolean;
    visibleDemographicsFilters: DemographicFilterHeader[];
    visibleCommentsByDemographicValues: DemographicFilter[];
    customConditions: CustomConditionDemographics[];
  };
  analysisDemographicHeaders: DemographicFilterHeader[];
  showCustomConditionError: boolean;
  showGroupNameError: boolean;
};

type Props = {
  children: ReactNode;
};

export const SharingProvider = ({ children }: Props) => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);

  const [state] = useContext(AnalysisContext);
  const { getResource } = useResource();
  const [sharingState, setSharingState] = useState<SharingContextState>(
    getInitialState(getResource)
  );
  const [pendingUpdateSharingDraftApiCall, setPendingUpdateSharingDraftApiCall] =
    useState<boolean>(false);

  const sharingKeys = useRef<string[]>(Object.keys(sharingState));

  const isUserAnalystInHisAnalysis =
    currentUser.roleType === RoleType.Analyst && !state.analysisDetails.sharing;
  const isUserViewer = currentUser.roleType === RoleType.Viewer;
  const isUserAdmin = isAnyAdmin(currentUser.roleType);
  const analysisFailed = state.analysisDetails.status === AnalysisStatus.Failed;
  const fileUploadFailed = state.analysisDetails.uploadStatus === FileUploadStatus.Failed;
  const analysisNotAnalyzed =
    state.analysisDetails.uploadStatus === FileUploadStatus.Failed ||
    state.analysisDetails.status === AnalysisStatus.NotAnalyzed ||
    state.analysisDetails.status === AnalysisStatus.InProgress;
  const loadingAnalysisDetails = state.loadingAnalysisDetails;

  const analysisId = parseInt(useParams<{ analysisId: string }>().analysisId);
  const history = useHistory();
  const { view } = useParams<{ view: string }>();

  const [isFetchingDataRestricted, setIsFetchingDataRestricted] = useState<boolean>(false);

  const updateSharingState = useCallback(
    (
      property: string,
      value: string | boolean | Record<any, any> | number,
      options?: UpdateSharingStateOptions
    ) => {
      if (!options) options = {};

      if (sharingKeys.current.includes(property)) {
        setSharingState((state) => ({ ...state, [property]: value }));
        if (options.save) {
          setPendingUpdateSharingDraftApiCall(true);
        }
      }
    },
    []
  );

  const handleUserSharingDraft = async (userIds: number[], isSelected: boolean) => {
    const action = isSelected ? removeUsersFromNewSharingDraft : addUsersToNewSharingDraft;
    const count = isSelected ? -userIds.length : userIds.length;
    updateSharingState("selectedUsersCount", sharingState.selectedUsersCount + count);
    await action({
      analysisId,
      sharingMethod: getSharingMethodFromView(sharingState.view),
      userIds: userIds,
    });
  };

  const getSharableUsers = async () => {
    if (isFetchingDataRestricted) {
      return;
    }
    await getShareableUsers({ analysisId })
      .then(({ data }) => {
        updateSharingState("shareableUsersApiData", {
          itemCount: data.itemCount,
          totalCount: data.totalCount,
          users: data.users.map((u) => ({
            ...u,
            isSelected: u.shareStatus === ShareStatus.InDraft,
          })),
        });
      })
      .catch((e) => console.error(e.message));
    await updateSelectedUsers();
  };

  const validateUsersShown = (arr) => {
    if (sharingState.selectedUsers.filter((u) => u.isShown).length <= 5) {
      for (let i = 0; i < PILLS_LIMITS.MIN; i++) {
        if (arr[i]) {
          arr[i].isShown = true;
        }
      }
    }
    return arr;
  };

  const removeAllUsersFromDraft = async () => {
    await removeUsersFromNewSharingDraft({
      analysisId,
      sharingMethod: getSharingMethodFromView(sharingState.view),
      userIds: sharingState.selectedUsers.map((user) => user.id),
      removeAll: true,
    });
    updateSharingState("selectedUsersCount", 0);
  };

  const updateSelectedUsers = async () => {
    if (!sharingState.pillsPage) return;

    try {
      const { data } = await getSharingDraftUsers({
        analysisId,
        page: sharingState.pillsPage,
        limit: PILLS_LIMITS.REQUEST_LIMIT,
      });
      //updated array with unique user.id made by pushing data.users to selectedUsers
      const uniqueUsersMap = new Map();

      sharingState.selectedUsers.forEach((user) => {
        uniqueUsersMap.set(user.id, user);
      });

      data.users.forEach((user) => uniqueUsersMap.set(user.id, user));

      const updatedArray = Array.from(uniqueUsersMap.values());

      const users = updatedArray.map((u, i) => {
        return {
          ...u,
          isShown: i < (sharingState.pillsPage - 1) * PILLS_LIMITS.REQUEST_LIMIT + PILLS_LIMITS.MIN,
        };
      });
      updateSharingState("selectedUsersCount", data.totalCount);
      updateSharingState("selectedUsers", users);
    } catch (e: any) {
      console.error(e.message);
    }
  };

  useEffect(() => {
    const isFetchingDataRestricted =
      (analysisFailed && fileUploadFailed && (isUserAdmin || isUserAnalystInHisAnalysis)) ||
      analysisNotAnalyzed ||
      (!isUserAnalystInHisAnalysis && !isAnyAdmin) ||
      isUserViewer ||
      loadingAnalysisDetails;

    setIsFetchingDataRestricted(isFetchingDataRestricted);
  }, [
    analysisFailed,
    fileUploadFailed,
    isUserAdmin,
    isUserAnalystInHisAnalysis,
    isUserViewer,
    loadingAnalysisDetails,
    analysisNotAnalyzed,
  ]);

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const shareBy =
      sharingState.shareByDropdownOptions.find((o) => params.get("view")?.includes(o.value)) ||
      sharingState.shareByDropdownOptions.find((o) => o.value === ShareByOption.Users);

    updateSharingState("shareBy", shareBy);
  }, []); // eslint-disable-line

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const step = queryParams.get("step");
    const view = queryParams.get("view");

    // Here we update step and view based on query params
    // We check if step and view are valid then if state and query params match
    // and at the end we set the default values.

    //View logic Bloc
    if (
      view &&
      Object.values(SharingView).includes(view as SharingView) &&
      view === sharingState.view
    ) {
      updateSharingState("view", view as SharingView);
    } else if (view && view !== sharingState.view) {
      updateSharingState("view", view as SharingView);
    } else if (sharingState.shareBy.value === ShareByOption.Groups) {
      queryParams.set("view", SharingView.GroupsSharedWith);
      updateSharingState("view", SharingView.GroupsSharedWith);
    } else {
      queryParams.set("view", SharingView.UsersSharedWith);
      updateSharingState("view", SharingView.UsersSharedWith);
    }

    //Step logic Bloc
    if (
      step &&
      Object.values(ShareStep).includes(step as ShareStep) &&
      step === sharingState.step
    ) {
      updateSharingState("step", step as ShareStep);
    } else if (step && step !== sharingState.step) {
      updateSharingState("step", step as ShareStep);
    } else if (view === SharingView.ShareToUsers || view === SharingView.ShareToGroups) {
      queryParams.set("step", ShareStep.UserSelection);
      updateSharingState("step", ShareStep.UserSelection);
    }

    //Apply changes to url
    history.push(`${window.location.pathname}?${queryParams.toString()}`);
  }, [sharingState.step, sharingState.view, view]); // eslint-disable-line

  useEffect(() => {
    if (
      sharingState.view === SharingView.ShareToUsers ||
      sharingState.view === SharingView.ShareToGroups
    ) {
      updateSelectedUsers();
    }
  }, [sharingState.pillsPage]); // eslint-disable-line

  // Set up state with live data
  useEffect(() => {
    if (isFetchingDataRestricted) {
      return;
    }
    getRecipientsGroups({ analysisId })
      .then(({ data }) => {
        updateSharingState("groupsApiData", {
          ...data,
          groups: data.groups.map((g) => ({ ...g, userCount: parseInt(g.userCount) })),
        });
      })
      .catch((e) => console.error(e.message))
      .finally(() => updateSharingState("isGroupsApiDataLoading", false));
    getRecipientsUsers({ analysisId })
      .then(({ data }) => {
        updateSharingState("sharedUsersApiData", {
          ...data,
          users: getSharedUsersFromApiData(data.users),
        });
      })
      .catch((e) => console.error(e.message))
      .finally(() => updateSharingState("isSharedUsersApiDataLoading", false));
    getShareableUsers({ analysisId })
      .then(({ data }) => updateSharingState("shareableUsersApiData", data))
      .catch((e) => console.error(e.message));
  }, [analysisId, isFetchingDataRestricted]); // eslint-disable-line

  useEffect(() => {
    getDemographicsByAnalysisId({ analysisId })
      .then(({ data }) =>
        updateSharingState("analysisDemographicHeaders", data.analysis.demographics)
      )
      .catch((e) => console.error(e.message));
  }, [analysisId, isFetchingDataRestricted]); // eslint-disable-line

  /* eslint-disable */
  useEffect(() => {
    if (isFetchingDataRestricted) {
      return;
    }
    if (!sharingState.view || !state.availableDemographicFilters) return;
    getSharingDraft(analysisId, getSharingMethodFromView(sharingState.view))
      .then(({ data }) => {
        setSharingState((prevState) => ({
          ...prevState,
          permissionFields: {
            ...prevState.permissionFields,
            groupName: data.groupName ?? getResource("sharing.groupName.default"),
            ...(data.permissionLevel && { permissionLevel: data.permissionLevel }),
            commentExplorerAccess: data.commentExplorerAccess,
            topicExplorerAccess: data.topicExplorerAccess,
            dashboardAccess: data.dashboardAccess,
            defaultWidgets: data.defaultWidgets,
            allowExportData: data.allowExport,
            ...(state.availableDemographicFilters.length > 0 && {
              visibleDemographicsFilters: data.demographicsToDisplay.map((d) =>
                getVisibleDemFiltersFromData(d, state.availableDemographicFilters)
              ),
              visibleCommentsByDemographicValues: data.demographicFilters
                .map((df) => getVisibleCommentDemsFromData(df, state.availableDemographicFilters))
                .filter(Boolean),
            }),
            customConditions: data.customFilters,
          },
        }));
      })
      .catch((err) => console.error(err));
  }, [
    analysisId,
    sharingState.view,
    state.availableDemographicFilters.length,
    isFetchingDataRestricted,
  ]);

  /* eslint-enable */

  // Update sharing draft API call when we pass "save" option to `updateSharingState`
  useEffect(() => {
    if (isFetchingDataRestricted || !sharingState.permissionFields.groupName) {
      return;
    }
    if (pendingUpdateSharingDraftApiCall) {
      const requestBody = {
        method: getSharingMethodFromView(sharingState.view),
        groupName: sharingState.permissionFields.groupName,
        permissionLevel: sharingState.permissionFields.permissionLevel,
        demographicFilters: sharingState.permissionFields.visibleCommentsByDemographicValues.map(
          (d) => getVisibleCommentDemsForFetch(d)
        ),
        customFilters: sharingState.permissionFields.customConditions.filter(
          (cc) => cc.analysisDemographic.length > 0 && cc.userDemographic.length > 0
        ),
        demographicsToDisplay: sharingState.permissionFields.visibleDemographicsFilters.map(
          (cd) => cd.name
        ),
        allowExport: sharingState.permissionFields.allowExportData,
        overviewAccess: SharingOverviewAccess.Shared,
        defaultWidgets: sharingState.permissionFields.defaultWidgets || DefaultWidgets.None,
        dashboardAccess: sharingState.permissionFields.dashboardAccess,
        topicExplorerAccess: sharingState.permissionFields.topicExplorerAccess,
        commentExplorerAccess: sharingState.permissionFields.commentExplorerAccess,
        definition: undefined, // TODO: implement definitions?
      };

      updateSharingDraft({ analysisId, ...requestBody })
        .then(() => setPendingUpdateSharingDraftApiCall(false))
        .catch((err) => console.error(err));
    }
  }, [sharingState, pendingUpdateSharingDraftApiCall, analysisId, isFetchingDataRestricted]);

  useEffect(() => {
    getAllUserDemographics()
      .then(({ data }) => {
        updateSharingState(
          "allUserDemographics",
          data.userDemographics
            .filter((d) => d.values !== null)
            .map((d) => {
              const typedData = typeDataToUserDemographics(d);
              return {
                ...typedData,
                values: typedData.values.map((val) =>
                  val === "sharing.userDemographics.nullValue" ? getResource(val) : val
                ),
              };
            })
        );
      })
      .catch((err) => console.error(err.message));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (isFetchingDataRestricted) return;
    getSharableUsers();
  }, [isFetchingDataRestricted]); // eslint-disable-line

  // refetch data functions
  const refetchSharedGroupsData = async (params: GetRecipientsGroupsParams) => {
    updateSharingState("isGroupsApiDataLoading", true);
    try {
      const { data } = await getRecipientsGroups(params);
      updateSharingState("groupsApiData", {
        ...data,
        groups: data.groups.map((g) => ({ ...g, userCount: parseInt(g.userCount) })),
      });
    } catch (e: any) {
      console.error(e.message);
    } finally {
      updateSharingState("isGroupsApiDataLoading", false);
    }
  };

  const refetchSharedUsersData = async (params: GetRecipientsUsersParams) => {
    updateSharingState("isSharedUsersApiDataLoading", true);
    try {
      const { data } = await getRecipientsUsers(params);
      updateSharingState("sharedUsersApiData", {
        totalCount: data.totalCount,
        itemCount: data.itemCount,
        users: getSharedUsersFromApiData(data.users),
      });
    } catch (e: any) {
      console.error(e.message);
    } finally {
      updateSharingState("isSharedUsersApiDataLoading", false);
    }
  };

  const refetchSharingUsersData = async (
    params: GetShareableUsersParams,
    demographics: SharingUserDemographic[]
  ) => {
    try {
      const { data } = await getShareableUsers(params, demographics);
      updateSharingState("shareableUsersApiData", {
        itemCount: data.itemCount,
        totalCount: data.totalCount,
        users: data.users.map((u) => ({
          ...u,
          isSelected: u.shareStatus === ShareStatus.InDraft,
        })),
      });
    } catch (e: any) {
      console.error(e.message);
    }
  };

  return (
    <SharingContext.Provider
      value={{
        sharingState,
        updateSharingState,
        refetchSharedGroupsData,
        refetchSharedUsersData,
        refetchSharingUsersData,
        handleUserSharingDraft,
        removeAllUsersFromDraft,
        validateUsersShown,
        updateSelectedUsers,
      }}
    >
      {children}
    </SharingContext.Provider>
  );
};

export const useSharingContext = (): SharingContextReturnType => useContext(SharingContext);
