import { mlyApi, getHeaders } from "config/mlyApi";
import { NotificationReadStatus } from "@explorance/mly-types";

export async function getNotifications() {
  const requestConfig = await getHeaders();
  const url = "/notifications";
  return mlyApi.get(url, requestConfig);
}

export async function updateNotificationStatus(
  notificationIds: Array<number>,
  status: NotificationReadStatus
) {
  const requestConfig = await getHeaders();
  const url = "/notifications";
  const requestBody = { notificationIds, status };
  return mlyApi.put(url, requestBody, requestConfig);
}

export async function deleteNotifications(notificationIds: number[]) {
  const requestConfig = await getHeaders();
  const notificationParams = notificationIds.join("&notificationIds=");
  const url = `notifications?notificationIds=${notificationParams}`;
  return mlyApi.delete(url, requestConfig);
}
