import React from "react";
import styled, { css } from "styled-components";
import { Color } from "ts/enums/color";

type Props = {
  width: number;
  height: number;
  responsive?: boolean;
  backgroundColor?: string;
  className?: string;
  borderRadius?: string;
  full?: boolean;
  customStyles?: Record<string, any>;
};

export const Skeleton = (props: Props) => {
  return <StyledSkeleton style={props.customStyles} {...props} />;
};

type StyleProps = {
  width: number;
  height: number;
  responsive?: boolean;
  backgroundColor?: string;
  borderRadius?: string;
  full?: boolean;
};

const StyledSkeleton = styled.div<StyleProps>`
  display: inline-block;
  border-radius: ${({ borderRadius }) => borderRadius ?? "2px"};
  background: ${({ backgroundColor }) => backgroundColor ?? Color.sky20};
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;

  @media (min-width: 960px) {
    width: ${({ responsive, width }) => (responsive ? width * 0.7 : width)}px;
  }

  @media (min-width: 1080px) {
    width: ${({ responsive, width }) => (responsive ? width * 0.78 : width)}px;
  }

  @media (min-width: 1366px) {
    width: ${({ responsive, width }) => (responsive ? width * 1 : width)}px;
  }

  @media (min-width: 1920px) {
    width: ${({ responsive, width }) => (responsive ? width * 1.2 : width)}px;
  }
  ${({ full }) =>
    full &&
    css`
      width: 100% !important;
    `}
`;
