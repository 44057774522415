import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";

import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Notification, NotificationType, TaskStatus } from "@explorance/mly-types";
import { routes } from "routes";
import { Color } from "ts/enums/color";

import { Text } from "components/Text";

type Props = {
  notification: Notification;
  notificationCenterCloseHandler: () => void;
  retryImportFunc?: () => void;
  retryExportFunc?: () => void;
  downloadExportFunc?: () => void;
};

export const ActionButtonContainer = ({
  notification,
  notificationCenterCloseHandler,
  retryImportFunc,
  retryExportFunc,
  downloadExportFunc,
}: Props) => {
  // hooks
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const history = useHistory();

  // variables
  const exportSuccess =
    notification.type === NotificationType.Export &&
    notification.message.status === TaskStatus.Success;
  const exportFailure =
    notification.type === NotificationType.Export &&
    notification.message.status === TaskStatus.Failure;
  const importSuccess =
    notification.type === NotificationType.Import &&
    notification.message.status === TaskStatus.Success;
  const importFailure =
    notification.type === NotificationType.Import &&
    notification.message.status === TaskStatus.Failure;
  const analysisSuccess =
    notification.type === NotificationType.Analysis &&
    notification.message.status === TaskStatus.Success;
  const analysisFailure =
    notification.type === NotificationType.Analysis &&
    notification.message.status === TaskStatus.Failure;
  const successfulTask = notification.message.status === TaskStatus.Success;

  // functions
  const getButtonText = (): React.JSX.Element => {
    if (importSuccess || importFailure) {
      return <Text resource={`notificationCard.button.import${notification.message.status}`} />;
    }
    if (analysisSuccess || analysisFailure) {
      return <Text resource={`notificationCard.button.analysis${notification.message.status}`} />;
    }
    if (exportSuccess || exportFailure) {
      return <Text resource={`notificationCard.button.export${notification.message.status}`} />;
    }
  };

  const handleClick = () => {
    notification.message.status === TaskStatus.Failure && setIsDisabled(true);
    notificationCenterCloseHandler();
    if (importSuccess) return history.push(routes.dataPreviewPage(notification.message.analysisId));
    if (importFailure) return retryImportFunc();
    if (analysisSuccess) return history.push(routes.overviewPage(notification.message.analysisId));
    if (analysisFailure) return history.push(routes.settingsPage(notification.message.analysisId));
    if (exportSuccess) return history.push(routes.downloadedFilesPage);
    if (exportFailure) return retryExportFunc();
  };

  if (!getButtonText()) return null;

  return (
    <StyledActionButtonContainer>
      <Button
        size={ButtonSize.sm}
        variant={ButtonVariant.outlineBlue}
        onClick={handleClick}
        disabled={isDisabled}
      >
        <StyledButtonContentWrapper>
          {getButtonText()}
          {successfulTask && (
            <Icon
              type={IconType.chevronRight}
              color={Color.blue50}
              size={14}
              style={{ marginTop: "2.5px" }}
            />
          )}
        </StyledButtonContentWrapper>
      </Button>
      {exportSuccess && (
        <Button size={ButtonSize.sm} variant={ButtonVariant.primary} onClick={downloadExportFunc}>
          <Text resource="notificationCard.button.download" />
        </Button>
      )}
    </StyledActionButtonContainer>
  );
};

const StyledActionButtonContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const StyledButtonContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
