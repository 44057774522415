import { VariableMappingGroupItem, BackendSingleVariableMapping } from "ts/variableMappings";

export const generateEmptyRows = (nbrRows: number): VariableMappingGroupItem[] => {
  const tbEmptyMapping: VariableMappingGroupItem[] = [];
  for (let i = 0; i < nbrRows; i++) {
    tbEmptyMapping[i] = { variable: "", definition: "" };
  }
  return tbEmptyMapping;
};

export const generateMappingRows = (mappingItems: VariableMappingGroupItem[]) => {
  const emptyMappings =
    mappingItems.length > 0 ? generateEmptyRows(10 - mappingItems.length) : generateEmptyRows(10);
  return [...mappingItems, ...emptyMappings];
};

export const undoShortcutEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.key === "z" && (e.ctrlKey || e.metaKey)) {
    e.preventDefault();
  }
};

export const checkIfMappingDuplicates = (mappings: VariableMappingGroupItem[]): boolean => {
  if (mappings) {
    const variableArray: string[] = mappings
      .map((m) => m.variable.toLocaleLowerCase())
      .filter((m) => m.trim().length > 0);
    const duplicateTestObj = {};

    for (const el of variableArray) {
      if (duplicateTestObj[el] === 1) return true;
      duplicateTestObj[el] = 1;
    }
  }
  return false;
};

export const checkIfEmptyInput = (mappings: VariableMappingGroupItem[]): boolean => {
  if (mappings) {
    const errorArray = mappings.filter((m) => {
      const variable = m.variable;
      const definition = m.definition;
      return (
        (variable.length > 0 && definition.length === 0) ||
        (variable.length === 0 && definition.length > 0)
      );
    });
    return errorArray.length > 0;
  }
  return false;
};

export const prepareVariableMappingsForBackend = (
  mappings: VariableMappingGroupItem[]
): BackendSingleVariableMapping[] => {
  if (!mappings || mappings.length === 0) return [];

  const newMappingArray: BackendSingleVariableMapping[] = [...mappings]
    .filter((m) => m.variable !== "" && m.definition !== "")
    .map((m: BackendSingleVariableMapping) => (m = { [m.variable.trim()]: m.definition.trim() }));
  return newMappingArray;
};
