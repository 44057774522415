import React from "react";
import { SentimentType } from "@explorance/mly-types";
import { Icon, IconType } from "components/_icons/Icon";
import { Color } from "ts/enums/color";

type Props = {
  type: SentimentType;
  size?: number;
  style?: Record<string, any>;
  bnw?: boolean;
};

export const SentimentIcon = ({ type, size = 16, style, bnw = false }: Props) => {
  switch (type) {
    case SentimentType.positive:
      return (
        <Icon
          type={IconType.positive}
          size={size}
          secondaryColor={bnw ? Color.white : Color.green30}
          color={bnw ? Color.gray50 : Color.green50}
          style={style}
        />
      );
    case SentimentType.negative:
      return (
        <Icon
          type={IconType.negative}
          size={size}
          secondaryColor={bnw ? Color.white : Color.red10}
          color={bnw ? Color.gray50 : Color.red40}
          style={style}
        />
      );
    case SentimentType.neutral:
      return (
        <Icon
          type={IconType.neutral}
          size={size}
          secondaryColor={bnw ? Color.white : Color.sky30}
          color={bnw ? Color.gray50 : Color.blue50}
          style={style}
        />
      );
    case SentimentType.notExplicit:
      return (
        <Icon
          type={IconType.neutral}
          size={size}
          secondaryColor={bnw ? Color.white : Color.orange20}
          color={bnw ? Color.gray50 : Color.orange50}
          style={style}
        />
      );
    case SentimentType.mixed:
      return (
        <Icon
          type={IconType.mixed}
          size={size}
          secondaryColor={bnw ? Color.white : Color.blue20}
          color={bnw ? Color.gray50 : Color.indigo45}
          style={style}
        />
      );
    default:
      return null;
  }
};
