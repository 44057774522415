import { WidgetType, WidgetStat } from "@explorance/mly-types";

const statsByWidgetType = [
  {
    type: WidgetType.MostPopular,
    stats: [
      { type: WidgetStat.CommentCount, isMandatory: true, isDefault: true },
      { type: WidgetStat.AlertCount, isMandatory: false, isDefault: true },
      { type: WidgetStat.RecommendationCount, isMandatory: false, isDefault: false },
      { type: WidgetStat.DominantSentiment, isMandatory: false, isDefault: true },
    ],
  },
  {
    type: WidgetType.MostPositive,
    stats: [
      { type: WidgetStat.DominantSentiment, isMandatory: true, isDefault: true },
      { type: WidgetStat.AlertCount, isMandatory: false, isDefault: true },
      { type: WidgetStat.RecommendationCount, isMandatory: false, isDefault: false },
      { type: WidgetStat.CommentCount, isMandatory: false, isDefault: true },
    ],
  },
  {
    type: WidgetType.WhatToImprove,
    stats: [
      { type: WidgetStat.DominantSentiment, isMandatory: true, isDefault: true },
      { type: WidgetStat.AlertCount, isMandatory: false, isDefault: true },
      { type: WidgetStat.RecommendationCount, isMandatory: false, isDefault: false },
      { type: WidgetStat.CommentCount, isMandatory: false, isDefault: true },
    ],
  },
  {
    type: WidgetType.MostAlerts,
    stats: [
      { type: WidgetStat.AlertCount, isMandatory: true, isDefault: true },
      { type: WidgetStat.CommentCount, isMandatory: false, isDefault: true },
      { type: WidgetStat.RecommendationCount, isMandatory: false, isDefault: true },
    ],
  },
  {
    type: WidgetType.PrioritizedRecommendations,
    stats: [
      { type: WidgetStat.RecommendationCount, isMandatory: true, isDefault: true },
      { type: WidgetStat.AlertCount, isMandatory: false, isDefault: true },
      { type: WidgetStat.CommentCount, isMandatory: false, isDefault: true },
      { type: WidgetStat.DominantSentiment, isMandatory: false, isDefault: true },
    ],
  },
  {
    type: WidgetType.AllRecommendations,
    stats: [
      { type: WidgetStat.RecommendationCount, isMandatory: true, isDefault: true },
      { type: WidgetStat.AlertCount, isMandatory: false, isDefault: true },
      { type: WidgetStat.CommentCount, isMandatory: false, isDefault: true },
      { type: WidgetStat.DominantSentiment, isMandatory: false, isDefault: true },
    ],
  },
  {
    type: WidgetType.CategorizedRecommendations,
    stats: [
      { type: WidgetStat.RecommendationCount, isMandatory: true, isDefault: true },
      { type: WidgetStat.AlertCount, isMandatory: false, isDefault: true },
      { type: WidgetStat.CommentCount, isMandatory: false, isDefault: true },
      { type: WidgetStat.DominantSentiment, isMandatory: false, isDefault: false },
    ],
  },
  {
    type: WidgetType.AllSentiments,
    stats: [
      { type: WidgetStat.CommentCount, isMandatory: true, isDefault: true },
      { type: WidgetStat.AlertCount, isMandatory: false, isDefault: true },
      { type: WidgetStat.RecommendationCount, isMandatory: false, isDefault: true },
      { type: WidgetStat.DominantSentiment, isMandatory: false, isDefault: true },
    ],
  },
];

export const getStatsForWidgetType = (type: WidgetType, initialStats, isCreate) => {
  const stats = statsByWidgetType.find((x) => x.type === type).stats;
  if (isCreate) return stats;

  const adjustedStatsByExistingWidget = stats.map((stat) => {
    const existingStat = initialStats.find((x) => x === stat.type);
    return { ...stat, isDefault: !!existingStat };
  });

  return adjustedStatsByExistingWidget;
};
