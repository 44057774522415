export enum Color {
  // BNW
  black = "#031722",
  white = "#ffffff",
  // neutral
  neutral50 = "#bcc4cc",
  neutral40 = "#b8b8bc",
  neutral30 = "#f2f2f3",
  neutral20 = "#f7f7f7",
  neutral10 = "#f4f6fa",
  // gray
  gray70 = "#1a1c26",
  gray50 = "#35384d",
  gray40 = "#515c72",
  gray30 = "#838da3",
  gray20 = "#a7a9bf",
  // blue
  blue90 = "#253048",
  blue55 = "#094da9",
  blue50 = "#0b5ac4",
  blue45 = "#006edb",
  blue40 = "#006eff",
  blue20 = "#dce1f1",
  // sky
  sky60 = "#cfd9eb",
  sky50 = "#d5dfe9",
  sky40 = "#e1ebf5",
  sky30 = "#edf5ff",
  sky20 = "#f1f3f9",
  sky15 = "#f8fafe",
  sky10 = "#fbfcfd",
  // indigo
  indigo60 = "#3a1eb5",
  indigo50 = "#4230e5",
  indigo45 = "#5454d5",
  indigo40 = "#b8b5dd",
  indigo30 = "#d0d0ff",
  indigo20 = "#deddee",
  indigo10 = "#f2f2f8",
  // red
  red55 = "#ae251f",
  red50 = "#be0611",
  red40 = "#c14d4d",
  red30 = "#ef9fa1",
  red20 = "#ffd8d8",
  red10 = "#fff2f2",
  // orange
  orange60 = "#ea7e00",
  orange50 = "#e8ac58",
  orange40 = "#ffbe00",
  orange30 = "#f8dc9a",
  orange20 = "#fff4e5",
  orange10 = "#fcf7f3",
  // green
  green50 = "#0d8d16",
  green40 = "#c3e9c3",
  green30 = "#e3ffee",
  green10 = "#f1f5f2",
  //light green
  lightGreen50 = "#3FA586",
  lightGreen40 = "#C0E9D8",
  lightGreen30 = "#E3F9F2",
  lightGreen20 = "#F1FAF7",
  lightGreen10 = "#EAFBF5",
  // shadow
  shadow20 = "#35384d33",
}
