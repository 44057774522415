import { mlyApi, getHeaders } from "config/mlyApi";

import { getUrlQueryString } from "utils/getUrlQueryString";

import { CategorizationType, SortingOrder } from "@explorance/mly-types";
import { CustomModelListSortingParameterForBackendMap } from "ts/enums/sorting";

type RequestBodyTopic = {
  id: number;
  parentId: number;
  name: string;
  mappings: Record<string, string>;
  parentName: string;
};

export type SaveModelValues = {
  name: string;
  graphId: number;
  topics: RequestBodyTopic[];
};

export type CustomModelParameters = {
  modelFilters?: CategorizationType[];
  searchField?: string;
  pageNumber?: number;
  limit?: number;
  sortingParameter?: CustomModelListSortingParameterForBackendMap;
  sortingOrder?: SortingOrder;
};

export async function getCustomModels(parameters: CustomModelParameters) {
  const requestConfig = await getHeaders();
  const url =
    "/models/customs" +
    getUrlQueryString({
      models: parameters.modelFilters?.join(","),
      search: parameters.searchField,
      page: parameters.pageNumber,
      limit: parameters.limit,
      sort_column: parameters.sortingParameter,
      sort_order: parameters.sortingOrder,
    });
  return mlyApi.get(url, requestConfig);
}

export async function getCustomModel(id: string) {
  const requestConfig = await getHeaders();
  const url = `/models/customs/${id}`;

  return mlyApi.get(url, requestConfig);
}

export async function createCustomModel(requestBody: SaveModelValues) {
  const requestConfig = await getHeaders();
  const url = "/models/customs";

  return mlyApi.post(url, requestBody, requestConfig);
}

export async function updateCustomModel(id: string, requestBody: SaveModelValues) {
  const requestConfig = await getHeaders();
  const url = `/models/customs/${id}`;

  return mlyApi.put(url, requestBody, requestConfig);
}

export async function deleteCustomModel(id: number) {
  const requestConfig = await getHeaders();
  const url = `/models/customs/${id}`;

  return mlyApi.delete(url, requestConfig);
}
