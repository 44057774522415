import React, { useContext } from "react";

import { DemographicsMenu } from "./DemographicsMenu";

import { setSelectedDemographicFilters } from "context/AnalysisContext/actions";
import { AnalysisContext } from "context/AnalysisContext";
import { removeEmptyDemographicFilters } from "utils/filters";

type Props = {
  demographicFilterId: number;
  resetLocalState: boolean;
  onSubmit: () => void;
};

export const DemographicsMenuContainer = ({
  demographicFilterId,
  resetLocalState,
  onSubmit,
}: Props) => {
  const [state, dispatch] = useContext(AnalysisContext);

  return (
    <DemographicsMenu
      selectedDemographicId={demographicFilterId}
      selectedDemographics={state.selectedDemographicFilters}
      resetLocalState={resetLocalState}
      onSubmit={onSubmit}
      updateFilters={(updatedFilters) => {
        dispatch(setSelectedDemographicFilters(removeEmptyDemographicFilters(updatedFilters)));
      }}
    />
  );
};
