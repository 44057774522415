import React from "react";
import styled from "styled-components";

import { Text } from "components/Text";

type Props = {
  rowLabel: React.JSX.Element;
  countLabel: string;
  count: string;
};

export const BlockRow = ({ rowLabel, countLabel, count }: Props) => {
  return (
    <StyledBlockRow>
      {rowLabel}
      <StyledContentContainer>
        <Text resource={{ key: countLabel, args: [`<b>${count}</b>`] }} />
      </StyledContentContainer>
    </StyledBlockRow>
  );
};

const StyledBlockRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 11px;
`;

const StyledContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
