import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import useClickOutside from "hooks/useClickOutside";
import { useResource } from "hooks/useResource";
import { useHistory } from "react-router-dom";
import { routes } from "routes";
import { getUrlQueryString } from "utils/getUrlQueryString";
import { getModels } from "services/models";

import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { Model, ModelSelectionDropdownData, ModelSelectionOptions } from "ts/models";
import { ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";

import { Button } from "components/_buttons/Button";
import { ModelSelection } from "components/ModelSelection";
import { getModelSelectionDropdownData } from "components/ModelSelection/helpers";
import { StyledDropdownTransition } from "components/DropdownMenu/StyledDropdownTransition";
import { getAvailableModelOptions } from "utils/getModelsByEnv";
import { getUniqueEnvs } from "utils/getUniqueEnvs";
import { Select } from "components/_inputs/Select";
import { Text } from "components/Text";

export const CreateNewModel = () => {
  const modelNameInputRef = useRef<HTMLInputElement>();
  const dropdownRef = useClickOutside(() => setShowDropdown(false));
  const { push } = useHistory();
  const { getResource } = useResource();

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [selectedModel, setSelectedModel] = useState<Model>();
  const [envDropdownOptions, setEnvDropdownOptions] = useState<string[]>([]);
  const [selectedEnv, setSelectedEnv] = useState<string>("");
  const [isTemplateChecked, setIsTemplateChecked] = useState<boolean>(false);
  const [nameInput, setNameInput] = useState<string>(
    getResource("customModels.createNewModel.name.placeholder")
  );
  const [modelOptions, setModelOptions] = useState<ModelSelectionOptions>();
  const [modelOptionsDropdownData, setModelOptionsDropdownData] =
    useState<ModelSelectionDropdownData>();

  useEffect(() => {
    getModels().then(({ data }) => {
      const modelOptionsDropdownData = getModelSelectionDropdownData(data);
      setModelOptionsDropdownData(modelOptionsDropdownData);

      const uniqueEnv = getUniqueEnvs(modelOptionsDropdownData.options);
      setEnvDropdownOptions(uniqueEnv);
      setSelectedEnv(uniqueEnv[0]);

      const availableModels = getAvailableModelOptions(modelOptionsDropdownData, uniqueEnv[0]);
      setModelOptions(availableModels);
      setSelectedModel(Object.values(availableModels.models)[0][0]);
    });
  }, []);

  useEffect(() => {
    if (selectedEnv) {
      const availableModels = getAvailableModelOptions(modelOptionsDropdownData, selectedEnv);
      setModelOptions(availableModels);
      setSelectedModel(Object.values(availableModels.models)[0][0]);
    }
  }, [selectedEnv]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showDropdown) modelNameInputRef.current.focus();
  }, [showDropdown]);

  const handleClickCreate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    push(
      routes.newCustomModelPage +
        getUrlQueryString({
          name: nameInput,
          isTemplate: isTemplateChecked,
          modelId: selectedModel.graphId,
        })
    );
  };

  if (!modelOptions) return null;

  return (
    <StyledCreateNewModel ref={dropdownRef}>
      <Button onClick={() => setShowDropdown(!showDropdown)}>
        <Text resource="button.createNewCustomModel" />
      </Button>

      <StyledCreateNewModelDropdown show={showDropdown}>
        <StyledLabel>
          <Text resource="customModels.createNewModel.name.label" />:
        </StyledLabel>
        <StyledCustomModelInput
          value={nameInput}
          onChange={(e) => setNameInput(e.target.value)}
          onFocus={(e) => e.target.select()}
          ref={modelNameInputRef}
          maxLength={100}
        />
        {envDropdownOptions.length > 1 && (
          <>
            <StyledLabel>
              <Text resource="customModels.createNewModel.env.label" />
            </StyledLabel>
            <Select
              options={envDropdownOptions.map((env) => ({ label: env, value: env }))}
              selectedOption={{ label: selectedEnv, value: selectedEnv }}
              handleChange={(arg: string) => setSelectedEnv(arg)}
              dropdownWidth="400px"
              buttonStyle={{
                width: "400px",
                marginBottom: "15px",
                cursor: envDropdownOptions.length === 1 ? "default" : "pointer",
                height: "34px",
              }}
              dropdownPosition={{ top: 35 }}
              buttonVariant={ButtonVariant.neutral}
              labelStyle={{ fontWeight: "normal", color: Color.gray50 }}
            />
          </>
        )}
        <StyledLabel>
          <Text resource="customModels.createNewModel.baseModel.label" />
        </StyledLabel>
        {modelOptions && (
          <ModelSelection
            selectedModel={selectedModel}
            options={modelOptions}
            hideDefaultOption={true}
            buttonStyling={{ borderRadius: 2, width: 400, height: 34 }}
            handleSelectModel={setSelectedModel}
          />
        )}

        <StyledCTASection>
          <StyledCheckboxSection>
            <input
              type="checkbox"
              id="template-checkbox"
              checked={isTemplateChecked}
              onChange={() => setIsTemplateChecked(!isTemplateChecked)}
            />
            <label htmlFor="template-checkbox">
              <Text resource="customModels.createNewModel.selectAsTemplate.label" />
              {""}
            </label>
          </StyledCheckboxSection>
          <Button variant={ButtonVariant.primaryPurple} onClick={handleClickCreate}>
            <Text resource="button.create" />
          </Button>
        </StyledCTASection>
      </StyledCreateNewModelDropdown>
    </StyledCreateNewModel>
  );
};

const StyledCheckboxSection = styled.div`
  display: flex;
  align-items: center;

  input {
    height: 14px;
    width: 14px;
    cursor: pointer;

    &:checked {
      accent-color: ${Color.blue50};
    }
  }

  label {
    color: ${Color.black};
    font: normal normal normal 14px/31px Lato;
    margin-left: 4px;
    cursor: pointer;
    user-select: none;
  }
`;

const StyledLabel = styled.label`
  display: block;
  color: ${Color.gray30};
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
`;

const StyledCustomModelInput = styled.input`
  padding: 12px;
  display: block;
  width: 100%;
  height: 34px;
  font: normal normal normal 13px/7px Lato;
  border: 1px solid ${Color.sky50};
  border-radius: 2px;
  color: ${Color.gray40};
  margin-bottom: 15px;
  outline: none;

  :focus {
    border: 1px solid ${Color.blue50};
  }
`;

const StyledCreateNewModel = styled.div`
  position: relative;
`;

const StyledCTASection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

const StyledCreateNewModelDropdown = styled(StyledDropdownTransition)<{ show: boolean }>`
  z-index: ${ZIndexStackingContext.low};
  position: absolute;
  right: 0;
  top: calc(100% + 3px);
  background-color: ${Color.white};
  box-shadow: 0px 3px 6px #00000029;
  padding: 16px;
  border-radius: 5px;
  width: 432px;
`;
