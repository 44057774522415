import { ModelSelectionOptions } from "ts/models";

export const getUniqueEnvs = (options: ModelSelectionOptions): string[] => {
  const uniqueEnv = new Set<string>();
  Object.values(options.models).forEach((model) => {
    model.forEach((subModel) => {
      subModel.env && uniqueEnv.add(subModel.env);
    });
  });

  return Array.from(uniqueEnv.values());
};
