import { getDominantSentimentForTopic } from "utils/topics";
import { SentimentType, RecommendationType } from "@explorance/mly-types";

export type DominantSentiment = {
  type: SentimentType;
  rate?: number;
};

export type Topic = {
  id: number;
  parentId: number | null;
  name: string;
  fullPath: string;
  comments: number;
  topics: Topic[] | null;
  // sentiments view
  insights: number;
  positive: number;
  negative: number;
  neutral: number;
  notExplicit: number;
  mixed: number;
  dominantSentiment: DominantSentiment;
  // recommendations view
  recommendations: number;
  doMore: number;
  doLess: number;
  start: number;
  stop: number;
  continue: number;
  change: number;
};

export type TopicModalData = {
  id: number;
  fullPath: string;
  dominantSentiment: SentimentType | RecommendationType;
  comments?: number;
  parentId?: number | null;
  name?: string;
  topics?: Topic[] | null;
  insights?: number;
  positive?: number;
  negative?: number;
  neutral?: number;
  notExplicit?: number;
  mixed?: number;
  recommendations?: number;
  doMore?: number;
  doLess?: number;
  start?: number;
  stop?: number;
  continue?: number;
  change?: number;
};

export type TopicTreeNode = {
  id: number;
  name: string;
  fullPath: string;
  parentId: number;
  topics: TopicTreeNode[];
  isSelected?: boolean;
  isHidden?: boolean;
};

export type ApiTopicNode = {
  id: number;
  parentId: number | null;
  fullPath?: string;
};

export type TopicAttributeCount = {
  count: number;
  attribute: SentimentType | RecommendationType;
  isDominantSentiment?: boolean;
};

export const mapDataToTopic = (topic: any): Topic => ({
  ...topic,
  dominantSentiment: getDominantSentimentForTopic(topic),
});
