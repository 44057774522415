import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "store";

import { FlexTable, FlexTableSorting } from "components/FlexTable";
import { PermissionLevelCell } from "pages/analysis/[id]/sharing/_layouts/PermissionLevelCell";
import { EmptyState } from "components/EmptyState";
import { Header } from "./Header";
import { EditSharingBlock } from "./EditSharingBlock";

import { useResource } from "hooks/useResource";
import { useSharingContext } from "context/SharingContext";
import { formatSearchForFetchRequest } from "utils/formatters";
import { GetRecipientsGroupsParams, unshare } from "services/analysis/sharing";

import { Color } from "ts/enums/color";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { ShareStep, SharingView } from "ts/enums/sharing";
import { GroupSharingSortingParameter, SortingOrder } from "@explorance/mly-types";
import { useQueryParams } from "hooks/useQueryParams";
import { GroupNameCell } from "./FlexTableCells/GroupNamePill";
import { routes } from "routes";
import { getUrlQueryString } from "utils/getUrlQueryString";
import { DEFAULT_LIST_LIMIT } from "assets/constants/listLimits";
import { FlexTableType } from "ts/enums/flexTable";
import { setSearchTerm } from "store/search/searchbarSlice";

const GROUPS_SHARED_WITH_HEADERS = [
  { columnNameKey: "groupName", sortingParameter: GroupSharingSortingParameter.GroupName },
  { columnNameKey: "usersInGroup", sortingParameter: GroupSharingSortingParameter.UserCount },
  {
    columnNameKey: "permissionLevel",
    sortingParameter: GroupSharingSortingParameter.PermissionLevel,
  },
];

type RefetchParams = {
  updatedPage?: number;
  order?: SortingOrder;
  column?: GroupSharingSortingParameter;
};

// Main component
export const GroupsSharedWith = () => {
  const searchTerm = useAppSelector((state) => state.search.searchTerm);
  const dispatch = useAppDispatch();
  const [sortingColumn, setSortingColumn] = useState<GroupSharingSortingParameter>(
    GroupSharingSortingParameter.GroupName
  );
  const [sortOrder, setSortOrder] = useState<SortingOrder>(SortingOrder.DESC);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [expandedRowId, setExpandedRowId] = useState<number>(null);
  const [returningFromPreview, setReturningFromPreview] = useState<boolean>(false);

  const { sharingState, refetchSharedGroupsData, updateSharingState } = useSharingContext();

  const analysisId = parseInt(useParams<{ analysisId: string }>().analysisId);
  const { expandedRowId: expandedRowIdFromUrl, sharedWithPage } = useQueryParams();

  const queryParams = new URLSearchParams(location.search); // eslint-disable-line

  const { getResource } = useResource();
  const history = useHistory();

  useEffect(() => {
    if (expandedRowIdFromUrl) {
      setExpandedRowId(parseInt(expandedRowIdFromUrl));
      setCurrentPage(parseInt(sharedWithPage));
      refetch({ updatedPage: parseInt(sharedWithPage) });
      setReturningFromPreview(true);
      //remove &expandedRowId from URL
      const params = new URLSearchParams(history.location.search);
      params.delete("expandedRowId");
      params.delete("sharedWithPage");
      history.replace(history.location.pathname + "?" + params.toString());
    }
    return () => {
      setSortingColumn(GroupSharingSortingParameter.GroupName);
      setSortOrder(SortingOrder.DESC);
      setCurrentPage(1);
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    refetch({ order: sortOrder, column: sortingColumn });
  }, [sortOrder, sortingColumn, searchTerm]); // eslint-disable-line

  const navigateToShareEditPage = () => {
    updateSharingState("step", ShareStep.UserSelection);
    updateSharingState("view", SharingView.ShareToGroups);
    history.push(
      routes.sharingPage(analysisId) +
        getUrlQueryString({ view: SharingView.ShareToGroups, step: ShareStep.UserSelection })
    );
  };

  const refetch = (options: RefetchParams = {}) => {
    const { updatedPage, order, column } = options;
    const fetchParams: GetRecipientsGroupsParams = {
      analysisId,
      search: formatSearchForFetchRequest(searchTerm),
      page: updatedPage ?? currentPage,
      sort_column: column,
      sort_order: order,
      limit: DEFAULT_LIST_LIMIT,
    };
    refetchSharedGroupsData(fetchParams);
  };

  const deleteSharedGroup = async (sharingId: number) => {
    await unshare(sharingId)
      .then(() => {
        refetch();
        setReturningFromPreview(false);
        setExpandedRowId(null);
      })
      .catch((err) => console.error(err));
  };

  const updateSorting = (updatedSorting: FlexTableSorting) => {
    const { columnIndex, order } = updatedSorting;
    setSortingColumn(GROUPS_SHARED_WITH_HEADERS[columnIndex].sortingParameter);
    setSortOrder(order);
    setCurrentPage(1);
  };

  const clearSearch = () => {
    dispatch(setSearchTerm(""));
    setReturningFromPreview(false);
  };

  const expandCollapseEditBlock = (groupId: number) => {
    if (!expandedRowId) return setExpandedRowId(groupId);
    setReturningFromPreview(false);
    return expandedRowId === groupId ? setExpandedRowId(null) : null;
  };

  return (
    <>
      <Header
        pageTitle={getResource("sharing.title.groups")}
        searchCountDataType="searchBar.dataType.sharing.groups"
        placeholderText={getResource("sharing.searchBar.placeholder.groups")}
        currentPage={currentPage}
        updatePage={(updatedPage) => {
          setCurrentPage(updatedPage);
          refetch({ updatedPage });
        }}
      />
      {sharingState.groupsApiData.totalCount === 0 ? (
        <EmptyState
          type={
            queryParams.get("search")
              ? EmptyStateType.noSearchResults
              : EmptyStateType.noSharedGroups
          }
          customStyles={{ marginTop: "85px", marginBottom: "85px" }}
          handleClickCaptionLink={searchTerm.length > 0 ? clearSearch : navigateToShareEditPage}
        />
      ) : (
        <FlexTable
          type={FlexTableType.Card}
          data={{
            headers: GROUPS_SHARED_WITH_HEADERS.map((header) =>
              getResource(`sharing.table.${header.columnNameKey}`)
            ),
            rows: sharingState.groupsApiData.groups.map((group) => ({
              contentId: group.groupId,
              data: [
                <GroupNameCell group={group} key={group.groupId} />,
                getResource(
                  `sharing.table.usersInGroup.cell${group.userCount === 1 ? ".singular" : ""}`,
                  group.userCount
                ),
                <PermissionLevelCell
                  key={group.groupId}
                  permissionLevel={group.permissionLevel}
                  editButtonStyle={
                    expandedRowId === group.groupId
                      ? { backgroundColor: Color.gray50, color: Color.white }
                      : null
                  }
                  disabled={expandedRowId && expandedRowId !== group.groupId}
                  handleClickEdit={() => expandCollapseEditBlock(group.groupId)}
                  handleClickDelete={() => deleteSharedGroup(group.sharingId)}
                />,
              ],
              expandedContent: (
                <EditSharingBlock
                  show={expandedRowId === group.groupId}
                  sharingId={group.sharingId}
                  sharedWithPage={currentPage}
                  usersSharedWithCount={group.userCount}
                  showEditAddUsers={true}
                  groupName={group.name}
                  expandedRowId={expandedRowId}
                  returningFromPreview={returningFromPreview}
                  closeShowEditSharing={() => expandCollapseEditBlock(group.groupId)}
                  refetch={() => refetch({ order: sortOrder, column: sortingColumn })}
                />
              ),
              isDisabled: expandedRowId && expandedRowId !== group.groupId,
              isSelected: expandedRowId === group.groupId,
            })),
            columnWidths: ["30%", "20%", "50%"],
          }}
          customStyles={{
            rows: {
              backgroundColor: Color.white,
              padding: "16px 12px",
              flexWrap: "wrap",
            },
          }}
          initialSorting={{
            columnIndex: GROUPS_SHARED_WITH_HEADERS.findIndex(
              (h) => h.sortingParameter === sortingColumn
            ),
            order: sortOrder,
          }}
          onSortingChange={updateSorting}
        />
      )}
    </>
  );
};
