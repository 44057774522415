import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "store";
import { TotalBlockCount } from "../TotalBlockCount";
import { Icon, IconType } from "components/_icons/Icon";
import { StackedBarChartContainer } from "../charts/StackedBarChartContainer";
import { BlockEmptyState } from "./BlockEmptyState";

import { useResource } from "hooks/useResource";
import { AnalysisContext } from "context/AnalysisContext";
import {
  getGlobalSentiment,
  formatSentimentsForStackedBarChart,
  formatRecommendationsForStackedBarChart,
} from "utils/overview";
import { useQueryParams } from "hooks/useQueryParams";
import { getUrlQueryString } from "utils/getUrlQueryString";
import { isAnyAdmin } from "utils/isAnyAdmin";

import { SentimentType, SharingCommentExplorerAccess, TopicsListView } from "@explorance/mly-types";
import {
  SentimentCounts,
  RecommendationCounts,
  getSentimentCountsFromData,
  GlobalSentiment,
  StackedBarChartCount,
  getRecommendationCountsFromData,
} from "ts/overview";
import { routes } from "routes";
import { sentimentStyles } from "assets/constants/sentimentStyles";
import { BlockEmptyStateType } from "ts/enums/blockEmptyStateType";
import { PreviewUser } from "ts/analysis";
import { Text } from "components/Text";

type Props = {
  attributeType: TopicsListView;
  isEmptyState?: boolean;
  sharedUser: PreviewUser;
};

export const HighLevelAttributes = ({ attributeType, isEmptyState, sharedUser }: Props) => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);

  const [state] = useContext(AnalysisContext);

  const history = useHistory();
  const { getResource } = useResource();
  const { sharingPreview, sharingId, sharedWithPage, expandedRowId, step } = useQueryParams();

  const [globalSentiment, setGlobalSentiment] = useState<GlobalSentiment>();
  const [attributeCounts, setAttributeCounts] = useState<SentimentCounts | RecommendationCounts>();

  const userCanAccessComments = sharingPreview
    ? sharedUser?.commentExplorerAccess === SharingCommentExplorerAccess.Shared
    : isAnyAdmin(currentUser.roleType) ||
      !state.analysisDetails?.sharing ||
      state.analysisDetails?.sharing?.commentExplorerAccess === SharingCommentExplorerAccess.Shared;

  useEffect(() => {
    if (isEmptyState) return;
    if (attributeType === TopicsListView.Sentiments) {
      if (state.commentStatistics?.allComments) {
        const comments = getSentimentCountsFromData(state.commentStatistics.allComments);
        setAttributeCounts(comments);
        const gs = getGlobalSentiment(comments);
        setGlobalSentiment(gs);
      } else {
        setAttributeCounts(undefined);
        setGlobalSentiment(undefined);
      }
    } else {
      if (state.commentStatistics?.allRecommendations) {
        setAttributeCounts(
          getRecommendationCountsFromData(
            state.commentStatistics.allRecommendations as RecommendationCounts
          )
        );
      } else {
        setAttributeCounts(undefined);
      }
    }
  }, [state.commentStatistics, attributeType, isEmptyState]);

  const getTotalCountLabel = (): string => {
    if (isEmptyState) return getResource("totalBlockCount.commentersCount.undefined");
    if (!attributeCounts) return;
    const type = attributeType === TopicsListView.Sentiments ? "comments" : "recommendations";
    const resource =
      attributeCounts.total === 1
        ? `overview.summaryStats.countSingular.${type}`
        : `overview.summaryStats.count.${type}`;

    return getResource(resource, attributeCounts.total);
  };

  const getSentimentCountLabel = (): string => {
    if (isEmptyState) return getResource("totalBlockCount.commentersCount.undefined");
    if (!globalSentiment) return;
    return `${globalSentiment.percentage}% ${getResource(
      `sentimentPill.${globalSentiment.type}`
    ).toLowerCase()}`;
  };

  const getStackedBarChartCount = (): StackedBarChartCount[] => {
    if (!attributeCounts) return [];
    const countArray = Object.entries(attributeCounts)
      .filter((ac) => ac[0] !== "total")
      .filter((ac) => ac[0] !== "itemCount");
    if (attributeType === TopicsListView.Sentiments) {
      return countArray.map((ac) => formatSentimentsForStackedBarChart(ac));
    }
    const total = Object.entries(attributeCounts).find((c) => c[0] === "total")[1];
    return countArray.map((ac) => formatRecommendationsForStackedBarChart(ac, total));
  };

  const handleTotalCountClick = (attribute?: string) => {
    const view = attributeType === TopicsListView.Sentiments ? "allComments" : "allRecommendations";
    history.push(
      routes.commentsPage(
        state.analysisDetails.id,
        getUrlQueryString({
          statistics: attribute,
          view,
          sharingPreview,
          sharingId,
          sharedWithPage,
          expandedRowId,
          step,
        })
      )
    );
  };

  return (
    <>
      <StyledTopContainer
        isEmptyState={attributeType === TopicsListView.Recommendations && isEmptyState}
      >
        <TotalBlockCount
          title={
            <Text resource={`totalBlockCount.title.${attributeType.toLowerCase()}AttributeView`} />
          }
          displayedCount={getTotalCountLabel()}
          addBorder={attributeType === TopicsListView.Sentiments}
          isEmptyState={attributeType === TopicsListView.Recommendations && isEmptyState}
          handleClick={!userCanAccessComments ? undefined : () => handleTotalCountClick()}
        >
          <Icon
            type={
              attributeType === TopicsListView.Sentiments
                ? IconType.comment
                : IconType.recommendation
            }
            size={12}
          />
        </TotalBlockCount>
        {attributeType === TopicsListView.Sentiments && (
          <TotalBlockCount
            title={<Text resource="totalBlockCount.title.globalSentiment" />}
            displayedCount={getSentimentCountLabel()}
            textColor={sentimentStyles[globalSentiment?.type]?.color}
            handleClick={
              !userCanAccessComments
                ? undefined
                : () => handleTotalCountClick(globalSentiment?.type)
            }
          >
            {globalSentiment ? (
              <Icon
                type={
                  globalSentiment.type === SentimentType.notExplicit
                    ? IconType[SentimentType.neutral]
                    : IconType[globalSentiment.type]
                }
                size={16}
                color={sentimentStyles[globalSentiment.type]?.color}
                secondaryColor={sentimentStyles[globalSentiment.type]?.backgroundColor}
              />
            ) : (
              <Icon type={IconType.positive} size={16} />
            )}
          </TotalBlockCount>
        )}
        {isEmptyState && (
          <BlockEmptyState
            type={
              attributeType === TopicsListView.Sentiments
                ? BlockEmptyStateType.sentiments
                : BlockEmptyStateType.recommendations
            }
          />
        )}
      </StyledTopContainer>
      {!isEmptyState && (
        <StyledBottomContainer>
          <StackedBarChartContainer
            counts={getStackedBarChartCount()}
            attributeType={attributeType}
            sharedUser={sharedUser}
          />
        </StyledBottomContainer>
      )}
    </>
  );
};

const StyledTopContainer = styled.div<{ isEmptyState: boolean }>`
  display: flex;
  align-items: center;
  column-gap: ${({ isEmptyState }) => !isEmptyState && "25px"};

  :first-child > div {
    flex: none;
  }
`;

const StyledBottomContainer = styled.div`
  margin-top: 16px;
`;
