import styled from "styled-components";
import { FlexTableType } from "ts/enums/flexTable";

export const StyledBaseRow = styled.div<{
  customStyles?: Record<string, string>;
}>`
  display: flex;
  align-items: center;
  padding: ${({ customStyles }) => customStyles?.padding || "10px 12px"};
`;

export const StyledCell = styled.div<{ width?: string; type?: FlexTableType }>`
  flex-grow: 1;
  flex-basis: ${({ width }) => width || "auto"};
  max-width: ${({ width }) => width || "auto"}; // ensures long text does not expand the cell
  font-size: 14px;

  div > span {
    font-size: ${({ type }) => type === FlexTableType.Table && "12px"};
  }
`;
