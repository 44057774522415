import { CommentsView } from "@explorance/mly-types";
import { StatType } from "ts/enums/statsMenu";

const countsSchema = {
  [CommentsView.allComments]: [
    { name: "total", nameOverride: "comments", type: StatType.Count },
    { name: "positive", type: StatType.Percentage },
    { name: "negative", type: StatType.Percentage },
    { name: "neutral", type: StatType.Percentage },
    { name: "notExplicit", type: StatType.Percentage },
    { name: "mixed", type: StatType.Percentage },
  ],
  [CommentsView.categorizedComments]: [
    { name: "total", nameOverride: "insights", type: StatType.Count },
    { name: "positive", type: StatType.Percentage },
    { name: "negative", type: StatType.Percentage },
    { name: "neutral", type: StatType.Percentage },
    { name: "notExplicit", type: StatType.Percentage },
    { name: "mixed", type: StatType.Percentage },
  ],
  [CommentsView.notCategorizedComments]: [
    { name: "total", nameOverride: "comments", type: StatType.Count },
    { name: "positive", type: StatType.Percentage },
    { name: "negative", type: StatType.Percentage },
    { name: "neutral", type: StatType.Percentage },
    { name: "notExplicit", type: StatType.Percentage },
    { name: "mixed", type: StatType.Percentage },
  ],
  [CommentsView.allRecommendations]: [
    { name: "total", nameOverride: "recommendations", type: StatType.Count },
    { name: "doMore", type: StatType.Count },
    { name: "doLess", type: StatType.Count },
    { name: "start", type: StatType.Count },
    { name: "stop", type: StatType.Count },
    { name: "continue", type: StatType.Count },
    { name: "change", type: StatType.Count },
  ],
  [CommentsView.alerts]: [{ name: "total", nameOverride: "alerts", type: StatType.Count }],
};

countsSchema[CommentsView.categorizedRecommendations] =
  countsSchema[CommentsView.allRecommendations];
countsSchema[CommentsView.notCategorizedRecommendations] =
  countsSchema[CommentsView.allRecommendations];

export { countsSchema };
