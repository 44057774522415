import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Modal, StyledModalCaption, StyledModalTitle } from "components/_modals/Modal";
import { useHistory } from "react-router-dom";
import { routes } from "routes";
import { Icon, IconType } from "components/_icons/Icon";
import { Color } from "ts/enums/color";
import { AnalysisContext } from "context/AnalysisContext";
import { AnalysisStatus } from "@explorance/mly-types";
import { Text } from "components/Text";

export const JobFailedModal = () => {
  const [showJobFailedModal, setShowJobFailedModal] = useState<boolean>(false);
  const [state] = useContext(AnalysisContext);
  const history = useHistory();

  useEffect(() => {
    setShowJobFailedModal(state.analysisDetails.status === AnalysisStatus.Failed);
  }, [state.analysisDetails.status]);

  return (
    <Modal isOpen={showJobFailedModal} width={"375px"} height={"auto"}>
      <StyledModal>
        <div className="left-container">
          <Icon type={IconType.xCircle} color={Color.red55} size={20} />
        </div>
        <div>
          <div className="top-section">
            <StyledModalTitle>
              <Text resource="modal.jobFailed.title" />
            </StyledModalTitle>
          </div>
          <StyledModalCaption>
            <Text resource="modal.jobFailed.caption" />
          </StyledModalCaption>
          <div className="buttons-row">
            <div className="link" onClick={() => history.push(routes.homePage)}>
              <Text resource="modal.homepageLink" />
            </div>
            <div className="link right" onClick={() => setShowJobFailedModal(false)}>
              <Text resource="button.okay" />
            </div>
          </div>
        </div>
      </StyledModal>
    </Modal>
  );
};

const StyledModal = styled.div`
  display: flex;
  margin-top: 10px;

  .left-container {
    margin-left: 10px;
    margin-right: 20px;
  }

  button {
    cursor: pointer;
    outline-style: none;
  }

  button:focus {
    border-radius: 3px;
    border: 1px solid ${Color.blue50};
  }

  .top-section {
    display: flex;
    margin-bottom: 10px;
  }

  .buttons-row {
    display: flex;

    .right {
      margin-left: auto;
    }
  }

  .link {
    font-size: 0.875em;
    color: ${Color.blue50};
    font-weight: bold;

    &:hover {
      cursor: pointer;
    }
  }
`;
