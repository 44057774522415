import { CategorizedTopic } from "ts/enums/categorizedTopic";
import { Color } from "ts/enums/color";

export const sentimentStyles = {
  [CategorizedTopic.positive]: {
    color: Color.green50,
    backgroundColor: Color.green30,
    border: `1px solid ${Color.green40}`,
  },
  [CategorizedTopic.negative]: {
    color: Color.red40,
    backgroundColor: Color.red10,
    border: `1px solid ${Color.red30}`,
  },
  [CategorizedTopic.neutral]: {
    color: Color.blue50,
    backgroundColor: Color.sky30,
    border: `1px solid ${Color.sky60}`,
  },
  [CategorizedTopic.notExplicit]: {
    color: Color.orange60,
    backgroundColor: Color.orange20,
    border: `1px solid ${Color.orange30}`,
  },
  [CategorizedTopic.mixed]: {
    color: Color.indigo45,
    backgroundColor: Color.indigo20,
    border: `1px solid ${Color.indigo30}`,
  },
  [CategorizedTopic.doMore]: {
    color: Color.blue40,
  },
  [CategorizedTopic.doLess]: {
    color: Color.indigo50,
  },
  [CategorizedTopic.start]: {
    color: Color.green50,
  },
  [CategorizedTopic.stop]: {
    color: Color.red40,
  },
  [CategorizedTopic.continue]: {
    color: Color.blue90,
  },
  [CategorizedTopic.change]: {
    color: Color.orange60,
  },
};
