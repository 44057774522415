import React from "react";
import styled from "styled-components";

import { Color } from "ts/enums/color";
import { DEFAULT_LIST_LIMIT } from "assets/constants/listLimits";
import { Icon, IconType } from "components/_icons/Icon";
import { NumberInput } from "components/_inputs/NumberInput";
import { Text } from "components/Text";

type Props = {
  currentPage: number;
  currentItemsCount: number;
  pageSize?: number;
  handlePageSelection(pageNumber: number): void;
};

export const Pagination = ({
  currentPage,
  currentItemsCount,
  pageSize = DEFAULT_LIST_LIMIT,
  handlePageSelection,
}: Props) => {
  const pagesTotalCount = Math.ceil(currentItemsCount / pageSize);

  const handleClickNavigationButton = (increment?: number, goTo?: number) => () => {
    let newPage: number;
    if (goTo) newPage = goTo;
    if (increment) newPage = currentPage + increment;
    handlePageSelection(newPage);
  };

  return (
    <StyledPagination>
      <StyledButtonContainer>
        <StyledNavigationButton
          disabled={currentPage === 1}
          onClick={handleClickNavigationButton(null, 1)}
        >
          <Icon
            type={IconType.chevronsLeft}
            size={12}
            style={{ marginLeft: "2px", marginRight: "6px" }}
          />
        </StyledNavigationButton>
        <StyledNavigationButton
          disabled={currentPage === 1}
          onClick={handleClickNavigationButton(-1)}
        >
          <Icon type={IconType.chevronLeft} size={12} />
        </StyledNavigationButton>
      </StyledButtonContainer>
      <StyledPageSelection>
        <NumberInput
          value={currentPage}
          minimum={1}
          maximum={pagesTotalCount}
          styling={{ maxWidth: "35px", textAlign: "center" }}
          handleChange={handlePageSelection}
        />
        <span>
          <span>
            <Text resource="pagination.of" />{" "}
          </span>
          {pagesTotalCount}
        </span>
      </StyledPageSelection>
      <StyledButtonContainer>
        <StyledNavigationButton
          disabled={currentPage === pagesTotalCount}
          onClick={handleClickNavigationButton(1)}
        >
          <Icon type={IconType.chevronRight} size={12} />
        </StyledNavigationButton>
        <StyledNavigationButton
          disabled={currentPage === pagesTotalCount}
          onClick={handleClickNavigationButton(null, pagesTotalCount)}
        >
          <Icon
            type={IconType.chevronsRight}
            size={12}
            style={{ marginRight: "2px", marginLeft: "6px" }}
          />
        </StyledNavigationButton>
      </StyledButtonContainer>
    </StyledPagination>
  );
};

const StyledPagination = styled.div`
  display: flex;
  color: ${Color.black};
  font-weight: normal;
  font-family: Lato, sans-serif;
  font-size: 0.75em;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
`;

const StyledPageSelection = styled.div`
  display: inline-block;
  white-space: nowrap;
  margin: 0 10px;
  font-size: 1.2em;

  span {
    margin-left: 3px;

    > span {
      margin-right: 3px;
    }
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledNavigationButton = styled.button<{ disabled: boolean }>`
  background: none;
  border: none;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  outline-style: none;
  padding: 0px;
  display: inline-flex;

  svg {
    margin-top: 2px;
    stroke: ${({ disabled }) => (disabled ? Color.gray20 : Color.blue50)};
  }
`;
