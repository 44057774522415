import React from "react";
import styled from "styled-components";

import { SentimentType } from "@explorance/mly-types";
import { InterpretationIconType } from "ts/enums/interpretationIconType";
import { Color } from "ts/enums/color";
import { Comment as CommentType } from "ts/comments";

import { InterpretationPill } from "components/_pills/InterpretationPill";
import { SentimentPill } from "components/_pills/SentimentPill";
import { NonCategorizedRecommendationPillList } from "common-layouts/NonCategorizedRecommendationPillList";
import { CategorizedTopicPillList } from "common-layouts/CategorizedTopicPillList";
import { Tooltip } from "components/Tooltip";

import { Text } from "components/Text";

export type ResultsIconCount = {
  count: number | null;
  type: InterpretationIconType;
};

type Props = {
  sentiment: SentimentType;
  interpretations: ResultsIconCount[];
  bnw?: boolean;
  showSentimentName?: boolean;
  removeSentimentTooltip?: boolean;
  interpretationTooltipData: CommentType;
  isCustomModel: boolean;
};

export const CommentResultsIcons = ({
  sentiment,
  interpretations,
  bnw = false,
  showSentimentName = false,
  removeSentimentTooltip = false,
  interpretationTooltipData,
  isCustomModel,
}: Props) => {
  //returns the interpretation pills (aside from sentiment pill) and their tooltip contents.
  const getTooltipContent = () => {
    return interpretations.map((i: ResultsIconCount, index: number) => {
      return (
        <div key={index}>
          <div data-tip data-for={`pill-${interpretationTooltipData.id}-${i.type}`}>
            {i.count > 0 && (
              <InterpretationPill key={i.type} count={i.count} iconType={i.type} bnw />
            )}
          </div>
          {getTooltip(i)}
        </div>
      );
    });
  };

  //Returns appripriate tooltip based on its type
  const getTooltip = (interpretation: ResultsIconCount) => {
    if (
      interpretation.type === InterpretationIconType.topicInsight &&
      interpretationTooltipData.categorizedTopics
    ) {
      return (
        <Tooltip
          tooltipId={`pill-${interpretationTooltipData.id}-${interpretation.type}`}
          arrowColor={Color.blue90}
          backgroundColor={Color.blue90}
          zIndexPriority
        >
          <StyledTip>
            <StyledLabel>
              <Text resource="comments.detailsPopup.topicInsights.label" />:
            </StyledLabel>
            {interpretationTooltipData.categorizedTopics && (
              <CategorizedTopicPillList
                isCustomModel={isCustomModel}
                topics={interpretationTooltipData.categorizedTopics}
              />
            )}
          </StyledTip>
        </Tooltip>
      );
    } else if (interpretation.type === InterpretationIconType.recommendation) {
      return (
        <Tooltip
          tooltipId={`pill-${interpretationTooltipData.id}-${interpretation.type}`}
          arrowColor={Color.blue90}
          backgroundColor={Color.blue90}
          zIndexPriority
        >
          <StyledTip>
            {interpretationTooltipData.categorizedRecommendations &&
              Object.keys(interpretationTooltipData.categorizedRecommendations).length !== 0 &&
              !Object.values(interpretationTooltipData.categorizedRecommendations).every(
                (arr) => arr.length === 0
              ) && (
                <>
                  <StyledLabel>
                    <Text resource="comments.detailsPopup.recommendations.label" />:
                  </StyledLabel>
                  <CategorizedTopicPillList
                    isCustomModel={isCustomModel}
                    topics={interpretationTooltipData.categorizedRecommendations}
                  />
                </>
              )}

            {interpretationTooltipData.recommendations &&
              interpretationTooltipData.recommendations.length !== 0 && (
                <>
                  <StyledLabel>
                    <Text resource="comments.detailsPopup.generalRecommendations.label" />:
                  </StyledLabel>
                  <NonCategorizedRecommendationPillList
                    recommendations={interpretationTooltipData.recommendations}
                    style={{ marginRight: 5, marginBottom: 5 }}
                  />
                </>
              )}
          </StyledTip>
        </Tooltip>
      );
    } else {
      return;
    }
  };

  return (
    <StyledIconSection>
      {sentiment && (
        <>
          <div data-tip data-for={`sentimentPill-${interpretationTooltipData.id}`}>
            <SentimentPill
              bnw={bnw}
              sentiment={sentiment}
              showSentimentName={showSentimentName}
              customStyles={{ marginRight: 5 }}
            />
          </div>
          {!removeSentimentTooltip && (
            <Tooltip
              tooltipId={`sentimentPill-${interpretationTooltipData.id}`}
              backgroundColor={Color.blue90}
              arrowColor={Color.blue90}
              zIndexPriority
            >
              <StyledTip>
                <StyledLabel>
                  <Text resource="comments.detailsPopup.sentiment.label" />:
                </StyledLabel>
                <SentimentPill sentiment={sentiment} showSentimentName />
              </StyledTip>
            </Tooltip>
          )}
        </>
      )}
      {getTooltipContent()}
    </StyledIconSection>
  );
};

const StyledIconSection = styled.div`
  padding: 10px 0px;
  display: flex;
  align-items: center;
`;

const StyledTip = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
`;

const StyledLabel = styled.div`
  position: relative;
  margin-top: 3px;
  bottom: 2px;
`;
