import React from "react";
import styled from "styled-components";

import { CloseButton } from "components/_buttons/CloseButton";
import { Color } from "ts/enums/color";
import { TextType } from "ts/TextType";

type Props = {
  headerTitle: TextType;
  closeHandler: () => void;
};

export const Header = ({ headerTitle, closeHandler }: Props) => {
  return (
    <StyledHeader>
      <StyledTitle>{headerTitle}</StyledTitle>
      <CloseButton closeHandler={closeHandler} titleKey="button.close" />
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  height: 25px;
  padding: 0 24px;
  margin: 25px 0;
  display: flex;
  align-items: center;
  color: ${Color.gray50};
`;

const StyledTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
