export enum ButtonVariant {
  primary = "primary",
  primaryPurple = "primaryPurple",
  secondary = "secondary",
  light = "light",
  outline = "outline",
  outlineBlue = "outlineBlue",
  danger = "danger",
  // following two variants are mainly used in dropdowns
  outlineGray = "outlineGray",
  error = "error",
  neutral = "neutral",
}

export enum ButtonSize {
  sm = "sm",
  md = "md",
  ml = "ml",
  lg = "lg",
}
