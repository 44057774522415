import { allowedFileFormats } from "assets/constants/allowedFileFormats";

export const validateEmail = (email: string): boolean => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email.toLowerCase());
};

//Minmum 8 characters, one digit, one special character, upper and lower case
export const isPasswordValid = (password: string): boolean => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?])[A-Za-z\d-+_!@#$%^&*.,?s]{8,}$/;
  return regex.test(password);
};

export type PasswordRequirements = {
  minimumLength: boolean;
  hasUppercase: boolean;
  hasLowercase: boolean;
  hasDigit: boolean;
  hasSpecialCharacter: boolean;
};

export const validatePasswordIndividualRequirements = (password: string): PasswordRequirements => {
  return {
    minimumLength: password.length >= 8,
    hasUppercase: /(?=.*[A-Z])/.test(password),
    hasLowercase: /(?=.*[a-z])/.test(password),
    hasDigit: /(?=.*\d)/.test(password),
    hasSpecialCharacter: /(?=.*[-+_!@#$%^&*.,?])/.test(password),
  };
};

export const isFileFormatValid = (file: File): boolean => {
  if (!file) return;
  return allowedFileFormats.includes(file.type);
};

export const isFileNameLengthValid = (file: File): boolean => {
  if (!file) return;
  return file.name.length <= 200; // BE limitation for transferred file names
};
