import { UserManager, WebStorageStateStore } from "oidc-client";
import { IDENTITY_CONFIG } from "config/identityConfig";

export class AuthenticationManager {
  private userManager: UserManager = null;

  constructor() {
    this.userManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    });

    this.userManager.events.addSilentRenewError(() => {
      this.login();
    });
  }

  async getUser() {
    return await this.userManager.getUser();
  }

  async isLoggedIn() {
    let user = await this.getUser();

    if (user && user.access_token && !user.expired) {
      return true;
    }

    try {
      user = await this.userManager.signinSilent();

      return Boolean(user && user.access_token && !user.expired);
    } catch {
      return false;
    }
  }

  async completeSilentRefresh() {
    await this.userManager.signinSilentCallback().catch((error) => this.handleError(error));
  }

  silentRefresh() {
    return this.userManager.signinSilent().catch(() => {
      this.login();
    });
  }

  login() {
    this.userManager.signinRedirect().catch((error) => this.handleError(error));
  }

  async completeLogin() {
    return await this.userManager
      .signinRedirectCallback()
      .catch((error) => this.handleError(error));
  }

  logout() {
    this.getUser().then((user) => {
      const currentUser = user;
      this.userManager.removeUser().then(() => {
        this.navigateToTridentSignout(currentUser.id_token);
      });
    });
  }

  private navigateToTridentSignout(tridentIdToken: string) {
    const clientId = this.urlEncode(IDENTITY_CONFIG.client_id);
    const idToken = this.urlEncode(tridentIdToken);
    const redirectUrl = this.urlEncode(`${window.location.origin}/post-logout`);
    const queryParams = `client_id=${clientId}&id_token=${idToken}&redirect_uri=${redirectUrl}`;
    const tridentSignoutUrl = `${IDENTITY_CONFIG.authority}/signout?${queryParams}`;

    window.location.href = tridentSignoutUrl;
  }

  private urlEncode(text: string) {
    return encodeURIComponent(text);
  }

  handleError(error) {
    console.error("A problem occurred with authentication:", error);
  }
}
