import { Precision } from "@explorance/mly-types";

export const precisionSelectionRange = [
  { label: "lowest", value: 5, bodyValue: Precision.lowest },
  { label: "veryLow", value: 6, bodyValue: Precision.veryLow },
  { label: "low", value: 7, bodyValue: Precision.low },
  { label: "highRecommended", value: 8, bodyValue: Precision.high },
  { label: "veryHigh", value: 9, bodyValue: Precision.veryHigh },
  { label: "highest", value: 10, bodyValue: Precision.highest },
];

export const DEFAULT_PRECISION_SELECTION = precisionSelectionRange.find(
  (p) => p.bodyValue === Precision.high
);
