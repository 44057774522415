import {
  BreakdownTypes,
  CommentsView,
  SentimentType,
  TopicsListView,
  WidgetType,
} from "@explorance/mly-types";
import { WidgetStatistic } from "ts/widgetStatistics";
import { DemographicFilter } from "ts/filters/demographicFilter";
import { WidgetFilter } from "ts/widget";
import { HandleClickCommentsParams, HandleClickTopicsParams } from "components/Widget";

export const getTranslatedFullPath = (fullPath: string, isVirtual: boolean, getResource: any) => {
  return fullPath
    .split(">")
    .map((topic) => (isVirtual ? topic : getResource(`ML.topic.[${topic}]`)));
};

export const getViewFromWidgetClick = ({
  widgetType,
  l1BreakdownType,
  l2BreakdownType,
  isL1Clicked,
  isL2LabelOrBarClicked,
}: {
  widgetType: WidgetType;
  l1BreakdownType?: BreakdownTypes;
  l2BreakdownType?: BreakdownTypes;
  isL1Clicked?: boolean;
  isL2LabelOrBarClicked?: boolean;
}): CommentsView | TopicsListView => {
  switch (widgetType) {
    case WidgetType.PrioritizedRecommendations:
    case WidgetType.CategorizedRecommendations:
      return (l1BreakdownType === BreakdownTypes.Topics && isL1Clicked) ||
        (l2BreakdownType === BreakdownTypes.Topics && isL2LabelOrBarClicked)
        ? TopicsListView.Recommendations
        : CommentsView.categorizedRecommendations;

    case WidgetType.MostAlerts:
      return CommentsView.alerts;
    case WidgetType.MostPopular:
      return CommentsView.categorizedComments;

    case WidgetType.AllRecommendations:
      return CommentsView.allRecommendations;

    case WidgetType.AllSentiments:
    case WidgetType.WhatToImprove:
    case WidgetType.MostPositive:
      return (
        (l2BreakdownType === BreakdownTypes.Topics || l1BreakdownType === BreakdownTypes.Topics) &&
        CommentsView.categorizedComments
      );
  }
};

export const l1ClickNavigation = (
  l1Data: WidgetStatistic,
  l1BreakdownType: BreakdownTypes,
  l1DemographicData: DemographicFilter[],
  widgetType: WidgetType,
  fixedStatistic: Record<string, SentimentType[]>,
  widgetFilter: WidgetFilter,
  onClickComments: (params: HandleClickCommentsParams) => void,
  onClickTopics: (params: HandleClickTopicsParams) => void
): CommentsView | TopicsListView => {
  if (l1Data?.isOthers) return;
  const l2BreakdownType = l1Data?.details?.type;
  const navigationPayload = {
    ...(BreakdownTypes.Demographics === l1BreakdownType && {
      breakdownDemographics: l1DemographicData,
      view: getViewFromWidgetClick({ widgetType, l2BreakdownType }),
      ...(fixedStatistic && fixedStatistic),
      ...(widgetFilter?.statistics?.length > 0 && { stats: widgetFilter.statistics }),
    }),
    ...(BreakdownTypes.SentimentType === l1BreakdownType && {
      stats: [l1Data.dominantSentiment.sentimentType],
      view: getViewFromWidgetClick({ widgetType, l2BreakdownType }),
    }),
    ...(BreakdownTypes.Topics === l1BreakdownType && {
      topic: [
        {
          id: l1Data.id,
          parentId: l1Data.parentId,
          fullPath: l1Data.fullPath,
        },
      ],
      view: getViewFromWidgetClick({
        widgetType,
        l2BreakdownType,
        l1BreakdownType,
        isL1Clicked: true,
      }),
      ...(widgetFilter?.statistics?.length > 0 && { stats: widgetFilter.statistics }),
    }),
    ...(BreakdownTypes.RecommendationType === l1BreakdownType && {
      stats: [l1Data.name],
      view: getViewFromWidgetClick({ widgetType }),
    }),
  };

  l1BreakdownType === BreakdownTypes.Topics
    ? onClickTopics(navigationPayload)
    : onClickComments(navigationPayload);
};
