import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  VariableMappingGroup,
  VariableMappingGroupItem,
  mapDataToVariableMapping,
} from "ts/variableMappings";
import {
  checkIfEmptyInput,
  checkIfMappingDuplicates,
  generateMappingRows,
} from "utils/variableMapping";
import { fetchVariableMapping } from "./thunks";

type NewMappingInitParams = {
  updatedName: string;
  updatedMappings: VariableMappingGroupItem[];
};

type EditVariableMappingState = {
  mappingId: number;
  variableMapping: VariableMappingGroup;
  updatedName: string;
  updatedMappings: VariableMappingGroupItem[];
  hasBeenEdited: boolean;
  showDiscardConfirmationModal: boolean;
  showSavePromptModal: boolean;
  showMissingValueError: boolean;
  showDuplicateVariableError: boolean;
  showEmptyPresetError: boolean;
  showErrors: boolean;
  apiErrorCode: number;
  newMappingInitParams: Record<string, VariableMappingGroupItem[]>;
};

const initialState: EditVariableMappingState = {
  mappingId: null,
  variableMapping: null,
  updatedName: "",
  updatedMappings: generateMappingRows([]),
  hasBeenEdited: false,
  showDiscardConfirmationModal: false,
  showSavePromptModal: false,
  showMissingValueError: false,
  showDuplicateVariableError: false,
  showEmptyPresetError: false,
  showErrors: false,
  apiErrorCode: null,
  newMappingInitParams: null,
};

const EditVariableMappingSlice = createSlice({
  name: "editVariableMappingSlice",
  initialState,
  reducers: {
    setMappingId: (state, action: PayloadAction<number>) => {
      state.mappingId = action.payload;
    },
    setVariableMapping: (state, action: PayloadAction<VariableMappingGroup>) => {
      state.variableMapping = action.payload;
    },
    setUpdatedName: (state, action: PayloadAction<string>) => {
      state.updatedName = action.payload;
      state.hasBeenEdited = true;
    },
    setUpdatedMappings: (state, action: PayloadAction<VariableMappingGroupItem[]>) => {
      state.updatedMappings = action.payload;
      state.hasBeenEdited = true;
    },
    setHasBeenEdited: (state, action: PayloadAction<boolean>) => {
      state.hasBeenEdited = action.payload;
    },
    setShowDiscardConfirmationModal: (state, action: PayloadAction<boolean>) => {
      state.showDiscardConfirmationModal = action.payload;
    },
    setShowSavePromptModal: (state, action: PayloadAction<boolean>) => {
      state.showSavePromptModal = action.payload;
    },
    setShowMissingValueError: (state, action: PayloadAction<boolean>) => {
      state.showMissingValueError = action.payload;
    },
    setShowErrors: (state, action: PayloadAction<boolean>) => {
      state.showErrors = action.payload;
    },
    newMappingInit: (state, action: PayloadAction<NewMappingInitParams>) => {
      const { updatedName, updatedMappings } = action.payload;
      state.variableMapping = { name: updatedName, mappings: updatedMappings };
      state.updatedName = updatedName;
      state.updatedMappings = updatedMappings;
    },
    discardChanges: (state) => {
      state.updatedName = state.variableMapping.name;
      state.updatedMappings = generateMappingRows(state.variableMapping.mappings);
      state.hasBeenEdited = false;
      state.showDiscardConfirmationModal = false;
      state.showErrors = false;
    },
    updateErrorLabels: (state) => {
      state.showDuplicateVariableError = checkIfMappingDuplicates(state.updatedMappings);
      state.showMissingValueError = checkIfEmptyInput(state.updatedMappings);
      state.showEmptyPresetError = state.updatedMappings.every(
        (m) => m.variable.trim() === "" && m.definition.trim() === ""
      );
    },
    checkForErrors: (state) => {
      const isEmptyMapping: boolean = state.updatedMappings.every(
        (m) => m.variable.trim() === "" && m.definition.trim() === ""
      );
      const hasVariableDuplicate: boolean = checkIfMappingDuplicates(state.updatedMappings);
      const hasEmptyInputError: boolean = checkIfEmptyInput(state.updatedMappings);

      state.showSavePromptModal = false;

      if (isEmptyMapping || hasVariableDuplicate || hasEmptyInputError) {
        state.showErrors = true;
        state.showDuplicateVariableError = hasVariableDuplicate;
        state.showMissingValueError = hasEmptyInputError;
        state.showEmptyPresetError = isEmptyMapping;
        state.hasBeenEdited = true;
      } else {
        state.showErrors = false;
      }
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVariableMapping.fulfilled, (state, action) => {
      const vMappings =
        Object.keys(action.payload.mappings).length === 0
          ? []
          : action.payload.mappings.map((m) => mapDataToVariableMapping(m));

      state.updatedName = action.payload.name;
      state.variableMapping = { name: action.payload.name, mappings: vMappings };
      state.updatedMappings = generateMappingRows(vMappings);
    });
  },
});

export const {
  setMappingId,
  setVariableMapping,
  setUpdatedName,
  setUpdatedMappings,
  setHasBeenEdited,
  setShowDiscardConfirmationModal,
  setShowSavePromptModal,
  setShowMissingValueError,
  setShowErrors,
  newMappingInit,
  discardChanges,
  updateErrorLabels,
  checkForErrors,
  clearState,
} = EditVariableMappingSlice.actions;
export default EditVariableMappingSlice.reducer;
