import React from "react";
import styled from "styled-components";
import { Icon, IconType } from "components/_icons/Icon";
import { BlockEmptyStateType } from "ts/enums/blockEmptyStateType";
import { Color } from "ts/enums/color";
import { Text } from "components/Text";

type Props = {
  type: BlockEmptyStateType;
  marginLeft?: string;
};

export const BlockEmptyState = ({ type, marginLeft }: Props) => {
  return (
    <StyledEmptyState marginLeft={marginLeft}>
      <Icon
        type={IconType.warningSolid}
        color={Color.blue20}
        secondaryColor={Color.gray30}
        style={{ marginRight: 12 }}
        size={30}
      />
      <div>
        <StyledTitle>
          <Text resource={`overview.emptyState.${type}.title`} />
        </StyledTitle>
        <StyledDescription>
          <Text resource={`overview.emptyState.${type}.description`} />
        </StyledDescription>
      </div>
    </StyledEmptyState>
  );
};
const StyledEmptyState = styled.div<{ marginLeft?: string }>`
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-left: ${({ marginLeft }) => marginLeft};
  column-gap: 10px;
`;

const StyledTitle = styled.div`
  color: ${Color.gray50};
  font-size: 20px;
  margin-bottom: 6px;
  font-weight: bold;
`;
const StyledDescription = styled.div`
  color: ${Color.gray30};
  font-size: 14px;
`;
