import React, { ReactElement } from "react";
import styled, { css } from "styled-components";

import { Color } from "ts/enums/color";

import { Skeleton } from "components/Skeleton";
import { Tooltip } from "components/Tooltip";
import { TextType } from "ts/TextType";

type Props = {
  title: TextType;
  displayedCount: string;
  children: ReactElement;
  addBorder?: boolean;
  textColor?: Color;
  hasTooltip?: boolean;
  addMaxWidth?: boolean;
  customSkeletonWidth?: number;
  originalCount?: number;
  isEmptyState?: boolean;
  handleClick?: () => void;
};

export const TotalBlockCount = ({
  title,
  displayedCount,
  children,
  addBorder,
  textColor,
  hasTooltip,
  addMaxWidth,
  customSkeletonWidth,
  originalCount,
  isEmptyState,
  handleClick,
}: Props) => (
  <StyledTotalBlockCount
    addBorder={addBorder}
    isClickable={!!handleClick}
    isEmptyState={isEmptyState}
    data-tip
    data-for={`total-block-count-${displayedCount}-tooltip`}
    onClick={handleClick}
  >
    <StyledIconContainer>{children}</StyledIconContainer>
    <div>
      <StyledTitle textColor={textColor} addMaxWidth={addMaxWidth}>
        {title}
      </StyledTitle>
      {displayedCount ? (
        <>
          <StyledCount textColor={textColor}>{displayedCount}</StyledCount>
          {hasTooltip && originalCount > 9999 && (
            <Tooltip
              tooltipId={`total-block-count-${displayedCount}-tooltip`}
              content={originalCount}
            />
          )}
        </>
      ) : (
        <div className="pulse">
          <Skeleton
            width={customSkeletonWidth ?? 100}
            height={26}
            backgroundColor={Color.neutral10}
            borderRadius="5px"
          />
        </div>
      )}
    </div>
  </StyledTotalBlockCount>
);

const StyledIconContainer = styled.div`
  background-color: ${Color.neutral10};
  min-width: 50px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTitle = styled.div<{ textColor: Color; addMaxWidth: boolean }>`
  font-size: 0.875em;
  color: ${({ textColor }) => textColor ?? Color.gray30};

  @media (min-width: 1366px) {
    max-width: ${({ addMaxWidth }) => addMaxWidth && "114px"};
  }
`;

const StyledCount = styled.div<{ textColor: Color }>`
  color: ${({ textColor }) => textColor ?? Color.gray50};
  font-size: 1.75em;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
`;

const StyledTotalBlockCount = styled.div<{
  addBorder: boolean;
  isClickable: boolean;
  isEmptyState: boolean;
}>`
  display: flex;
  font-weight: bold;
  border-right: ${({ addBorder }) => addBorder && `1px solid ${Color.indigo10}`};
  padding-right: 35px;
  column-gap: 5px;
  max-height: 55px;
  padding-top: 2px;
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "default")};
  flex: ${({ isEmptyState }) => !isEmptyState && "1 0 auto"};

  ${({ isClickable }) =>
    isClickable &&
    css`
      :hover ${StyledIconContainer} {
        background-color: ${Color.sky40};
      }

      :hover ${StyledTitle}, :hover ${StyledCount} {
        color: ${Color.blue50};
      }
    `}

  @media (max-width: 1365px) {
    padding-right: 15px;
    width: ${({ isEmptyState }) => isEmptyState && "260px"};
  }

  @media (min-width: 1366px) {
    width: ${({ isEmptyState }) => isEmptyState && "280px"};
  }
`;
