import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { Color } from "ts/enums/color";

type Props = {
  value: number;
  minimum: number;
  maximum: number;
  styling: Record<string, string>;
  allowNull?: boolean;
  hideIncrementButtons?: boolean;
  updateOnType?: boolean;
  disabled?: boolean;
  handleChange: (page: number | null) => void;
};

export const NumberInput = ({
  value,
  minimum,
  maximum,
  styling,
  allowNull,
  disabled = false,
  hideIncrementButtons = true,
  updateOnType,
  handleChange,
}: Props) => {
  const [localInputValue, setLocalInputValue] = useState<string>("");

  useEffect(() => {
    if (value === null) return setLocalInputValue("");
    if (parseInt(localInputValue) !== value) return setLocalInputValue(value.toString());
    if (value > maximum) {
      setLocalInputValue(maximum.toString());
      handleChange(maximum);
      return;
    }
  }, [value, maximum]); // eslint-disable-line

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0) return setLocalInputValue("");
    const val = Number(e.target.value);
    if (updateOnType) return updateNumber(val.toString());
    if (!Number.isNaN(val) && val <= maximum) return setLocalInputValue(val.toString());
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") return updateNumber(localInputValue);
  };

  const updateNumber = (newValue: string) => {
    if (newValue.length === 0) {
      return allowNull ? handleChange(null) : setLocalInputValue(value.toString());
    }

    const num = parseInt(newValue);
    if (num < minimum) {
      setLocalInputValue(minimum.toString());
      handleChange(minimum);
      return;
    }

    if (num > maximum) {
      setLocalInputValue(maximum.toString());
      handleChange(maximum);
      return;
    }

    if (num === value) return;

    const onlyNumbersRegEx = new RegExp(/\d/g);
    if (onlyNumbersRegEx.test(newValue.toString())) return handleChange(num);
  };

  return (
    <StyledNumberInput
      value={localInputValue}
      type="number"
      min={minimum}
      max={maximum}
      disabled={disabled}
      hideIncrementButtons={hideIncrementButtons}
      onChange={disabled ? undefined : handleChangeInput}
      onKeyDown={disabled ? undefined : handleKeyDown}
      onBlur={disabled ? undefined : () => updateNumber(localInputValue)}
      styling={styling}
    />
  );
};

const StyledNumberInput = styled.input<{
  hideIncrementButtons: boolean;
  styling: Record<string, string>;
  disabled?: boolean;
}>`
  ${({ styling }) =>
    css`
      width: ${styling.width ?? "25px"};
      border: ${styling.border ?? `1px solid ${Color.neutral50}`};
      height: ${styling.height ?? "27px"};
      max-width: ${styling.maxWidth ?? "none"};
      text-align: ${styling.textAlign ?? "left"};
      padding: ${styling.padding ?? "4px 2px"};
      margin: ${styling.margin ?? "none"};
      border-radius: ${styling.borderRadius ?? "3px"};

      :focus {
        border: ${styling.focusBorder ?? `1px solid ${Color.blue50}`};
        outline-style: none;
      }
    `};

  ${({ hideIncrementButtons, disabled }) =>
    (hideIncrementButtons || disabled) &&
    css`
      -moz-appearance: textfield;

      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    `};
`;
