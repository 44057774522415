import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { cloneDeep } from "lodash";

import {
  setSelectedTopicNodes,
  setSelectedModelId,
  setLastSelectedTopicNodes,
  setLastSelectedModelId,
} from "context/AnalysisContext/actions";

import { ApiTopicNode } from "ts/topic";
import { AnalysisContext } from "context/AnalysisContext";
import { AnalysisModel } from "ts/filters/analysisModel";

import { Button } from "components/_buttons/Button";
import { ButtonVariant } from "ts/enums/button";
import { TopicsFilter } from "common-layouts/TopicFilterSelection";
import { ModelDropdown } from "components/ModelDropdown";
import { Color } from "ts/enums/color";
import { Text } from "components/Text";

type Props = {
  resetLocalState: boolean;
  onSubmit: () => void;
};

const TopicsMenu = ({ resetLocalState, onSubmit }: Props) => {
  const [
    {
      analysisModels,
      selectedTopicNodes,
      selectedModelId,
      lastSelectedTopicNodes,
      lastSelectedModelId,
    },
    dispatch,
  ] = useContext(AnalysisContext);
  const [localSelectedModel, setLocalSelectedModel] = useState<AnalysisModel>(null);
  const [localSelectedTopicNodes, setLocalSelectedTopicNodes] = useState<ApiTopicNode[]>([]);

  const handleSubmit = () => {
    dispatch(setLastSelectedModelId(selectedModelId));
    dispatch(setLastSelectedTopicNodes(selectedTopicNodes));
    dispatch(setSelectedTopicNodes(localSelectedTopicNodes));
    dispatch(setSelectedModelId(localSelectedModel.modelId));
    onSubmit();
  };

  const handleAddLastTopics = () => {
    const currentFiltersCopy = cloneDeep(selectedTopicNodes);
    const currentModelIdCopy = selectedModelId;
    dispatch(setSelectedTopicNodes(lastSelectedTopicNodes));
    dispatch(setSelectedModelId(lastSelectedModelId));
    dispatch(setLastSelectedTopicNodes(currentFiltersCopy));
    dispatch(setLastSelectedModelId(currentModelIdCopy));
    onSubmit();
  };

  const handleChangeSelectedModel = (id: number) => {
    setLocalSelectedModel(analysisModels.find((am) => am.modelId === id));
    setLocalSelectedTopicNodes([]);
  };

  useEffect(() => {
    if (analysisModels) {
      const selectedModel = analysisModels.find((m) => m.modelId === selectedModelId);
      const nonVirtualModel = analysisModels.find((m) => !m.isVirtual);
      setLocalSelectedModel(selectedModel || nonVirtualModel);
    }
  }, [selectedTopicNodes, analysisModels, selectedModelId]);

  useEffect(() => {
    setLocalSelectedTopicNodes(selectedTopicNodes);
  }, [selectedTopicNodes, resetLocalState]);

  return (
    <StyledTopicsMenu>
      <div className="main">
        <div className="model-section">
          <div className="header first-header">
            <Text resource="filter.topics.model.label" />
          </div>
          <ModelDropdown
            selectedModel={localSelectedModel}
            options={analysisModels}
            handleSelectMenuOption={handleChangeSelectedModel}
          />
        </div>
        <StyledSeparator />
        <div className="topic-section">
          <div className="header">
            <Text resource="filter.topics.label" /> ({localSelectedTopicNodes.length})
          </div>
          <div className="topics-filter-container">
            <TopicsFilter
              localSelectedModel={localSelectedModel}
              localSelectedTopicNodes={localSelectedTopicNodes}
              setLocalSelectedTopicNodes={setLocalSelectedTopicNodes}
            />
          </div>
        </div>
      </div>
      <StyledSeparator />
      <div className="bottom-section">
        <Button variant={ButtonVariant.light} onClick={handleAddLastTopics}>
          <Text resource="button.addLastTopics" />
          &nbsp;({lastSelectedTopicNodes.length})
        </Button>
        <Button variant={ButtonVariant.primaryPurple} onClick={handleSubmit}>
          <Text resource="button.apply" />
        </Button>
      </div>
    </StyledTopicsMenu>
  );
};

const StyledSeparator = styled.div`
  width: 100%;
  border-top: 1px solid ${Color.sky50};
`;

const StyledTopicsMenu = styled.div`
  padding: 16px;
  height: 556px;
  max-width: 460px;
  min-width: 320px;
  display: flex;
  flex-direction: column;

  .main {
    flex-grow: 1;
    width: calc(100% + 15px);
    padding-right: 15px;
    /* keep the two rules - the incompatible one will be automatically disabled */
    overflow-y: auto;
    overflow-y: overlay;
  }

  .header {
    color: ${Color.gray20};
    font: normal normal bold 12px Lato;
  }

  .first-header {
    margin-bottom: 12px;
  }

  .bottom-section {
    height: 60px;
    padding-top: 16px;
    display: flex;
    align-items: center;
    column-gap: 15px;
    justify-content: space-between;
  }
  .model-section {
    padding-bottom: 16px;
  }
  .topic-section {
    padding: 16px 0;
    .topics-filter-container {
      padding-top: 8px;
    }
  }
`;

export { TopicsMenu };
