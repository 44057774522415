import { Skeleton } from "components/Skeleton";
import React from "react";
import styled from "styled-components";
import { Color } from "ts/enums/color";

export const Placeholder = () => {
  return (
    <div className="pulse">
      {[...Array(3)].map((_, i) => (
        <StyledNotificationSkeleton key={`placeholder-${i}`}>
          <Skeleton
            width={16}
            height={16}
            borderRadius="5px"
            backgroundColor={Color.neutral10}
            customStyles={{ marginRight: 16 }}
          />

          <div>
            <Skeleton
              width={348}
              height={18}
              customStyles={{ display: "block", marginBottom: 2 }}
              borderRadius="5px"
              backgroundColor={Color.blue20}
            />
            <Skeleton
              width={79}
              height={12}
              customStyles={{ display: "block", marginBottom: 10 }}
              borderRadius="5px"
              backgroundColor={Color.neutral10}
            />
            <Skeleton
              width={191}
              height={29}
              customStyles={{ display: "block" }}
              borderRadius="5px"
              backgroundColor={Color.neutral10}
            />
          </div>
        </StyledNotificationSkeleton>
      ))}
    </div>
  );
};

const StyledNotificationSkeleton = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${Color.indigo20};
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 12px;
`;
