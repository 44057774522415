import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import { AnalysisContext } from "context/AnalysisContext";
import {
  setThreshold,
  setSelectedTopicNodes,
  setSelectedModelId,
  setSelectedDemographicFilters,
  setSelectedColumnFilters,
  setSelectedAlertRange,
} from "context/AnalysisContext/actions";
import { removeEmptyDemographicFilters } from "utils/filters";

import { AnalysisModel } from "ts/filters/analysisModel";
import { DemographicFilter } from "ts/filters/demographicFilter";
import { ApiTopicNode } from "ts/topic";
import { ButtonVariant } from "ts/enums/button";
import { Range } from "ts/range";
import { DEFAULT_ALERT_RANGE } from "assets/constants/defaultAlertRange";

import { FilterSelectionSection } from "../FilterSelectionSection";
import { Header } from "components/SideDrawer/Header";
import { StyledFooter } from "components/SideDrawer/StyledFooter";
import { Button } from "components/_buttons/Button";
import { Text } from "components/Text";

type Props = {
  isOpen: boolean;
  closeHandler: () => void;
};

export const AnalysisFilterDrawerLayout = ({ isOpen, closeHandler }: Props) => {
  const [state, dispatch] = useContext(AnalysisContext);

  const location = useLocation();

  // useState
  const [localSelectedTopicNodes, setLocalSelectedTopicNodes] = useState<ApiTopicNode[]>([]);
  const [localSelectedModel, setLocalSelectedModel] = useState<AnalysisModel>(null);
  const [thresholdInputVal, setThresholdInputVal] = useState<number>(null);
  const [currentDemographicFilters, setCurrentDemographicFilters] = useState<DemographicFilter[]>(
    []
  );
  const [localSelectedColumnFilters, setLocalSelectedColumnFilters] = useState<string[]>([]);
  const [localAlertRange, setLocalAlertRange] = useState<Range>();

  // useEffects to set initial drawer values based off of context
  useEffect(() => {
    setThresholdInputVal(state.threshold);
  }, [state.threshold, isOpen]);

  useEffect(() => {
    setLocalSelectedModel(state.analysisModels?.[0]);
  }, [state.analysisModels, isOpen]);

  useEffect(() => {
    setLocalSelectedTopicNodes(state.selectedTopicNodes);
  }, [state.selectedTopicNodes, isOpen]);

  useEffect(() => {
    if (state.analysisModels) {
      const selectedCustomModel = state.analysisModels.find(
        (m) => m.modelId === state.selectedModelId
      );
      const nonCustomModel = state.analysisModels.find((m) => !m.isVirtual);
      setLocalSelectedModel(selectedCustomModel || nonCustomModel);
    }
  }, [state.selectedTopicNodes, state.analysisModels, state.selectedModelId, isOpen]);

  useEffect(() => {
    setCurrentDemographicFilters(state.selectedDemographicFilters);
  }, [isOpen, state.selectedDemographicFilters]);

  useEffect(() => {
    setLocalSelectedColumnFilters(state.selectedColumnFilters);
  }, [isOpen, state.selectedColumnFilters]);

  useEffect(() => {
    // the alert range should be set and available only in the comments side drawer
    // the alert range shall not consider the alert threshold set by the user in the overview page
    if (
      location.pathname.includes("/comments") &&
      state.analysisDetails.availableResults.alerts === true
    ) {
      return state.selectedAlertRange
        ? setLocalAlertRange(state.selectedAlertRange)
        : setLocalAlertRange(DEFAULT_ALERT_RANGE);
    }
  }, [
    isOpen,
    location.pathname,
    state.selectedAlertRange,
    state.analysisDetails.availableResults.alerts,
  ]);

  // functions
  const handleChangeSelectedModel = (id) => {
    setLocalSelectedModel(state.analysisModels.find((am) => am.modelId === id));
    setLocalSelectedTopicNodes([]);
  };

  const clickApplyHandler = () => {
    dispatch(setThreshold(thresholdInputVal));
    dispatch(setSelectedTopicNodes(localSelectedTopicNodes));
    dispatch(setSelectedModelId(localSelectedModel?.modelId));
    dispatch(
      setSelectedDemographicFilters(removeEmptyDemographicFilters(currentDemographicFilters))
    );
    dispatch(setSelectedColumnFilters(localSelectedColumnFilters));
    dispatch(setSelectedAlertRange(localAlertRange));
    closeHandler();
  };

  return (
    <>
      <Header
        headerTitle={<Text resource="sideDrawer.filters.title" />}
        closeHandler={closeHandler}
      />

      <StyledFilterSelectionSectionContainer>
        <FilterSelectionSection
          {...{
            thresholdInputVal,
            setThresholdInputVal,
            localSelectedModel,
            handleChangeSelectedModel,
            currentDemographicFilters,
            setCurrentDemographicFilters,
            localSelectedTopicNodes,
            setLocalSelectedTopicNodes,
            localSelectedColumnFilters,
            setLocalSelectedColumnFilters,
            localAlertRange,
            hasAlertRange: true,
            setLocalAlertRange,
            isOpen,
          }}
        />
      </StyledFilterSelectionSectionContainer>
      <StyledFooter>
        <Button
          variant={ButtonVariant.outline}
          onClick={closeHandler}
          style={{ marginLeft: "auto" }}
        >
          <Text resource="button.cancel" />
        </Button>
        <Button variant={ButtonVariant.primary} onClick={clickApplyHandler} disabled={false}>
          <Text resource="button.apply" />
        </Button>
      </StyledFooter>
    </>
  );
};

const StyledFilterSelectionSectionContainer = styled.div`
  height: calc(100% - 25px - 50px - 92px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 25px 0 25px;
`;
