import React from "react";
import styled from "styled-components";

import { SentimentType } from "@explorance/mly-types";
import { InterpretationIconType } from "ts/enums/interpretationIconType";

import { getCommentInterpretationIcon } from "common-layouts/CommentResultsIcons/helpers";
import { sentimentStyles } from "assets/constants/sentimentStyles";
import { Color } from "ts/enums/color";
import { Text } from "components/Text";
import { Pill, PillSize } from "./Pill";

export type ResultsIconCount = {
  count: number | null;
  type: InterpretationIconType;
};

type Props = {
  sentiment: SentimentType;
  bnw?: boolean;
  showSentimentName?: boolean;
  coloredBorder?: boolean;
  customStyles?: React.CSSProperties;
};

export const SentimentPill = ({
  sentiment,
  bnw = false,
  showSentimentName,
  coloredBorder,
  customStyles,
}: Props) => (
  <Pill
    size={PillSize.sm}
    style={{
      ...sentimentStyles[sentiment],
      border:
        bnw || !coloredBorder ? `1px solid ${Color.neutral10}` : sentimentStyles[sentiment].border,
      backgroundColor: bnw ? Color.white : sentimentStyles[sentiment].backgroundColor,
      padding: "3px 6px",
      ...customStyles,
    }}
  >
    {getCommentInterpretationIcon(InterpretationIconType.sentiment, sentiment, undefined, bnw)}
    {showSentimentName && (
      <StyledSentimentText bnw={bnw}>
        <Text resource={`sentimentPill.${sentiment}`} />
      </StyledSentimentText>
    )}
  </Pill>
);

const StyledSentimentText = styled.div<{ bnw?: boolean }>`
  color: ${({ bnw }) => bnw && Color.gray50};
  font-weight: bold;
  font-size: 12px;
  margin-left: 3px;
`;
