import React from "react";
import styled from "styled-components";

import { Button } from "components/_buttons/Button";
import { ButtonVariant } from "ts/enums/button";
import { Icon, IconType } from "components/_icons/Icon";
import { Color } from "ts/enums/color";
import { TextType } from "ts/TextType";

type ErrorInfo = {
  hasError: boolean;
  errorLabel: TextType;
};

type Props = {
  backToListButtonLabel: TextType;
  showErrors: boolean;
  errorInfo: ErrorInfo[];
  pageHasBeenEdited: boolean;
  discardButtonLabel: TextType;
  saveButtonLabel: TextType;
  onClickBackToButton(): void;
  onClickDiscard(): void;
  onClickSave(): void;
};

export const EditPageBottomContainer = ({
  backToListButtonLabel,
  showErrors,
  errorInfo,
  pageHasBeenEdited,
  discardButtonLabel,
  saveButtonLabel,
  onClickBackToButton,
  onClickDiscard,
  onClickSave,
}: Props) => {
  const getErrorLabel = (label: TextType, index: number) => (
    <span key={index}>
      <Icon type={IconType.alert} color={Color.red30} />
      {label}
    </span>
  );
  return (
    <StyledBottomContainer className="fade-in">
      <Button variant={ButtonVariant.outline} onClick={onClickBackToButton}>
        {backToListButtonLabel}
      </Button>
      <div className="errors-container">
        {errorInfo.map(
          (x, index) => showErrors && x.hasError && getErrorLabel(x.errorLabel, index)
        )}
      </div>
      <div className="right-container">
        <Button
          variant={ButtonVariant.light}
          disabled={!pageHasBeenEdited}
          onClick={onClickDiscard}
        >
          {discardButtonLabel}
        </Button>
        <Button onClick={onClickSave} style={{ marginLeft: 10 }} disabled={!pageHasBeenEdited}>
          {saveButtonLabel}
        </Button>
      </div>
    </StyledBottomContainer>
  );
};

const StyledBottomContainer = styled.div`
  display: flex;
  margin-top: 16px;

  .errors-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: auto;
    margin-left: 8px;
    font-size: 1em;
    color: ${Color.red30};

    span {
      display: flex;

      svg {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        margin-top: 2.5px;
      }
    }
  }
  .right-container {
    margin-left: auto;
    display: flex;
  }
`;
