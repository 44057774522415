import { UserSortingParameter } from "ts/enums/sorting";

export const USER_LIST_HEADERS = [
  { columnNameKey: "avatar" },
  { columnNameKey: "username", sortingParameter: UserSortingParameter.Username },
  { columnNameKey: "firstName", sortingParameter: UserSortingParameter.FirstName },
  { columnNameKey: "lastName", sortingParameter: UserSortingParameter.LastName },
  { columnNameKey: "roleType", sortingParameter: UserSortingParameter.RoleType },
  { columnNameKey: "lastLogin", sortingParameter: UserSortingParameter.LastLogin },
  { columnNameKey: "menu" },
];
