import React, { ReactNode, useState } from "react";
import styled, { css } from "styled-components";

import { Color } from "ts/enums/color";
import { ExpandCollapseButton } from "components/_buttons/ExpandCollapseButton";
import { StyledBottomDragIndicator } from "pages/analysis/[id]/dashboard/_layouts/ManageWidgetsModal/DraggableGroupCard";
import { TextInput } from "components/_inputs/TextInput";
import { MoveUpDownButtons } from "components/_buttons/MoveUpDownButtons";
import { VerticalSeparator } from "components/Separators/VerticalSeparator";
import { useAppDispatch } from "store";
import { changeSectionName, moveContainer } from "store/widgetOrdering/widgetOrderingModal";

type CustomStyles = {
  backgroundColor?: string;
  removePaddingButtom?: boolean;
};

type Props = {
  titleLeft: string;
  children: ReactNode;
  customStyles: CustomStyles;
  bottomDragIndicator: boolean;
  isExpanded: boolean;
  disabled: boolean;
  groupId: number;
  onExpandedChange: (expanded: boolean) => void;
};

export const CollapsibleWidgetContainer = ({
  titleLeft,
  children,
  customStyles,
  bottomDragIndicator,
  groupId,
  disabled,
  isExpanded: expanded,
  onExpandedChange,
}: Props) => {
  const dispatch = useAppDispatch();
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded);

  const handleExpand = () => {
    onExpandedChange && onExpandedChange(!isExpanded);
    setIsExpanded((expand) => !expand);
  };

  return (
    <StyledCollapsibleWidgetContainer backgroundColor={customStyles?.backgroundColor}>
      <StyledHeaderContainer
        isExpanded={isExpanded}
        removePaddingButtom={customStyles?.removePaddingButtom}
      >
        <StyledClickableHeader data-no-dnd="true">
          <ExpandCollapseButton
            isCollapsed={!isExpanded}
            customWidth={16}
            customHeight={16}
            disabled={disabled}
            onClick={handleExpand}
          />
          <TextInput
            value={titleLeft}
            onChange={(e) => dispatch(changeSectionName({ title: e.target.value, id: groupId }))}
            customStyles={{ bold: true, fontSize: "15px" }}
          />
          <VerticalSeparator height="32px" />
          <MoveUpDownButtons
            id={groupId}
            moveItem={(direction, id) => dispatch(moveContainer({ direction, id }))}
          />
        </StyledClickableHeader>
      </StyledHeaderContainer>
      <StyledChildrenContainer isExpanded={isExpanded}>{children}</StyledChildrenContainer>
      {bottomDragIndicator && !isExpanded && <StyledBottomDragIndicator />}
    </StyledCollapsibleWidgetContainer>
  );
};

const StyledCollapsibleWidgetContainer = styled.div<{
  backgroundColor?: string;
}>`
  padding: 16px;
  border-radius: 2px;
  background-color: ${Color.white};
  font-size: 15px;
  background-color: ${({ backgroundColor }) => backgroundColor || Color.white};
`;

const StyledHeaderContainer = styled.div<{
  isExpanded: boolean;
  removePaddingButtom: boolean;
}>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid transparent;
  flex-wrap: wrap;
  row-gap: 10px;
  cursor: pointer;

  ${({ isExpanded, removePaddingButtom }) =>
    isExpanded &&
    css`
      padding-bottom: ${removePaddingButtom ? "0px" : "10px"};
      transition: 200ms padding-bottom ease-in, 200ms border-bottom ease-in;
    `}

  span {
    font-weight: bold;
  }
`;

const StyledClickableHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  user-select: none;
  flex: 1;
`;

const StyledChildrenContainer = styled.div<{ isExpanded: boolean }>`
  opacity: ${({ isExpanded }) => (isExpanded ? 1 : 0)};
  max-height: ${({ isExpanded }) => (isExpanded ? "100000px" : "0px")};
  overflow: ${({ isExpanded }) => (isExpanded ? "visible" : "hidden")};
  cursor: default;
  transition: ${({ isExpanded }) =>
    isExpanded ? "opacity 200ms ease-in, max-height 200ms ease-in" : "max-height"};
`;
