import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { RoleType } from "@explorance/mly-types";
import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";

import useClickOutside from "hooks/useClickOutside";
import { getUserRolesList } from "services/users";

import { Icon, IconType } from "components/_icons/Icon";
import { StyledFieldLabel } from "components/UserProfileEditorField/index";
import { StyledDropdownTransition } from "components/DropdownMenu/StyledDropdownTransition";
import { Button } from "components/_buttons/Button";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Text } from "components/Text";

type Props = {
  currentRole: RoleType;
  disabled: boolean;
  isInvalid: boolean;
  onSelectRole(roleType: RoleType): void;
};

export const UserRoleDropdown = ({ currentRole, disabled, isInvalid, onSelectRole }: Props) => {
  const [roleTypes, setRoleTypes] = useState<RoleType[]>([]);
  const [showRolesDropdown, setShowRolesDropdown] = useState<boolean>(false);

  useEffect(() => {
    getUserRolesList().then(({ data }) => setRoleTypes(data.roles as RoleType[]));
  }, []);

  const roleDropdownRef = useClickOutside(() => setShowRolesDropdown(false));

  const onClickRole = (type: RoleType) => () => {
    onSelectRole(type);
    setShowRolesDropdown(false);
  };

  return (
    <div>
      <StyledFieldLabel>
        <Text resource="userProfile.roleField.label" />
      </StyledFieldLabel>

      <StyledUserRoleDropdownContainer ref={roleDropdownRef}>
        <Button
          variant={isInvalid ? ButtonVariant.error : ButtonVariant.outlineGray}
          size={ButtonSize.md}
          disabled={disabled}
          style={{ justifyContent: "flex-start", width: "100%" }}
          onClick={() => setShowRolesDropdown((show) => !show)}
        >
          <Text resource={`roleType.${currentRole}`} />
          <Icon
            type={IconType.chevronDown}
            size={10}
            color={Color.gray20}
            style={{ position: "absolute", right: 18, top: 14 }}
          />
        </Button>
        <StyledUserRoleDropdown show={showRolesDropdown}>
          <ul>
            {roleTypes.map((type) => (
              <li key={type} onClick={onClickRole(type as RoleType)}>
                <div className="role-title">
                  <Text resource={`roleType.${type}`} />
                </div>
                <div className="role-description">
                  <Text resource={`roleType.${type}.description`} />
                </div>
              </li>
            ))}
          </ul>
        </StyledUserRoleDropdown>
      </StyledUserRoleDropdownContainer>
    </div>
  );
};

const StyledUserRoleDropdownContainer = styled.div`
  margin-top: 5px;
  margin-bottom: 0px;
  position: relative;
`;

const StyledUserRoleDropdown = styled(StyledDropdownTransition)<{ show: boolean }>`
  position: absolute;
  top: 41px;
  left: 0px;
  width: 352px;
  z-index: ${ZIndexStackingContext.low};
  border: 1px solid ${Color.sky50};
  border-radius: 2px;
  box-shadow: 0px 3px 6px #00000029;
  background: ${Color.white};
  font-size: 0.875em;
  padding-bottom: 5px;

  ul {
    list-style: none;
    padding: 5px 0;
    text-align: left;
    margin: 3px 0;

    li {
      margin: 5px 0;
      padding: 3px 10px 3px 10px;
      color: ${Color.gray40};
      cursor: pointer;

      .role-title {
        color: ${Color.gray50};
        font-weight: bold;
      }

      .role-description {
        color: ${Color.gray40};
      }
    }

    li:hover {
      background-color: ${Color.sky15};

      .role-title {
        color: ${Color.blue50};
      }
    }
  }
`;
