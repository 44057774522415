import React, { ReactNode } from "react";
import styled from "styled-components";
import { Icon, IconType } from "components/_icons/Icon";
import { Color } from "ts/enums/color";

type Props = {
  isCollapsed: boolean;
  disabled?: boolean;
  customWidth?: number;
  customHeight?: number;
  isLoading?: boolean;
  onClick(): void;
};

export const ExpandCollapseButton = ({
  isCollapsed,
  disabled,
  customWidth = 24,
  customHeight = 24,
  isLoading,
  onClick,
}: Props) => {
  const getButtonIcon = (): ReactNode => {
    if (isLoading) return <StyledLoadingButton className="pulse" />;
    if (disabled) return <Icon type={IconType.minus} color={Color.blue20} size={14} />;
    if (isCollapsed) return <Icon type={IconType.plus} color={Color.gray30} size={14} />;
    return <Icon type={IconType.minus} color={Color.gray30} size={14} />;
  };

  return (
    <StyledExpandCollapseButton
      disabled={disabled}
      customWidth={customWidth}
      customHeight={customHeight}
      onClick={disabled ? null : onClick}
    >
      {getButtonIcon()}
    </StyledExpandCollapseButton>
  );
};

type StyledProps = {
  disabled: boolean;
  customWidth: number;
  customHeight: number;
};

const StyledExpandCollapseButton = styled.div<StyledProps>`
  display: flex;
  justify-content: center;
  height: ${({ customHeight }) => customHeight}px;
  width: ${({ customWidth }) => customWidth}px;
  border-radius: 3px;
  ${(props) => !props.disabled && "cursor: pointer;"}
  background: ${(props) => (props.disabled ? Color.sky15 : Color.sky40)};

  svg {
    display: block;
    margin: auto;
  }
`;

const StyledLoadingButton = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${Color.sky40};
  border-radius: 3px;
`;
