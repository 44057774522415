import React from "react";
import styled from "styled-components";

import { Color } from "ts/enums/color";
import { useAppDispatch } from "store";
import { executeQuickAnalysis } from "store/analysis/thunks";
import { Text } from "components/Text";

export const QuickAnalysisRetry = () => {
  const dispatch = useAppDispatch();

  return (
    <StyledTryAgainButton onClick={() => dispatch(executeQuickAnalysis())}>
      <Text resource="quickAnalysis.error.tooManyRequests.cta" />
    </StyledTryAgainButton>
  );
};

const StyledTryAgainButton = styled.button`
  border: none;
  color: ${Color.blue40};
  font-weight: bold;
  background: transparent;
  font-size: 1em;
  text-decoration: underline;
  padding: 0;
`;
