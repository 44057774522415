import React from "react";

import { useSharingContext } from "context/SharingContext";
import { getUserFullName } from "utils/getUserFullName";
import { cloneDeep } from "lodash";
import { Pill, PillSize } from "components/_pills/Pill";
import { ExpandableFilterPillWrapper } from "common-layouts/FilterSelectionSection/FilterPillList/ExpandableFilterPillWrapper";

import { outlineBluePillStyle } from "ts/filters/pillStyle";
import { Color } from "ts/enums/color";
import { PILLS_LIMITS } from "assets/constants/sharingDraftLimits";
import { Text } from "components/Text";

export const SelectedUserPills = () => {
  const {
    sharingState,
    updateSharingState,
    removeAllUsersFromDraft,
    handleUserSharingDraft,
    validateUsersShown,
  } = useSharingContext();
  const { selectedUsers, selectedUsersCount } = sharingState;
  const loadMoreCount = selectedUsersCount - selectedUsers.filter((u) => u.isShown).length;
  const shownUsersCount = selectedUsers.filter((u) => u.isShown).length;

  const handleDeletePill = (selectedUserId: number) => {
    const filteredSelectedUsers = selectedUsers.filter((u) => u.id !== selectedUserId);
    updateSharingState("selectedUsers", validateUsersShown(filteredSelectedUsers));
    const apiData = cloneDeep(sharingState.shareableUsersApiData);
    const apiUserIndex = apiData.users.findIndex((c) => c.id === selectedUserId);
    if (apiUserIndex !== -1) {
      apiData.users.splice(apiUserIndex, 1, {
        ...apiData.users[apiUserIndex],
        isSelected: !apiData.users[apiUserIndex].isSelected,
      });
    }
    updateSharingState("shareableUsersApiData", apiData);
    handleUserSharingDraft([selectedUserId], true);
  };

  const loadMorePills = () => {
    const newLimit = shownUsersCount + PILLS_LIMITS.REQUEST_LIMIT;
    if (selectedUsers.length < selectedUsersCount) {
      updateSharingState("pillsPage", sharingState.pillsPage + 1);
    } else {
      updateSharingState(
        "selectedUsers",
        selectedUsers.map((u, i) => ({ ...u, isShown: i < newLimit }))
      );
    }
  };

  const handleDeleteAllSelected = () => {
    const apiData = cloneDeep(sharingState.shareableUsersApiData);
    apiData.users.forEach((u) => {
      if (u.isSelected) {
        u.isSelected = false;
      }
    });
    updateSharingState("shareableUsersApiData", apiData);
    updateSharingState("selectedUsers", []);
    removeAllUsersFromDraft();
  };

  return (
    <ExpandableFilterPillWrapper
      showDeleteFilters={true}
      deleteButtonLabelKey="sharing.pill.clearSelection"
      deleteFilters={handleDeleteAllSelected}
    >
      <b>
        <Text
          resource={{
            key:
              selectedUsersCount === 1
                ? "sharing.pill.usersSelectedCount.singular"
                : "sharing.pill.usersSelectedCount",
            args: [`${selectedUsersCount}`],
          }}
        />
      </b>
      {selectedUsers.map(
        (selectedUser) =>
          selectedUser.isShown && (
            <Pill
              key={selectedUser.id}
              backgroundColor={outlineBluePillStyle.backgroundColor}
              hoverColor={outlineBluePillStyle.hoverBackgroundColor}
              color={outlineBluePillStyle.color}
              border={outlineBluePillStyle.border}
              size={PillSize.sm}
              deleteButtonHoverBackgroundColor={
                outlineBluePillStyle.deleteButtonHoverBackgroundColor
              }
              style={{ fontSize: "14px" }}
              onDeletePill={() => handleDeletePill(selectedUser.id)}
            >
              {getUserFullName({
                firstname: selectedUser.firstname,
                lastname: selectedUser.lastname,
              })}
            </Pill>
          )
      )}
      {loadMoreCount > 0 && (
        <Pill
          backgroundColor={Color.blue50}
          style={{ cursor: "pointer" }}
          color={Color.white}
          round={true}
          size={PillSize.sm}
          onClick={loadMorePills}
        >
          +{loadMoreCount}
        </Pill>
      )}
    </ExpandableFilterPillWrapper>
  );
};
