import React from "react";
import styled from "styled-components";

type Props = {
  scaleSize?: number;
};

export const LoadingDots = ({ scaleSize }: Props) => {
  return (
    <StyledLoadingDots scaleSize={scaleSize}>
      <div className="loading-anim">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </StyledLoadingDots>
  );
};

const StyledLoadingDots = styled.div<{ scaleSize: number }>`
  width: 35px;
  height: 35px;
  display: inline-block;
  background: rgba(255, 255, 255, 0);
  transform: scale(${({ scaleSize }) => scaleSize ?? 1});

  @keyframes loading-anim {
    0% {
      transform: translate(6px, 40px) scale(0);
    }
    25% {
      transform: translate(6px, 40px) scale(0);
    }
    50% {
      transform: translate(6px, 40px) scale(1);
    }
    75% {
      transform: translate(40px, 40px) scale(1);
    }
    100% {
      transform: translate(74px, 40px) scale(1);
    }
  }

  @keyframes loading-anim-r {
    0% {
      transform: translate(74px, 40px) scale(1);
    }
    100% {
      transform: translate(74px, 40px) scale(0);
    }
  }

  @keyframes loading-anim-c {
    0% {
      background: #f99a9b;
    }
    25% {
      background: #00b8ec;
    }
    50% {
      background: #7be5a6;
    }
    75% {
      background: #fede90;
    }
    100% {
      background: #f99a9b;
    }
  }

  .loading-anim div {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transform: translate(40px, 40px) scale(1);
    background: #f99a9b;
    animation: loading-anim 2s infinite cubic-bezier(0, 0.5, 0.5, 1);
  }

  .loading-anim div:nth-child(1) {
    background: #fede90;
    transform: translate(74px, 40px) scale(1);
    animation: loading-anim-r 0.5s infinite cubic-bezier(0, 0.5, 0.5, 1),
      loading-anim-c 2s infinite step-start;
  }

  .loading-anim div:nth-child(2) {
    animation-delay: -0.5s;
    background: #f99a9b;
  }

  .loading-anim div:nth-child(3) {
    animation-delay: -1s;
    background: #fede90;
  }

  .loading-anim div:nth-child(4) {
    animation-delay: -1.5s;
    background: #7be5a6;
  }

  .loading-anim div:nth-child(5) {
    animation-delay: -2s;
    background: #00b8ec;
  }

  .loading-anim {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.35);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }

  .loading-anim div {
    box-sizing: content-box;
  }
`;
