import { SortingOrder } from "@explorance/mly-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { VariableMappingsPresetListSortingParameter } from "ts/enums/sorting";
import { VariablePresetDetail } from "ts/variableMappings";
import { fetchVariableMappings } from "./thunks";

type GlossariesListSorting = {
  sortColumn: VariableMappingsPresetListSortingParameter;
  sortOrder: SortingOrder;
};
type GlossariesListState = {
  variableMappingList: VariablePresetDetail[];
  currentPage: number;
  totalPresetCount: number;
  currentPresetCount: number;
  searchField: string;
  sortColumn: VariableMappingsPresetListSortingParameter;
  sortOrder: SortingOrder;
  isSearchReset: boolean;
  emptyStateType: EmptyStateType;
  showDeleteConfirmation: boolean;
  currentEditedRow: VariablePresetDetail;
  isLoading: boolean;
  initialLoadComplete: boolean;
};

const initialState: GlossariesListState = {
  variableMappingList: [],
  currentPage: 1,
  totalPresetCount: 0,
  currentPresetCount: 0,
  searchField: "",
  sortColumn: VariableMappingsPresetListSortingParameter.LastUpdated,
  sortOrder: SortingOrder.DESC,
  isSearchReset: false,
  emptyStateType: null,
  showDeleteConfirmation: false,
  currentEditedRow: null,
  isLoading: false,
  initialLoadComplete: false,
};

const GlossariesListSlice = createSlice({
  name: "glossariesListSlice",
  initialState,
  reducers: {
    setShowDeleteConfirmation: (state, action: PayloadAction<boolean>) => {
      state.showDeleteConfirmation = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setSearchField: (state, action: PayloadAction<string>) => {
      state.searchField = action.payload;
    },
    setIsSearchReset: (state, action: PayloadAction<boolean>) => {
      state.isSearchReset = action.payload;
    },
    updateGlossariesListSorting: (state, action: PayloadAction<GlossariesListSorting>) => {
      state.currentPage = 1;
      state.sortOrder = action.payload.sortOrder;
      state.sortColumn = action.payload.sortColumn;
    },
    setCurrentEditedRow: (state, action: PayloadAction<VariablePresetDetail>) => {
      state.currentEditedRow = action.payload;
    },
    setEmptyStateType: (state, action: PayloadAction<EmptyStateType>) => {
      state.emptyStateType = action.payload;
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVariableMappings.pending, (state) => {
      if (!state.initialLoadComplete) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchVariableMappings.fulfilled, (state, action) => {
      state.variableMappingList = action.payload.mappings;
      state.currentPresetCount = action.payload.itemCount;
      state.totalPresetCount = action.payload.totalCount;
      state.isLoading = false;
      state.initialLoadComplete = true;
    });
    builder.addCase(fetchVariableMappings.rejected, (state) => {
      state.isLoading = false;
      state.initialLoadComplete = true;
    });
  },
});
export const {
  setShowDeleteConfirmation,
  updateGlossariesListSorting,
  setCurrentPage,
  setSearchField,
  setIsSearchReset,
  setCurrentEditedRow,
  setEmptyStateType,
  clearState,
} = GlossariesListSlice.actions;
export default GlossariesListSlice.reducer;
