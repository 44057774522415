import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "store";
import styled from "styled-components";

import { setUpdatedMappings } from "store/editVariableMapping/editVariableMappingSlice";
import { generateEmptyRows } from "utils/variableMapping";

import { VariableMappingDefinitionListRow } from "./VariableMappingDefinitionListRow";
import { Color } from "ts/enums/color";
import { Text } from "components/Text";

export const VariableMappingDefinitionList = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.editVariableMapping);
  // hooks
  const [listWidth, setListWidth] = useState<string>();

  // functions
  const getListWidth = () => {
    const mainBody = document.querySelector(".main-body");
    // set width based on whether a page scrollbar is present
    const width =
      mainBody.scrollHeight > mainBody.clientHeight
        ? window.innerWidth - 25
        : window.innerWidth - 16;
    const mainContent = document.querySelector(".main-content");
    // use the margin-left of the container to know how much to offset the width by
    const bodyMarginLeft = window.getComputedStyle(mainContent).marginLeft;
    const newWidth = width - parseInt(bodyMarginLeft);
    setListWidth(`${newWidth}px`);
  };

  // hooks
  useEffect(() => {
    getListWidth();

    window.addEventListener("resize", getListWidth);
    return () => window.removeEventListener("resize", getListWidth);
  }, []);

  // functions

  const addMoreRows = () => {
    dispatch(setUpdatedMappings([...state.updatedMappings, ...generateEmptyRows(10)]));
  };

  const deleteRow = (id: number) => {
    const initialCount = state.updatedMappings.length;
    const updatedMappingItems = [...state.updatedMappings].filter((m, index) => index !== id);
    dispatch(
      setUpdatedMappings([
        ...updatedMappingItems,
        ...generateEmptyRows(initialCount - updatedMappingItems.length),
      ])
    );
  };

  const updateVariable = (newVariable: string, index: number) => {
    const updatedItems = JSON.parse(JSON.stringify(state.updatedMappings));
    updatedItems[index].variable = newVariable;
    dispatch(setUpdatedMappings(updatedItems));
  };

  const updateDefinition = (newDefinition: string, index: number) => {
    const updatedItems = JSON.parse(JSON.stringify(state.updatedMappings));
    updatedItems[index].definition = newDefinition;
    dispatch(setUpdatedMappings(updatedItems));
  };

  return (
    <StyledVariableMappingDefinitionList>
      <StyledListHeader>
        <StyledHeader>
          <Text resource="variableMappingGroup.column.variable.label" />
        </StyledHeader>
        <StyledHeader>
          <Text resource="variableMappingGroup.column.definition.label" />
        </StyledHeader>
      </StyledListHeader>
      <StyledListBody listWidth={listWidth}>
        {state.updatedMappings?.map((_, i) => (
          <VariableMappingDefinitionListRow
            key={i}
            mappingIndex={i}
            deleteRow={deleteRow}
            updateVariable={updateVariable}
            updateDefinition={updateDefinition}
          />
        ))}
        <StyledAddRowsButton onClick={addMoreRows}>
          <Text resource="button.addMoreRows" />
        </StyledAddRowsButton>
      </StyledListBody>
    </StyledVariableMappingDefinitionList>
  );
};

const StyledVariableMappingDefinitionList = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  border: 1px solid ${Color.blue20};
  color: ${Color.black};
  padding: 0px 16px 0px 16px;
  font-size: 0.875em;
  height: 70vh;
`;

const StyledListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0px 12px 0px;
  background-color: ${Color.white};
  font-weight: bold;
  column-gap: 136px;

  @media (max-width: 1365px) {
    column-gap: 190px;
  }
`;

const StyledHeader = styled.div`
  width: 600px;

  @media (max-width: 1365px) {
    width: 400px;
  }
`;

const StyledListBody = styled.div<{ listWidth: string }>`
  overflow-y: scroll;
  width: ${({ listWidth }) => listWidth};

  ::-webkit-scrollbar {
    height: 0;
  }
`;

const StyledAddRowsButton = styled.button`
  font-size: 0.875rem;
  color: ${Color.blue40};
  margin-bottom: 10px;
  font-weight: bold;
  background: ${Color.white};
  border: none;
  padding: 0;

  @media (max-width: 1365px) {
    margin-left: 780px;
  }

  @media (min-width: 1366px) {
    margin-left: 1025px;
  }
`;
