import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_LIST_LIMIT } from "assets/constants/listLimits";
import { upperFirst } from "lodash";
import { getVariableMappings } from "services/variableMappings";

import { EmptyStateType } from "ts/enums/emptyStateType";
import { VariableMappingsPresetListSortingParameter } from "ts/enums/sorting";
import { VariableMappingApiData } from "ts/variableMappings";
import { setEmptyStateType } from "./glossariesListSlice";
import { AppState } from "store/appReducer";

export const fetchVariableMappings = createAsyncThunk<
  VariableMappingApiData,
  void,
  { state: AppState }
>(
  "variableMappingsList/fetchVariableMappings",
  async (_, { getState, dispatch }): Promise<VariableMappingApiData> => {
    const state = getState();
    const variableMappingListState = state.glossariesList;
    const searchTerm = state.search.searchTerm;

    //if sort parameter is dateCreated we want to use the backend version of the parameter
    const variableMappingDateCreatedColumn =
      variableMappingListState.sortColumn === VariableMappingsPresetListSortingParameter.DateCreated
        ? VariableMappingsPresetListSortingParameter.DateForBackend
        : null;

    const { data: variableMappingData } = await getVariableMappings(
      searchTerm,
      DEFAULT_LIST_LIMIT,
      variableMappingListState.currentPage,
      variableMappingDateCreatedColumn ??
        VariableMappingsPresetListSortingParameter[upperFirst(variableMappingListState.sortColumn)],
      variableMappingListState.sortOrder
    );

    const emptyStateType =
      variableMappingData.itemCount === 0 && searchTerm?.length === 0
        ? EmptyStateType.noListResults
        : variableMappingData.itemCount === 0 && searchTerm.length > 0
        ? EmptyStateType.noSearchResults
        : null;

    dispatch(setEmptyStateType(emptyStateType));

    if (!variableMappingData) {
      throw new Error("Failed to fetch glossaries");
    }
    return variableMappingData;
  }
);
