import React from "react";
import styled from "styled-components";
import notSupportedImg from "assets/images/not-supported.svg";
import { Color } from "ts/enums/color";
import { Text } from "components/Text";

export const ScreenSizeNotSupported = () => (
  <StyledScreenSizeNotSupportedPage className="notSupported">
    <img src={notSupportedImg} alt="" />

    <h1>
      <Text resource="screenSizeNotSupported.title" />
    </h1>
    <p>
      <Text resource="screenSizeNotSupported.description" />
    </p>
  </StyledScreenSizeNotSupportedPage>
);

const StyledScreenSizeNotSupportedPage = styled.div`
  text-align: center;
  cursor: default;
  font-family: Lato, sans-serif;
  background-color: ${Color.white};

  img {
    margin-top: 80px;
    margin-bottom: 30px;
  }

  h1 {
    font-size: 1.5em;
    font-weight: bold;
    color: ${Color.gray50};
    margin: 0px;
  }

  p {
    margin-top: 10px;
    text-align: center;
    color: ${Color.gray40};
    font-size: 1em;
  }
`;
