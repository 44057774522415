import React from "react";
import { useAppSelector } from "store";
import { useUserProfile } from "context/UserProfileContext";

import { StyledSSOProviderFieldContainer } from "./AuthenticationBlock";
import { UserProfileEditorField } from "components/UserProfileEditorField";
import { ToggleSwitch } from "components/_inputs/ToggleSwitch";
import { Text } from "components/Text";

import { RoleType } from "@explorance/mly-types";
import { SSOMapping } from "ts/user";

type Props = {
  ssoMapping: SSOMapping;
  index: number;
  handleBlur: () => boolean;
};

export const SsoMappingField = ({ ssoMapping, index, handleBlur }: Props) => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);

  const { fields, setField, fieldErrors } = useUserProfile();

  const ssoMappingField = fields.ssoMappings.find((ssoM) => ssoM.ssoType === ssoMapping.ssoType);

  const handleChangeSsoMappingField = (newSsoMapping: SSOMapping) => {
    const newSsoMappings = fields.ssoMappings.map((ssoM) =>
      ssoM.ssoType === newSsoMapping.ssoType ? newSsoMapping : ssoM
    );
    setField("ssoMappings", newSsoMappings);
  };

  return (
    <StyledSSOProviderFieldContainer key={ssoMapping.ssoType}>
      <UserProfileEditorField
        label={<Text resource="userProfile.auth.ssoProvider.label" />}
        value={ssoMapping.ssoType}
        isDisabled
        handleChangeFieldValue={() => undefined}
        style={{ marginRight: 16 }}
      />
      <UserProfileEditorField
        label={<Text resource="userProfile.auth.metaId.label" />}
        value={ssoMapping.username}
        handleChangeFieldValue={(value) =>
          handleChangeSsoMappingField({ ...ssoMappingField, username: value })
        }
        required={ssoMappingField.activated}
        isInvalid={fieldErrors.ssoMappings[index]?.length > 0}
        handleBlur={handleBlur}
        isDisabled={currentUser.roleType === RoleType.Viewer}
      />
      <ToggleSwitch
        checked={ssoMappingField.activated}
        labelKey=""
        isDisabled={currentUser.roleType === RoleType.Viewer}
        onChange={(value) => {
          handleChangeSsoMappingField({ ...ssoMappingField, activated: value });
        }}
      />
    </StyledSSOProviderFieldContainer>
  );
};
