import React from "react";
import styled from "styled-components";

import { TextType } from "ts/TextType";
import { Color } from "ts/enums/color";

type Props = {
  title: TextType;
};

export const OverviewSectionTitle = ({ title }: Props) => {
  return (
    <StyledOverviewSectionTitle>
      <StyledLine />
      <StyledTitle>{title}</StyledTitle>
      <StyledLine />
    </StyledOverviewSectionTitle>
  );
};

const StyledOverviewSectionTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const StyledTitle = styled.div`
  font-size: 12px;
  color: ${Color.indigo50};
  font-weight: bold;
  margin: 0px 24px;
`;

const StyledLine = styled.hr`
  color: ${Color.indigo30};
  background-color: ${Color.indigo30};
  height: 1px;
  border: none;
  flex: 1 0 auto;
`;
