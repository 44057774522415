import styled from "styled-components";
import { Color } from "ts/enums/color";

export const StyledBlock = styled.div`
  background-color: ${Color.white};
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 16px;
  margin-bottom: 25px;
`;
