export enum ShareByOption {
  Users = "Users",
  Groups = "Groups",
}

export enum SharingPageTab {
  Show = "Show",
  Edit = "Edit",
}

export enum SharingView {
  UsersSharedWith = "UsersSharedWith",
  GroupsSharedWith = "GroupsSharedWith",
  ShareToUsers = "ShareToUsers",
  ShareToGroups = "ShareToGroups",
}

export enum ShareStep {
  UserSelection = "UserSelection",
  PermissionSettings = "PermissionSettings",
}

// mly-types coming soon
export enum DefaultWidgets {
  None = "None",
  Owner = "Owner",
  Base = "Base",
}
