import React, { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";

import { DotsMenuButton } from "components/_buttons/DotsMenuButton";
import { DropdownMenuItem, DropdownSize } from "ts/dropdown";
import { Tooltip } from "components/Tooltip";
import { DropdownMenu } from "components/DropdownMenu";

import useClickOutside from "hooks/useClickOutside";
import { useResource } from "hooks/useResource";

import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { DEFAULT_DROPDOWN_TRANSITION_TIME } from "components/DropdownMenu/StyledDropdownTransition";

type Props = {
  menuContents: DropdownMenuItem[];
  showDotsButton: boolean;
  menuWidth?: number;
  hasTooltip?: boolean;
  tooltipId?: number;
  shouldCloseMenuOnClick?: boolean;
  altDropdownContent?: ReactElement;
  tooltipDelayHide?: number;
  onDotsMenuClick?: () => void;
  handleHideUserDetails?: () => void;
};

export const DotsMenu = ({
  menuContents,
  showDotsButton,
  menuWidth,
  hasTooltip,
  tooltipId,
  shouldCloseMenuOnClick,
  altDropdownContent,
  tooltipDelayHide,
  onDotsMenuClick,
  handleHideUserDetails,
}: Props) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [popupHeight, setPopupHeight] = useState<number>(0);

  const dotsMenuRef = useClickOutside(() => {
    setShowMenu(false);
  });

  const handleDotsMenuClick = () => {
    onDotsMenuClick && onDotsMenuClick();
    setShowMenu((prevValue) => !prevValue);
  };

  const { getResource } = useResource();

  useEffect(() => {
    if (!showMenu && handleHideUserDetails) {
      setTimeout(() => handleHideUserDetails(), DEFAULT_DROPDOWN_TRANSITION_TIME);
    }

    if (showMenu) {
      setPopupHeight(dotsMenuRef.current.getBoundingClientRect().top);
    }
  }, [showMenu]); //eslint-disable-line

  useEffect(() => {
    shouldCloseMenuOnClick && setShowMenu(false);
  }, [shouldCloseMenuOnClick]);

  return (
    <StyledDotsMenu
      showDotsButton={showDotsButton || (showMenu ?? false)}
      data-tip
      data-for={`${tooltipId}-options-tooltip`}
      ref={dotsMenuRef}
    >
      <DotsMenuButton onClick={handleDotsMenuClick} />

      <DropdownMenu
        items={menuContents}
        altContent={altDropdownContent}
        show={showMenu}
        position={{ top: popupHeight + 25, right: "unset", zIndex: ZIndexStackingContext.low }}
        positionType="fixed"
        size={DropdownSize.sm}
        width={altDropdownContent ? "fit-content" : `${menuWidth}px`}
        handleCloseMenu={shouldCloseMenuOnClick ? () => setShowMenu(false) : undefined}
      />

      {hasTooltip && !showMenu && (
        <Tooltip
          tooltipId={`${tooltipId}-options-tooltip`}
          content={getResource("tooltip.moreOptions")}
          fontSize="0.875em"
          delayHide={tooltipDelayHide}
        />
      )}
    </StyledDotsMenu>
  );
};

const StyledDotsMenu = styled.div<{ showDotsButton: boolean }>`
  visibility: ${({ showDotsButton }) => (showDotsButton ? "visible" : "hidden")};
`;
