import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_LIST_LIMIT } from "assets/constants/listLimits";
import { upperFirst } from "lodash";
import { getCustomModels } from "services/customModels";

import { CustomModelsApiData } from "ts/customizedModel";
import { CustomModelListSortingParameterForBackendMap } from "ts/enums/sorting";
import { formatSearchForFetchRequest } from "utils/formatters";
import { setEmptyStateType } from "./customModelListSlice";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { AppState } from "store/appReducer";

export const fetchCustomModels = createAsyncThunk<CustomModelsApiData, void, { state: AppState }>(
  "customModelList/getCustomModels",
  async (_, { getState, dispatch }): Promise<CustomModelsApiData> => {
    const state = getState();
    const customModelListState = state.customModelList;
    const searchTerm = state.search.searchTerm;

    const { data: customModels } = await getCustomModels({
      searchField: formatSearchForFetchRequest(searchTerm.trim()),
      pageNumber: customModelListState.currentPage,
      limit: DEFAULT_LIST_LIMIT,
      sortingParameter:
        CustomModelListSortingParameterForBackendMap[upperFirst(customModelListState.sortColumn)],
      sortingOrder: customModelListState.sortOrder,
    });

    const emptyStateType =
      customModels.itemCount === 0 && searchTerm?.length === 0
        ? EmptyStateType.noListResults
        : customModels.itemCount === 0 && searchTerm.length > 0
        ? EmptyStateType.noSearchResults
        : null;

    dispatch(setEmptyStateType(emptyStateType));

    if (!customModels) {
      throw new Error("Failed to fetch custom models");
    }

    return customModels;
  }
);
