import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { TreeItem } from "@atlaskit/tree";

import { CollapsibleName } from "components/CollapsibleName";
import { SectionTitle } from "components/SectionTitle";
import { TopicsFilter } from "../TopicFilterSelection";
import { Header } from "components/SideDrawer/Header";
import { StyledFooter } from "components/SideDrawer/StyledFooter";
import { EmptyState } from "components/EmptyState";

import { AnalysisModel } from "ts/filters/analysisModel";
import { ApiTopicNode } from "ts/topic";
import { CustomModelTopicUpdateMethod } from "ts/enums/customModelTopicUpdateMethod";

import { recursiveFindAllChildTopicNodes } from "utils/topics";
import { useResource } from "hooks/useResource";
import {
  insertTopicsIntoTree,
  mergeTopicsIntoItem,
  getCurrentCount,
} from "../CustomModelBuilder/CustomTopicsTree/helpers";
import { cloneDeep } from "lodash";
import { useCustomModelBuilder } from "common-layouts/CustomModelBuilder";
import { Button } from "components/_buttons/Button";
import { ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { Text } from "components/Text";

type Props = {
  selectedTreeItem: TreeItem;
  selectedTopicName: string;
  closeHandler: () => void;
  updateBaseModel: (
    baseModel: AnalysisModel,
    selectedTopics: ApiTopicNode[],
    type: CustomModelTopicUpdateMethod
  ) => void;
  onTreeChange: () => void;
};

export const MapTopicsSideDrawer = ({
  selectedTreeItem,
  selectedTopicName,
  closeHandler,
  updateBaseModel,
  onTreeChange,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [currentlySelectedTopics, setCurrentlySelectedTopics] = useState<ApiTopicNode[]>([]);

  const ctx = useCustomModelBuilder();
  const { getResource } = useResource();

  useEffect(() => {
    setCurrentlySelectedTopics([]);
  }, [closeHandler]);

  const totalTopics: number = ctx.updatedBaseModel?.topics?.flatMap((t) =>
    recursiveFindAllChildTopicNodes(t)
  ).length;

  const selectedTopicLeaves = [];
  [...ctx.allSelectedTopics].forEach((stn) => {
    if (!ctx.allSelectedTopics.some((x) => x.parentId === stn.id)) selectedTopicLeaves.push(stn);
  });

  const currentNumberOfTopics: number = useMemo(() => {
    return getCurrentCount(ctx.allSelectedTopics) + getCurrentCount(currentlySelectedTopics);
  }, [ctx.allSelectedTopics, currentlySelectedTopics]); // eslint-disable-line

  const handleInsert = () => {
    const topicsCopy = [...ctx.allSelectedTopics, ...currentlySelectedTopics];

    insertTopicsIntoTree(
      ctx.tree,
      ctx.setTree,
      ctx.updatedBaseModel,
      selectedTreeItem,
      currentlySelectedTopics,
      getResource
    );
    ctx.setAllSelectedTopics(topicsCopy);
    onTreeChange();
    closeHandler();
  };

  const handleMerge = () => {
    const topicsCopy = [...ctx.allSelectedTopics, ...currentlySelectedTopics];
    // remove parent topics from merge tooltip
    const parentTopicsToRemove = [];
    [...currentlySelectedTopics].forEach((cst) => {
      if (currentlySelectedTopics.some((x) => x.parentId === cst.id)) {
        parentTopicsToRemove.push(cst);
      }
    });
    mergeTopicsIntoItem(
      selectedTreeItem,
      ctx.tree,
      currentlySelectedTopics.filter((i) => !parentTopicsToRemove.includes(i)),
      ctx.setTree
    );
    ctx.setAllSelectedTopics(topicsCopy);

    onTreeChange();
    closeHandler();
  };

  const handleSelectCurrentTopics = (selectedTopics: ApiTopicNode[]) => {
    const copy = [...selectedTopics];
    const newTopics = [];
    copy.forEach((st) => {
      if (
        !ctx.allSelectedTopics.find(
          (tn) => tn.id === st.id && tn.parentId === st.parentId && tn.fullPath === st.fullPath
        )
      )
        // need to check for full path for SCE knowledge topics
        newTopics.push(st);
    });
    setCurrentlySelectedTopics(newTopics);
  };

  const handleRemoveAllMappedTopics = () => {
    // tree is flat >> map through tree and find all items with mapped topics, empty
    const treeCopy = cloneDeep(ctx.tree);
    Object.keys(treeCopy.items).forEach((x) => {
      if (treeCopy.items[x].data?.mappedTopics?.length > 0)
        treeCopy.items[x].data.mappedTopics = [];
    });
    // leave tree structure as is and set tree with newly updated items
    ctx.setTree(treeCopy);
    // send entire list of selected topics to updateBaseModel to be removed
    updateBaseModel(
      ctx.updatedBaseModel,
      ctx.allSelectedTopics,
      CustomModelTopicUpdateMethod.delete
    );
    // close side drawer
    closeHandler();
    onTreeChange();
  };

  return (
    <>
      <Header
        headerTitle={getResource("customModels.mapTopics.label") + ` "${selectedTopicName}"`}
        closeHandler={closeHandler}
      />
      <StyledBody>
        <div className="body-container">
          <div className="body-header">
            <SectionTitle titleKey="sideDrawer.mapTopics.title" />
            <div className="topics-count">
              <span className="count">
                <Text
                  resource={{
                    key: "comments.outOf",
                    args: [`${totalTopics - currentNumberOfTopics}`, `${totalTopics}`],
                  }}
                />
              </span>{" "}
              <Text resource="customModels.topicsAvailable.label" />
            </div>
          </div>
          {selectedTopicLeaves.length === totalTopics ? (
            <EmptyState
              type={EmptyStateType.allTopicsMapped}
              customStyles={{
                imageWidth: "218px",
                imageHeight: "168px",
                marginTop: "25px",
              }}
              handleClickCaptionLink={handleRemoveAllMappedTopics}
            />
          ) : (
            <div>
              <CollapsibleName
                name={
                  getResource("customModels.topics.label") +
                  (currentNumberOfTopics > 0 ? ` (${currentNumberOfTopics})` : "")
                }
                isExpanded={isExpanded}
                onClick={() => setIsExpanded((prevState) => !prevState)}
              />
              {isExpanded && (
                <TopicsFilter
                  localSelectedModel={ctx.updatedBaseModel}
                  localSelectedTopicNodes={[...ctx.allSelectedTopics, ...currentlySelectedTopics]}
                  allSelectedNodes={ctx.allSelectedTopics}
                  setLocalSelectedTopicNodes={handleSelectCurrentTopics}
                  initialAllSelected={totalTopics - currentNumberOfTopics === 0}
                  includeParentsForInsert
                />
              )}
            </div>
          )}
        </div>
      </StyledBody>
      <StyledFooter>
        <Button variant={ButtonVariant.light} onClick={closeHandler} style={{ marginLeft: "auto" }}>
          <Text resource="button.cancel" />
        </Button>
        {!selectedTreeItem?.data?.mappedTopics?.length && (
          <Button
            variant={ButtonVariant.primaryPurple}
            disabled={selectedTopicLeaves.length === totalTopics}
            onClick={handleInsert}
          >
            <Text resource="button.insertTopics" />
          </Button>
        )}
        {!selectedTreeItem?.children.length && (
          <Button
            variant={ButtonVariant.primaryPurple}
            disabled={selectedTopicLeaves.length === totalTopics}
            onClick={handleMerge}
          >
            <Text resource="button.mergeTopics" />
          </Button>
        )}
      </StyledFooter>
    </>
  );
};

const StyledBody = styled.div`
  height: calc(100% - 25px - 50px - 92px);
  overflow-y: auto;
  padding: 0 5px 0 25px;

  .body-container {
    border-top: 1px solid ${Color.neutral30};
    margin-right: 25px;

    .body-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .topics-count {
        margin-right: 8px;
        font-size: 0.875em;
        color: ${Color.gray50};

        .count {
          font-weight: bold;
        }
      }
    }
  }
`;
