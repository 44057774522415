// config
import { mlyApi, getHeaders } from "config/mlyApi";

import { getUrlQueryString } from "utils/getUrlQueryString";
import { VariableMappingsPresetListSortingParameter } from "ts/enums/sorting";
import { BackendSingleVariableMapping } from "ts/variableMappings";
import { SortingOrder } from "@explorance/mly-types";

const variableMappingUrl = "/variables/mappings";

export async function getVariableMappings(
  search?: string,
  limit?: number,
  page?: number,
  sort_column?: VariableMappingsPresetListSortingParameter,
  sort_order?: SortingOrder
) {
  const requestConfig = await getHeaders();
  const url =
    variableMappingUrl + getUrlQueryString({ search, page, limit, sort_column, sort_order });
  return mlyApi.get(url, requestConfig);
}

export async function getVariableMapping(mappingId: number) {
  const requestConfig = await getHeaders();
  const url = variableMappingUrl + `/${mappingId}`;
  return mlyApi.get(url, requestConfig);
}

export async function deleteVariableMapping(mappingId: number) {
  const requestConfig = await getHeaders();
  const url = variableMappingUrl + `/${mappingId}`;
  return mlyApi.delete(url, requestConfig);
}

export type CreateUpdateVariableMappingBody = {
  title: string;
  mappings: BackendSingleVariableMapping[];
};

export async function createVariableMapping(requestBody: CreateUpdateVariableMappingBody) {
  const requestConfig = await getHeaders();
  return mlyApi.post(variableMappingUrl, requestBody, requestConfig);
}

export async function updateVariableMapping(
  mappingId: number,
  requestBody: CreateUpdateVariableMappingBody
) {
  const requestConfig = await getHeaders();
  const url = variableMappingUrl + `/${mappingId}`;
  return mlyApi.put(url, requestBody, requestConfig);
}
