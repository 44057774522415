import React from "react";
import styled from "styled-components";
import { Icon, IconType } from "components/_icons/Icon";
import { Color } from "ts/enums/color";
import { WidgetType } from "@explorance/mly-types";

type CustomStyles = {
  margin?: string;
};

type Props = {
  widgetType: WidgetType;
  displayBackground: boolean;
  className?: string;
  useSolidIcons?: boolean;
  customStyles?: CustomStyles;
};

export const WidgetTypeIcon = ({
  widgetType,
  displayBackground,
  className,
  customStyles,
  useSolidIcons,
}: Props) => {
  const widgetTypeIcon = {
    MostPopular: {
      icon: (
        <Icon
          type={useSolidIcons ? IconType.mostPopularTopicsSolid : IconType.mostPopularTopics}
          size={12}
        />
      ),
      iconBackgroundColor: Color.sky30,
    },
    MostPositive: {
      icon: (
        <Icon
          type={useSolidIcons ? IconType.mostPositiveTopicsSolid : IconType.mostPositiveTopics}
          size={12}
        />
      ),
      iconBackgroundColor: Color.green30,
    },
    AllSentiments: {
      icon: (
        <Icon
          type={useSolidIcons ? IconType.allSentiments : IconType.allSentimentsSolid}
          size={12}
        />
      ),
      iconBackgroundColor: Color.indigo20,
    },
    WhatToImprove: {
      icon: (
        <Icon
          type={useSolidIcons ? IconType.mostNegativeTopicsSolid : IconType.mostNegativeTopics}
          size={12}
        />
      ),
      iconBackgroundColor: Color.red10,
    },
    AllRecommendations: {
      icon: <Icon type={IconType.allRecommendations} size={12} color={Color.orange60} />,
      iconBackgroundColor: Color.orange10,
    },
    CategorizedRecommendations: {
      icon: <Icon type={IconType.categorizedRecommendations} size={12} color={Color.indigo50} />,
      iconBackgroundColor: Color.indigo10,
    },
    MostAlerts: {
      icon: <Icon type={IconType.mostAlerts} size={12} color={Color.red50} />,
      iconBackgroundColor: Color.red10,
    },
    PrioritizedRecommendations: {
      icon: (
        <Icon type={IconType.prioritizedRecommendations} size={12} color={Color.lightGreen50} />
      ),
      iconBackgroundColor: Color.lightGreen10,
    },
    OverallStats: {
      icon: <Icon type={IconType.commentChart} />,
      iconBackgroundColor: Color.indigo10,
    },
  };

  return displayBackground ? (
    <StyledWidgetTypeIcon
      className={className}
      backgroundColor={widgetTypeIcon[widgetType]?.iconBackgroundColor}
      margin={customStyles?.margin}
    >
      <span className="icon">{widgetTypeIcon[widgetType]?.icon}</span>
    </StyledWidgetTypeIcon>
  ) : (
    <StyledWidgetTypeIcon
      className={className}
      backgroundColor="transparent"
      margin={customStyles?.margin}
    >
      <span className="small-icon">{widgetTypeIcon[widgetType]?.icon}</span>
    </StyledWidgetTypeIcon>
  );
};

type StyledProps = {
  backgroundColor: string;
  margin?: string;
};

const StyledWidgetTypeIcon = styled.div<StyledProps>`
  margin: ${(props) => props.margin};
  .icon {
    width: 24px;
    height: 24px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.backgroundColor};
  }

  .small-icon {
    display: flex;
    align-items: center;
  }
`;
