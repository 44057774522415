import { TopicsSortingParameter } from "ts/enums/sorting";
import {
  RecommendationType,
  SentimentType,
  SortingOrder,
  TopicsListView,
} from "@explorance/mly-types";
import { ApiTopicNode, Topic, mapDataToTopic, TopicTreeNode, TopicAttributeCount } from "ts/topic";

import { isArray, isEmpty, cloneDeep, lowerFirst } from "lodash";
import { TopicNode } from "ts/filters/topicNode";

export const getSentimentRatesForTopic = (topic: Topic) => {
  const { positive, negative, neutral, mixed, notExplicit, insights } = topic;

  if (insights === 0) {
    return [
      { type: SentimentType.positive, rate: 0 },
      { type: SentimentType.negative, rate: 0 },
      { type: SentimentType.neutral, rate: 0 },
      { type: SentimentType.mixed, rate: 0 },
      { type: SentimentType.notExplicit, rate: 0 },
    ];
  }
  return [
    { type: SentimentType.positive, rate: positive / insights },
    { type: SentimentType.negative, rate: negative / insights },
    { type: SentimentType.neutral, rate: neutral / insights },
    { type: SentimentType.mixed, rate: mixed / insights },
    { type: SentimentType.notExplicit, rate: notExplicit / insights },
  ];
};

export const getDominantSentimentForTopic = (topic: Topic) => {
  return getSentimentRatesForTopic(topic).sort((a, b) => b.rate - a.rate)[0];
};

const getTopicsForSentiment = (topics: Topic[], type: SentimentType) => {
  if (topics.every((t) => !t.dominantSentiment)) return;
  return [...topics]
    .filter((r) => r.dominantSentiment.type === type)
    .sort((a, b) => b.comments - a.comments);
};

export const sortTopicResults = (
  topics: Topic[],
  sortingType: TopicsSortingParameter,
  sortingOrder: SortingOrder
): Topic[] => {
  const sortByCount = (topics, property) =>
    topics.sort((a, b) =>
      sortingOrder === SortingOrder.ASC ? a[property] - b[property] : b[property] - a[property]
    );

  const sortByName = (topics, property) =>
    topics.sort((a, b) =>
      sortingOrder === SortingOrder.ASC
        ? a[property].localeCompare(b[property])
        : b[property].localeCompare(a[property])
    );

  // Recursive for going down the topics tree
  const sortNestedTopics = (topics, property, func) => {
    func(topics, property).map(
      (t) => t.topics.length > 0 && sortNestedTopics(t.topics, property, func)
    );
    return [...topics];
  };

  // Sorting by Sentiment Percentage methods
  const compareRate = (type) => {
    switch (type) {
      case SortingOrder.ASC:
        return (a, b) =>
          Math.round(a.dominantSentiment.rate * 100) - Math.round(b.dominantSentiment.rate * 100);
      case SortingOrder.DESC:
        return (a, b) =>
          Math.round(b.dominantSentiment.rate * 100) - Math.round(a.dominantSentiment.rate * 100);
    }
  };

  const getRootTopicsBySentiments = (type: SortingOrder.ASC | SortingOrder.DESC): Topic[] => {
    const method = compareRate(type.toUpperCase());
    const positiveTopics = getTopicsForSentiment(topics, SentimentType.positive);
    const negativeTopics = getTopicsForSentiment(topics, SentimentType.negative);
    const neutralTopics = getTopicsForSentiment(topics, SentimentType.neutral);
    const mixedTopics = getTopicsForSentiment(topics, SentimentType.mixed);
    const notExplicitTopics = getTopicsForSentiment(topics, SentimentType.notExplicit);
    return [
      ...positiveTopics.sort(method),
      ...negativeTopics.sort(method),
      ...neutralTopics.sort(method),
      ...mixedTopics.sort(method),
      ...notExplicitTopics.sort(method),
    ];
  };

  const getTopicBySentiment = (
    topicObject: Topic,
    type: SortingOrder.ASC | SortingOrder.DESC
  ): Topic => {
    if (isEmpty(topicObject.topics)) return topicObject;

    const method = compareRate(type.toUpperCase());

    const positiveChildTopics = getTopicsForSentiment(topicObject.topics, SentimentType.positive);
    const negativeChildTopics = getTopicsForSentiment(topicObject.topics, SentimentType.negative);
    const neutralChildTopics = getTopicsForSentiment(topicObject.topics, SentimentType.neutral);
    const mixedChildTopics = getTopicsForSentiment(topicObject.topics, SentimentType.mixed);
    const notExplicitTopics = getTopicsForSentiment(topicObject.topics, SentimentType.notExplicit);

    topicObject.topics = [
      ...positiveChildTopics.sort(method),
      ...negativeChildTopics.sort(method),
      ...neutralChildTopics.sort(method),
      ...mixedChildTopics.sort(method),
      ...notExplicitTopics.sort(method),
    ].map((t) => {
      if (isEmpty(t.topics)) return t;
      return getTopicBySentiment(t, type);
    });

    return topicObject;
  };

  // Topics are sorted by type and returned
  switch (sortingType) {
    case TopicsSortingParameter.Topics:
      return sortNestedTopics(topics, "name", sortByName);
    case TopicsSortingParameter.Sentiments:
      return getRootTopicsBySentiments(sortingOrder).map((t) =>
        getTopicBySentiment(t, sortingOrder)
      );
    case sortingType:
      return sortNestedTopics(topics, lowerFirst(sortingType), sortByCount);
  }
};

export const getMappedTopic = (topic: Topic): Topic => {
  if (isEmpty(topic.topics)) return mapDataToTopic(topic);

  const childTopicsCopy = cloneDeep(topic.topics);
  const typedChildTopics = childTopicsCopy.map((t) => mapDataToTopic(t));

  return { ...topic, topics: typedChildTopics };
};

export const recurseOverTopics = (topicsObject: Topic, func): Topic => {
  if (isEmpty(topicsObject.topics)) return topicsObject;

  topicsObject.topics = func(topicsObject).topics.map((t) => {
    if (isEmpty(t.topics)) return t;
    return recurseOverTopics(func(t), func);
  });
  return topicsObject;
};

// Topics filters

export const deepFindOneTopic = (nodes, topic) => {
  let res: TopicTreeNode;
  const findNode = (nodes, topic) => {
    for (let i = 0; i < nodes.length; i++) {
      if (
        nodes[i].id === topic.id &&
        nodes[i].parentId === topic.parentId &&
        nodes[i].fullPath === topic.fullPath
      ) {
        res = nodes[i];
        break;
      }
      if (nodes[i].topics) {
        findNode(nodes[i].topics, topic);
      }
    }
  };
  findNode(nodes, topic);
  return res;
};

export const findOneTopicWithFullPath = (nodes, topic): ApiTopicNode => {
  let t: ApiTopicNode;

  const findNode = (nodes, topic) => {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].fullPath === topic.fullPath) {
        t = nodes[i];
        break;
      }
      if (nodes[i].topics) {
        findNode(nodes[i].topics, topic);
      }
    }
  };
  findNode(nodes, topic);
  return { id: t.id, parentId: t.parentId, fullPath: t.fullPath };
};

export const recursiveFindAllTopicNodes = (obj, topics = []) => {
  const topicsArray = isArray(obj) ? obj : obj.topics;
  if (isEmpty(topicsArray)) {
    return topics;
  } else {
    topicsArray.forEach((element) => {
      topics.push({ id: element.id, parentId: element.parentId, fullPath: element.fullPath });
      recursiveFindAllTopicNodes(element.topics, topics);
    });
  }

  return topics;
};

export const recursiveFindAllTopicNodesWithName = (obj, topics = []) => {
  const topicsArray = isArray(obj) ? obj : obj.topics;
  if (isEmpty(topicsArray)) {
    return topics;
  } else {
    topicsArray.forEach((element) => {
      topics.push({
        id: element.id,
        parentId: element.parentId,
        fullPath: element.fullPath,
        name: element.name,
      });
      recursiveFindAllTopicNodes(element.topics, topics);
    });
  }

  return topics;
};

export const recursiveFindAllTopicNodesWithChildren = (obj, topics = []) => {
  const topicsArray = isArray(obj) ? obj : obj.topics;
  if (isEmpty(topicsArray)) {
    return topics;
  } else {
    topicsArray.forEach((element) => {
      topics.push({
        ...element,
        name: element.name || element.fullPath.split(">")[element.fullPath.split(">").length - 1],
      });
      recursiveFindAllTopicNodesWithChildren(element.topics, topics);
    });
  }
  return topics;
};

export const recursiveFindAllChildTopicNodes = (obj, topics = []) => {
  const topicsArray = isArray(obj) ? obj : obj.topics;
  if (isEmpty(topicsArray)) {
    return [{ id: obj.id, parentId: obj.parentId, fullPath: obj.fullPath }];
  } else {
    topicsArray.forEach((element) => {
      if (isEmpty(element.topics)) {
        topics.push({
          id: element.id,
          parentId: element.parentId,
          fullPath: element.fullPath,
        });
      }
      recursiveFindAllChildTopicNodes(element.topics, topics);
    });
  }

  return topics;
};

export const recursiveFindAllParents = (topic: ApiTopicNode, allTopics: TopicNode[]) => {
  const topics = [...allTopics.flatMap((t) => recursiveFindAllTopicNodes(t)), ...allTopics];
  const allParents = [];

  let parentId = topic.parentId;
  while (parentId) {
    const parent = topics.find((t) => t.id === parentId && topic.fullPath.includes(t.fullPath));
    if (!parent) break;

    parentId = parent.parentId;
    const parentToAdd = {
      id: parent.id,
      parentId: parent.parentId,
      fullPath: parent.fullPath,
    };
    allParents.push(parentToAdd);
  }

  return allParents.filter(Boolean);
};

export const getAttributeCountsByView = (
  view: TopicsListView,
  topic: Topic
): TopicAttributeCount[] => {
  switch (view) {
    case TopicsListView.Sentiments:
      return [
        { count: topic.positive, attribute: SentimentType.positive },
        { count: topic.negative, attribute: SentimentType.negative },
        { count: topic.neutral, attribute: SentimentType.neutral },
        { count: topic.mixed, attribute: SentimentType.mixed },
        { count: topic.notExplicit, attribute: SentimentType.notExplicit },
        {
          count: topic.dominantSentiment.rate,
          attribute: topic.dominantSentiment.type,
          isDominantSentiment: true,
        },
      ];
    case TopicsListView.Recommendations:
      return [
        { count: topic.doMore, attribute: RecommendationType.doMore },
        { count: topic.doLess, attribute: RecommendationType.doLess },
        { count: topic.start, attribute: RecommendationType.start },
        { count: topic.stop, attribute: RecommendationType.stop },
        { count: topic.continue, attribute: RecommendationType.continue },
        { count: topic.change, attribute: RecommendationType.change },
      ];
  }
};
