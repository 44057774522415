import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import { DemographicFilterSelection } from "common-layouts/FilterSelectionSection/DemographicFilterSelection";
import { FilterTypeDropdown } from "components/FilterTypeDropdown";
import { ApplyFiltersButton } from "components/_buttons/ApplyFilterButton";
import { Tooltip } from "components/Tooltip";

import { DemographicFilter } from "ts/filters/demographicFilter";
import { DemographicFilterMethod } from "@explorance/mly-types";
import { Color } from "ts/enums/color";

type Props = {
  selectedDemographicId: number;
  selectedDemographics: DemographicFilter[];
  resetLocalState: boolean;
  onSubmit: () => void;
  updateFilters: (updatedFilterSelection: DemographicFilter[]) => void;
};

export const DemographicsMenu = ({
  selectedDemographicId,
  selectedDemographics,
  resetLocalState,
  onSubmit,
  updateFilters,
}: Props) => {
  // variables
  const currentFilter = selectedDemographics.filter((sdf) => sdf.id === selectedDemographicId)[0];

  // hooks
  const [filterType, setFilterType] = useState<DemographicFilterMethod>(currentFilter.method);
  const [filterCount, setFilterCount] = useState<string>("");
  const [currentDemographicFilterSelection, setCurrentDemographicFilterSelection] =
    useState<DemographicFilter[]>(selectedDemographics);
  const [showNameTooltip, setShowNameTooltip] = useState<boolean>();

  const filterNameRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (filterNameRef.current.offsetWidth < filterNameRef.current.scrollWidth) {
      setShowNameTooltip(true);
    }
  }, [filterNameRef]);

  useEffect(() => {
    setCurrentDemographicFilterSelection(selectedDemographics);
    setFilterType(currentFilter.method);
  }, [selectedDemographics, currentFilter, resetLocalState]);

  // functions
  const handleSubmit = () => {
    updateFilters(currentDemographicFilterSelection);
    onSubmit();
  };

  const handleFilterTypeChange = (
    type: DemographicFilterMethod.List | DemographicFilterMethod.Search
  ) => {
    const currentFilterCopy = { ...currentFilter };
    const currentFilterStateCopy = [...currentDemographicFilterSelection];
    const currentFilterIndex = currentFilterStateCopy.indexOf(currentFilter);
    currentFilterCopy.method = type;
    type === DemographicFilterMethod.List
      ? (currentFilterCopy.searchValue = [])
      : (currentFilterCopy.listValues = []);

    currentFilterStateCopy.splice(currentFilterIndex, 1, currentFilterCopy);

    setFilterType(type);
    setCurrentDemographicFilterSelection(currentFilterStateCopy);
  };

  const handleFilterCount = (count: string) => {
    setFilterCount(count);
  };

  return (
    <StyledDemographicsMenuContainer>
      <StyledDemographicsMenu>
        <StyledHeader>
          <StyledTitleContainer>
            <div data-tip data-for={`${currentFilter.name}-tooltip`} ref={filterNameRef}>
              {currentFilter.name.toUpperCase()}
              {showNameTooltip && (
                <Tooltip
                  tooltipId={`${currentFilter.name}-tooltip`}
                  place="bottom"
                  content={currentFilter.name}
                />
              )}
            </div>
            {filterCount && <span>{filterCount}</span>}
          </StyledTitleContainer>
          <FilterTypeDropdown currentFilterType={filterType} onSelect={handleFilterTypeChange} />
        </StyledHeader>
        <DemographicFilterSelection
          key={currentFilter.id}
          demographic={currentFilter}
          filterType={filterType}
          currentFilterState={currentDemographicFilterSelection}
          isFilterPill
          handleCurrentFilterState={setCurrentDemographicFilterSelection}
          handleFilterCount={handleFilterCount}
        />
      </StyledDemographicsMenu>
      <ApplyFiltersButton handleClick={handleSubmit} />
    </StyledDemographicsMenuContainer>
  );
};

const StyledDemographicsMenuContainer = styled.div`
  padding: 16px;
  max-height: 438px;
  max-width: 425px;
  display: flex;
  flex-direction: column;
`;

const StyledDemographicsMenu = styled.div`
  min-width: 240px;
  flex-grow: 1;
`;

const StyledHeader = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const StyledTitleContainer = styled.div`
  font-size: 0.75em;
  color: ${Color.gray20};
  font-weight: bold;
  margin-left: 2px;
  display: flex;

  div {
    max-width: 330px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  span {
    margin: 0 6px 0 2px;
  }
`;
