import React, { ReactElement } from "react";
import styled from "styled-components";
import { lowerFirst } from "lodash";

import { TopicsSortingParameter } from "ts/enums/sorting";
import { Icon, IconType } from "components/_icons/Icon";
import { Text } from "components/Text";
import { Color } from "ts/enums/color";
import { SentimentType, SortingOrder } from "@explorance/mly-types";

import { StyledTableHeader } from ".";
import { TextType } from "ts/TextType";

export type TableInformation<SortingParameter> = {
  headerName: TextType;
  sortingParameter: SortingParameter;
  icon?: ReactElement;
  className?: string;
};

type Props<SortingParameter> = {
  tableInformation: TableInformation<SortingParameter>[];
  sortingParameter: SortingParameter;
  sortingOrder: SortingOrder;
  cssTopOffset?: number;
  addEmptyHeaderCell?: boolean;
  handleSort(parameter: SortingParameter): void;
};

export const ClickableTableHeader = <SortingParameter,>({
  tableInformation,
  sortingParameter,
  sortingOrder,
  addEmptyHeaderCell,
  cssTopOffset,
  handleSort,
}: Props<SortingParameter>) => {
  return (
    <StyledTableHeader topOffset={cssTopOffset}>
      <tr>
        {addEmptyHeaderCell && <th />}
        {tableInformation.map((ti, i) => (
          <th key={i} onClick={() => handleSort(ti.sortingParameter)}>
            <StyledHeaderCell
              headerWidth={
                lowerFirst(ti.sortingParameter?.toString()) === SentimentType.notExplicit
                  ? "130px"
                  : "fit-content"
              }
              className={ti.className}
            >
              {ti.icon && ti.icon} &nbsp;
              <span>
                <Text resource={ti.headerName} />
              </span>
              <StyledSortingArrowContainer
                sortingOrder={sortingOrder}
                addMarginBottom={
                  !Object.keys(TopicsSortingParameter).some((tsp) => tsp === ti.sortingParameter)
                }
              >
                {sortingParameter === ti.sortingParameter && (
                  <Icon type={IconType.arrowDown} size={12} color={Color.gray40} />
                )}
              </StyledSortingArrowContainer>
            </StyledHeaderCell>
          </th>
        ))}
      </tr>
    </StyledTableHeader>
  );
};

const StyledHeaderCell = styled.div<{ headerWidth: string }>`
  display: flex;
  align-items: center;
  height: 17px;
  width: ${({ headerWidth }) => headerWidth};
`;

const StyledSortingArrowContainer = styled.div<{
  sortingOrder: SortingOrder;
  addMarginBottom: boolean;
}>`
  margin-left: 6px;
  width: 12px;
  transform: ${({ sortingOrder }) => sortingOrder === SortingOrder.ASC && "rotate(180deg)"};
  margin-bottom: ${({ sortingOrder, addMarginBottom }) =>
    sortingOrder === SortingOrder.ASC && addMarginBottom && "3px"};
`;
