import { CSS } from "@dnd-kit/utilities";
import React, { memo, useEffect, useState } from "react";
import { useDroppable } from "@dnd-kit/core";
import styled from "styled-components";
import { useAppSelector, useAppDispatch } from "store";
import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";

import { DraggableWidgetCard } from "../DraggableWidgetCard";
import { Color } from "ts/enums/color";
import { handleSectionExpand } from "store/widgetOrdering/widgetOrderingModal";
import { CollapsibleWidgetContainer } from "./CollapsibleWidgetContainer";
import { Widget } from "ts/widget";

type Props = {
  widgets: Widget[];
  title: string;
  id: number;
  isExpanded?: boolean;
};

// eslint-disable-next-line react/display-name
export const DraggableGroupCard = memo(({ widgets, title, id, isExpanded }: Props) => {
  const { setNodeRef, isOver, over } = useDroppable({ id });
  const [isNodeOver, setIsNodeOver] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.widgetOrdering);
  const {
    attributes,
    listeners,
    setNodeRef: dragRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.3 : 1,
    cursor: "grab",
  };

  useEffect(() => {
    if (state.currentlyDragging?.widgets) return;
    const isWidgetInContainer = widgets.some((widget) => widget.id === state.currentlyDragging?.id);

    setIsNodeOver(isOver && !isWidgetInContainer);
  }, [isOver, over]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SortableContext items={widgets} strategy={verticalListSortingStrategy} key={id}>
      <StyledDraggableGroupCard ref={dragRef} style={style}>
        <StyledWidgetSection
          ref={setNodeRef}
          isOver={isNodeOver}
          isDragging={state.currentlyDragging?.id === id}
          {...listeners}
          {...attributes}
        >
          <CollapsibleWidgetContainer
            titleLeft={title}
            isExpanded={isExpanded}
            groupId={id}
            onExpandedChange={(isExpanded) => dispatch(handleSectionExpand({ id, isExpanded }))}
            disabled={widgets.length === 0}
            customStyles={{
              backgroundColor:
                isNodeOver || state.currentlyDragging?.id === id ? Color.sky30 : Color.white,
              removePaddingButtom: widgets.length === 0,
            }}
            bottomDragIndicator={isNodeOver}
          >
            {!isNodeOver ? (
              widgets.map((widget) => (
                <DraggableWidgetCard widget={widget} key={widget.id} sectionId={id} />
              ))
            ) : (
              <>
                {widgets.map((widget) => (
                  <DraggableWidgetCard widget={widget} key={widget.id} sectionId={id} placeholder />
                ))}
                <StyledBottomDragIndicator />
              </>
            )}
          </CollapsibleWidgetContainer>
        </StyledWidgetSection>
      </StyledDraggableGroupCard>
    </SortableContext>
  );
});

const StyledWidgetSection = styled.div<{ isOver: boolean; isDragging: boolean }>`
  border: ${({ isOver, isDragging }) =>
    `1px ${isOver || isDragging ? "dashed" : "solid"} ${
      isOver || isDragging ? Color.blue50 : Color.sky60
    }`};
  border-radius: 2px;
  margin-bottom: 16px;
  width: 100%;
  cursor: ${({ isDragging }) => isDragging && "grabbing"};
  background-color: ${({ isOver, isDragging }) =>
    isOver || isDragging ? Color.sky30 : Color.white};
`;

export const StyledBottomDragIndicator = styled.div`
  height: 2px;
  background-color: ${Color.blue50};
  margin-top: 10px;
`;

const StyledDraggableGroupCard = styled.div`
  display: flex;
  flex-direction: row;
`;
