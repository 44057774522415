import React, { useEffect, useState, useContext, useCallback, useRef } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { useAppSelector } from "store";

import axios, { AxiosError } from "axios";

import { Comment as CommentModel } from "ts/comments";
import {
  CommentsSortingParameter,
  CommentsView,
  DemographicFilterMethod,
  SharingCommentExplorerAccess,
  StatisticsType,
} from "@explorance/mly-types";
import { upperFirst } from "lodash";
import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { ButtonSize } from "ts/enums/button";
import { PageErrorType } from "ts/enums/pageErrorType";

import { getCommentStatisticsByAnalysisId, getCommentsByAnalysisId } from "services/analysis";
import { removeHangingSeparatorsFromSearch, statisticsURLStringToArray } from "utils/comments";
import { getCustomModelId } from "utils/getCustomModelId";
import { useResource } from "hooks/useResource";
import { getUrlQueryString } from "utils/getUrlQueryString";
import { useQueryParams } from "hooks/useQueryParams";
import { routes } from "routes";
import {
  getAvailableCommentViews,
  mapCommentStatisticsForMenu,
} from "components/StatsMenu/helpers";
import { AnalysisContext } from "context/AnalysisContext";
import {
  handleAxiosError,
  setAnalysisError,
  setCommentStatistics,
  setSelectedTopicNodes,
} from "context/AnalysisContext/actions";
import { isAlertRangeActive } from "utils/isAlertRangeActive";
import { formatSearchForFetchRequest } from "utils/formatters";
import { resetPageScroll } from "utils/resetPageScroll";

import { Pagination } from "components/Pagination";
import { SideDrawer } from "components/SideDrawer";
import { PaginationPlaceholder } from "components/Pagination/Placeholder";
import { StatsMenu } from "components/StatsMenu";
import { StatsPlaceholder } from "components/StatsMenu/Placeholder";
import { Searchbar } from "components/_inputs/Searchbar";
import { FilterPillList } from "common-layouts/FilterSelectionSection/FilterPillList";
import { AnalysisFilterDrawerLayout } from "common-layouts/FilterDrawerLayout";
import { CommentList } from "./_layouts/CommentList";
import { Select } from "components/_inputs/Select";
import { ErrorScreen } from "components/ErrorScreen";
import { Text } from "components/Text";

const DEFAULT_LIST_LIMIT = 50;

export const CommentsPage = () => {
  // hooks
  const searchTerm = useAppSelector((state) => state.search.searchTerm);
  const [state, dispatch] = useContext(AnalysisContext);
  const [comments, setComments] = useState<CommentModel[]>([]);
  const [commentsLoading, setCommentsLoading] = useState<boolean>(true);
  const [commentsStatisticsLoading, setCommentsStatisticsLoading] = useState<boolean>(true);
  const [sideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
  const [currentCommentsCount, setCurrentCommentsCount] = useState<number>();
  const [selectedCommentsView, setSelectedCommentsView] = useState<CommentsView>(null);

  const history = useHistory();
  const cancelTokenSourceRef = useRef(axios.CancelToken.source());

  const { getResource } = useResource();
  const { pathname } = useLocation();
  const {
    reset,
    statistics,
    view,
    page,
    sharingPreview,
    sharingId,
    sharedWithPage,
    expandedRowId,
    step,
    sort,
  } = useQueryParams() as {
    reset;
    statistics;
    view;
    page;
    sharingPreview;
    sharingId;
    sharedWithPage;
    expandedRowId;
    step;
    sort;
  };
  const customModelId = getCustomModelId(state);

  const commentSortingOptions = [
    { label: getResource("comments.sortDropdown.default"), value: null },
  ].concat(
    Object.keys(CommentsSortingParameter).map((csp) => {
      return {
        label: getResource(`comments.sortBy.${upperFirst(csp)}`),
        value: csp,
      };
    })
  );

  const [sortBy, setSortBy] = useState(commentSortingOptions[0]);

  const availableCommentViews = getAvailableCommentViews(state.analysisDetails.availableResults);

  const commentViewDropdownOptions = availableCommentViews.map((cv) => ({
    label: getResource(`comments.viewDropdown.${cv}`),
    value: cv,
    isActive: selectedCommentsView === cv,
  }));

  useEffect(() => {
    return () => {
      cancelTokenSourceRef.current.cancel("Comments page unmounted"); // eslint-disable-line
    };
  }, []);

  // Update the "custom_model_id" URL query param according to the values from the Context
  useEffect(() => {
    if (state.loadingAnalysisModels) return;

    const params = new URLSearchParams(history.location.search);
    if (customModelId && !params.get("custom_model_id")) {
      params.set("custom_model_id", customModelId.toString());
    } else if (!customModelId) params.delete("custom_model_id");
    history.replace(history.location.pathname + "?" + params.toString());
  }, [history, customModelId, state.loadingAnalysisModels]);

  // Update the "page" URL query param if the query is greater than the total number of pages
  const updatePageUrlQueryParam = useCallback(
    (newPageNumber: number) => {
      const params = new URLSearchParams(history.location.search);
      params.set("page", newPageNumber.toString());
      history.replace(history.location.pathname + "?" + params.toString());
    },
    [history]
  );

  const updateSortQueryParam = useCallback(
    (newSort: CommentsSortingParameter) => {
      const params = new URLSearchParams(history.location.search);
      newSort === null ? params.delete("sort") : params.set("sort", newSort);
      history.replace(history.location.pathname + "?" + params.toString());
    },
    [history]
  );

  // the custom model, threshold, and topics filters + pill shall be shown only in the categorized comments/recommendations views
  const showModelTopicThresholdFilters =
    view === CommentsView.categorizedComments || view === CommentsView.categorizedRecommendations;

  const requestBody = {
    ...(showModelTopicThresholdFilters && {
      topics: state.selectedTopicNodes.map((tn) => tn.fullPath),
    }),
    demographics: state.selectedDemographicFilters.map((filter) => ({
      id: filter.id,
      name: filter.name,
      method: filter.method,
      values:
        filter.method === DemographicFilterMethod.List ? filter.listValues : filter.searchValue,
    })),
    ...(showModelTopicThresholdFilters && { threshold: state.threshold }),
    statistics: statisticsURLStringToArray(statistics).filter((s) => s !== StatisticsType.total),
    ...(state.selectedColumnFilters.length > 0 && {
      selectedColumns: state.selectedColumnFilters,
    }),
    ...(state.analysisDetails?.availableResults?.alerts === true &&
      isAlertRangeActive(state.selectedAlertRange) && {
        alertScore: state.selectedAlertRange,
      }),
  };

  const fetchCommentsData = (): Promise<void> => {
    return new Promise((resolve) => {
      setCommentsLoading(true);
      dispatch(setAnalysisError(null));

      if (page <= 0 || isNaN(page)) updatePageUrlQueryParam(1);

      if (page >= 1 && !state.loadingAnalysisDetails) {
        getCommentsByAnalysisId(
          state.analysisDetails.id,
          customModelId,
          page <= 0 || isNaN(page) ? 1 : page,
          view,
          searchTerm
            ? formatSearchForFetchRequest(removeHangingSeparatorsFromSearch(searchTerm))
            : undefined,
          sortBy.value,
          state.previewUser.id,
          sharingId,
          requestBody,
          cancelTokenSourceRef.current.token
        )
          .then(({ data }) => {
            setComments(data?.comments);
            setCurrentCommentsCount(data?.itemCount);
            const totalPages = Math.ceil(data?.totalCount / DEFAULT_LIST_LIMIT);
            if (page > totalPages && totalPages > 0) updatePageUrlQueryParam(totalPages);
            resetPageScroll();
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              dispatch(handleAxiosError(err as AxiosError));
            }
          })
          .finally(() => {
            resolve();
            setCommentsLoading(false);
          });
      } else {
        resolve();
      }
    });
  };

  useEffect(() => {
    if (state.loadingAnalysisModels) return;
    fetchCommentsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.selectedDemographicFilters,
    state.selectedTopicNodes,
    state.threshold,
    state.selectedAlertRange,
    state.loadingAnalysisDetails,
    state.selectedColumnFilters,
    state.loadingAnalysisModels,
    state.previewUser.id,
    customModelId,
    view,
    statistics,
    sortBy,
    page,
    searchTerm,
    dispatch,
    updatePageUrlQueryParam,
  ]);

  const fetchCommentStatistics = (): Promise<void> => {
    return new Promise((resolve) => {
      setCommentsStatisticsLoading(true);
      getCommentStatisticsByAnalysisId(
        state.analysisDetails.id,
        customModelId,
        view,
        state.previewUser.id,
        sharingId,
        requestBody,
        cancelTokenSourceRef.current.token
      )
        .then(({ data }) => dispatch(setCommentStatistics(data.statistics)))
        .catch((err) => {
          if (!axios.isCancel(err)) {
            dispatch(handleAxiosError(err as AxiosError));
          }
        })
        .finally(() => {
          resolve();
          setCommentsStatisticsLoading(false);
        });
    });
  };

  // fetch comment statistics
  useEffect(() => {
    if (state.loadingAnalysisModels) return;
    fetchCommentStatistics();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customModelId,
    view,
    dispatch,
    state.selectedAlertRange,
    state.analysisDetails.id,
    state.selectedColumnFilters,
    state.selectedDemographicFilters,
    state.selectedTopicNodes,
    state.threshold,
    state.previewUser.id,
    state.loadingAnalysisModels,
  ]);

  // Sets the topic filters to the previous ones, ONLY if we click the back button of the browser AND `reset` is present in the query params
  useEffect(() => {
    return () => {
      if (reset && history.action === "POP") {
        dispatch(setSelectedTopicNodes(state.lastSelectedTopicNodes));
      }
    };
  }, [history, dispatch, reset, state.lastSelectedTopicNodes, pathname]);

  // Set initial view
  useEffect(() => {
    // if view is present in query params,
    if (view && Object.keys(CommentsView).includes(view)) {
      setSelectedCommentsView(view);
    } else {
      setSelectedCommentsView(CommentsView.allComments);
    }
  }, [view]);

  useEffect(() => {
    if (sort) {
      setSortBy({
        label: getResource(`comments.sortBy.${upperFirst(sort)}`),
        value: sort,
      });
    }
  }, [sort]); // eslint-disable-line

  const handleSelectView = (view: CommentsView) => {
    setSelectedCommentsView(view);
    setCommentsLoading(true);
    setCommentsStatisticsLoading(true);
    history.push(
      routes.commentsPage(state.analysisDetails.id) +
        getUrlQueryString({
          custom_model_id: customModelId,
          view,
          search: searchTerm,
          sharingPreview,
          sort: sortBy.value || "",
          sharingId,
          sharedWithPage,
          expandedRowId,
          step,
        })
    );
  };

  const onChangePage = (newPage: number) => {
    history.push(
      routes.commentsPage(state.analysisDetails.id) +
        getUrlQueryString({
          custom_model_id: customModelId,
          statistics,
          view,
          page: newPage,
          search: searchTerm,
          sort: sortBy.value || "",
          sharingPreview,
          sharingId,
          sharedWithPage,
          expandedRowId,
          step,
        })
    );
  };

  const getCommentsCaption = (): string => {
    if (currentCommentsCount === 1) return getResource("comments.countSentence.commentsSingular");
    return getResource("comments.countSentence.comments", currentCommentsCount);
  };

  const handleSort = (option) => {
    setSortBy(commentSortingOptions.find((o) => o.value === option));
    updateSortQueryParam(option);
  };

  const handleSearch = () => {
    setCommentsLoading(true);
    //remove page from query params
    const params = new URLSearchParams(history.location.search);
    params.delete("page");
    history.push(history.location.pathname + "?" + params.toString());
  };

  if (
    state.analysisDetails.sharing &&
    state.analysisDetails.sharing?.commentExplorerAccess !== SharingCommentExplorerAccess.Shared
  ) {
    return <ErrorScreen errorType={PageErrorType.GeneralInsufficientPermission} />;
  }

  return (
    <div className="fade-in">
      <SideDrawer
        render={AnalysisFilterDrawerLayout}
        isOpen={sideDrawerOpen}
        closeHandler={() => setSideDrawerOpen(false)}
        width="600px"
      />
      <StyledPageHeader whiteBackground={state.showWhiteBackground}>
        <FilterPillList
          containerMargin={"0 0 16px 0"}
          openFiltersMenu={() => setSideDrawerOpen(true)}
        />

        <StyledStatsMenuSection>
          {!commentsStatisticsLoading && state.commentStatistics ? (
            <StatsMenu
              menuItems={mapCommentStatisticsForMenu(state.commentStatistics)}
              commentsLoading={commentsLoading}
              commentsViewOptions={commentViewDropdownOptions}
              handleSelectView={handleSelectView}
            />
          ) : (
            <StatsPlaceholder />
          )}
          <StyledSortBySection>
            <span>
              <Text resource="comments.sortBy.label" />:
            </span>
            <Select
              selectedOption={sortBy}
              options={commentSortingOptions}
              dropdownWidth={"248px"}
              handleChange={handleSort}
              buttonSize={ButtonSize.md}
              buttonStyle={{
                padding: "8px 10px",
                fontWeight: "normal",
                fontSize: "15px",
                gap: "10px",
              }}
              dropdownPosition={{ right: 0 }}
            />
          </StyledSortBySection>
        </StyledStatsMenuSection>
        <StyledSearchbarSection>
          <div>
            <Searchbar
              onSearch={handleSearch}
              placeholder={getResource("comments.searchInput.placeholder")}
              customStyles={{
                border: `1px solid ${Color.sky50}`,
                width: "600px",
              }}
            />
            {state.commentStatistics && currentCommentsCount >= 0 && !commentsLoading && (
              <StyledCommentsCount>
                <StyledCommentCountCaption>{getCommentsCaption()}</StyledCommentCountCaption>
              </StyledCommentsCount>
            )}
          </div>

          {currentCommentsCount >= 1 ? (
            <Pagination
              currentPage={Number(page)}
              currentItemsCount={currentCommentsCount > 0 ? currentCommentsCount : 1}
              handlePageSelection={onChangePage}
              pageSize={DEFAULT_LIST_LIMIT}
            />
          ) : currentCommentsCount === 0 ? undefined : (
            <PaginationPlaceholder />
          )}
        </StyledSearchbarSection>
      </StyledPageHeader>

      <CommentList
        comments={comments}
        loading={commentsLoading || commentsStatisticsLoading}
        searchField={searchTerm}
      />
    </div>
  );
};

const StyledPageHeader = styled.div<{ whiteBackground: boolean }>`
  z-index: ${ZIndexStackingContext.medium};
  position: sticky;
  top: 100px;
  background-color: ${({ whiteBackground }) => (whiteBackground ? Color.white : "transparent")};
  transition: background-color 100ms ease-in-out 50ms;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
`;

const StyledSearchbarSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875em;
  margin-top: 12px;

  > div {
    display: flex;
    align-items: center;
  }
`;

const StyledStatsMenuSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledSortBySection = styled.div`
  display: flex;
  align-items: center;
  > span {
    color: ${Color.gray50};
    font-size: 15px;
    margin-right: 10px;
  }
`;

const StyledCommentsCount = styled.div`
  padding-left: 10px;
  color: ${Color.black};
`;

const StyledCommentCountCaption = styled.div`
  display: flex;
  justify-content: left;
  font-weight: bold;
  margin-left: 5px;
`;
