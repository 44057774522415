import React from "react";
import styled, { css } from "styled-components";

import { Color } from "ts/enums/color";
import { TextType } from "ts/TextType";

type Props = {
  label: TextType;
  value: string;
  isDisabled?: boolean;
  style?: Record<string, string | number>;
  required?: boolean;
  placeholder?: string;
  isInvalid?: boolean;
  handleChangeFieldValue: (string) => void;
  handleBlur?: () => void;
};

export const UserProfileEditorField = ({
  label,
  value,
  isDisabled,
  style,
  required,
  placeholder,
  isInvalid,
  handleBlur,
  handleChangeFieldValue,
}: Props) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeFieldValue(e.currentTarget.value);
  };

  return (
    <StyledUserProfileEditorField style={style}>
      <StyledFieldLabel>
        {label}
        {required && <span>*</span>}
      </StyledFieldLabel>
      <StyledFieldInput
        type="text"
        isInvalid={isInvalid}
        value={value}
        disabled={isDisabled}
        onChange={onChange}
        placeholder={placeholder}
        onBlur={handleBlur}
      />
    </StyledUserProfileEditorField>
  );
};

const StyledUserProfileEditorField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledFieldLabel = styled.label`
  font-size: 15px;
  display: block;
  span {
    color: ${Color.red50};
  }
`;

const StyledFieldInput = styled.input<{ isInvalid: boolean }>`
  margin-top: 5px;
  margin-bottom: 18px;
  background-color: ${Color.white};
  border: 1px solid ${Color.blue20};
  border-radius: 2px;
  padding: 9px 13px;
  font-family: Lato, sans-serif;
  font-size: 0.875em;
  color: ${Color.gray50};
  font-size: 15px;

  :focus {
    outline: none;
    border: 1px solid ${Color.blue50};
  }

  :disabled {
    background: ${Color.sky15};
    color: ${Color.gray30};
  }

  &::placeholder {
    color: ${Color.gray20};
    font-weight: normal;
  }

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      outline: none;
      border: 1px solid ${Color.red30};
    `}
`;
