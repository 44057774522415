import { cloneDeep } from "lodash";
import { UserDemographic } from "context/UserProfileContext";

export const typeDataToUserDemographics = (data: Record<any, any>): UserDemographic => {
  const newValuesArray = [...data.values];
  const nullValueIndex = newValuesArray.indexOf(newValuesArray.find((v) => v === null));
  if (nullValueIndex > -1) {
    newValuesArray.splice(nullValueIndex, 1, "sharing.userDemographics.nullValue");
  }
  return { name: data.name, values: newValuesArray };
};

const selectAllSectionValues = (
  allValues: UserDemographic[],
  selectedValues: Array<any>,
  selectedLabel: string
): boolean => {
  const sectionValues = allValues.find((val) => val.name === selectedLabel)?.values ?? allValues;
  return isAllSelected(sectionValues, selectedValues);
};

export const isAllSelected = (selectedValues: Array<any>, allValues: Array<any>): boolean => {
  return selectedValues.every((val) => allValues.includes(val));
};

export const selectFilter = (
  allFilters: UserDemographic[],
  selectedFilters: UserDemographic[],
  filterName: string,
  filterValues: string[],
  updateFilters: (filters: UserDemographic[]) => void
) => {
  const copy = cloneDeep(selectedFilters);
  const filterCopy = copy.find((c) => c.name === filterName);
  if (!selectedFilters.find((f) => f.name === filterName)) {
    copy.push({ name: filterName, values: filterValues });
  } else {
    const selectEntireSection = selectAllSectionValues(allFilters, filterValues, filterName);
    if (selectEntireSection) {
      const isSectionSelected = isAllSelected(filterValues, filterCopy.values);
      filterCopy.values = isSectionSelected ? [] : filterValues;
    } else {
      filterValues.forEach((value) => {
        const foundValue = filterCopy.values.find((v) => v === value);
        const selectedFilterValueArray = filterCopy.values;
        foundValue?.length
          ? selectedFilterValueArray.splice(selectedFilterValueArray.indexOf(foundValue), 1)
          : selectedFilterValueArray.push(value);
      });
    }
    if (filterCopy.values.length === 0) {
      copy.splice(copy.indexOf(filterCopy), 1);
    }
  }
  updateFilters(copy);
};
