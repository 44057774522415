import {
  CategorizationType,
  FileUploadStatus,
  AnalysisStatus,
  SharingOverviewAccess,
  SharingTopicExplorerAccess,
  SharingCommentExplorerAccess,
  SharingDashboardAccess,
  PermissionLevel,
  SortingOrder,
} from "@explorance/mly-types";
import { SharingAnalysis } from "./sharing";
import { SelectedAnalysisModel, SuggestedAnalysisModel } from "./models";
import { VariablePresetDetail } from "./variableMappings";
import { AnalysisSortingParameter } from "./enums/sorting";

export type Analysis = {
  analysisId: number;
  name: string;
  lastUpdated: Date;
  family: CategorizationType;
  fileUploadStatus: FileUploadStatus;
  fileUploadLastUpdated: Date;
  analysisStatus: AnalysisStatus;
  analysisLastUpdated: Date;
  counts: AnalysisCounts;
  languages?: string[];
  isPowerset?: boolean;
  isShared: boolean;
  owner: string;
  // only included in Shared with Me view
  sharingProperties?: { permissionLevel: PermissionLevel };
};

export type AnalysisJobSummary = {
  analysisJobSummary: Analysis[];
  itemCount: number;
  sortColumn: AnalysisSortingParameter;
  sortOrder: SortingOrder;
  totalCount: number;
};

export type PreviewUser = {
  firstname: string;
  lastname: string;
  id: number;
  allowExport: boolean;
  overviewAccess: SharingOverviewAccess;
  topicExplorerAccess: SharingTopicExplorerAccess;
  commentExplorerAccess: SharingCommentExplorerAccess;
  dashboardAccess: SharingDashboardAccess;
};

type DataSourceComment = {
  values: Record<string, string>;
};

export type AvailableResults = {
  alerts: boolean;
  recommendations: boolean;
  sentiments: boolean;
  topics: boolean;
};

export type AnalysisDataSource = {
  analysisId: number;
  name: string;
  selectedColumns: string[];
  comments: DataSourceComment[];
  commentsTotalCount: number;
  uploadStatus: FileUploadStatus;
  analysisStatus: AnalysisStatus;
  categorizationType: CategorizationType;
};

export type AnalysisJobInput = {
  selectedColumns: string[];
  graphId?: number;
  customModelId: number | null;
  precision?: string;
  commenterColumn?: string;
  variableMappingId?: number;
  excludedValues?: string[];
  env: string;
};

export type AnalysisCounts = {
  alertCount: number;
  analyzedCommentCount: number;
  recommendationCount: number;
  topicInsightCount: number;
};

export type AnalysisDetails = {
  id: number;
  name: string;
  graphId: number;
  env: string;
  precision: string;
  allColumns: string[];
  selectedColumns: [];
  selectedCommentersColumn: string;
  selectedVariableMappingId: number | null;
  selectedVariableMappingName: string | null;
  status: AnalysisStatus;
  uploadStatus: FileUploadStatus;
  maxSelectableColumns: number;
  availableResults: AvailableResults;
  sharing: SharingAnalysis;
  selectedModel: SelectedAnalysisModel;
  suggestedModel: SuggestedAnalysisModel;
  suggestedColumns: string[];
  availableVariableMappings: VariablePresetDetail[];
  excludedValues: string[] | null;
  totalExcluded: number;
};

export type RecentAnalysis = {
  analysisId: number;
  name: string;
  categorizationType: CategorizationType;
  lastUpdated: string;
  analysisStatus: AnalysisStatus;
  analyzedCommentCount: number;
  fileUploadStatus: FileUploadStatus;
  analysisLastUpdated: Date;
  fileUploadLastUpdated: Date;
  sharedWithMe: boolean;
};

type SuggestedCommentToRemove = {
  comment: string;
  occurrence: number;
};

export type PreFilteringSuggestedWords = {
  totalCount: number;
  suggestedWordsToRemove: SuggestedCommentToRemove[];
};

export const mapRESTAPIDataToAnalysisModel = (data) => ({
  analysisId: data.analysisId,
  name: data.name,
  family: data.family,
  lastUpdated: data.lastUpdated,
  owner: data.analysisOwner,
  commentsCount: data.analyzedCommentCount + data.notAnalyzedCommentCount,
  fileUploadStatus: data.fileUploadStatus,
  fileUploadLastUpdated: data.fileUploadLastUpdated,
  analysisStatus: data.analysisStatus,
  analysisLastUpdated: data.analysisLastUpdated,
  isShared: data.isShared,
  counts: {
    alertCount: data.alertCount,
    analyzedCommentCount: data.analyzedCommentCount,
    recommendationCount: data.recommendationCount,
    topicInsightCount: data.topicInsightCount,
  },
  languages: data.languages,
  isPowerset: data.isPowerset,
  sharingProperties: data.sharingProperties,
});
