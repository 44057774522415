import React, { useState } from "react";
import styled from "styled-components";
import { SharingGroup } from "ts/sharing";
import { Tooltip } from "components/Tooltip";
import { Icon, IconType } from "components/_icons/Icon";
import { Color } from "ts/enums/color";

type Props = {
  group: SharingGroup;
};

export const GroupNameCell = ({ group }: Props) => {
  const [showGroupNameTooltip, setShowGroupNameTooltip] = useState<boolean>();

  const handleHoverGroupName = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) setShowGroupNameTooltip(true);
  };

  return (
    <>
      <StyledGroupNameCell key={group.groupId}>
        <Icon type={IconType.group} size={16} />
        <span
          onMouseOver={handleHoverGroupName}
          onMouseOut={() => setShowGroupNameTooltip(false)}
          data-tip
          data-for={`${group.groupId}-groupname-tooltip`}
        >
          {group.name}
        </span>
      </StyledGroupNameCell>
      {showGroupNameTooltip && (
        <Tooltip tooltipId={`${group.groupId}-groupname-tooltip`} content={group.name} />
      )}
    </>
  );
};

const StyledGroupNameCell = styled.div`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background-color: ${Color.sky40};
  font-size: 15px;
  max-width: fit-content;
  margin-right: 14px;

  > span {
    font-weight: bold;
    font-size: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 26px);
  }
`;
