import React from "react";
import styled from "styled-components";

import { getCommentInterpretationIcon } from "common-layouts/CommentResultsIcons/helpers";
import { InterpretationIconType } from "ts/enums/interpretationIconType";

import { Color } from "ts/enums/color";

type Props = {
  count: number;
  iconType: InterpretationIconType;
  bnw?: boolean;
};

export const InterpretationPill = ({ count, iconType, bnw = false }: Props) => (
  <StyledInterpretationPillContainer isRed={iconType === InterpretationIconType.alertRedFlagAlert}>
    {getCommentInterpretationIcon(
      iconType,
      undefined,
      iconType === InterpretationIconType.topicInsight ? 10 : null,
      bnw
    )}
    <span>{iconType.includes("alert") ? `${Math.round(count)}%` : count}</span>
  </StyledInterpretationPillContainer>
);

const StyledInterpretationPillContainer = styled.div<{ isRed: boolean }>`
  border: 1px solid ${Color.neutral10};
  background-color: ${Color.white};
  width: fit-content;
  height: 22px;
  padding: 4px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  margin-right: 5px;

  span {
    font-size: 0.75em;
    margin-left: 3px;
    color: ${({ isRed }) => (isRed ? Color.red50 : Color.gray50)};
    font-weight: bold;
  }
`;
