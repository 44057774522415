import { SharingMethod } from "@explorance/mly-types";
import { SharingView } from "ts/enums/sharing";

export const getSharingMethodFromView = (sharingView: SharingView): SharingMethod => {
  if (sharingView === SharingView.ShareToUsers || sharingView === SharingView.UsersSharedWith) {
    return SharingMethod.Individual;
  }

  if (sharingView === SharingView.ShareToGroups || sharingView === SharingView.GroupsSharedWith) {
    return SharingMethod.Group;
  }
};
