import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import error from "assets/images/error.svg";
import { routes } from "routes";
import { Icon, IconType } from "components/_icons/Icon";
import { Color } from "ts/enums/color";
import { Text } from "components/Text";

export const ErrorPage = () => {
  const history = useHistory();

  return (
    <StyledErrorPage className="fade-in">
      <button className="back-button" onClick={() => history.push(routes.homePage)}>
        <Icon
          type={IconType.arrowLeft}
          color={Color.blue50}
          size={18}
          style={{ marginRight: 20 }}
        />
        <Text resource="button.backToHome" />
      </button>
      <div className="content">
        <img className="image" src={error} alt="" />
        <div className="text">
          <div className="title">
            <Text resource="errorPage.title" />
          </div>
          <div className="caption">
            <Text resource="errorPage.description" />
          </div>
        </div>
      </div>
    </StyledErrorPage>
  );
};

const StyledErrorPage = styled.div`
  margin-top: 35px;

  .back-button:focus {
    outline-style: none;
  }

  .back-button {
    background: none;
    border: none;
    color: ${Color.blue50};
    font-family: Lato, sans-serif;
    font-size: 21px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .content {
    margin: 100px auto;
    width: 500px;
  }

  .image {
    display: block;
    margin: 0 auto;
  }

  .text {
    text-align: center;
  }

  .title {
    margin: 40px 0 20px 0;
    font-size: 21px;
    font-weight: bold;
  }

  .caption {
    color: ${Color.gray40};
    font-size: 14px;

    p {
      margin: 5px 0;
      text-align: center;
    }

    a {
      color: ${Color.blue50};
      text-decoration: underline;
    }
  }
`;
