import styled from "styled-components";
import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";

export const StyledTablePageHeader = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  background-color: ${Color.white};
  z-index: ${ZIndexStackingContext.medium};
  cursor: default;
`;

export const StyledTablePageTitle = styled.div`
  font-size: 1.05em;
  font-weight: bold;
  color: ${Color.gray50};
  float: left;
  margin-top: 0px;
  margin-right: 6px;
`;

export const StyledTablePageFilterSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  top: 105px;
  background: ${Color.white};
  z-index: ${ZIndexStackingContext.medium};
  width: 100%;
  box-sizing: content-box;
`;
