import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "store";
import { useSocket } from "./SocketContext";
import { getNotifications } from "services/notifications";

import {
  Notification,
  NotificationReadStatus,
  NotificationType,
  SocketEvent,
  TaskStatus,
} from "@explorance/mly-types";
import { showToastSuccess } from "store/toast/toastSlice";

const NotificationsContext = createContext(null);

export const NotificationsProvider = ({ children }: { children: ReactNode }) => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const socket = useSocket();

  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    if (!socket || !currentUser) return;

    const handleNotifications = (notifs: Notification[]) => {
      if (
        notifs.length > 0 &&
        notifs[0].message.status === TaskStatus.Success &&
        notifs[0].type === NotificationType.UserImport &&
        notifs[0].readStatus === NotificationReadStatus.Unread
      ) {
        dispatch(showToastSuccess("toast.usersSuccesfullyImported"));
      } // Required access to notifs to trigger proper toaster for bulk user import
      setNotifications(notifs);
    };

    socket.on(SocketEvent.StreamNotifications, handleNotifications);

    return () => {
      socket.off(SocketEvent.StreamNotifications, handleNotifications);
    };
  }, [socket, currentUser, dispatch]);

  useEffect(() => {
    getNotifications().then(({ data }) => {
      setNotifications(data.notifications);
    });
  }, []);

  return (
    <NotificationsContext.Provider value={{ notifications }}>
      {children}
    </NotificationsContext.Provider>
  );
};

type RecordType = Record<string, any>;

export const useNotifications = (): RecordType => useContext(NotificationsContext);
