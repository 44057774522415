import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_LIST_LIMIT } from "assets/constants/listLimits";

import { DownloadedFilesApiData } from "ts/downloadedFiles";
import { formatSearchForFetchRequest } from "utils/formatters";
import { getDownloadedFiles } from "services/downloadedFiles";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { setEmptyStateType } from "./exportedFilesListSlice";
import { AppState } from "store/appReducer";

export const fetchDownloadedFiles = createAsyncThunk<
  DownloadedFilesApiData,
  void,
  { state: AppState }
>(
  "exportedFilesList/fetchDownloadedFiles",
  async (_, { getState, dispatch }): Promise<DownloadedFilesApiData> => {
    const state = getState();
    const downloadedFilesListState = state.exportedFilesList;
    const searchTerm = state.search.searchTerm;

    const { data: downloadedFilesData } = await getDownloadedFiles(
      downloadedFilesListState.sortColumn,
      downloadedFilesListState.sortOrder,
      DEFAULT_LIST_LIMIT,
      downloadedFilesListState.currentPage,
      formatSearchForFetchRequest(searchTerm)
    );

    const emptyStateType =
      downloadedFilesData.itemCount === 0 && searchTerm?.length === 0
        ? EmptyStateType.noListResults
        : downloadedFilesData.itemCount === 0 && searchTerm.length > 0
        ? EmptyStateType.noSearchResults
        : null;

    dispatch(setEmptyStateType(emptyStateType));

    if (!downloadedFilesData) {
      throw new Error("Failed to fetch exported files");
    }

    return downloadedFilesData;
  }
);
