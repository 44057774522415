import { DemographicFilter } from "ts/filters/demographicFilter";

export const getUpdatedDemographicFilters = (
  availableDemographicFilters: DemographicFilter[],
  demographicApiData: Record<string, any>
) =>
  availableDemographicFilters.map((filter: DemographicFilter) =>
    filter.id === demographicApiData.id
      ? {
          ...filter,
          listValues: [...filter.listValues, ...demographicApiData.values],
          totalCount: demographicApiData.totalCount,
        }
      : filter
  );
