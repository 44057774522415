import React from "react";
import styled from "styled-components";

import { Skeleton } from "components/Skeleton";

export const StatsPlaceholder = () => {
  const statSizes = [
    {
      width: 110,
      height: 62,
    },
    {
      width: 110,
      height: 62,
    },
    {
      width: 92,
      height: 62,
    },
    {
      width: 91,
      height: 62,
    },
    {
      width: 92,
      height: 62,
    },
    {
      width: 110,
      height: 62,
    },
  ];

  return (
    <StyledStatsPlaceholder>
      {statSizes.map((placeholder, i) => (
        <div key={i} className="stats-placeholder pulse">
          <Skeleton width={placeholder.width} height={placeholder.height} />
        </div>
      ))}
    </StyledStatsPlaceholder>
  );
};

const StyledStatsPlaceholder = styled.div`
  display: flex;
  margin-bottom: 14px;

  .stats-placeholder {
    border-radius: 4px;
    margin-right: 8px;
  }
`;
