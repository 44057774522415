import React from "react";
import styled from "styled-components";
import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import emptyNotificationState from "assets/images/empty-notification-state.svg";
import { Icon, IconType } from "components/_icons/Icon";
import { Text } from "components/Text";

export const EmptyState = () => (
  <StyledEmptyState>
    <StyledBellIcon>
      <Icon type={IconType.bell} size={81.2} color={Color.blue20} />
    </StyledBellIcon>
    <StyledSearchImgContainer>
      <img src={emptyNotificationState} alt="Empty Notifications" width={250} />
    </StyledSearchImgContainer>
    <StyledLabel>
      <Text resource="notificationCenter.emptyState" />
    </StyledLabel>
  </StyledEmptyState>
);

const StyledEmptyState = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: none;
  margin-top: 105px;
  margin-left: 67px;
  width: 298px;
  height: 147.5px;
`;

const StyledBellIcon = styled.div`
  position: relative;
  top: 81px;
`;
const StyledSearchImgContainer = styled.div`
  position: relative;
  bottom: 79px;
  z-index: ${ZIndexStackingContext.medium};
`;

const StyledLabel = styled.div`
  position: relative;
  bottom: 81px;
  font-size: 20px;
  font-weight: bold;
  color: ${Color.gray50};
  height: 24px;
`;
