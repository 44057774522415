import React, { useState } from "react";
import styled from "styled-components";

import { AnalysisModel } from "ts/filters/analysisModel";
import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";

import useClickOutside from "hooks/useClickOutside";

import { CategorizationTypeSymbol } from "components/_icons/CategorizationTypeSymbol";
import { Icon, IconType } from "components/_icons/Icon";
import { Tooltip } from "components/Tooltip";
import { StyledDropdownTransition } from "components/DropdownMenu/StyledDropdownTransition";
import { Text } from "components/Text";

type Props = {
  selectedModel: AnalysisModel;
  options: AnalysisModel[];
  isDisabled?: boolean;
  customWidth?: string;
  // parameter is untyped to prevent union type errors but argument is explicitly typed when function is invoked
  handleSelectMenuOption: (option) => void;
};

export const ModelDropdown = ({
  selectedModel,
  options,
  isDisabled,
  customWidth,
  handleSelectMenuOption,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const dropdownRef = useClickOutside(() => setIsOpen(false));

  const handleClickOption = (option: number) => {
    handleSelectMenuOption(option);
    setIsOpen(false);
  };

  const getDropdownOption = (modelOption: AnalysisModel, isTooltip?: boolean) => {
    if (!modelOption?.modelId) {
      return <Text resource="modelSelection.defaultOption" />;
    }

    if (modelOption.isVirtual) {
      return (
        <StyledCustomModelName isTooltip={isTooltip} onMouseOver={handleHoverModelName}>
          {modelOption.modelName}
        </StyledCustomModelName>
      );
    }
    return (
      <div onMouseOver={handleHoverModelName}>
        <CategorizationTypeSymbol categorizationType={modelOption.categorizationType} />
        <span className="analysis-model-name">{modelOption.modelName}</span>
      </div>
    );
  };

  const getDropdownButton = () => {
    return (
      <StyledAnalysisDropdownButton
        isOpen={isOpen}
        onClick={() => setIsOpen((prevValue) => !prevValue)}
        onMouseOver={handleHoverModelName}
        onMouseOut={() => setShowTooltip(false)}
        data-tip
        data-for={`${selectedModel}-tooltip`}
        disabled={isDisabled || !selectedModel?.modelId}
      >
        {getDropdownOption(selectedModel)}
        <Icon type={IconType.triangleFilledDown} color={Color.gray20} size={8} />
        {showTooltip && (
          <StyledTooltip tooltipId={`${selectedModel}-tooltip`}>
            {getDropdownOption(selectedModel, true)}
          </StyledTooltip>
        )}
      </StyledAnalysisDropdownButton>
    );
  };

  const handleHoverModelName = (
    e: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth && !isOpen) setShowTooltip(true);
  };

  return (
    <StyledModelDropdown width={customWidth} ref={dropdownRef}>
      {getDropdownButton()}
      <StyledDropdownMenu width={customWidth} show={isOpen}>
        <ul>
          {options?.map((o) => (
            <li
              key={o.modelId}
              className={selectedModel?.modelId === o?.modelId ? "selected" : ""}
              onClick={() => handleClickOption(o.modelId)}
            >
              {getDropdownOption(o)}
            </li>
          ))}
        </ul>
      </StyledDropdownMenu>
    </StyledModelDropdown>
  );
};

const StyledModelDropdown = styled.div<{ width?: string }>`
  position: relative;
  width: ${({ width }) => width ?? "100%"};
  display: inline-block;

  .cat-dropdown-option {
    display: flex;
    align-items: center;

    .model-container {
      display: flex;
    }
  }
`;

const StyledAnalysisDropdownButton = styled.button<{
  isOpen: boolean;
  disabled: boolean;
}>`
  border-radius: 3px;
  outline: none;
  display: flex;
  align-items: center;
  background: ${Color.white};
  border: 1px solid ${Color.sky50};
  height: 36px;
  font-weight: normal;
  color: ${Color.black};
  cursor: pointer;
  padding-right: 24px;
  min-width: 100%;
  max-width: 100%;
  font-size: 14px;

  :disabled {
    background: ${Color.neutral10};
    color: ${Color.gray30};
  }

  svg {
    margin-top: 3px;
    margin-left: 10px;
    right: 7px;
    position: absolute;
  }

  .analysis-model-name {
    margin: 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
  }
`;

const StyledTooltip = styled(Tooltip)`
  display: flex;
  align-items: center;

  .model-container {
    span {
      margin-left: 8px;
    }
  }
`;

const StyledDropdownMenu = styled(StyledDropdownTransition)<{
  width: string;
  show: boolean;
}>`
  position: absolute;
  margin-top: 3px;
  width: 100%;
  z-index: ${ZIndexStackingContext.medium};
  border: 1px solid ${Color.sky50};
  border-radius: 2px;
  box-shadow: 0px 3px 6px #00000029;
  background: ${Color.white};
  max-height: 300px;
  overflow: auto;

  ul {
    list-style: none;
    padding: 5px 0;
    text-align: left;
    margin: 3px 0;

    li {
      display: flex;
      align-items: center;
      padding: 8px 12px;
      font-size: 14px;
      color: ${Color.gray40};
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;

      .analysis-model-name {
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 10px;
        white-space: nowrap;
      }

      .cat-model-name {
        margin-left: 8px;
        margin-right: 5px;
      }

      &.selected {
        color: ${Color.blue50};
        font-weight: bold;
      }

      :hover {
        background: ${Color.sky15};
        color: ${Color.blue50};
        cursor: pointer;
      }
    }
  }
`;

const StyledCustomModelName = styled.span<{ isTooltip: boolean }>`
  white-space: ${({ isTooltip }) => (isTooltip ? "wrap" : "nowrap")};
  overflow: hidden;
  text-overflow: ellipsis;
`;
