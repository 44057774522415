import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useQueryParams = (query?: string) => {
  const location = useLocation();
  return useMemo(
    () => Object.fromEntries(new URLSearchParams(query || location.search)),
    [query, location.search]
  );
};
