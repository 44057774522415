import React, { useState, forwardRef, Ref } from "react";
import styled from "styled-components";
import { Icon, IconType } from "components/_icons/Icon";
import { Text } from "components/Text";
import { Color } from "ts/enums/color";

type Props = {
  show?: boolean;
  labelKey: string;
  isExpanded: boolean;
  isActive?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  unreadCount: number;
};

const NotificationItem = forwardRef(
  (
    { show = true, labelKey, isExpanded, onClick, isActive, onMouseEnter, unreadCount = 0 }: Props,
    ref: Ref<HTMLDivElement>
  ) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);

    if (!show) return null;

    const handleMouseEnter = () => {
      onMouseEnter && onMouseEnter();
      setIsHovered(true);
    };

    return (
      <StyledNotificationItem
        isActive={isActive}
        isExpanded={isExpanded}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => setIsHovered(false)}
        ref={ref}
      >
        <StyledIconContainer>
          <StyledBellContainer>
            <Icon
              type={IconType.bell}
              size={16}
              color={isActive ? Color.indigo45 : isHovered ? Color.gray50 : Color.gray20}
            />
          </StyledBellContainer>
          <StyledCircle unreadCount={unreadCount} />
        </StyledIconContainer>
        <StyledExpandedNameContainer isActive={isActive} isExpanded={isExpanded}>
          <Text resource={labelKey} />
          <StyledPill isExpanded={isExpanded} unreadCount={unreadCount}>
            <StyledUnreadCount>{unreadCount > 9 ? "9+" : unreadCount}</StyledUnreadCount>
          </StyledPill>
        </StyledExpandedNameContainer>
      </StyledNotificationItem>
    );
  }
);

NotificationItem.displayName = "Notification Item";

const StyledIconContainer = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  svg * {
    transition: fill 50ms ease-in-out;
  }
`;

const StyledBellContainer = styled.div`
  position: relative;
  top: 2.5px;
`;

const StyledCircle = styled.div<{ unreadCount: number }>`
  position: absolute;
  height: 10px;
  width: 10px;
  right: 2px;
  top: 9px;
  border: 1px solid ${Color.white};
  background-color: ${Color.red50};
  border-radius: 50%;
  visibility: ${({ unreadCount }) => (unreadCount <= 0 ? "hidden" : "visible")};
`;

const StyledPill = styled.div<{ isExpanded: boolean; unreadCount: number }>`
  position: absolute;
  display: flex;
  margin-right: 8px;
  justify-content: center;
  align-items: center;
  right: 0;
  height: 16px;
  width: 22px;
  background-color: ${Color.red50};
  font-size: 10;
  font-weight: bold;
  color: ${Color.white};
  border-radius: 12px;
  visibility: ${({ isExpanded, unreadCount }) =>
    isExpanded && unreadCount > 0 ? "visible" : "hidden"};
  opacity: ${({ isExpanded }) => (isExpanded ? "1" : "0")};
  transition: opacity 150ms ease-in-out 100ms;
`;

const StyledUnreadCount = styled.div`
  font-size: 10px;
  font-weight: bold;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  color: ${Color.white};
`;

const StyledNotificationItem = styled.div<{
  isActive: boolean;
  isExpanded: boolean;
}>`
  background-color: ${({ isActive }) => (isActive ? Color.sky15 : "none")};
  border-radius: 5px;
  width: ${({ isExpanded }) => (isExpanded ? "204px" : "28px")};
  height: 28px;
  display: flex;
  align-items: center;
  margin: 8px auto;
  cursor: pointer;
  transition: background-color 50ms ease-in-out, width 250ms ease-in-out;

  &:hover {
    background-color: ${Color.indigo10};
    transition: background-color 50ms ease-in-out;

    span {
      color: ${({ isActive }) => !isActive && Color.gray50};
      transition: color 50ms ease-in-out;
    }
  }

  a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

const StyledExpandedNameContainer = styled.div<{
  isExpanded: boolean;
  isActive: boolean;
}>`
  display: flex;
  align-items: center;
  opacity: ${({ isExpanded }) => (isExpanded ? "1" : "0")};
  position: relative;
  left: 10px;
  visibility: ${({ isExpanded }) => (isExpanded ? "visible" : "hidden")};
  width: ${({ isExpanded }) => (isExpanded ? "80%" : "0px")};
  transition: visibility 150ms ease-in-out 150ms, opacity 150ms ease-in-out 150ms,
    width 250ms ease-in-out;
  white-space: nowrap;
  text-overflow: clip;
  overflow: hidden;

  span {
    color: ${({ isActive }) => (isActive ? Color.indigo45 : Color.gray20)};
    font-size: 0.875em;
    margin-right: 35px;
    font-weight: ${({ isActive }) => isActive && "bold"};
    white-space: nowrap;
    text-overflow: clip;
    overflow: hidden;
    transition: color 50ms ease-in-out;
  }

  svg {
    margin-top: 1px;
  }
`;

export { NotificationItem };
