import React from "react";
import { isNumber } from "lodash";

import { Color } from "ts/enums/color";
import { SentimentType } from "@explorance/mly-types";
import { InterpretationIconType } from "ts/enums/interpretationIconType";

import { Icon, IconType } from "components/_icons/Icon";
import { SentimentIcon } from "components/_icons/SentimentIcon";

export const getCommentInterpretationIcon = (
  type: InterpretationIconType,
  sentiment?: SentimentType,
  size?: number,
  bnw?: boolean
) => {
  switch (type) {
    case InterpretationIconType.comment:
      return (
        <Icon
          type={
            IconType[sentiment === SentimentType.notExplicit ? SentimentType.neutral : sentiment]
          }
          size={16}
        />
      );
    case InterpretationIconType.topicInsight:
      return (
        <Icon
          type={IconType.categories}
          size={size ?? 14}
          color={Color.gray50}
          style={{ marginLeft: 2 }}
        />
      );
    case InterpretationIconType.recommendation:
      return <Icon type={IconType.recommendation} size={size ?? 12} color={Color.gray50} />;
    case InterpretationIconType.alertNothingToReport:
      return <Icon type={IconType.alertNoBgColor} size={size ?? 14} color={Color.gray50} />;
    case InterpretationIconType.sentiment:
      return <SentimentIcon type={sentiment} size={size ?? 14} bnw={bnw} />;
    case InterpretationIconType.alertRedFlagAlert:
      return (
        <Icon
          type={IconType.alertNoBgColor}
          size={size ?? 14}
          color={bnw ? Color.gray50 : Color.red50}
        />
      );
  }
};

export const getCommentInterpretationCounts = (results, alertThreshold) => {
  const countTopics = (accumulator, currentValue, arr) => accumulator + arr[currentValue].length;
  const countsOutput = [];

  // TOPIC INSIGHTS
  if (results["categorizedTopics"]) {
    const topicInsightCount = Object.keys(results["categorizedTopics"]).reduce(
      (a, b) => countTopics(a, b, results["categorizedTopics"]),
      0
    );
    countsOutput.push({ count: topicInsightCount, type: InterpretationIconType.topicInsight });
  }

  // RECOMMENDATIONS
  const recommendationCount = results["categorizedRecommendations"]
    ? Object.keys(results["categorizedRecommendations"]).reduce(
        (a, b) => countTopics(a, b, results["categorizedRecommendations"]),
        0
      ) + results["recommendations"]?.length
    : results["recommendations"]?.length || 0;
  countsOutput.push({ count: recommendationCount, type: InterpretationIconType.recommendation });

  // ALERTS
  const alertScore = results["alertScore"];

  // check if alertScore exists
  if (isNumber(alertScore)) {
    const hasAlert = alertScore && alertScore > alertThreshold;
    const alertType = hasAlert
      ? InterpretationIconType.alertRedFlagAlert
      : InterpretationIconType.alertNothingToReport;
    countsOutput.push({ count: alertScore, type: alertType });
  }

  return countsOutput;
};
