// library
import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";

// components
import { FilterTopicTree } from "common-layouts/TopicFilterSelection/FilterTopicTree";
import { Checkbox } from "components/_inputs/Checkbox";
import { onSelectTopicFilter, handleSelectAll, isChildTopic } from "./helpers";
import { recursiveFindAllChildTopicNodes } from "utils/topics";
import { AnalysisModel } from "ts/filters/analysisModel";
import { ApiTopicNode } from "ts/topic";
import { CheckboxCheckedAppearance } from "ts/enums/checkboxCheckedAppearance";
import { Color } from "ts/enums/color";
import { Text } from "components/Text";

interface Props {
  localSelectedModel: AnalysisModel;
  localSelectedTopicNodes: ApiTopicNode[];
  allSelectedNodes?: ApiTopicNode[];
  disabled?: boolean;
  setLocalSelectedTopicNodes: (selectedTopics: ApiTopicNode[]) => void;
  includeParentsForInsert?: boolean;
  initialAllSelected?: boolean;
}

const TopicsFilter = ({
  localSelectedModel,
  localSelectedTopicNodes,
  allSelectedNodes,
  disabled,
  setLocalSelectedTopicNodes,
  includeParentsForInsert,
  initialAllSelected,
}: Props) => {
  const [isAllSelected, setIsAllSelected] = useState<boolean>(initialAllSelected ?? false);

  const topicsTreeData = localSelectedModel?.topics;
  const nbLeaves = topicsTreeData?.flatMap((branch) =>
    recursiveFindAllChildTopicNodes(branch)
  ).length;

  const getIsAllSelected = useCallback((nbLeaves, selectedTopicNodes) => {
    return selectedTopicNodes.some((tn) => isChildTopic(tn)) && includeParentsForInsert
      ? nbLeaves ===
          selectedTopicNodes.filter(
            (t) => isChildTopic(t) && !selectedTopicNodes.some((tn) => tn.parentId === t.id)
          ).length
      : nbLeaves === selectedTopicNodes.length;
  }, []); //eslint-disable-line

  useEffect(() => {
    setIsAllSelected(getIsAllSelected(nbLeaves, localSelectedTopicNodes));
  }, [localSelectedTopicNodes, nbLeaves, getIsAllSelected, initialAllSelected]);

  if (!topicsTreeData) return null;

  return (
    <StyledTopicsFilter disabled={disabled}>
      <div className="select-all-section">
        <Checkbox
          checkedAppearance={
            initialAllSelected ?? getIsAllSelected(nbLeaves, localSelectedTopicNodes)
              ? CheckboxCheckedAppearance.Default
              : CheckboxCheckedAppearance.Partial
          }
          checked={localSelectedTopicNodes.length > 0}
          onChange={() =>
            disabled
              ? undefined
              : handleSelectAll(topicsTreeData, isAllSelected, setLocalSelectedTopicNodes)
          }
          size={14}
          disabled={disabled}
        />
        <div
          className="select-all-label"
          onClick={() =>
            !disabled && handleSelectAll(topicsTreeData, isAllSelected, setLocalSelectedTopicNodes)
          }
        >
          <Text resource="columnFilterSection.selectAll" />
        </div>
      </div>
      {topicsTreeData?.map((item) => (
        <FilterTopicTree
          key={`${item.id}-${item.fullPath}`}
          definition={item}
          selectAll={isAllSelected}
          localSelectedTopicNodes={localSelectedTopicNodes}
          allSelectedNodes={allSelectedNodes}
          includeParentsForInsert={includeParentsForInsert}
          disabled={disabled}
          onChangeFilter={(selectedFilter) =>
            onSelectTopicFilter(
              selectedFilter,
              topicsTreeData,
              localSelectedTopicNodes,
              setLocalSelectedTopicNodes,
              includeParentsForInsert
            )
          }
          isCustomModel={localSelectedModel.isVirtual}
        />
      ))}
    </StyledTopicsFilter>
  );
};

const StyledTopicsFilter = styled.div<{ disabled?: boolean }>`
  .select-all-section {
    display: flex;
    align-items: center;
    margin-bottom: 6px;

    .checkbox input {
      margin: 0px 0px 0px 1px;
    }
  }
  .select-all-label {
    margin-left: 10px;
    font-size: 0.875em;
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
    color: ${({ disabled }) => (disabled ? Color.gray30 : Color.gray50)};
  }
`;

export { TopicsFilter };
