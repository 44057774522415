import { Text } from "components/Text";
import { Icon, IconType } from "components/_icons/Icon";
import React from "react";
import styled from "styled-components";
import { Color } from "ts/enums/color";
import { WidgetStatistic } from "ts/widgetStatistics";
import { DisplayedStats } from "./WidgetStat/L1/DisplayedStats";
import { CommentsView, WidgetStat, WidgetType } from "@explorance/mly-types";
import { HandleClickCommentsParams } from ".";

type Props = {
  data: WidgetStatistic[];
  selectedStats: WidgetStat[];
  onClickComments: (params: HandleClickCommentsParams) => void;
};

export const GeneralMostAlertsStat = ({ data, selectedStats, onClickComments }: Props) => {
  return (
    <StyledGeneralMostAlertStat>
      <StyledMetricName>
        <Text resource="totalBlockCount.title.alerts" />
        <DisplayedStats
          selectedStats={selectedStats}
          data={data[0]}
          widgetType={WidgetType.MostAlerts}
          onClickComments={() => onClickComments({ view: CommentsView.alerts })}
        />
      </StyledMetricName>
      <StyledWidgetStat>
        <b>
          <Text resource="widget.mostAlerts.stats.requiresAttention" />
        </b>
        <StyledAlertCount>
          <Icon type={IconType.warning} color={Color.red50} size={16} />
          <span style={{ color: Color.red50 }}>{data[0].alertCount}</span>
        </StyledAlertCount>
      </StyledWidgetStat>
    </StyledGeneralMostAlertStat>
  );
};

const StyledGeneralMostAlertStat = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 1px solid ${Color.blue20};
  border-radius: 5px;
  padding: 12px;
`;

const StyledWidgetStat = styled.div`
  display: flex;
  align-items: center;
`;

const StyledMetricName = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
`;

const StyledAlertCount = styled.div`
  display: flex;
  align-items: baseline;
  gap: 3px;
  margin-left: auto;
  font-size: 22px;
  font-weight: bold;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
`;
