import { CategorizationType } from "@explorance/mly-types";

export const generatedComments = {
  default: [
    "Considering the volume of work that we are doing at my company around data integrity and the increased focus of data integrity by regulatory agencies, I would venture to say this is a potential huge pool of work out there to audit and help companies remediate shortcomings.",
    "Stewards are generally pleasant, bright and hard working, which are key ingredients for good teams and successful delivery of client projects. There have been moments of public recognition for steward achievements...need more of it.",
    "I have seen more conversations within the firm around balance between work life and personal life and I have had more open conversations with other stewards around this lately and that definitely helps because then we all learn from one another. I would keep encouraging these kinds of organic discussions. Life isn't always balanced when you are traveling but learning from others has definitely helped me achieve more frequent balance.",
    "Knowledge Sharing within the institution to enhance our industry expertise on the client site, and knowledge of what other clients do (some type of internal communication method besides slack that shows who is an expert in what).",
    "Having worked on one project so far. With company mergers trending, there is need to detangle from bureaucracy. Inevitably in a bid to merge company resources, there is overlap and sometimes red tape which only works to slow down productivity. A clear understanding of the existing business processes is needed and then a strategy for seamless integration of both resources and business processes is necessary.",
    "It can be difficult to manage learning with client work or sales. I understand this is the nature of consulting and it requires you to learn on the job and manage your time effectively. However, for stewards who are in time consuming roles that they currently do not enjoy, this can be an almost impossible task.",
    "After delivering so many ERP Projects, we owe it to ourselves to make sure that we go with set of requirements, set of transactions, set of business documents, set of roles and say that, we will implement core functionalities on this ERP projects with fixed fee with no scope changes. I think that will help us win smaller deals and smaller projects, which eventually can grow into bigger accounts.",
    "Sometimes priorities get crossed up and direction given is not exactly what management is looking for from support team. I am not a mind reader, nor is anyone else, so need management to communicate exactly what they want (final deliverable) versus providing high level guidance and expecting steward(s) will hit the specific mark.",
    "Things are fine with me.",
    "Doing okay!",
    "Implementation and operations practices: post implementation assessments, redesign and enhancements, migrate from on premise to cloud solution, user experience simplification ( like SAP screen personas)",
    "Train, adopt, develop, and deliver methodology is the key concept to retain the present business partners and gain new pockets based on present client satisfaction and our talented resources' quality on time deliverables.",
    "There is a great deal of favoritism within the firm which causes talented stewards to be overlooked for opportunities they may be qualified for.",
    "I have seen numerous examples of a project where a client is deliberately disrespectful to the institutions on employees with a partner present and this behavior is either condoned or ignored by the partner. No follow up action to the individual affected or the person who made the comment. I do understand that addressing publicly is sometimes difficult, but a follow up in private would have been expected if we hold ourselves to a high standard.",
    "I think there is some disconnect with the influx of new hires in the last two years on what providing brilliant client service means. I think it would be good to teach them the expectations of clients, how to work as a professional consultant, handling difficult situations and how to interact properly with the client (this may need to include spelling out exactly what not to do). Perhaps the institution could look into providing training at the company meeting for the newer stewards on how to handle difficult situations with clients and teach them how to be more professional. I know the institution provides a consulting skills training, but it looks like some stewards are still missing the mark especially on the client interaction. Also, for the younger stewards it should be stressed to stay off your personal phone while you are at work interacting with the client and also the institution's stewards as it is disrespectful to the person you are interacting with.",
    "The firm's benefits package and reward programs are due for a revamp. There is an opportunity here to differentiate the institution from other consulting companies. Things like VTO (volunteer time off), additional paid vacation time, caregiver leave, wellness allowance, increased parental leave, etc.",
    "Retailers need help developing (and executing) a strategy to compete against the big players. In addition, their strategies should incorporate how to protect or carve out a niche. In other words, we should be offering our clients services to help them devise and/or pressure test their strategies. We have Retail Data Strategies but need to think broader. From a technology standpoint, we need a clear approach on how to advise clients on updating their existing SAP systems. There are so many options...need to make it as clear as possible.",
    "I feel that we are not really agile. We have gone through a couple scrum masters and each one has their own ideas and understanding of how it should work. We start over every time, very wasteful and confusing. We are using too many unrelated tools (Devops tools) which increases our time and decreases our innovativeness and creativity. We do need testing tools but they should be chosen for each department according to what they need, not all departments write software. I think we need less blame and more teamwork.",
    "I would like to hear more questions from the stewards and less presentations from leadership and stewards. There are so many massive campaigns going on the amount if information we are being given can become overwhelming and redundant. Yes BD is important, especially now, but so is delivering our current commitments.",
    "We have a lot of initiatives, migrations and training going on at the same time at a very rapid pace. I feel we should ease up slightly, so associates can have a better focus on the overall goals vs too much on everyone's plate and losing focus. I love working for the institution and all the initiatives are important, just all at once can be overwhelming for some associates.",
    "Having worked on one project so far. With company mergers trending, there is need to detangle from bureaucracy. Inevitably in a bid to merge company resources, there is overlap and sometimes red tape which only works to slow down productivity. A clear understanding of the existing business processes is needed and then a strategy for seamless integration of both resources and business processes is necessary.",
    "Like stated earlier, a need for a system so that people don't think there are no consequences. I love the freedom here but some people take too much advantage of that freedom and no one tells them they shouldn't be doing whatever it is they are doing/not doing until its past a point of return and has messed up the team goals",
    "Slow down and analyze current processes/procedures. Chasing innovation does us no good if our current infrastructure is inadequate. Treat employees with respect and stop manipulating their titles to try and pump more results out of them for the same amount of pay.",
    "I have seen more conversations within the firm around balance between work life and personal life and I have had more open conversations with other stewards around this lately and that definitely helps because then we all learn from one another. I would keep encouraging these kinds of organic discussions. Life isn't always balanced when you are traveling but learning from others has definitely helped me achieve more frequent balance.",
    "Sometimes priorities get crossed up and direction given is not exactly what management is looking for from support team. I am not a mind reader, nor is anyone else, so need management to communicate exactly what they want (final deliverable) versus providing high level guidance and expecting steward(s) will hit the specific mark.",
    "I do not believe that methodologies advertised on cloud and in training are not actually used on client projects. I think this is fine, as long as we are upfront with clients about this beforehand. I think some transparency on leaderships ability to generate new business should be enhanced, especially with the significant amount of layoffs that we had recently as a result.",
  ],
  [CategorizationType.EEC]: [
    "The benefits package is excellent, and the company is very supportive of work-life balance. However, I do wish there were more opportunities for individual recognition. It can be discouraging to feel like our hard work isn't being noticed, and more recognition would help to motivate and inspire us to continue to do our best.",
    "The organization has a strong focus on sustainability, which I really appreciate. However, I think there's room for improvement in terms of reducing waste in our office. It would be great to have more recycling bins and other initiatives to reduce our environmental impact. It would also be helpful to have more education and awareness around sustainability practices.",
    "The organization's commitment to employee wellness and mental health is something that I value highly. However, I do think there could be more resources and support for employees dealing with stress and mental health issues. It's important to provide access to counseling and other services that can help employees cope with these challenges.",
    "I really appreciate the opportunities for growth and development within the organization. However, I do sometimes feel like there's a lack of transparency around promotion and advancement opportunities. It would be helpful to have more clarity around what it takes to move up in the company.",
    "The company culture is fantastic, but I do think there could be more emphasis on work-life balance for certain departments. Some teams seem to be working around the clock, which can lead to burnout and dissatisfaction. It's important to ensure that all employees are able to maintain a healthy balance between work and personal life.",
    "I love the diversity and inclusivity that is promoted within the organization, but I do think there could be more training and education around these topics. It's important to ensure that all employees are aware of and sensitive to issues of diversity and inclusion, and that we're all working towards a more equitable and just workplace.",
    "The company mission and values align with my own personal beliefs, but I do think there could be more effort to improve diversity and inclusion within the organization. It's important to ensure that all employees feel included and valued, regardless of their background or identity, and to work towards a more diverse and representative workforce.",
    "Like I previously stated my manger so awesome. I'd really love to let you guys know that she kept us involved and engaged as a team and individuals. She has always been very supportive with my growth here. My manager is one of the individuals here that make this company a great place to work at. Thank you for everything you have done for me and your team.",
    "My direct supervisor is an honest, hard-working, creative, passionately dedicated, intelligent, analytical leader who demonstrates a great deal of integrity. He supports his teams and leads by example. He listens, provides appropriate feedback, and evidently cares about the well-being of not only the company, but also its people, especially his teams. He's an advocate for logical, cost-effective, elegant solutions at all levels of the company, and is sincerely concerned when they stray from that standard. He's a strong voice that stands up and steady for what he believes to be in the best interest of the company, our members, and our companies community as a whole.",
    "A typical day at work consists of our call center receiving a daily high call volume. Our team of 40 assist providers with claim status inquiries. We have great management who truly cares about the success and growth of their team members. The hardest part of this job is the extreme high call volume, however the most enjoyable part of this job is the work environment and wonderful co-workers. I have learned the true meaning of teamwork.",
    "During my interview they made the company sound like it was a great place for advancement and opportunities to grow in my position and pay. Upon hire I soon realized that although a lot of nice 'perks' were being promised, that rarely anyone received these perks as they would have a lot of exclusions and ways of you not being eligible for such benefits. The upper management treats employees as numbers and it is not uncommon to receive very short notice of mandatory overtime and last minute changes. The pay was awful and promises of future opportunities for pay increases was nothing but hot air. All and all, I would NEVER recommend anyone work here. It is a place for someone that just needs a job, not a place that they enjoy going to or that they want to start a career at. Two thumbs down for the company.",
    "Identify a successful strategy/technology to track the training stewards currently have, the training they receive, how they are progressing through their careers, and where they want their careers to go.",
    "Teams could still benefit from continual reminders to provide feedback and solicit comments on their work to increase awareness that it's expected and reduce the stigma around such feedback. Regarding resources for career and personal development, this could benefit from more education around eLearning opportunities, certifications, and open enrollments/awareness for the internal classes that are currently very limited or are invite only.",
    "On remote delivery projects, keeping people engaged that do not live near strategic cities. Suggest building into the cost a quarterly or monthly team get together. Work/life balance such as forcing people to take red eyes from west coast / east coast should not be condoned. Personal choice is fine, but that should not be the only option given to staff.",
    "I do not believe that methodologies advertised on cloud and in training are not actually used on client projects. I think this is fine, as long as we are upfront with clients about this beforehand. I think some transparency on leaderships ability to generate new business should be enhanced, especially with the significant amount of layoffs that we had recently as a result.",
    "The role that I was assigned changed into a role with little to no autonomy. I feel frequently micromanaged in small tasks (review of emails before sending, multiple reviews of team correspondences, etc.). I being challenged due to work load, but the work is not a personal interest of mine. I can definitely leverage many of the skills that I am gaining at the current project (time management, managing multiple activities simultaneously, presentation skills etc.). However, I am too overwhelmed with everyday workflow to pursue additional learning.",
    "The institution will not be successful in retaining talent if projects are managed like the previous one. I have had a positive experience with my personal assistance and with Corporate senior leadership. Unfortunately, however, as I spend most of my time on the client side, I have not had a positive experience with senior leadership at the project level. This is because senior leadership is not at all responsive to stewards work/life balance, career goals, or project input. This has been the most discouraging part of work and feeling like my voice does not matter and that no matter what I express, the institution's leadership is not concerned about protecting my best interest or the well-being of the team. It is clear that the on this project financial gain is the sole driving force of senior leadership.",
    "Although I could personally do more to encourage more active feedback, recurring meetings that I have set up have not been attended due to competing priorities (and have not been rescheduled). The main feedback that I have received was in performance reviews. My manager does not inform me of firm activities or opportunities. I don't personally feel as though my manager actively supports my professional development. My manager is open to hearing concerns, ideas, and suggestions (though they are infrequently taken). I am well respected on a personal level, however. There is no effort to maintain a work-life balance on my current project. There have been frequent requests for information past 9 PM during the week, and weekend work feels expected. I feel inundated with activities with little direction on how to prioritize. I was actively discouraged from recording all the hours I work on the project. I have only recorded 40 since this event.",
    "My current manager listens to her team which is so important. She is fair and tries very hard to deal with conflict within the team. She is a manager that will jump in to help her team if we are busy and overwhelmed. I can understand why people leave her team only when they retire. She also promotes advancement with the team and always give positive feedback, even when a team member gets praise from another source. It is a joy working with my manager.",
  ],
  [CategorizationType.ELC]: [
    "My current manager listens to her team which is so important. She is fair and tries very hard to deal with conflict within the team. She is a manager that will jump in to help her team if we are busy and overwhelmed. I can understand why people leave her team only when they retire. She also promotes advancement with the team and always give positive feedback, even when a team member gets praise from another source. It is a joy working with my manager.",
    "I particularly believe that we should not make the course a copy of the same applied to the Aeronautic and Medical industry since our industry even when is dangerous our levels of severity when mistakes and accidents happen are not so extreme as are in the above-mentioned industries.",
    "As for virtual training, the course organization was very good, but for the future, I would prefer having such a long course postponed for several weeks or even months than to have it virtually.",
    "Most valuable is the interaction/experience of each instructor in course A, where we are missing such courses course B, as we are always in the field, and not knowing all the technology/technical parts from the experts like those who were in charge of the course.",
    "I particularly believe that we should not make the course a copy of the same applied to the Aeronautic and Medical industry since our industry even when is dangerous our levels of severity when mistakes and accidents happen are not as extreme as are in the above-mentioned industries.",
    "Demanding more cooperation from the client personnel to give us requirements; emphasize training for the client personnel so they can be familiar with the implemented system and shorten the learning curve to get used to the new system.",
    "My manager is a dream to work under. I know that she is looking out for me and my best interest. She always has an open line of communication and is willing to speak at any time as long as she is available. She listens effectively and provides feedback in a professional and honest manner. She has been a great sounding board and offered recommendations whenever I've been faced with a difficult client.",
    "Fast-paced needs training and people are often very busy. The workplace was supportive but I felt cut off from the culture of mental health in a clinical way.",
    "I love my job but I am really upset that the company gave us Central and our tool to work out of. I am in member services and I get back-to-back calls. Central honestly gives more problems and takes so much longer to help a member. It's very frustrating that we have to complete call sheets to tell the Central folks what is not working with their system and ask them to fix t. We also have to ask for enhancement and tell them how they can make it better for us. When the system was presented to us it sounded great and like it would be wonderful. Well, I have to be honest after a year of using it, it is just awful the worst system. We should have a system that works and is fast and user-friendly. We should not have to submit tickets for enhancements and try to get it to work. It's sad and it makes me upset.",
    "My manager is always available to discuss anything I may need assistance with. She holds me accountable and provides great feedback letting me know where I may need improvement along with what I am doing well. I look forward to working with her going forward.",
    "She is extremely and is always willing to help me in any way shape or form. She provides support when needed and is always available. She provides constructive criticism when needed. She hears my concerns and try's her best to accommodate what I need. Given her history with the company, she is extremely intelligent in what the current processes should be and provides great support if she doesn't know. She gives me the room to plan out my work as I need and best fits and most importantly she respects me and does Micro- manages. She is absolutely wonderful to work with!!",
    "There is massive disorganization in upper/middle management. Many times I have been put on alert for a large Jira to work as it was escalated to the director. I notify others that I have this to work as a priority. I have even started on them and then gotten word that the benefits are still being worked on and to stop. This wastes a lot of time. The training we received was in a word, horrible. The trainers, for the most part, had never worked claims so how could they train us? Flipped learning is a huge failure also. Our benefits keep decreasing, our responsibilities increasing, and our salary is not rising in proportion.",
    "System data is our weakness. We need accurate, up-to-the-minute data. Our reports on claims and data files are usually about 6 weeks old. Recently, tickets were assigned to more than one member, which could lead to privacy breaches. Consolidation of software, claims systems, and reporting processes across regions and lines of business should be a part of future planning. I believe investment in upgrading our technology, software, and IT department is vital to the company's future.",
    "Moving away from an employment model that supports system at all levels of the company has negatively impacted productivity in that a majority of meetings no longer start on time (associates having to move from one conference room to another), poor audio quality (using a speaker in the center of a large room with attendees shuffling papers or being too far from the speaker to be heard), meetings to run long because they started late and if associates take calls from their desk, they are a disruption to other around them or the background noise makes it tough to focus on the priorities of the call. It seems that the company is on the complete opposite side of the spectrum of industry leaders embracing remote employees ... especially those that are growing their digital footprint and using the technology we want our members to use.",
    "Communication, accountability, but also giving the freedom to complete my work.",
    "Goal setting, reviews on goals, focus on up-skilling and certification as part of personal growth along with company growth.",
    "The focus on Product quality should be the primary goal; Hold people accountable at every job level; Promote transparency.",
    "My manager is not only very, very smart, but she has a terrific ability to wisely lead the people who work for her. She takes into account our strengths and unique abilities in order to use our skills to the best effect. She is encouraging and supportive, which makes us want to do our very best work for her.",
    "There was a person on the team who was so sharp with her tongue, mean, hurtful, and toxic. Wounded several of us. That kind of behavior needs to GO! I've never been treated so poorly, I was impressed with my personal growth and ability to manage to transition her work and working with her. He does not micromanage, he is supportive, understands balancing life",
  ],
  [CategorizationType.SCE]: [
    "Financial help or maybe more flexible due dates on the tuition fees for international students. It is really hard for me since work is affected as well. If there is more time to save money for my tuition, that will be a great help. But when I asked to extend the due date, there was a charge. No consideration at all.",
    "Some weeks there was a lot more reading to do than others, so at times it felt overwhelming. I found the videos just as helpful as the reading and less time-consuming, so maybe have the amount of reading consistently each week.",
    "This class moved very fast, sometimes making it hard to keep up. That being said, whenever I fell behind, the instructor would stop and help me catch up. I asked more questions in this class than any other and I always got a helpful answer.",
    "A great teacher. She was engaging and knowledgeable, very funny, but also professional. She was very efficient in returning our assignments, and she took the time to leave comments regarding our grades. The instructor made a could-be-boring class interesting and informative: I really liked the guest speakers and relevant assignments.",
    "Cher is a fantastic professor, very kind, warm, and welcoming. She delivers the material well. The area I would like to see some growth in is with her participation marks section. Either the assignments need to be shortened, or they need to be worth more of the student's overall mark. Sometimes the assignments are so lengthy that I feel like dying. The amount of hours that need to be put in for 1% of participation is a lot for students with heavy course loads in other programs.",
    "In these stressful and terrible situations, colleges should provide financial and emotional support to students. Professors should understand that we didn't opt for online studies and each student has a different perspective to learn and concentrate on teaching. Administration and instructors should not compel students on participating in everything with videos because we didn't opt for this type of study. They should be cooperative and considerate in grading students because there is no proper communication to clear our doubts and feedback about assignments. We can't express our thoughts on feedback and grades given in these virtual classes. There is no financial assistance or emotional support in regard to international students.",
    "I know everyone is experiencing this pandemic times differently both students and the institution staff. But since College is close and everything we need or any question we have is through Email, sometimes I found it hard to communicate with staff. It was a couple of times I send an email or call about a problem but I did not receive any reply on that. However, in the previous semester before the pandemic when College was open, I took advantage of services that are provided for students and I know everyone is trying their best at all times during each semester. but it was an experience I wanted to share with you. Thank you.",
    "Due to virtual learning, I think the instructor has done a fantastic job - she is well organized, easy to approach, has in-depth knowledge of the course, and explains in an understanding way. She uses various ways to teach individuals who are more visual.",
    "I love this course and I love my teacher. She is very kind and helpful with any questions I have- and I have many. She tries her best to rephrase concepts so that I will understand. I love the critical thinking questions and the Evolve site is really great. I really enjoyed writing my Care in Nursing Essay and the Care Plan assignment is fun, too. I liked using the concept map and care plan program.",
    "The course is very good. The videos are very informative and the instructor does a great job of explaining each topic. Though I do feel the workload is excessive. To watch all videos, do readings, and do all assignments, quizzes, tests, and studying, on average is about 20 hours a week. With this amount of homework on top of our other classes, I find it very difficult and overwhelming.",
    "My biggest concern is with finances - getting bursaries or more finical help would be nice. It's been so hard to keep myself motivated to study alone at home, I don't understand why we're paying full tuition but don't have the resources that we need. I can't study at school, I can't make friends, I can't build a relationship with the teachers.",
    "Look at the course loads of each course on a semesterly basis. A lot is expected of students. Also, tuition is the same/higher yet we are not plus we are being asked to purchase online access codes for almost all classes. If classes are running online (at no fault of the student) they should have the online required material included, not on top of tuition.",
    "The college has provided many facilities to students to help during this difficult time. I received help from food bank when required. I got suggestions to various challenges I faced from my Professors. The college authority stood strong as a pillar for students when needed. However, having said so the financing becomes challenging during such times. With job crisis and huge expenses as an international student, gift vouchers or monetary help is of great necessity. Hence, receiving funding or job placement inside college premises is highly appreciated.",
    "All students may be concerned about their health problems as it seems the COVID-19 crisis is getting worse. I hope the college offers a health card as soon as possible. Although it's been about 1 month since my program started, I've not received my health card yet. so, I spent lots of money whenever I visit medical clinics. This gives economic burden to me.",
    "The institution College should tell all the professors providing online education to be lenient with all the students and be helpful as much as they can as some of them force students to attend all the lectures it is essential also but sometimes it is hard for some as they may have their part-time work on the same day and they cannot leave the work as it is the only way of financial support. So, professors should be lenient in that even if students see the recording of the class, it is okay too.",
    "In this pandemic students are unable to get jobs, students have many expenses house rent, phone bills, and groceries but due to no work, it is very difficult to manage this situation. I am arranging my expenses with great difficulty because I do not have a job, and sometimes get work for 7 to 10 hours a week which is not sufficient for my daily life. I had to pay this semester's fees and I had no money so asked for help from my family it was very difficult for them as well to send me money due to COVID-19. So, I can say that colleges should help students who are struggling during this pandemic.",
    "The instructor had this course laid out so simply and was very good about offering us tonnes of instruction and help. She asked really engaging questions in our in-class assignments and really made an effort to make us think about ourselves and the world with life as well as how we interact with it.",
    "Some weeks there was a lot more reading to do than others, so at times it felt overwhelming. I found the videos just as helpful as the reading and less time-consuming, so maybe have the amount of reading consistently each week.",
    "I wouldn't say I like the Soft Labs. I find them very time-consuming and there is way too much to do on each topic. I also didn't like the fact I had to pay to do the Soft Labs for me to get marks for it on my final grade.",
  ],
};
