import React, { useState } from "react";
import styled from "styled-components";
import { SharingUser } from "ts/sharing";
import { Tooltip } from "components/Tooltip";

type Props = {
  user: SharingUser;
};

export const EmailCell = ({ user }: Props) => {
  const [showEmailTooltip, setShowEmailTooltip] = useState<boolean>();

  const handleHoverModelName = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) setShowEmailTooltip(true);
  };

  return (
    <StyledEmailCell
      onMouseOver={handleHoverModelName}
      onMouseOut={() => setShowEmailTooltip(false)}
      data-tip
      data-for={`${user.id}-email-tooltip`}
    >
      <span>{user.email}</span>
      {showEmailTooltip && <Tooltip tooltipId={`${user.id}-email-tooltip`} content={user.email} />}
    </StyledEmailCell>
  );
};

const StyledEmailCell = styled.div`
  max-width: 85%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  > span {
    font-size: 15px;
  }
`;
