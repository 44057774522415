import React, { useReducer, useCallback, useRef } from "react";

export const useCustomReducer: (
  reducer: React.Reducer<any, any>,
  initialState: any
) => [any, any] = (reducer: React.Reducer<any, any>, initialState) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const stateRef = useRef(state);
  stateRef.current = state;

  const customDispatch = useCallback((action) => {
    if (typeof action === "function") {
      action(customDispatch, stateRef.current);
    } else {
      dispatch(action);
    }
  }, []);

  return [state, customDispatch];
};
