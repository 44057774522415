import React from "react";
import styled from "styled-components";

import { useResource } from "hooks/useResource";
import { useUserProfile } from "context/UserProfileContext";

import { PasswordField } from "./PasswordField";
import { CollapsibleSection } from "components/CollapsibleSection";
import { StyledUserEditorBlock } from "./StyledUserEditorBlock";
import { isPasswordValid } from "utils/validation";

type Props = {
  handleValidatePasswordFields: () => boolean;
};

export const ResetPasswordBlock = ({ handleValidatePasswordFields }: Props) => {
  const { getResource } = useResource();
  const { fields, setField, fieldErrors, setFieldError } = useUserProfile();

  const handleUpdateNewPassword = (value: string) => {
    setField("newPassword", value);

    if (value && !isPasswordValid(value)) {
      setFieldError("newPassword", getResource("userProfile.passwordError.message"));
      return;
    }
    setFieldError("newPassword", "");
  };

  return (
    <StyledUserEditorBlock>
      <CollapsibleSection
        titleLeftResource={"userProfile.resetPasswordBlock.title"}
        hideBoxShadow
        hideHeaderBorder
      >
        <StyledExpandedPasswordContainer>
          <PasswordField
            label={getResource("userProfile.oldPassField.label")}
            password={fields.oldPassword}
            isInvalid={fieldErrors.oldPassword.length > 0}
            onChange={(value) => setField("oldPassword", value)}
            onBlur={handleValidatePasswordFields}
          />

          <PasswordField
            label={getResource("userProfile.newPassField.label")}
            password={fields.newPassword}
            isInvalid={fieldErrors.newPassword.length > 0}
            hasTooltip={true}
            onChange={handleUpdateNewPassword}
            onBlur={handleValidatePasswordFields}
          />
          <PasswordField
            label={getResource("userProfile.confirmPassword.label")}
            password={fields.confirmPassword}
            isInvalid={fieldErrors.confirmPassword.length > 0}
            className="confirm-password-field"
            onChange={(value) => setField("confirmPassword", value)}
            onBlur={handleValidatePasswordFields}
          />
        </StyledExpandedPasswordContainer>
      </CollapsibleSection>
    </StyledUserEditorBlock>
  );
};

const StyledExpandedPasswordContainer = styled.div`
  cursor: default;
  padding: 8px 8px 0 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;

  .confirm-password-field {
    grid-column-start: 2;
  }
`;
