import { Icon, IconType } from "components/_icons/Icon";
import React from "react";
import styled from "styled-components";
import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";

type Props = {
  range: number[];
  selectedValue: number;
  onChange: (arg: number) => void;
};

export const DiscreteSlider = ({ range, selectedValue, onChange }: Props) => {
  const handleDecrease = () => {
    if (selectedValue > range[0]) onChange(selectedValue - 1);
  };
  const handleIncrease = () => {
    if (selectedValue < range[range.length - 1]) onChange(selectedValue + 1);
  };

  const adjustPrecisionValue = (e) => {
    onChange(Number(e.target.value));
  };

  const minPrecision = range[0];
  const maxPrecision = range[range.length - 1];

  return (
    <StyledDiscreteSlider>
      <StyledIncreaseDecreaseButton onClick={handleDecrease}>
        <Icon type={IconType.minus} color={Color.gray30} size={10} />
      </StyledIncreaseDecreaseButton>
      <StyledFullSliderContainer>
        <StyledSliderContainer>
          {range.map((el, i) => (
            <StyledSliderPiece key={i}>
              <StyledBall
                isSelected={el === selectedValue}
                isBeforeSelected={el < selectedValue}
              ></StyledBall>
              {i !== range.length - 1 && (
                <StyledBallConnector isBeforeSelected={el < selectedValue}></StyledBallConnector>
              )}
            </StyledSliderPiece>
          ))}
        </StyledSliderContainer>
        <StyledBigButtonContainer>
          <input
            type="range"
            min={minPrecision}
            max={maxPrecision}
            step={1}
            value={selectedValue}
            onInput={(e) => adjustPrecisionValue(e)}
          />
        </StyledBigButtonContainer>
      </StyledFullSliderContainer>
      <StyledIncreaseDecreaseButton onClick={handleIncrease}>
        <Icon type={IconType.plus} color={Color.gray30} size={10} />
      </StyledIncreaseDecreaseButton>
    </StyledDiscreteSlider>
  );
};

const StyledDiscreteSlider = styled.div`
  display: flex;
  align-items: center;
`;

const StyledFullSliderContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10px, max-content));
`;

const StyledSliderContainer = styled.div`
  padding: 0 12px;
  display: flex;
  align-items: center;
`;

const StyledBigButtonContainer = styled.div`
  position: relative;
  left: -121px;
  z-index: ${ZIndexStackingContext.low};

  input {
    position: relative;
    width: 238px;
    outline: 1px solid ${Color.white};
    background-color: transparent;
    appearance: none;
    accent-color: ${Color.blue50};
  }
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 22px;
    height: 22px;
    background: ${Color.blue50};
    border: 2px solid ${Color.white};
    cursor: pointer;
    border-radius: 30px;
    outline: none;
  }
`;

const StyledIncreaseDecreaseButton = styled.button`
  display: flex;
  justify-content: center;
  height: 16px;
  width: 16px;
  border-radius: 3px;
  cursor: ${(props) => !props.disabled && "pointer"};
  background: ${(props) => (props.disabled ? Color.sky15 : Color.sky40)};
  border: none;
  padding: 0;
  flex-shrink: 0;

  svg {
    display: block;
    margin: auto;
  }
`;

const StyledSliderPiece = styled.div`
  display: flex;
  align-items: center;
`;

const StyledBall = styled.div<{ isSelected: boolean; isBeforeSelected: boolean }>`
  min-width: 14px;
  min-height: 14px;

  background-color: ${({ isSelected, isBeforeSelected }) =>
    isSelected ? Color.blue50 : isBeforeSelected ? Color.gray50 : Color.blue20};
  border: 2px solid ${Color.white};
  border-radius: 999px;
  box-shadow: ${({ isSelected }) => (isSelected ? "0px 0px 10px rgba(0, 0, 0, 0.1)" : "none")};
`;

const StyledBallConnector = styled.div<{ isBeforeSelected: boolean }>`
  height: 6px;
  width: 27.5px;
  background-color: ${({ isBeforeSelected }) => (isBeforeSelected ? Color.gray50 : Color.blue20)};
`;
