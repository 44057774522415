import React from "react";
import styled from "styled-components";

import { Color } from "ts/enums/color";

type Props = {
  title: string;
};

export const FilterMenuTitle = ({ title }: Props) => <StyledMenuTitle>{title}</StyledMenuTitle>;

const StyledMenuTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${Color.gray20};
`;
