import React, { ReactNode, useState, useEffect } from "react";
import styled from "styled-components";
import { useAppSelector } from "store";
import { StyledSectionHeader, SectionTitle } from "pages/licensing/_layouts/SectionTitle";
import { Pill } from "components/_pills/Pill";
import { StackedBarChart } from "components/StackedBarChart";
import { Skeleton } from "components/Skeleton";
import { BlockRow } from "pages/licensing/_layouts/blocks/BlockRow";
import { QuotaMenu } from "pages/licensing/QuotaMenu";
import { Select } from "components/_inputs/Select";
import { RowLabel } from "pages/licensing/_layouts/RowLabel";
import { EmptyState } from "components/EmptyState";

import { useResource } from "hooks/useResource";
import { getLicensePeriodStatistics, getLicensePeriods } from "services/licensing";
import { useGetModelName } from "hooks/useGetModelName";
import { getModelStatisticsFromData, typeLicensePeriodsForDropdown } from "./helpers";

import { Color } from "ts/enums/color";
import { RoleType } from "@explorance/mly-types";
import { LicensePeriod, ModelStatistics, getLicensePeriodFromData } from "ts/licensing";
import { SelectOption } from "ts/select";
import { ButtonVariant } from "ts/enums/button";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { Text } from "components/Text";

type PercentageColors = {
  pillColor: Color;
  barColor: Color;
};

export const CommentsAnalyzed = () => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);

  // hooks
  const [totalCommentsAnalyzed, setTotalCommentsAnalyzed] = useState<number>();
  const [modelStatistics, setModelStatistics] = useState<ModelStatistics[]>([]);
  const [percentageUsed, setPercentageUsed] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [licensingPeriods, setLicensingPeriods] = useState<LicensePeriod[]>([]);
  const [periodDropdownOptions, setPeriodDropdownOptions] = useState<SelectOption[]>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<SelectOption>();

  const { getResource } = useResource();
  const getModelName = useGetModelName();

  const hasValidLicensingPeriod = licensingPeriods.length > 0;

  useEffect(() => {
    setIsLoading(true);
    getLicensePeriods()
      .then(({ data }) => {
        const typedLicensingPeriods = data.licencePeriods.map((lp) => getLicensePeriodFromData(lp));
        const typedDropdownOptions = typedLicensingPeriods.map((lp) =>
          typeLicensePeriodsForDropdown(lp, getResource)
        );
        const currentPeriod = typedDropdownOptions.find(
          (o) => parseInt(o.value) === typedLicensingPeriods.find((lp) => lp.isActive)?.id
        );

        if (currentPeriod) {
          setSelectedPeriod(currentPeriod);
          setLicensingPeriods(typedLicensingPeriods);
          setPeriodDropdownOptions(typedDropdownOptions);
          fetchLicensePeriodStatistics(parseInt(currentPeriod.value));
        } else {
          const undefinedOption = {
            label: getResource("license.dropdown.label.undefined"),
            value: undefined,
          };
          setSelectedPeriod(undefinedOption);
          setPeriodDropdownOptions([undefinedOption]);
          setIsLoading(false);
        }
      })
      .catch((err) => console.error(err));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (selectedPeriod && hasValidLicensingPeriod) {
      const currentPeriodId = parseInt(selectedPeriod.value);
      fetchLicensePeriodStatistics(currentPeriodId);
    }
  }, [selectedPeriod, hasValidLicensingPeriod]); // eslint-disable-line

  // functions
  const fetchLicensePeriodStatistics = async (selectedPeriodId: number) => {
    if (!isLoading) setIsLoading(true);
    try {
      const { data } = await getLicensePeriodStatistics(selectedPeriodId);
      setTotalCommentsAnalyzed(data.totalAnalyzedCount);
      setModelStatistics(
        data.licenceStatistics.map((ls) => getModelStatisticsFromData(ls, getModelName))
      );
      setPercentageUsed(Math.round((data.totalAnalyzedCount / data.commentLimit) * 100));
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getPercentageColor = (percentage: number): PercentageColors => {
    if (percentage > 50 && percentage < 80) {
      return { pillColor: Color.orange20, barColor: Color.orange50 };
    } else if (percentage > 80) {
      return { pillColor: Color.red10, barColor: Color.red50 };
    }
    return { pillColor: Color.green30, barColor: Color.green50 };
  };

  const getCommentsAnalyzedBar = (): ReactNode => {
    if (isLoading) {
      return (
        <StyledSkeletonContainer className="pulse">
          <Skeleton
            width={1140}
            height={12}
            backgroundColor={Color.blue20}
            responsive={true}
            customStyles={{ position: "absolute" }}
          />
        </StyledSkeletonContainer>
      );
    }
    const chartPieces = [
      { portion: percentageUsed, color: percentageColors.barColor },
      { portion: 100 - percentageUsed, color: Color.blue20 },
    ];
    return <StackedBarChart chartPieces={chartPieces} />;
  };

  const updateSelectedPeriod = (periodId: string) => {
    setSelectedPeriod(periodDropdownOptions.find((o) => parseInt(o.value) === parseInt(periodId)));
  };

  // variables
  const percentageColors: PercentageColors = getPercentageColor(percentageUsed);

  const selectedLicensingPeriod = licensingPeriods.filter(
    (lp) => lp.id === parseInt(selectedPeriod.value)
  )[0];

  return (
    <>
      <StyledSectionHeader>
        <StyledHeaderContentContainer>
          <SectionTitle
            titleKey={"licensing.section.title.commentsAnalyzed"}
            isLoading={isLoading}
          />

          {!isLoading && (
            <>
              {hasValidLicensingPeriod && (
                <Pill
                  backgroundColor={percentageColors.pillColor}
                  color={percentageColors.barColor}
                  style={{ fontWeight: "bold" }}
                >
                  <Text
                    resource={{
                      key: "licensing.commentsAnalyzed.title",
                      args: [`${percentageUsed}`],
                    }}
                  />
                </Pill>
              )}
              <Select
                selectedOption={selectedPeriod}
                options={periodDropdownOptions}
                handleChange={hasValidLicensingPeriod ? updateSelectedPeriod : undefined}
                dropdownWidth="100%"
                buttonVariant={ButtonVariant.light}
                buttonStyle={{
                  width: "100%",
                  fontSize: "15px",
                  padding: "12px 20px 12px 10px",
                  gap: "4px",
                }}
              />
            </>
          )}
        </StyledHeaderContentContainer>
        {(currentUser?.roleType === RoleType.SuperAdmin ||
          currentUser?.roleType === RoleType.Admin) &&
          !isLoading && <QuotaMenu periodId={parseInt(selectedPeriod.value)} />}
      </StyledSectionHeader>

      <StyledContentContainer validLicensePeriod={hasValidLicensingPeriod}>
        {isLoading ? (
          <>
            <StyledHeader className="pulse">
              <Skeleton width={150} height={18} backgroundColor={Color.neutral10} />
              <Skeleton width={150} height={18} backgroundColor={Color.neutral10} />
            </StyledHeader>
          </>
        ) : !hasValidLicensingPeriod ? (
          <EmptyState type={EmptyStateType.noLicensingPeriod} />
        ) : (
          <StyledHeader>
            <b>
              <Text resource="licensing.commentsAnalyzed.title" />
            </b>
            <b>
              <Text
                resource={{
                  key: "licensing.commentsAnalyzed.totalCountLabel",
                  args: [
                    `${new Intl.NumberFormat().format(totalCommentsAnalyzed)}`,
                    `${new Intl.NumberFormat().format(selectedLicensingPeriod.commentLimit)}`,
                  ],
                }}
              />
            </b>
          </StyledHeader>
        )}
        <div>{getCommentsAnalyzedBar()}</div>
        {!isLoading &&
          modelStatistics.map((ms, index) => (
            <BlockRow
              key={index}
              rowLabel={<RowLabel name={ms.name} catType={ms.modelFamily} />}
              countLabel="licensing.commentsAnalyzed.countLabel"
              count={ms.analyzedCount}
            />
          ))}
      </StyledContentContainer>
    </>
  );
};

const StyledHeaderContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  height: 37px;
`;

const StyledSkeletonContainer = styled.div`
  height: 12px;
  position: relative;
  width: 100%;

  @media (min-width: 1920px) {
    width: 1140px;
    overflow: hidden;
  }
`;

const StyledContentContainer = styled.div<{ validLicensePeriod: boolean }>`
  padding: 16px;
  border-radius: 2px;
  background-color: ${Color.white};
  border: ${({ validLicensePeriod }) => validLicensePeriod && "1px solid ${Color.sky50}"};
  box-shadow: ${({ validLicensePeriod }) => validLicensePeriod && "0px 3px 10px 0px #35384d33"};
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;
