import { isArray } from "lodash";

export const getDemographicFields = (userDemographics, apiDemographics) => {
  const mainDemographics = {};
  const additionalDemographics = {};

  apiDemographics.forEach((ud) => {
    if (isArray(ud.values)) {
      mainDemographics[ud.name] = userDemographics ? userDemographics[ud.name] : null; // for dropdown fields
    } else {
      additionalDemographics[ud.name] = userDemographics ? userDemographics[ud.name] : null; // for text fields
    }
  });

  return [mainDemographics, additionalDemographics];
};
