import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useAppSelector } from "store";
import { UserProfileEditorField } from "components/UserProfileEditorField";
import { UserRoleDropdown } from "./UserRoleDropdown";
import { StyledUserEditorBlock } from "./StyledUserEditorBlock";
import { UserAvatar } from "components/UserAvatar.tsx";
import { LanguageSelect } from "components/LanguageSelect";

import { useResource } from "hooks/useResource";
import { useUserProfile } from "context/UserProfileContext";
import { validateEmail } from "utils/validation";

import { Color } from "ts/enums/color";
import { AvatarSize } from "ts/enums/avatarSize";
import { RoleType } from "@explorance/mly-types";
import { Text } from "components/Text";

const INPUT_MAX_LENGTH = 200;

type Props = {
  isCreation: boolean;
  savedUserFirstName?: string;
  savedUserLastName?: string;
  validateBasicUserInfo(): boolean;
};

export const BasicUserInfoBlock = ({
  isCreation,
  savedUserFirstName,
  savedUserLastName,
  validateBasicUserInfo,
}: Props) => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);

  const { getResource } = useResource();
  const { fields, setField, fieldErrors, setFieldError } = useUserProfile();

  const { userId } = useParams<{ userId: string }>();
  const userIdToEdit = parseInt(userId);

  const handleChangeUsername = (value: string) => {
    setField("username", value.substring(0, INPUT_MAX_LENGTH));

    if (value && !validateEmail(value)) {
      setFieldError("username", getResource("userProfile.invalidUsername.message"));
      return;
    }
    setFieldError("username", "");
  };

  const updateNameField = (field: string, value: string) => {
    setField(field, value);
    if (value.length > 0) setFieldError(field, "");
  };

  const updateRoleType = (roleType: RoleType) => {
    setField("roleType", roleType);
    if (fieldErrors.roleType.length > 0) setFieldError("roleType", "");
  };

  return (
    <StyledBasicUserInfoBlock>
      {!isCreation && (
        <StyledAvatarContainer>
          <UserAvatar
            firstName={savedUserFirstName}
            lastName={savedUserLastName}
            userId={userIdToEdit}
            avatarSize={AvatarSize.lg}
          />
        </StyledAvatarContainer>
      )}
      <h2>
        <Text resource="userProfile.basicInfo.title" />
      </h2>
      <h3>
        <span>*</span>
        <Text resource="userProfile.basicInfo.required" />
      </h3>
      <StyledBasicInfoBlockForm>
        <UserProfileEditorField
          label={<Text resource="userProfile.usernameField.label" />}
          value={fields.username}
          isDisabled={!isCreation}
          required
          isInvalid={fieldErrors.username.length > 0}
          handleChangeFieldValue={handleChangeUsername}
          handleBlur={validateBasicUserInfo}
        />
        <UserRoleDropdown
          currentRole={fields.roleType}
          disabled={
            !isCreation &&
            (userIdToEdit === currentUser.id || fields.roleType === RoleType.SuperAdmin)
          }
          isInvalid={fieldErrors.roleType.length > 0}
          onSelectRole={updateRoleType}
        />
        <UserProfileEditorField
          label={<Text resource="userProfile.firstNameField.label" />}
          value={fields.firstname}
          required
          isInvalid={fieldErrors.firstname.length > 0}
          handleChangeFieldValue={(firstname) => updateNameField("firstname", firstname)}
          handleBlur={validateBasicUserInfo}
        />

        <UserProfileEditorField
          label={<Text resource="userProfile.lastNameField.label" />}
          value={fields.lastname}
          required
          isInvalid={fieldErrors.lastname.length > 0}
          handleChangeFieldValue={(lastName) => updateNameField("lastname", lastName)}
          handleBlur={validateBasicUserInfo}
        />
        <StyledLanguageSelectContainer>
          <span>
            <Text resource="userProfile.preferredLanguageSelect.label" />
          </span>
          <LanguageSelect
            currentLanguage={fields.preferredLanguage}
            handleSelectLanguage={(lang) => setField("preferredLanguage", lang)}
          />
        </StyledLanguageSelectContainer>
      </StyledBasicInfoBlockForm>
    </StyledBasicUserInfoBlock>
  );
};

const StyledBasicUserInfoBlock = styled(StyledUserEditorBlock)`
  border-radius: 2px;
  border: 1px solid ${Color.sky40};
  padding: 24px;

  h2 {
    font-weight: bold;
    margin: 0 0 16px 0;
    font-size: 15px;
  }

  h3 {
    font-size: 15px;
    font-weight: normal;
    span {
      color: ${Color.red50};
    }
  }
`;

const StyledBasicInfoBlockForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
`;

const StyledAvatarContainer = styled.div`
  height: 35px;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  div {
    box-shadow: 0px 3px 15px #4230e51a;
    border: 5px solid ${Color.white};
  }
`;

const StyledLanguageSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  span {
    margin-bottom: 5px;
  }
`;
