import React from "react";
import styled from "styled-components";

import { Skeleton } from "components/Skeleton";
import { Color } from "ts/enums/color";

export const RecentActivitiesPlaceholder = () => (
  <>
    {[...Array(13)].map((_, i) => (
      <StyledPlaceholderRow key={`row-${i}`}>
        <StyledIconSkeletonContainer>
          <Skeleton height={20} width={40} borderRadius="5px" backgroundColor={Color.neutral10} />
        </StyledIconSkeletonContainer>
        <StyledSkeletonContainer>
          <Skeleton width={125} height={12} borderRadius="5px" backgroundColor={Color.neutral10} />
          <Skeleton width={210} height={12} borderRadius="5px" backgroundColor={Color.neutral10} />
        </StyledSkeletonContainer>
      </StyledPlaceholderRow>
    ))}
  </>
);

const StyledPlaceholderRow = styled.div`
  display: flex;
  height: 57px;
  padding: 10px 0;
  margin-left: 15px;
`;

const StyledSkeletonContainer = styled.div`
  height: 55px;
`;

const StyledIconSkeletonContainer = styled.div`
  margin-right: 15px;
  padding-top: 8px;
`;
