import {
  PermissionLevel,
  SharingCommentExplorerAccess,
  SharingDashboardAccess,
  SharingTopicExplorerAccess,
} from "@explorance/mly-types";
import { DefaultWidgets, ShareByOption, SharingView } from "ts/enums/sharing";
import { SharingContextState } from ".";
import { GetResourceFunction } from "hooks/useResource";

export const getInitialState = (getResource: GetResourceFunction): SharingContextState => ({
  isGroupsApiDataLoading: true,
  isSharedUsersApiDataLoading: true,
  groupsApiData: {
    groups: [],
    itemCount: null,
    totalCount: null,
  },
  step: undefined,
  sharedUsersApiData: {
    users: [],
    itemCount: null,
    totalCount: null,
  },
  shareableUsersApiData: {
    users: [],
    itemCount: null,
    totalCount: null,
  },
  selectedUsers: [],
  selectedUsersCount: 0,
  allUserDemographics: [],
  selectedUserDemographicFilters: [],
  view: SharingView.UsersSharedWith,
  shareBy: { label: ShareByOption.Users, value: ShareByOption.Users },
  shareByDropdownOptions: [
    { label: getResource("sharing.shareByDropdown.users"), value: ShareByOption.Users },
    { label: getResource("sharing.shareByDropdown.group"), value: ShareByOption.Groups },
  ],
  pillsPage: 1,
  permissionFields: {
    groupName: getResource("sharing.groupName.default"),
    permissionLevel: PermissionLevel.View,
    defaultWidgets: DefaultWidgets.None,
    dashboardAccess: SharingDashboardAccess.Hidden,
    commentExplorerAccess: SharingCommentExplorerAccess.Hidden,
    topicExplorerAccess: SharingTopicExplorerAccess.Hidden,
    allowExportData: false,
    visibleDemographicsFilters: [],
    visibleCommentsByDemographicValues: [],
    customConditions: [],
  },
  analysisDemographicHeaders: [],
  showCustomConditionError: false,
  showGroupNameError: false,
});
