import React from "react";
import styled from "styled-components";
import { Skeleton } from "components/Skeleton";
import { Color } from "ts/enums/color";

type Props = {
  className?: string;
};

export const PaginationPlaceholder = ({ className }: Props) => {
  const placeholder = [
    {
      width: 20,
      height: 10,
    },
    {
      width: 20,
      height: 10,
    },
    {
      width: 27,
      height: 27,
    },
    {
      width: 20,
      height: 10,
    },
    {
      width: 20,
      height: 10,
    },
  ];

  return (
    <StyledPaginationPlaceholder className={className}>
      <div className="container pulse">
        {placeholder.map((placeholder, i) => (
          <Skeleton
            key={i}
            width={placeholder.width}
            height={placeholder.height}
            backgroundColor={Color.neutral10}
          />
        ))}
      </div>
    </StyledPaginationPlaceholder>
  );
};

const StyledPaginationPlaceholder = styled.div`
  .container {
    display: flex;
    align-items: center;
    div {
      margin: 0 4px;
    }
  }
`;
