import { RecommendationType } from "@explorance/mly-types";
import { CategorizedTopicPill } from "components/_pills/CategorizedTopicPill";
import React from "react";
import styled from "styled-components";
import { humanize } from "utils/humanize";

type Props = {
  style?: Record<string, number | string>;
  recommendations: RecommendationType[];
};

export const NonCategorizedRecommendationPillList = ({ recommendations, style }: Props) => {
  return (
    <StyledPillListContainer style={style}>
      {recommendations.map((topic) => (
        <CategorizedTopicPill
          key={topic}
          type={topic as any}
          label={humanize(topic)}
          style={{ marginRight: 5, marginBottom: 5 }}
          isNonCatRec={true}
        />
      ))}
    </StyledPillListContainer>
  );
};

const StyledPillListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
