import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import { RouteComponentProps, withRouter, useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "store";
import { routes } from "routes";

import { AnalysisJobProcessingModal } from "components/_modals/JobProcessingModal";
import { JobFailedModal } from "./_layouts/JobFailedModal";
import { DataPreviewTable } from "./_layouts/DataPreviewTable";
import { ImportMoreDataButton } from "./_layouts/ImportMoreDataButton";
import { LoadingDots } from "components/LoadingDots";
import { Notch } from "common-layouts/AnalysisLayout/Notch";
import { Button } from "components/_buttons/Button";
import { FileUploadingModal } from "components/_modals/FileUploadingModal";
import { ErrorScreen } from "components/ErrorScreen";
import { Header } from "pages/analysis/[id]/data-source/_layouts/Header";

import { useResource } from "hooks/useResource";
import { AnalysisContext } from "context/AnalysisContext";

import { ButtonVariant, ButtonSize } from "ts/enums/button";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { Color } from "ts/enums/color";
import { AnalysisStatus, FileUploadStatus, PermissionLevel, RoleType } from "@explorance/mly-types";
import { PageErrorType } from "ts/enums/pageErrorType";

import uploadFailedImg from "assets/images/upload-failed-empty-state.svg";
import { isAnyAdmin } from "utils/isAnyAdmin";
import { setShowFileUploadingModal } from "store/analysisSettings/dataSourceSlice";
import { fetchDataSources } from "store/analysisSettings/thunks";
import { Text } from "components/Text";

const DataPreviewPageBase = (props: RouteComponentProps<{ analysisId: string }>) => {
  // redux
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.dataSource);
  const { currentUser } = useAppSelector((state) => state.auth);

  const history = useHistory();
  const { getResource } = useResource();
  const [analysisState] = useContext(AnalysisContext);

  const analysisId = parseInt(props.match.params.analysisId);

  const isAdmin = isAnyAdmin(currentUser.roleType);
  const isViewer = currentUser.roleType === RoleType.Viewer;
  const isAnalysisOwner = !analysisState.analysisDetails.sharing;
  const isSharingViewer =
    analysisState.analysisDetails.sharing &&
    analysisState.analysisDetails.sharing.permissionLevel == PermissionLevel.View;

  useEffect(() => {
    dispatch(
      setShowFileUploadingModal(
        analysisState.analysisDetails.uploadStatus === FileUploadStatus.Uploading
      )
    );
  }, [analysisState.analysisDetails.uploadStatus]); //eslint-disable-line

  useEffect(() => {
    dispatch(fetchDataSources(analysisId));
  }, [dispatch, analysisId, analysisState.analysisDetails.uploadStatus]);

  const handleExit = () => {
    analysisState.analysisDetails.status === AnalysisStatus.NotAnalyzed ||
    analysisState.analysisDetails.status === AnalysisStatus.Failed
      ? history.push(routes.homePage)
      : history.push(routes.overviewPage(analysisId));
  };

  if ((isViewer && !isAnalysisOwner) || (!isAdmin && isSharingViewer)) {
    return <ErrorScreen errorType={PageErrorType.GeneralInsufficientPermission} />;
  }

  return (
    <>
      {analysisState.analysisError &&
      analysisState.analysisError !== PageErrorType.AnalysisNotAnalyzed ? (
        <ErrorScreen errorType={analysisState.analysisError} />
      ) : (
        <div className="fade-in">
          {state.analysisDataSource && (
            <StyledNotchContainer>
              <Notch />
            </StyledNotchContainer>
          )}
          <AnalysisJobProcessingModal />
          <JobFailedModal />
          {<FileUploadingModal isOpen={state.showFileUploadingModal} />}
          {!state.analysisDataSource ? (
            <StyledLoadingDotsContainer>
              <LoadingDots />
            </StyledLoadingDotsContainer>
          ) : (
            <StyledDataSourceContainer>
              <>
                <Header
                  titleKey={"dataSource.title"}
                  totalCount={getResource(
                    "dataSource.totalCount",
                    state.analysisDataSource.commentsTotalCount
                  )}
                  handleClickBackToPrevious={handleExit}
                >
                  <ImportMoreDataButton />
                </Header>

                {state.analysisDataSource?.comments.length > 0 && (
                  <StyledDataPreviewTableContainer>
                    <DataPreviewTable />
                  </StyledDataPreviewTableContainer>
                )}

                {state.analysisDataSource?.comments?.length === 0 &&
                  state.analysisDataSource?.uploadStatus === FileUploadStatus.Failed && (
                    <StyledEmptyState>
                      <img src={uploadFailedImg} alt="upload-failed" width={160} />
                      <h2>
                        <Text resource="emptyState.fileUploadFailed.title" />
                      </h2>
                      <p>
                        <Text resource="emptyState.fileUploadFailed.description" />
                      </p>
                    </StyledEmptyState>
                  )}
              </>

              <StyledButtonContainer>
                <Button variant={ButtonVariant.outline} size={ButtonSize.ml} onClick={handleExit}>
                  <Text resource="button.close" />
                </Button>
                <Button
                  variant={ButtonVariant.primary}
                  size={ButtonSize.ml}
                  onClick={() => history.push(routes.settingsPage(analysisId))}
                >
                  <Text resource="button.goToAnalysisSettings" />
                </Button>
              </StyledButtonContainer>
            </StyledDataSourceContainer>
          )}
        </div>
      )}
    </>
  );
};

export const DataPreviewPage = withRouter(DataPreviewPageBase);

const StyledEmptyState = styled.div`
  text-align: center;
  padding: 160px 0;
  h2 {
    font-size: 20px;
    margin: 20px 0 8px 0;
  }
  p {
    text-align: center;
    color: ${Color.gray40};
    font-size: 14px;
    margin: 0;
  }
`;

export const StyledBackButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

export const StyledNotchContainer = styled.div`
  position: absolute;
  left: 50%;
  z-index: ${ZIndexStackingContext.medium};
`;

const StyledLoadingDotsContainer = styled.div`
  margin-top: 25%;
  margin-left: 50%;
`;

const StyledDataSourceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledDataPreviewTableContainer = styled.div`
  overflow: auto;
  max-height: calc(100vh - 200px);
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
  gap: 10px;
  float: right;
  margin-bottom: 50px;
`;
