import React, { useEffect, useState, useContext, useCallback } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { CustomConditionDemographics } from "ts/sharing";
import { DemographicFilter, DemographicFilterHeader } from "ts/filters/demographicFilter";
import {
  DemographicFilterMethod,
  PermissionLevel,
  SharingCommentExplorerAccess,
  SharingDashboardAccess,
  SharingMethod,
  SharingOverviewAccess,
  SharingTopicExplorerAccess,
} from "@explorance/mly-types";
import { DefaultWidgets } from "ts/enums/sharing";
import { routes } from "routes";
import { EditGroupModalView } from "ts/enums/editGroupModalView";

import { AnalysisContext } from "context/AnalysisContext";
import {
  getSharingDraftBySharingId,
  saveSharingBySharingId,
  updateSharingDraftBySharingId,
} from "services/analysis/sharing";
import {
  getVisibleCommentDemsFromData,
  getVisibleDemFiltersFromData,
} from "context/SharingContext/helpers";
import { useSharingContext } from "context/SharingContext";
import { getUrlQueryString } from "utils/getUrlQueryString";
import { setSelectedModelId } from "context/AnalysisContext/actions";

import { PermissionForm } from "./PermissionForm";
import { UserSelectionPopup } from "./UserSelectionPopup";
import { useAppDispatch } from "store";
import { showToastError } from "store/toast/toastSlice";

type Props = {
  show: boolean;
  sharingId: number;
  expandedRowId: number;
  sharedWithPage: number;
  usersSharedWithCount?: number;
  returningFromPreview?: boolean;
  showEditAddUsers?: boolean;
  groupName?: string;
  refetch: () => void;
  closeShowEditSharing?: () => void;
};

export const EditSharingBlock = ({
  show,
  sharingId,
  usersSharedWithCount,
  showEditAddUsers = false,
  groupName = null,
  returningFromPreview,
  expandedRowId,
  sharedWithPage,
  refetch,
  closeShowEditSharing,
}: Props) => {
  const dispatch = useAppDispatch();
  // hooks
  const [state, analysisContextDispatch] = useContext(AnalysisContext);
  const { sharingState } = useSharingContext();
  const blockRef = useCallback((node) => {
    if (node !== null) {
      node.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedUsersCount, setSelectedUsersCount] = useState<number>(usersSharedWithCount || 0);
  const [editGroupModalView, setEditGroupModalView] = useState<EditGroupModalView>(
    EditGroupModalView.add
  );

  //Edit Block new State
  const [selectedPermissionLevel, setSelectedPermissionLevel] = useState<PermissionLevel>(
    PermissionLevel.View
  );
  const [selectedDemographics, setSelectedDemographics] = useState<DemographicFilter[]>([]);
  const [selectedCustomConditions, setSelectedCustomConditions] = useState<
    CustomConditionDemographics[]
  >([]);
  const [showCustomConditionError, setShowCustomConditionError] = useState<boolean>(false);
  const [visibleDemographicsFilters, setVisibleDemographicsFilters] = useState<
    DemographicFilterHeader[]
  >([]);
  const [dashboardAccess, setDashboardAccess] = useState<SharingDashboardAccess>(
    SharingDashboardAccess.Hidden
  );
  const [topicExplorerAccess, setTopicExplorerAccess] = useState<SharingTopicExplorerAccess>(
    SharingTopicExplorerAccess.Hidden
  );
  const [commentExplorerAccess, setCommentExplorerAccess] = useState<SharingCommentExplorerAccess>(
    SharingCommentExplorerAccess.Hidden
  );
  const [allowExportData, setAllowExportData] = useState<boolean>(false);
  const [allDataLoaded, setAllDataLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // variables
  const userDemographics = sharingState.allUserDemographics;
  const analysisDemographicHeaders = sharingState.analysisDemographicHeaders;
  const showPermissionFields = selectedPermissionLevel === PermissionLevel.View;

  // useEffects
  /* eslint-disable */
  useEffect(() => {
    const requestBody = {
      sharingId,
      groupName,
      demographicFilters: selectedDemographics.map((d) => {
        return {
          id: d.id,
          name: d.name,
          method: d.method,
          values: d.method === DemographicFilterMethod.List ? d.listValues : d.searchValue,
        };
      }),
      customFilters: selectedCustomConditions.filter(
        (cc) => cc.analysisDemographic.length > 0 && cc.userDemographic.length > 0
      ),
      demographicsToDisplay: visibleDemographicsFilters.map((d) => d.name),
      allowExport: allowExportData,
      overviewAccess: SharingOverviewAccess.Shared,
      topicExplorerAccess: topicExplorerAccess,
      commentExplorerAccess: commentExplorerAccess,
      dashboardAccess: dashboardAccess,
      defaultWidgets: DefaultWidgets.None,
      method: SharingMethod.Group,
      permissionLevel: selectedPermissionLevel,
    };
    if (!show || !allDataLoaded) return;

    updateSharingDraftBySharingId(requestBody).catch(() => {
      dispatch(showToastError("toast.defaultError"));
    });
  }, [
    selectedPermissionLevel,
    selectedDemographics,
    selectedCustomConditions,
    visibleDemographicsFilters,
    dashboardAccess,
    topicExplorerAccess,
    commentExplorerAccess,
    allowExportData,
  ]);
  /* eslint-enable */

  useEffect(() => {
    //reset state
    if (!show) {
      setSelectedPermissionLevel(PermissionLevel.View);
      setSelectedDemographics([]);
      setSelectedCustomConditions([]);
      setShowCustomConditionError(false);
      setVisibleDemographicsFilters([]);
      setDashboardAccess(SharingDashboardAccess.Hidden);
      setTopicExplorerAccess(SharingTopicExplorerAccess.Hidden);
      setCommentExplorerAccess(SharingCommentExplorerAccess.Hidden);
      setAllowExportData(false);
      setAllDataLoaded(false);
    }
  }, [show]); // eslint-disable-line

  useEffect(() => {
    if (!show) return;
    setSelectedUsersCount(usersSharedWithCount || 0);
  }, [show]); // eslint-disable-line

  useEffect(() => {
    if (show) {
      getSharingDraftBySharingId(sharingId, !returningFromPreview).then(({ data }) => {
        setCommentExplorerAccess(data.commentExplorerAccess);
        setDashboardAccess(data.dashboardAccess);
        setTopicExplorerAccess(data.topicExplorerAccess);
        setSelectedPermissionLevel(data.permissionLevel);
        setVisibleDemographicsFilters(
          data.demographicsToDisplay.map((d) =>
            getVisibleDemFiltersFromData(d, state.availableDemographicFilters)
          )
        );
        setSelectedCustomConditions(data.customFilters);
        setAllowExportData(data.allowExport);
        setSelectedDemographics(
          data.demographicFilters
            .map((d) => getVisibleCommentDemsFromData(d, state.availableDemographicFilters))
            .filter(Boolean)
        );
        setAllDataLoaded(true);
      });
    }
  }, [show, sharingId]); // eslint-disable-line

  // Functions
  const updateSelectedDemographics = (demographics: DemographicFilter[]) => {
    setSelectedDemographics(demographics);
  };

  const updateCustomConditions = (customConditions: CustomConditionDemographics[]) => {
    setSelectedCustomConditions(customConditions);
  };

  const handleSelectDashboardAccess = () => {
    setDashboardAccess(
      dashboardAccess === SharingDashboardAccess.Hidden
        ? SharingDashboardAccess.Shared
        : SharingDashboardAccess.Hidden
    );
  };

  const handleSelectTopicExplorerAccess = () => {
    setTopicExplorerAccess(
      topicExplorerAccess === SharingTopicExplorerAccess.Hidden
        ? SharingTopicExplorerAccess.Shared
        : SharingTopicExplorerAccess.Hidden
    );
  };

  const handleSelectCommentExplorerAccess = () => {
    setCommentExplorerAccess(
      commentExplorerAccess === SharingCommentExplorerAccess.Hidden
        ? SharingCommentExplorerAccess.Shared
        : SharingCommentExplorerAccess.Hidden
    );
    setAllowExportData(
      commentExplorerAccess === SharingCommentExplorerAccess.Shared ? false : allowExportData
    );
  };

  const handleSelectPermissionLevel = (permissionLevel: PermissionLevel) => {
    setSelectedPermissionLevel(permissionLevel);
  };

  const applyEditChanges = async () => {
    if (
      selectedCustomConditions.some(
        (cc) => (cc.analysisDemographic.length === 0) !== (cc.userDemographic.length === 0)
      )
    ) {
      setShowCustomConditionError(true);
      return;
    }

    setIsLoading(true);
    try {
      await saveSharingBySharingId(sharingId);
      closeShowEditSharing();
      refetch();
    } catch (error: any) {
      dispatch(showToastError(error.message));
    } finally {
      setIsLoading(false);
    }
  };

  const navigateToPreview = () => {
    // reset custom model id if not currently selected for preview page
    if (
      state.analysisDetails.selectedModel?.customModelId &&
      state.selectedModelId !== state.analysisDetails.selectedModel.customModelId
    ) {
      analysisContextDispatch(
        setSelectedModelId(state.analysisDetails.selectedModel.customModelId)
      );
    }

    const previewPageUrl = routes.overviewPage(
      state.analysisDetails.id,
      getUrlQueryString({
        sharingPreview: sharingState.view,
        step: sharingState.step,
        sharingId,
        expandedRowId,
        sharedWithPage,
      })
    );

    history.push(previewPageUrl);
  };

  const handleOpenEditGroupModal = (view: EditGroupModalView) => {
    setEditGroupModalView(view);
    setIsModalOpen(true);
  };

  // Props declarations
  const permissionLevelProps = {
    selectedPermissionLevel,
    handleSelectPermissionLevel,
  };

  const visibleAnalysisDataProps = {
    selectedDemographics,
    selectedCustomConditions,
    showCustomConditionError,
    userDemographics,
    updateSelectedDemographics,
    updateCustomConditions,
    handleHideCustomConditionError: () => setShowCustomConditionError(false),
  };

  const visibleDemographicsDataProps = {
    savedDemHeaders: visibleDemographicsFilters,
    updateSavedDemHeaders: (updatedHeaders: DemographicFilterHeader[]) => {
      setVisibleDemographicsFilters(updatedHeaders);
    },
  };

  const dashboardSettingsDataProps = {
    dashboardAccess,
    topicExplorerAccess,
    commentExplorerAccess,
    allowExportData,
    handleSelectDashboardAccess,
    handleSelectTopicExplorerAccess,
    handleSelectCommentExplorerAccess,
    handleToggleExportData: () => {
      if (commentExplorerAccess === SharingCommentExplorerAccess.Hidden && !allowExportData) {
        setCommentExplorerAccess(SharingCommentExplorerAccess.Shared);
      }
      setAllowExportData((allowExportData) => !allowExportData);
    },
  };

  if (!allDataLoaded) return null;

  return (
    <StyledEditSharingBlock show={show} ref={blockRef}>
      {show && (
        <PermissionForm
          showPermissionFields={showPermissionFields}
          analysisDemographicHeaders={analysisDemographicHeaders}
          // props for each section of the form have the following info: state, handler functions
          permissionLevelData={permissionLevelProps}
          visibleAnalysisData={visibleAnalysisDataProps}
          visibleDemographicsData={visibleDemographicsDataProps}
          dashboardSettingsData={dashboardSettingsDataProps}
          editSharing={true}
          showEditAddUsers={showEditAddUsers}
          usersSharedWithCount={selectedUsersCount}
          closeEditSharing={closeShowEditSharing}
          applyEditChanges={applyEditChanges}
          navigateToPreview={navigateToPreview}
          openEditGroupModal={handleOpenEditGroupModal}
          isLoading={isLoading}
        />
      )}
      <UserSelectionPopup
        isModalOpen={isModalOpen}
        sharingId={sharingId}
        view={editGroupModalView}
        setSelectedUsersCount={setSelectedUsersCount}
        closeModal={() => setIsModalOpen(false)}
      />
    </StyledEditSharingBlock>
  );
};

const StyledEditSharingBlock = styled.div<{ show: boolean }>`
  width: ${({ show }) => (show ? "100%" : "0")};
  margin-top: ${({ show }) => show && "18px"};
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: all 0.3s ease-in-out;
  height: ${({ show }) => (show ? "auto" : "0")};
  scroll-margin: 90px;
`;
