import React, { useEffect } from "react";
import { authenticationManager } from "..";
import { useHistory } from "react-router-dom";
import { routes } from "routes";

export const PostRefreshPage = () => {
  const history = useHistory();

  useEffect(() => {
    authenticationManager.completeSilentRefresh();
    const lastVisitedPath = localStorage.getItem("lastVisitedPath") || routes.homePage;
    history.push(lastVisitedPath);
  }, [history]);

  return <></>;
};
