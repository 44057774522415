import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Color } from "ts/enums/color";

type CustomStyles = {
  bold?: boolean;
  fontSize?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
  padding?: string;
  border?: string;
};

type Props = {
  value: string;
  customStyles?: CustomStyles;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const CHAR_LIMIT = 100;

export const TextInput = ({ value, onChange, customStyles }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.value = value;
    }
  }, [inputRef, value]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      inputRef.current.blur();
    }
  };

  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (e.currentTarget.value.trim().length === 0) {
      inputRef.current.value = value;
    }
    onChange && onChange(e);
  };

  return (
    <StyledTextInput
      type="text"
      data-no-dnd="true"
      customStyles={customStyles}
      ref={inputRef}
      maxLength={CHAR_LIMIT}
      onBlur={onBlurHandler}
      onKeyDown={handleKeyDown}
    />
  );
};

const StyledTextInput = styled.input<{ customStyles?: CustomStyles }>`
  border: 1px solid ${Color.sky50};
  flex: 1;
  font-size: ${(props) => props.customStyles?.fontSize ?? "14px"};
  color: ${Color.gray50};
  width: ${(props) => props.customStyles?.width ?? "100%"};
  height: ${(props) => props.customStyles?.height ?? "32px"};
  border-radius: ${(props) => props.customStyles?.borderRadius ?? "2px"};
  padding: ${(props) => props.customStyles?.padding ?? "8px"};
  border: ${(props) => props.customStyles?.border ?? `1px solid ${Color.sky50}`};
  &:focus {
    outline: none;
  }
`;
