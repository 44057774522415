import React, { ReactElement, useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { DropdownMenuItem, DropdownPosition, DropdownSize } from "ts/dropdown";
import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { StyledDropdownTransition } from "./StyledDropdownTransition";
import { Tooltip } from "components/Tooltip";
import { Text } from "components/Text";

type Props = {
  items: DropdownMenuItem[];
  show?: boolean;
  altContent?: ReactElement;
  position?: DropdownPosition;
  positionType?: string;
  size?: DropdownSize;
  width?: string;
  customFontSize?: number;
  maxHeight?: string;
  titleKey?: string;
  onChange?: (args) => void;
  handleCloseMenu?: () => void;
};

export const DropdownMenu = ({
  items,
  show = true,
  altContent,
  position,
  positionType,
  size = DropdownSize.lg,
  width = "116px",
  customFontSize = null,
  maxHeight = null,
  titleKey,
  onChange,
  handleCloseMenu,
}: Props) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleMouseOver = (e: React.MouseEvent<HTMLDivElement>) => {
    setShowTooltip(e.currentTarget.offsetWidth < e.currentTarget.scrollWidth);
  };

  return (
    <StyledDropdownMenu
      show={show}
      position={position}
      positionType={positionType}
      size={size}
      width={width}
      maxHeight={maxHeight}
    >
      {titleKey && (
        <StyledDropdownTitle>
          <Text resource={titleKey} />
        </StyledDropdownTitle>
      )}
      {altContent ||
        items.map(
          ({ href, label, onClick, isActive = false, isDisabled = false, external, value }, i) => {
            return (
              <StyledDropdownMenuItem
                key={i}
                as={external ? "a" : href ? Link : "div"}
                to={href}
                href={external && href}
                target={external && "_blank"}
                rel={external && "noopener noreferrer"}
                size={size}
                fontSize={customFontSize}
                width={width}
                // dollar sign added to prevent styled props from being passed to DOM node and causing error
                $isActive={isActive}
                $isDisabled={isDisabled}
                data-tip
                data-for={i}
                onMouseOver={handleMouseOver}
                onMouseOut={() => setShowTooltip(false)}
                onClick={() => {
                  onChange && onChange(value);
                  onClick && onClick();
                  handleCloseMenu && handleCloseMenu();
                }}
              >
                {label}
                {showTooltip && <Tooltip tooltipId={i.toString()} content={label} />}
              </StyledDropdownMenuItem>
            );
          }
        )}
    </StyledDropdownMenu>
  );
};

const DROPDOWN_SIZE_STYLES = {
  verticalPadding: {
    [DropdownSize.sm]: 5,
    [DropdownSize.lg]: 8,
  },
  fontSize: {
    [DropdownSize.sm]: 12,
    [DropdownSize.lg]: 15,
  },
  itemHorizontalPadding: {
    [DropdownSize.sm]: 12,
    [DropdownSize.lg]: 16,
  },
};

const StyledDropdownMenu = styled(StyledDropdownTransition)<{
  show: boolean;
  position: DropdownPosition;
  size: DropdownSize;
  width: string;
  maxHeight: string;
  positionType?: string;
  menuStyle?: string;
}>`
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  position: ${({ positionType }) => positionType || "absolute"};
  transform: ${({ positionType }) => (positionType ? "translateX(-80%)" : "none")};
  top: ${({ position }) => position?.top}px;
  right: ${({ position }) => position?.right}px;
  bottom: ${({ position }) => position?.bottom}px;
  left: ${({ position }) => position?.left}px;
  z-index: ${({ position }) => position?.zIndex || `calc(${ZIndexStackingContext.low} + 9)`};
  border: 1px solid ${Color.blue20};
  background-color: ${Color.white};
  border-radius: 2px;
  padding: ${({ size }) => DROPDOWN_SIZE_STYLES.verticalPadding[size]}px 0;
  width: ${({ width }) => width};
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      overflow-y: auto;
      max-height: ${maxHeight};
    `}
`;

const StyledDropdownMenuItem = styled.div<{
  $isActive: boolean;
  size: DropdownSize;
  $isDisabled: boolean;
  fontSize: number;
  width: string;
}>`
  padding: 8px ${({ size }) => DROPDOWN_SIZE_STYLES.itemHorizontalPadding[size]}px;
  transition: 0.2s background-color;
  cursor: pointer;
  font-size: ${({ size, fontSize }) =>
    fontSize ?? DROPDOWN_SIZE_STYLES.itemHorizontalPadding[size]}px;
  user-select: none;
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? "none" : "auto")};
  color: ${({ $isActive, $isDisabled }) =>
    $isDisabled ? Color.gray50 : $isActive ? Color.blue50 : Color.gray50};
  background-color: ${({ $isActive }) => ($isActive ? Color.sky15 : "")};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-lines: 1;
  display: block;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: ${({ width }) => width};

  &:hover {
    background: ${Color.sky15};
    color: ${Color.blue50};
  }
`;

const StyledDropdownTitle = styled.div`
  padding: 6px 16px;
  font-size: 12px;
  color: ${Color.gray30};
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
  max-width: 100%;
`;
