import React, { ReactNode } from "react";
import styled from "styled-components";

import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { DeleteFiltersButton } from "components/_buttons/DeleteFiltersButton";

type Props = {
  children: ReactNode;
  showDeleteFilters: boolean;
  margin?: string;
  deleteButtonLabelKey?: string;
  deleteFilters: () => void;
};

export const ExpandableFilterPillWrapper = ({
  children,
  showDeleteFilters,
  margin = "0",
  deleteButtonLabelKey,
  deleteFilters,
}: Props) => {
  return (
    <StyledFilterPillWrapper margin={margin}>
      {children}
      {showDeleteFilters && (
        <DeleteFiltersButton buttonLabelKey={deleteButtonLabelKey} onClick={deleteFilters} />
      )}
    </StyledFilterPillWrapper>
  );
};

const StyledFilterPillWrapper = styled.div<{ margin: string }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 40px;
  margin: ${({ margin }) => margin};
  overflow-y: auto;
  gap: 8px;
  z-index: ${ZIndexStackingContext.medium} + 1;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${Color.sky50};
    border: none;
  }

  ::-webkit-scrollbar-track {
    background-color: ${Color.sky20};
    border-radius: 3px;
  }
`;
