import React, { ReactNode } from "react";
import styled from "styled-components";
import { Icon, IconType } from "components/_icons/Icon";
import { Color } from "ts/enums/color";
import { useAppSelector } from "store";
import { AnalysisStatus } from "@explorance/mly-types";

export const DataPreviewTable = () => {
  const { selectedColumns, comments, analysisStatus } = useAppSelector(
    (state) => state.dataSource.analysisDataSource
  );
  const disabled = [AnalysisStatus.Completed, AnalysisStatus.Failed].includes(analysisStatus);

  const headers = Object.keys(comments?.[0]?.values ?? {});

  const isColumnSelected = (header) =>
    selectedColumns && selectedColumns.some((sc) => sc.toLowerCase() === header.toLowerCase());

  return (
    <StyledDataPreviewTable disabled={disabled}>
      <thead>
        <tr>
          {headers.map((header) => (
            <StyledTableHeaderContainer key={header} isSelected={isColumnSelected(header)}>
              <StyledTableHeader>
                <StyledHeaderTitle>{header}</StyledHeaderTitle>
                <StyledHeaderIconContainer>
                  {isColumnSelected(header) && (
                    <Icon type={IconType.checkCircle} color={Color.white} size={16} />
                  )}
                </StyledHeaderIconContainer>
              </StyledTableHeader>
            </StyledTableHeaderContainer>
          ))}
        </tr>
      </thead>
      <tbody>
        {comments.map((comment, index) => (
          <tr key={index}>
            {headers.map((header) => (
              <td key={`${index}-${header}`}>
                <StyledTableContentContainer>
                  {comment.values[header] as ReactNode}
                </StyledTableContentContainer>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledDataPreviewTable>
  );
};

type StyledProps = {
  disabled: boolean;
};

const StyledDataPreviewTable = styled.table<StyledProps>`
  text-align: left;
  width: auto;
  border-spacing: 0;
  cursor: default;
  border-collapse: separate;
  table-layout: fixed;
  font-size: 0.875em;

  td {
    border-bottom: 1px solid ${Color.blue20};
    margin: 0px;
    padding: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: 1365px) {
    th,
    td {
      max-width: 480px;
    }
  }

  @media (min-width: 1366px) {
    th,
    td {
      max-width: 600px;
    }
  }
`;

const StyledTableHeaderContainer = styled.th<{ isSelected: boolean }>`
  box-sizing: content-box;
  font-weight: bold;
  padding: 15px;
  text-align: left;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  border-top: 1px solid ${Color.blue20};
  border-bottom: 1px solid ${Color.blue20};
  min-width: 95px;
  max-width: 450px;
  vertical-align: top;
  background-color: ${(props) => (props.isSelected ? Color.gray40 : Color.sky20)};
  color: ${(props) => (props.isSelected ? Color.white : Color.gray50)};
`;

const StyledTableHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledHeaderTitle = styled.div`
  float: left;
  width: 92%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledHeaderIconContainer = styled.div`
  float: right;
`;

const StyledTableContentContainer = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 98%;
  color: ${Color.gray50};
`;
