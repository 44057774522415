import { getServerConfig } from "config/configProviders";

const getRandom = () => {
  return (Date.now() + "" + Math.random()).replace(".", "");
};

const authConfig = getServerConfig().authentication;

export const IDENTITY_CONFIG = {
  authority: authConfig.authority,
  client_id: authConfig.appClientId,
  redirect_uri: `${window.location.origin}/post-login`,
  silent_redirect_uri: `${window.location.origin}/post-refresh`,
  post_logout_redirect_uri: `${window.location.origin}/post-logout`,
  automaticSilentRenew: true,
  response_type: "id_token token",
  scope: "openid",
  nonce: getRandom(),
  loadUserInfo: false,
  revokeAccessTokenOnSignout: true,
};
