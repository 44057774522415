import React from "react";
import styled from "styled-components";
import { CategorizationTypeSymbol } from "components/_icons/CategorizationTypeSymbol";
import { CategorizationType } from "@explorance/mly-types";

type Props = {
  name: string;
  catType?: CategorizationType;
};

export const RowLabel = ({ name, catType }: Props) => {
  return (
    <StyledlRowLabelContainer>
      <CategorizationTypeSymbol categorizationType={catType} />
      <span>{name}</span>
    </StyledlRowLabelContainer>
  );
};

const StyledlRowLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
