import React, { useState } from "react";
import styled from "styled-components";

import { Icon, IconType } from "components/_icons/Icon";
import { Color } from "ts/enums/color";
import { Tooltip } from "components/Tooltip";

type CustomStylesProps = {
  arrowColor?: Color;
  textColor?: Color;
  fontSize?: number;
  fontWeight?: string;
  labelMargin?: number;
  marginBottom?: number;
};

type Props = {
  name: string | React.ReactNode;
  isExpanded?: boolean;
  id?: number;
  hoveredFieldNameId?: number;
  setHoveredFieldNameId?: any;
  children?: any;
  useLocalExpandedState?: boolean;
  defaultExpanded?: boolean;
  customStyles?: CustomStylesProps;
  padChildren?: boolean;
  onClick?: () => void;
};

const CollapsibleName = ({
  name,
  id,
  isExpanded,
  hoveredFieldNameId,
  setHoveredFieldNameId,
  useLocalExpandedState = false,
  defaultExpanded = false,
  children,
  customStyles,
  padChildren = false,
  onClick,
}: Props) => {
  const [localExpanded, setLocalExpanded] = useState<boolean>(defaultExpanded);

  if (useLocalExpandedState) {
    isExpanded = localExpanded;
  }

  const onHoverField = (id) => (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) {
      setHoveredFieldNameId(id);
    }
  };

  const handleClick = () => {
    onClick && onClick();
    useLocalExpandedState && setLocalExpanded((localExpanded) => !localExpanded);
  };

  return (
    <>
      <StyledCollapsibleName
        isExpanded={isExpanded}
        onClick={handleClick}
        marginBottom={customStyles?.marginBottom}
      >
        <div className="filter-name-chevron">
          <Icon
            type={IconType.chevronDown}
            size={8}
            color={customStyles?.arrowColor || (isExpanded ? Color.blue50 : Color.gray50)}
          />
        </div>
        <StyledLabel
          isExpanded={isExpanded}
          customStyles={customStyles}
          onMouseOver={onHoverField(id)}
          onMouseOut={() => {
            setHoveredFieldNameId && setHoveredFieldNameId(null);
          }}
          data-tip
          data-for={id}
        >
          {name}
        </StyledLabel>
        {hoveredFieldNameId && (
          <Tooltip tooltipId={id.toString()} className="tooltip-field-name">
            {name}
          </Tooltip>
        )}
      </StyledCollapsibleName>
      {isExpanded && (
        <StyledChildrenContainer padChildren={padChildren}>{children}</StyledChildrenContainer>
      )}
    </>
  );
};

const StyledCollapsibleName = styled.div<{ isExpanded: boolean; marginBottom?: number }>`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? 8}px;
  user-select: none;

  .filter-name-chevron > svg {
    cursor: pointer;
    display: block;
    ${({ isExpanded }) => isExpanded && "transform: rotate(180deg);"}
  }

  .tooltip-field-name {
    max-width: 300px;
  }
`;

const StyledChildrenContainer = styled.div<{ padChildren: boolean }>`
  padding-left: ${({ padChildren }) => padChildren && "20px"};
`;

const StyledLabel = styled.div<{ isExpanded: boolean; customStyles: CustomStylesProps }>`
  font-weight: ${({ isExpanded, customStyles }) =>
    customStyles?.fontWeight || (isExpanded ? "bold" : "normal")};
  color: ${({ isExpanded, customStyles }) =>
    customStyles?.textColor || (isExpanded ? Color.blue50 : Color.gray50)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: ${({ customStyles }) => customStyles?.labelMargin || 12}px;
  font-size: ${({ customStyles }) => customStyles?.fontSize || 14}px;
  max-width: 430px;
`;

export { CollapsibleName };
