import { DotsMenu } from "components/DotsMenu";
import { ProcessingPlaceholder } from "components/ProcessingPlaceholder";
import { Skeleton } from "components/Skeleton";
import { Checkbox } from "components/_inputs/Checkbox";
import { useResource } from "hooks/useResource";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { Color } from "ts/enums/color";
import { FlexTableType } from "ts/enums/flexTable";
import { StyledBaseRow, StyledCell } from "./commonStyles";
import { CHECKBOX_CELL_WIDTH, RowDotsMenuParams } from ".";

// row component
type RowProps = {
  row: ReactNode[];
  contentId: number;
  isSelected: boolean;
  isSelectable: boolean;
  customStyles: Record<string, string>;
  columnWidths: string[];
  isDisabled: boolean;
  expandedContent: ReactNode;
  dotsMenuParams?: RowDotsMenuParams;
  isLoading?: boolean;
  tableWidth: number;
  type: FlexTableType;
  handleClickOutside?: () => void;
  onSelectionChange?: (contentId: number) => void;
  handleDoubleClickRow?: (contentId: number) => void;
};

export const Row = ({
  row,
  contentId,
  isSelected,
  isSelectable,
  customStyles,
  columnWidths,
  isDisabled,
  expandedContent,
  dotsMenuParams,
  isLoading,
  tableWidth,
  type,
  handleClickOutside,
  onSelectionChange,
  handleDoubleClickRow,
}: RowProps) => {
  const rowRef = useRef<HTMLDivElement>();
  const { getResource } = useResource();
  const [showDotsButton, setShowDotsButton] = useState(false);

  const isRowInDeletion = (cellIndex: number) => {
    return (
      dotsMenuParams?.isDeleting &&
      cellIndex === row.length - 1 &&
      dotsMenuParams.rowId === dotsMenuParams.currentEditedRowId
    );
  };

  useEffect(() => {
    const handleClickDocument = (e: MouseEvent) => {
      if (rowRef.current && !rowRef.current.contains(e.target as Node) && handleClickOutside) {
        handleClickOutside();
      }
    };

    if (!isDisabled) document.addEventListener("click", handleClickDocument);

    return () => document.removeEventListener("click", handleClickDocument);
  }, [contentId, isDisabled, handleClickOutside]);

  return (
    <StyledRow
      key={contentId}
      isSelected={isSelected}
      isSelectable={isSelectable}
      customStyles={customStyles}
      isDisabled={isDisabled}
      ref={rowRef}
      onClick={onSelectionChange ? () => onSelectionChange(contentId) : undefined}
      onMouseOver={() => dotsMenuParams && setShowDotsButton(true)}
      onMouseLeave={() => dotsMenuParams && setShowDotsButton(false)}
      isLoading={isLoading}
      onDoubleClick={() => handleDoubleClickRow && handleDoubleClickRow(contentId)}
      type={type}
    >
      {isSelectable && (
        <StyledCell width={CHECKBOX_CELL_WIDTH}>
          <Checkbox
            checked={isSelected}
            onClickInput={(e) => e.stopPropagation()}
            onChange={() => undefined}
          />
        </StyledCell>
      )}
      {row.map((cell, cellIndex) =>
        dotsMenuParams && isRowInDeletion(cellIndex) ? (
          <ProcessingPlaceholder key={cellIndex} text={getResource("table.rowStatus.deleting")} />
        ) : (
          <StyledCell key={cellIndex} width={columnWidths[cellIndex]} type={type}>
            {isLoading && cellIndex !== columnWidths.length - 1 ? (
              <Skeleton
                width={(tableWidth * (parseFloat(columnWidths[cellIndex]) * 0.5)) / 100}
                height={10}
              />
            ) : (
              <span>{cell}</span>
            )}
          </StyledCell>
        )
      )}
      {!isLoading && expandedContent}
      {dotsMenuParams && !isLoading && (
        <StyledDotsMenuBox>
          <DotsMenu
            onDotsMenuClick={dotsMenuParams.onDotMenuClick}
            menuWidth={120}
            menuContents={dotsMenuParams.menuContents}
            showDotsButton={
              showDotsButton && !dotsMenuParams.isDeleting && !dotsMenuParams.hideDotsButton
            }
            altDropdownContent={dotsMenuParams?.altDropdownContent}
            handleHideUserDetails={dotsMenuParams.handleHideUserDetails}
            shouldCloseMenuOnClick={dotsMenuParams.clickToClose}
          />
        </StyledDotsMenuBox>
      )}
    </StyledRow>
  );
};

const StyledRow = styled(StyledBaseRow)<{
  isSelected: boolean;
  isSelectable: boolean;
  customStyles?: Record<string, string>;
  isDisabled: boolean;
  isLoading?: boolean;
  type: FlexTableType;
}>`
  border-radius: 2px;
  border: ${({ type, isSelected }) =>
    type === FlexTableType.Card ? `1px solid ${isSelected ? Color.blue50 : Color.blue20}` : "none"};
  border-bottom: ${({ type }) => type === FlexTableType.Table && `1px solid ${Color.blue20}`};
  background-color: ${({ isSelected, customStyles }) =>
    customStyles?.backgroundColor || (isSelected ? Color.sky40 : Color.sky10)};
  user-select: none;
  flex-wrap: ${({ customStyles }) => customStyles?.flexWrap || "nowrap"};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  font-size: ${({ type }) => type === FlexTableType.Table && "12px"};
  height: ${({ customStyles }) => customStyles?.rowHeight || "auto"};
  ${({ isSelectable, isSelected }) =>
    isSelectable &&
    css`
      cursor: pointer;
      &:hover {
        background-color: ${isSelected ? Color.sky40 : Color.sky20};
      }
    `}
  ${({ type, isLoading }) =>
    type === FlexTableType.Table &&
    !isLoading &&
    css`
      &:hover {
        background-color: ${Color.sky15};
      }
    `}
`;

const StyledDotsMenuBox = styled.div`
  margin-left: auto;
`;
