import React from "react";
import styled from "styled-components";
import { Color } from "ts/enums/color";

export const Kebab = () => {
  return (
    <StyledKebab>
      {[...Array(3)].map((_, i) => (
        <StyledDot key={i} />
      ))}
    </StyledKebab>
  );
};

const StyledKebab = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 8px;
`;

const StyledDot = styled.div`
  background-color: ${Color.gray20};
  border-radius: 100%;
  width: 4px;
  height: 4px;
  margin: 1px;
`;
