import { SpreadsheetImportMethod } from "@explorance/mly-types";
import { mlyApi, getHeaders } from "config/mlyApi";
import { UserSortingParameter } from "ts/enums/sorting";
import { UserListParameters, CreateUserBody, UpdateUserBody } from "ts/user";
import { getUrlQueryString } from "utils/getUrlQueryString";

export async function getUserRolesList(includeSuperAdmin?: boolean) {
  const requestConfig = await getHeaders();
  const url = `/roles` + getUrlQueryString({ includeSuperAdmin });
  return mlyApi.get<{ roles: string[] }>(url, requestConfig);
}

export async function getUsersList(parameters: UserListParameters) {
  const requestConfig = await getHeaders();
  const formattedSortColumn =
    parameters.sort_column === UserSortingParameter.FirstName ||
    parameters.sort_column === UserSortingParameter.LastName
      ? parameters.sort_column.toLowerCase()
      : parameters.sort_column.slice(0, 1).toLowerCase() + parameters.sort_column.slice(1);
  const url = `/users` + getUrlQueryString({ ...parameters, sort_column: formattedSortColumn });
  return mlyApi.get(url, requestConfig);
}

export async function getUser(userId: number | string, isExternalId?: boolean) {
  const requestConfig = await getHeaders();
  const url = `/users/${userId}${isExternalId ? "?isExternalId=true" : ""}`;
  return mlyApi.get(url, requestConfig);
}

export async function createUser(body: CreateUserBody) {
  const requestConfig = await getHeaders();
  const url = "/users";
  return mlyApi.post(url, body, requestConfig);
}

export async function updateUser(userId: number, body: UpdateUserBody) {
  const requestConfig = await getHeaders();
  const url = `/users/${userId}`;
  return mlyApi.put(url, body, requestConfig);
}

export async function patchUser(userId: number, body: Record<string, any>) {
  const requestConfig = await getHeaders();
  const url = `/users/${userId}`;
  return mlyApi.patch(url, body, requestConfig);
}

export async function deleteUser(userId: number) {
  const requestConfig = await getHeaders();
  const url = `/users/${userId}`;
  return mlyApi.delete(url, requestConfig);
}

export async function sendUserInvite(userId: number) {
  const requestConfig = await getHeaders();
  const url = `/users/${userId}/invitations`;
  return mlyApi.post(url, requestConfig);
}

export async function updateUserLastLogin(userId: number) {
  const requestConfig = await getHeaders();
  const url = `/users/${userId}/logins`;
  return mlyApi.post(url, requestConfig);
}

export async function importUsers(file: File, importMethod: SpreadsheetImportMethod) {
  const requestConfig = await getHeaders("multipart/form-data");
  const url = `/users/imports`;
  const formData = new FormData();
  formData.append("file", file);
  formData.append("importMethod", importMethod);
  return mlyApi.post(url, formData, requestConfig);
}

export async function exportUserList(headersOnly: boolean) {
  const requestConfig = await getHeaders();
  const url = "/users/export";
  return mlyApi.post(url, { headersOnly }, requestConfig);
}

export async function getAllUserDemographics(onlyHeaders?: boolean, limit?: number) {
  const requestConfig = await getHeaders();
  const queryString = getUrlQueryString({
    onlyHeaders,
    limit,
  });
  const url = `/users/demographics${queryString}`;
  return mlyApi.get(url, requestConfig);
}
