import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RecentAnalysis } from "ts/analysis";
import { fetchRecentAnalyses } from "./thunks";

interface HomePageState {
  analysisList: RecentAnalysis[];
  isWelcomeModalOpen: boolean;
  isLoading: boolean;
  initialLoadComplete: boolean;
}

const initialState: HomePageState = {
  analysisList: [],
  isWelcomeModalOpen: false,
  isLoading: true,
  initialLoadComplete: false,
};

const homePageSlice = createSlice({
  name: "homePage",
  initialState,
  reducers: {
    setAnalysisList: (state, action: PayloadAction<RecentAnalysis[]>) => {
      state.analysisList = action.payload;
    },
    setIsWelcomeModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isWelcomeModalOpen = action.payload;
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRecentAnalyses.pending, (state) => {
      if (!state.initialLoadComplete) {
        state.isLoading = true;
      }
    }),
      builder.addCase(fetchRecentAnalyses.fulfilled, (state, action) => {
        state.analysisList = action.payload;
        state.isLoading = false;
        state.initialLoadComplete = true;
      });
  },
});

export const { setAnalysisList, setIsWelcomeModalOpen, clearState } = homePageSlice.actions;

export default homePageSlice.reducer;
