import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "ts/user";

type AuthState = {
  currentUser: User;
};

const initialState: AuthState = {
  currentUser: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<User>) => {
      state.currentUser = action.payload;
    },
  },
});

export const { setCurrentUser } = authSlice.actions;

export default authSlice.reducer;
