import React, { useEffect } from "react";
import { useAppDispatch } from "store";
import { showToastError } from "store/toast/toastSlice";

type Props = {
  inputFileRef: React.RefObject<HTMLInputElement>;
  onSelectFile: (file: File) => Promise<string | void>;
  resetFileRef: boolean;
};

export const FileUploadInput = ({ inputFileRef, onSelectFile, resetFileRef }: Props) => {
  const dispatch = useAppDispatch();

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];

    const toastErrorLabelKey = await onSelectFile(file);
    toastErrorLabelKey && dispatch(showToastError(toastErrorLabelKey));

    event.target.value = ""; // keep this to be able to upload multiple times
  };

  useEffect(() => {
    if (resetFileRef) {
      inputFileRef.current.value = "";
    }
  }, [resetFileRef, inputFileRef]);

  return (
    <input
      type="file"
      ref={inputFileRef}
      hidden={true}
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      onChange={handleChange}
    />
  );
};
