import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";

import { useAppSelector } from "store";
import { useHistory } from "react-router-dom";

import { StatTab } from "components/StatTab";
import { AnalysisContext } from "context/AnalysisContext";

import { useQueryParams } from "hooks/useQueryParams";
import { getUrlQueryString } from "utils/getUrlQueryString";
import { StatsPlaceholder } from "./Placeholder";
import { routes } from "routes";
import { getCustomModelId } from "utils/getCustomModelId";

import { StatTabInfo } from "ts/statsTabInfo";
import { CommentsView, StatisticsType } from "@explorance/mly-types";
import { statisticsURLStringToArray } from "utils/comments";
import { DropdownMenuItem } from "ts/dropdown";

type Props = {
  menuItems: StatTabInfo[];
  commentsViewOptions: DropdownMenuItem[];
  overallStatsCustomModelId?: number;
  commentsLoading?: boolean;
  handleSelectView: (view: CommentsView) => void;
};

export const StatsMenu = ({
  menuItems,
  commentsViewOptions,
  overallStatsCustomModelId,
  commentsLoading,
  handleSelectView,
}: Props) => {
  const searchTerm = useAppSelector((state) => state.search.searchTerm);
  const [state] = useContext(AnalysisContext);
  const [selectedView, setSelectedView] = useState<CommentsView>(null);
  const [selectedStats, setSelectedStats] = useState<string[]>([]);

  const { statistics, view, sharingPreview, sharingId, sharedWithPage, expandedRowId, step } =
    useQueryParams() as {
      statistics;
      view;
      sharingPreview;
      sharingId;
      sharedWithPage;
      expandedRowId;
      step;
    };
  const { push } = useHistory();

  const customModelId = getCustomModelId(state);

  useEffect(() => {
    setSelectedStats(statisticsURLStringToArray(statistics));
  }, [statistics]);

  // Set initial view
  useEffect(() => {
    setSelectedView(view || CommentsView.allComments);
  }, [view]);

  const selectedCountInfo = menuItems[selectedView];

  const handleToggleStat = (name: StatisticsType) => {
    let newSelectedStats = [...selectedStats];
    const index = newSelectedStats.indexOf(name);

    if (name === StatisticsType.total) {
      newSelectedStats = [name];
    } else if (index === -1) {
      newSelectedStats = newSelectedStats.filter((stat) => stat !== StatisticsType.total);
      newSelectedStats.push(name);
    } else {
      newSelectedStats.splice(index, 1);
    }

    if (newSelectedStats.length === 0) {
      newSelectedStats.push(StatisticsType.total);
    }

    setSelectedStats(newSelectedStats);

    push(
      routes.commentsPage(state.analysisDetails.id) +
        getUrlQueryString({
          custom_model_id: overallStatsCustomModelId || customModelId,
          statistics: newSelectedStats.join(","),
          view: selectedView,
          page: 1,
          search: encodeURIComponent(searchTerm),
          sharingPreview,
          sharingId,
          sharedWithPage,
          expandedRowId,
          step,
        })
    );
  };

  const handleOverrideStat = (name: StatisticsType) => {
    setSelectedStats([name]);

    push(
      routes.commentsPage(state.analysisDetails.id) +
        getUrlQueryString({
          custom_model_id: overallStatsCustomModelId || customModelId,
          statistics: [name],
          view: selectedView,
          page: 1,
          search: encodeURIComponent(searchTerm),
          sharingPreview,
          sharingId,
          sharedWithPage,
          expandedRowId,
          step,
        })
    );
  };

  if (!menuItems) return null;

  if (!selectedCountInfo) return <StatsPlaceholder />;

  return (
    <StyledStatsMenu>
      {selectedCountInfo?.map((stat, index) => {
        const displayName = stat.nameOverride || stat.name;

        return (
          <StatTab
            {...stat}
            key={index}
            name={displayName}
            isChecked={selectedStats.includes(stat.name)}
            originalValue={stat.name}
            commentsLoading={commentsLoading}
            commentsViewOptions={commentsViewOptions}
            handleToggleStat={handleToggleStat}
            handleOverrideStat={handleOverrideStat}
            handleSelectView={handleSelectView}
          />
        );
      })}
    </StyledStatsMenu>
  );
};

const StyledStatsMenu = styled.div`
  display: flex;
  margin: 0 0 10px 0;
  user-select: none;
`;
