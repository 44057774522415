import { ToggleSwitch } from "components/_inputs/ToggleSwitch";
import React, { useEffect, useState } from "react";
import { updateModelActivation } from "services/licensing";
import { useAppDispatch } from "store";
import { showToastError } from "store/toast/toastSlice";
import { ModelLicense } from "ts/licensing";

type Props = {
  model: ModelLicense;
  setModels: React.Dispatch<React.SetStateAction<ModelLicense[]>>;
};

export const ModelToggleSwitch = ({ model, setModels }: Props) => {
  const dispatch = useAppDispatch();
  const [isPending, setIsPending] = useState<boolean>(false);
  const [isModelActive, setIsModelActive] = useState<boolean>();
  const [activationLabelKey, setActivationLabelKey] = useState<string>("");

  useEffect(() => {
    setActivationLabelKey(
      model.isActivated ? "licensing.table.toggle.active" : "licensing.table.toggle.inactive"
    );
    setIsModelActive(model.isActivated);
  }, []); // eslint-disable-line

  const handleUpdateActivationStatus = (status: boolean) => {
    setIsPending(true);
    const fn = async () => {
      await updateModelActivation(model.modelId, status)
        .then(() => {
          setActivationLabelKey(
            status ? "licensing.table.toggle.active" : "licensing.table.toggle.inactive"
          );
          setIsModelActive(status);
        })
        .catch(() => dispatch(showToastError("toast.defaultError")))
        .finally(() => {
          setIsPending(false);
          setModels((prev: ModelLicense[]) => {
            const updatedModels = prev.map((m) => {
              if (m.modelId === model.modelId) {
                return { ...m, isActivated: status };
              }
              return m;
            });
            return updatedModels;
          });
        });
    };
    fn();
  };

  if (isModelActive === undefined && activationLabelKey.length === 0) {
    return null;
  }

  return (
    <ToggleSwitch
      labelKey={activationLabelKey}
      checked={isModelActive}
      showLabelToRight={true}
      isPending={isPending}
      onChange={handleUpdateActivationStatus}
    />
  );
};
