import { lowerFirst } from "lodash";
import { AnalysisPageType } from "ts/enums/analysisPageType";
import { LanguageCode } from "@explorance/mly-types";

export const getAnalysisPageType = (slug: string) =>
  slug.includes("overview")
    ? AnalysisPageType.Overview
    : slug.includes("dashboard")
    ? AnalysisPageType.Dashboard
    : slug.includes("topics")
    ? AnalysisPageType.Topics
    : slug.includes("comments")
    ? AnalysisPageType.Comments
    : null;

export const getLastWidgetPushDateArgs = (
  lastPushedDate: Date,
  userLanguage: LanguageCode
): string[] => {
  // get locale by user language
  const userLang = lowerFirst(userLanguage);
  const langLocale = userLang.substring(0, 2);
  // get locale date and time formats
  const year = new Intl.DateTimeFormat(langLocale, { year: "numeric" }).format(lastPushedDate);
  const month = new Intl.DateTimeFormat(langLocale, { month: "long" }).format(lastPushedDate);
  const day = new Intl.DateTimeFormat(langLocale, { day: "numeric" }).format(lastPushedDate);
  const time = new Intl.DateTimeFormat(langLocale, { hour: "numeric", minute: "numeric" }).format(
    lastPushedDate
  );
  // get date with locale appropriate ordinal
  const dayAsNumber = parseInt(day);
  const formattedDate = getOrdinalDate(dayAsNumber, userLanguage);
  return [month, formattedDate, year, time];
};

const getOrdinalDate = (date: number, userLanguage: LanguageCode): string => {
  let formattedDate;
  if (userLanguage === LanguageCode.Fra) {
    formattedDate = date === 1 ? `${date}<span>er</span>` : date.toString();
  } else if (userLanguage === LanguageCode.Eng) {
    if (date > 3 && date < 21) formattedDate = `${date}<span>th</span>`;
    switch (date % 10) {
      case 1:
        formattedDate = `${date}st`;
        break;
      case 2:
        formattedDate = `${date}nd`;
        break;
      case 3:
        formattedDate = `${date}rd`;
        break;
      default:
        formattedDate = `${date}th`;
    }
  }
  return `${formattedDate}`;
};
