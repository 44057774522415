import React, { useState } from "react";
import styled, { css } from "styled-components";

import { Icon, IconType } from "components/_icons/Icon";
import { PasswordHelpTooltip } from "./PasswordHelpTooltip";

import { Color } from "ts/enums/color";
import { StyledFieldLabel } from "components/UserProfileEditorField";

type Props = {
  label?: string;
  password: string;
  isInvalid: boolean;
  hasTooltip?: boolean;
  className?: string;
  onChange(value: string): void;
  onBlur(): boolean;
};

export const PasswordField = ({
  label,
  password,
  isInvalid,
  hasTooltip,
  className,
  onChange,
  onBlur,
}: Props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    onChange(value);
  };

  return (
    <StyledPasswordContainer className={className}>
      <StyledPasswordLabel>
        {label}
        {hasTooltip && (
          <>
            <StyledPasswordHelpIconContainer data-tip data-for="pwd-tooltip">
              <Icon type={IconType.info} color={Color.gray30} size={12} />
            </StyledPasswordHelpIconContainer>
            <PasswordHelpTooltip id="pwd-tooltip" password={password} />
          </>
        )}
      </StyledPasswordLabel>
      <StyledPasswordField isInvalid={isInvalid}>
        <input
          type={showPassword ? "text" : "password"}
          value={password ?? ""}
          onChange={onChangePassword}
          onBlur={onBlur}
        />
        <StyledShowPasswordIconContainer onClick={() => setShowPassword((show) => !show)}>
          <Icon type={IconType.eye} color={showPassword ? Color.blue50 : Color.gray30} size={12} />
        </StyledShowPasswordIconContainer>
      </StyledPasswordField>
    </StyledPasswordContainer>
  );
};

const StyledPasswordContainer = styled.div`
  margin-bottom: 15px;
  width: 100%;
`;

const StyledPasswordLabel = styled(StyledFieldLabel)`
  display: flex;
  margin-bottom: 5px;
  color: ${Color.gray50};
`;

const StyledPasswordHelpIconContainer = styled.span`
  margin-left: auto;
  font-weight: bold;
  padding: 0 2px;
  cursor: pointer;

  :hover {
    background: ${Color.sky40};
    border-radius: 3px;
  }
`;

const StyledPasswordField = styled.div<{ isInvalid: boolean }>`
  position: relative;

  input {
    padding: 8px 10px;
    border: 1px solid ${Color.sky50};
    border-radius: 2px;
    display: block;
    width: 100%;

    :focus {
      outline: none;
      border: 1px solid ${Color.blue50};
    }

    ${({ isInvalid }) =>
      isInvalid &&
      css`
        outline: none;
        border: 1px solid ${Color.red50};
      `};
  }
`;

const StyledShowPasswordIconContainer = styled.span`
  position: absolute;
  top: 8px;
  right: 15px;
  cursor: pointer;
`;
