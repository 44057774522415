import { CategorizationType, Language } from "@explorance/mly-types";

export type Model = {
  categorizationType: CategorizationType;
  graphId: number;
  env?: string;
  modelName: string;
  customModelId?: number;
  languages?: Language[];
  isPowerset?: boolean;
  isOutdated?: boolean;
};

export const mapDataToCustomModels = (
  model: any,
  categorizationType: CategorizationType
): Model => ({
  graphId: model.graphId,
  modelName: model.name,
  customModelId: model.id,
  env: model.env,
  isOutdated: model.isOutdated,
  categorizationType,
});

export type ModelSelectionOptions = {
  models: {
    [CategorizationType.EEC]: Model[];
    [CategorizationType.ELC]: Model[];
    [CategorizationType.SCE]: Model[];
  };
  customModels?: {
    [CategorizationType.EEC]?: Model[];
    [CategorizationType.ELC]?: Model[];
    [CategorizationType.SCE]?: Model[];
  };
};

export type ModelSelectionDropdownData = {
  categorizedModelList: Model[];
  options: ModelSelectionOptions;
};

export type SelectedAnalysisModel = {
  customModelId: number;
  customModelName: string;
  family: CategorizationType;
  graphId: number;
  isAvailable: boolean;
  isCustom: boolean;
  isOutdated: boolean;
  isPowerset: boolean;
  env?: string;
  languages: Language[];
};

export type SuggestedAnalysisModel = {
  family: CategorizationType;
  graphId: number;
  languages: Language[];
  isPowerset: boolean;
};

export const getModelFromSelectedAnalysisModel = (analysisModel: SelectedAnalysisModel): Model => ({
  categorizationType: analysisModel.family,
  graphId: analysisModel.graphId,
  modelName: analysisModel.customModelName,
  customModelId: analysisModel.customModelId,
  languages: analysisModel.languages,
  isPowerset: analysisModel.isPowerset,
});
