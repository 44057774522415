export enum CategorizedTopic {
  positive = "positive",
  negative = "negative",
  neutral = "neutral",
  mixed = "mixed",
  notExplicit = "notExplicit",
  generalRecommendation = "generalRecommendation",
  recommendation = "recommendation",
  doMore = "doMore",
  doLess = "doLess",
  start = "start",
  stop = "stop",
  continue = "continue",
  change = "change",
}
