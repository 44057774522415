import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_LIST_LIMIT } from "assets/constants/listLimits";
import { getUsersList } from "services/users";

import { UsersApiData } from "ts/user";
import { formatSearchForFetchRequest } from "utils/formatters";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { setEmptyStateType } from "./usersListSlice";
import { AppState } from "store/appReducer";

export const fetchUserList = createAsyncThunk<UsersApiData, void, { state: AppState }>(
  "variableMappingsList/fetchVariableMappings",
  async (_, { getState, dispatch }): Promise<UsersApiData> => {
    const state = getState();
    const userListState = state.userList;
    const searchTerm = state.search.searchTerm;

    const { data: usersApiData } = await getUsersList({
      ...(userListState.currentRoleFilters.length > 0 && {
        role: userListState.currentRoleFilters,
      }),
      limit: DEFAULT_LIST_LIMIT,
      page: userListState.currentPage,
      search: searchTerm && formatSearchForFetchRequest(searchTerm.trim()),
      sort_column: userListState.sortColumn,
      sort_order: userListState.sortOrder,
    });

    const emptyStateType =
      usersApiData.itemCount === 0 && searchTerm?.length === 0
        ? EmptyStateType.noListResults
        : usersApiData.itemCount === 0 && searchTerm.length > 0
        ? EmptyStateType.noSearchResults
        : null;

    dispatch(setEmptyStateType(emptyStateType));

    if (!usersApiData) {
      throw new Error("Failed to fetch users list.");
    }
    return usersApiData;
  }
);

export type uploadUsersError = {
  hasError: boolean;
};
