import React from "react";
import styled from "styled-components";
import { Color } from "ts/enums/color";
import { Text } from "components/Text";

type Props = {
  buttonLabelKey?: string;
  onClick(): void;
};

export const DeleteFiltersButton = ({ buttonLabelKey = "button.clearFilters", onClick }: Props) => {
  return (
    <StyledDeleteFiltersButton onClick={onClick}>
      <Text resource={buttonLabelKey} />
    </StyledDeleteFiltersButton>
  );
};

const StyledDeleteFiltersButton = styled.div`
  font-size: 0.75rem;
  color: ${Color.blue50};
  text-decoration: underline;
  cursor: pointer;
`;
