// library
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

// components
import { DemographicFilterSelection } from "./DemographicFilterSelection";
import { DemographicFilter } from "ts/filters/demographicFilter";

import { CollapsibleName } from "components/CollapsibleName";
import { FilterTypeDropdown } from "components/FilterTypeDropdown";
import { DemographicFilterMethod } from "@explorance/mly-types";
import { getDemographicById } from "services/demographics";
import { AnalysisContext } from "context/AnalysisContext";
import { setAvailableDemographicFilters } from "context/AnalysisContext/actions";
import { DEMOGRAPHIC_LIST_LIMIT } from "assets/constants/demographicLimit";
import { getUpdatedDemographicFilters } from "./helpers";

type Props = {
  demographic: DemographicFilter;
  currentFilterState: DemographicFilter[];
  handleCurrentFilterState: (currentFilterState: DemographicFilter[]) => void;
  isOpen: boolean;
};

export const DemographicFilterContainer = ({
  demographic,
  currentFilterState,
  handleCurrentFilterState,
  isOpen,
}: Props) => {
  // hooks
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [hoveredFieldNameId, setHoveredFieldNameId] = useState<number>();
  const [filterCount, setFilterCount] = useState<string>("");
  const [filterType, setFilterType] = useState<DemographicFilterMethod>(
    DemographicFilterMethod.List
  );

  const [state, dispatch] = useContext(AnalysisContext);

  const currentFilter = currentFilterState?.filter((cf) => cf.id === demographic.id)[0];

  useEffect(() => {
    if (currentFilter) {
      if (currentFilter.method !== filterType) setFilterType(currentFilter.method);

      if (
        currentFilter.method === DemographicFilterMethod.List &&
        currentFilter.listValues.length > 0
      ) {
        setFilterCount(` (${currentFilter.listValues.length})`);
      } else if (
        currentFilter.method === DemographicFilterMethod.Search &&
        currentFilter.searchValue.length > 0
      ) {
        setFilterCount(` (${currentFilter.searchValue.length})`);
      } else {
        setFilterCount("");
      }
    } else setFilterCount("");
  }, [currentFilter, demographic.id, filterType]);

  useEffect(() => {
    if (!isOpen) {
      setIsExpanded(false);
      if (!currentFilter) setFilterType(DemographicFilterMethod.List);
    }
  }, [isOpen, currentFilter]);

  // functions
  const onChangeFilterType = (
    type: DemographicFilterMethod.List | DemographicFilterMethod.Search
  ) => {
    setFilterType(type);
    if (currentFilter) {
      const newCurrentFilter = {
        ...currentFilter,
        method: type,
        ...(type === DemographicFilterMethod.List && { searchValue: [] }),
        ...(type === DemographicFilterMethod.Search && { listValues: [] }),
      };
      const newFilterState = currentFilterState.filter((cf) => cf.id !== demographic.id);
      handleCurrentFilterState([...newFilterState, newCurrentFilter]);
    }
  };

  const handleExpand = async () => {
    setIsExpanded((prev) => !prev);
    const targetFilter = state.availableDemographicFilters.find((d) => d.id === demographic.id);
    if (targetFilter && targetFilter.listValues.length !== 0) return;

    const { data } = await getDemographicById(demographic.id, 1, DEMOGRAPHIC_LIST_LIMIT);
    const updatedFilters = getUpdatedDemographicFilters(state.availableDemographicFilters, data);
    dispatch(setAvailableDemographicFilters(updatedFilters));
  };

  return (
    <>
      <StyledDemographicFilterHeader>
        <CollapsibleName
          name={demographic.name + (filterCount || "")}
          id={demographic.id}
          onClick={handleExpand}
          isExpanded={isExpanded}
          hoveredFieldNameId={hoveredFieldNameId}
          setHoveredFieldNameId={setHoveredFieldNameId}
        />
        {isExpanded && (
          <FilterTypeDropdown currentFilterType={filterType} onSelect={onChangeFilterType} />
        )}
      </StyledDemographicFilterHeader>

      {isExpanded && (
        <DemographicFilterSelection
          demographic={demographic}
          key={demographic.id}
          filterType={filterType}
          currentFilterState={currentFilterState}
          isFilterPill={false}
          handleFilterCount={setFilterCount}
          handleCurrentFilterState={handleCurrentFilterState}
        />
      )}
    </>
  );
};

const StyledDemographicFilterHeader = styled.div`
  display: flex;
`;
