import React from "react";
import styled from "styled-components";

import { Icon, IconType } from "components/_icons/Icon";
import { Color } from "ts/enums/color";

type Props = {
  children: React.ReactNode | string;
  color?: Color;
  backgroundColor?: Color;
  hoverColor?: Color;
  border?: string;
  applyGridMargin?: boolean;
  inverted?: boolean;
  size?: PillSize;
  style?: Record<string, any>;
  round?: boolean;
  disabled?: boolean;
  deleteButtonHoverBackgroundColor?: Color;
  iconColor?: Color;
  onClick?: React.MouseEventHandler;
  onDeletePill?: () => void;
};

export enum PillSize {
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
}

const pillPaddings = {
  [PillSize.sm]: "4px 6px",
  [PillSize.md]: "5px 7px",
  [PillSize.lg]: "6px 8px",
  [PillSize.xl]: "11px 12px",
};
const pillFontSizes = {
  [PillSize.sm]: "12px",
  [PillSize.md]: "13px",
  [PillSize.lg]: "14px",
  [PillSize.xl]: "15px",
};

export const Pill = ({
  size = PillSize.md,
  children,
  disabled,
  backgroundColor = Color.indigo10,
  color = Color.indigo50,
  onDeletePill,
  onClick,
  ...props
}: Props) => (
  <StyledPill
    padding={pillPaddings[size]}
    fontSize={pillFontSizes[size]}
    onClick={disabled ? () => undefined : onClick}
    disabled={disabled}
    cursorType={disabled ? "not-allowed" : onClick ? "pointer" : "default"}
    backgroundColor={backgroundColor}
    color={color}
    {...props}
  >
    {children}

    {onDeletePill && (
      <StyledDeletePillButton
        inverted={props.inverted}
        deleteButtonHoverBackgroundColor={props.deleteButtonHoverBackgroundColor}
        onClick={onDeletePill}
      >
        <Icon
          type={IconType.x}
          size={12}
          color={props.iconColor || (props.inverted ? Color.white : Color.gray50)}
        />
      </StyledDeletePillButton>
    )}
  </StyledPill>
);

type StyledProps = {
  color?: string;
  backgroundColor?: string;
  fontSize?: string;
  applyGridMargin?: boolean;
  hoverColor?: string;
  border?: string;
  inverted?: boolean;
  padding?: string;
  round?: boolean;
  disabled?: boolean;
  cursorType: string;
  deleteButtonHoverBackgroundColor?: Color;
};

const StyledPill = styled.div<StyledProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ round }) => (round ? "99px" : "2px")};
  margin: ${(props) => (props.applyGridMargin ? "auto 8px 7px 0" : "")};
  font-size: ${({ fontSize }) => fontSize};
  color: ${(props) =>
    props.disabled ? Color.neutral40 : props.inverted ? Color.white : props.color};
  background-color: ${(props) =>
    props.disabled ? Color.indigo10 : props.inverted ? props.color : props.backgroundColor};
  padding: ${({ padding }) => padding};
  border: ${(props) => props.border ?? "none"};
  text-align: center;
  transition: 0.15s background-color;
  cursor: ${({ cursorType }) => cursorType};

  &:hover {
    background: ${(props) => (!props.inverted ? props.hoverColor : "")};
  }
`;

const StyledDeletePillButton = styled.button<{
  inverted: boolean;
  deleteButtonHoverBackgroundColor: Color;
}>`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  margin: 1px 0px 0px 6px;
  border-radius: 2px;

  &:hover {
    background: ${(props) => !props.inverted && props.deleteButtonHoverBackgroundColor};
  }
`;
