import React from "react";
import styled from "styled-components";
import { useAppSelector } from "store";
import { useUserProfile } from "context/UserProfileContext";

import { StyledUserEditorBlock } from "./StyledUserEditorBlock";
import { CollapsibleSection } from "components/CollapsibleSection";
import { Icon, IconType } from "components/_icons/Icon";
import { UserProfileEditorField } from "components/UserProfileEditorField";

import { useResource } from "hooks/useResource";

import { Color } from "ts/enums/color";
import { RoleType } from "@explorance/mly-types";
import { Text } from "components/Text";

export const AdditionalDemographicsBlock = () => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);

  const { fields, setField } = useUserProfile();
  const { getResource } = useResource();

  return (
    <StyledUserEditorBlock>
      <CollapsibleSection
        titleLeftResource={"userProfile.additionalDemographics.title"}
        hideBoxShadow
        hideHeaderBorder
      >
        <StyledExpandedSection>
          <StyledAlertCard>
            <Icon
              type={IconType.info}
              style={{ display: "inline", marginRight: 8, position: "relative", top: 2 }}
              size={14}
            />
            <Text resource="userProfile.additionalDemographics.description" />
          </StyledAlertCard>
          <StyledFieldGrid>
            {fields.additionalDemographics &&
              Object.entries(fields.additionalDemographics).map(([k, v]) => (
                <UserProfileEditorField
                  key={k}
                  label={k}
                  value={v}
                  isDisabled={currentUser.roleType === RoleType.Viewer}
                  handleChangeFieldValue={(value) =>
                    setField("additionalDemographics", {
                      ...fields.additionalDemographics,
                      [k]: value,
                    })
                  }
                  placeholder={getResource("userProfile.additionalDemographics.field.placeholder")}
                />
              ))}
          </StyledFieldGrid>
        </StyledExpandedSection>
      </CollapsibleSection>
    </StyledUserEditorBlock>
  );
};

const StyledExpandedSection = styled.div`
  padding: 8px 8px 0 8px;
`;

const StyledFieldGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
`;

const StyledAlertCard = styled.div`
  background-color: ${Color.orange20};
  border: 1px solid ${Color.orange30};
  padding: 12px;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 16px;
`;
