import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useClickOutside from "hooks/useClickOutside";
import { DemographicFilterMethod } from "@explorance/mly-types";
import { Icon, IconType } from "components/_icons/Icon";
import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { StyledDropdownTransition } from "components/DropdownMenu/StyledDropdownTransition";
import { Text } from "components/Text";

type Props = {
  currentFilterType: DemographicFilterMethod.List | DemographicFilterMethod.Search;
  onSelect: (filterType: DemographicFilterMethod.List | DemographicFilterMethod.Search) => void;
};

export const FilterTypeDropdown = ({ currentFilterType, onSelect }: Props) => {
  const [showDisplayOptions, setShowDisplayOptions] = useState<boolean>(false);

  const displayTypeRef = useClickOutside(() => setShowDisplayOptions(false));

  useEffect(() => document.addEventListener("scroll", () => setShowDisplayOptions(false), true));

  const onClickButton = () => {
    setShowDisplayOptions((showOptions) => !showOptions);
  };

  const onSelectDisplayType =
    (displayType: DemographicFilterMethod.List | DemographicFilterMethod.Search) => () => {
      onSelect(displayType);
      setShowDisplayOptions(false);
    };

  return (
    <StyledFilterTypeDropdown>
      <div className="dropdown-container" ref={displayTypeRef}>
        <button onClick={onClickButton}>
          {currentFilterType === DemographicFilterMethod.Search ? (
            <Icon type={IconType.search} color={Color.gray40} size={16} />
          ) : (
            <Icon type={IconType.checklist} color={Color.gray40} size={12} />
          )}

          <Icon
            type={IconType.dropdownArrow}
            color={Color.gray40}
            size={8}
            style={{ display: "block", marginLeft: 4 }}
          />
        </button>
        <StyledDropdownMenu
          top={displayTypeRef?.current?.getBoundingClientRect().top}
          left={displayTypeRef?.current?.getBoundingClientRect().left}
          show={showDisplayOptions}
        >
          <ul>
            <li
              className={
                currentFilterType === DemographicFilterMethod.List ? "disabled" : undefined
              }
              onClick={onSelectDisplayType(DemographicFilterMethod.List)}
            >
              <StyledIconContainer>
                <Icon type={IconType.checklist} color={Color.gray40} size={12} />
              </StyledIconContainer>
              <div>
                <div className="display-type">
                  <Text resource="filter.demographics.checklist" />
                </div>
                <div className="description">
                  <Text resource="filter.demographics.checklist.description" />
                </div>
              </div>
            </li>
            <li
              className={
                currentFilterType === DemographicFilterMethod.Search ? "disabled" : undefined
              }
              onClick={onSelectDisplayType(DemographicFilterMethod.Search)}
            >
              <StyledIconContainer>
                <Icon type={IconType.search} color={Color.gray40} size={16} />
              </StyledIconContainer>
              <div>
                <div className="display-type">
                  <Text resource="filter.demographics.searchbox" />
                </div>
                <div className="description">
                  <Text resource="filter.demographics.searchbox.description" />
                </div>
              </div>
            </li>
          </ul>
        </StyledDropdownMenu>
      </div>
    </StyledFilterTypeDropdown>
  );
};

const StyledFilterTypeDropdown = styled.div`
  margin-left: auto;

  .dropdown-container {
    position: relative;
  }

  button {
    padding: 2px 4px;
    background: ${Color.sky15};
    border: 1px solid ${Color.blue20};
    border-radius: 2px;
    height: 24px;
    display: flex;
    align-items: center;

    svg {
      display: block;
      margin: 0 auto;
    }

    .arrow {
      margin-left: 2.5px;

      svg {
        height: 4px;

        path {
          fill: ${Color.gray40};
        }
      }
    }
  }
`;

const StyledDropdownMenu = styled(StyledDropdownTransition)<{
  top: number;
  left: number;
  show: boolean;
}>`
  position: fixed;
  transform: translate(-178px, 30px);
  width: 211px;
  height: 115px;
  border: 1px solid ${Color.sky50};
  border-radius: 2px;
  box-shadow: 0px 3px 6px #00000029;
  background: ${Color.white};
  font-size: 0.75em;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  z-index: ${ZIndexStackingContext.low};

  ul {
    list-style: none;
    padding: 5px 0;
    text-align: left;
    margin: 3px 0;

    li,
    .disabled {
      padding: 3px 10px 3px 10px;
      color: ${Color.gray40};
      display: flex;
      cursor: pointer;
    }

    .disabled {
      color: ${Color.gray40};
      cursor: default;

      &:hover {
        background-color: transparent;

        .display-type {
          color: ${Color.gray40};
        }
      }
    }

    li:hover {
      background-color: ${Color.neutral20};

      .display-type {
        color: ${Color.blue50};
      }
    }
  }

  .display-type {
    font-weight: bold;
  }
`;

const StyledIconContainer = styled.div`
  width: 30px;
  padding-top: 3px;
`;
