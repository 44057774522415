import React from "react";
import styled, { css } from "styled-components";

import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { PermissionLevel } from "@explorance/mly-types";
import { SharingView } from "ts/enums/sharing";

import { useSharingContext } from "context/SharingContext";
import { Text } from "components/Text";

const editBtnResourceKeys = {
  [SharingView.GroupsSharedWith]: "button.editGroup",
  [SharingView.UsersSharedWith]: "button.edit",
};

type Props = {
  permissionLevel: PermissionLevel;
  editButtonStyle?: Record<string, string>;
  disabled?: boolean;
  handleClickEdit?: () => void;
  handleClickDelete?: () => void;
};

export const PermissionLevelCell = ({
  permissionLevel,
  editButtonStyle,
  disabled = false,
  handleClickEdit,
  handleClickDelete,
}: Props) => {
  const { sharingState } = useSharingContext();

  return (
    <StyledPermissionLevelCell isButtonDisabled={disabled}>
      <Text resource={`sharing.table.permissionLevel.[${permissionLevel}]`} />
      <div>
        <Button
          variant={ButtonVariant.light}
          size={ButtonSize.sm}
          style={{ color: Color.red50, marginRight: 5 }}
          onClick={handleClickDelete}
        >
          <Icon type={IconType.xCircle} size={14} style={{ marginRight: 5 }} />
          <Text resource="button.unshare" />
        </Button>
        <Button
          variant={ButtonVariant.light}
          size={ButtonSize.md}
          style={editButtonStyle}
          onClick={disabled ? undefined : handleClickEdit}
        >
          <Icon type={IconType.gear} size={14} style={{ marginRight: 5 }} color={Color.gray30} />
          <Text resource={editBtnResourceKeys[sharingState.view]} />
        </Button>
      </div>
    </StyledPermissionLevelCell>
  );
};

const StyledPermissionLevelCell = styled.div<{ isButtonDisabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    ${({ isButtonDisabled }) =>
      isButtonDisabled &&
      css`
        &:hover {
          background-color: ${Color.sky20};
          cursor: default;
        }
      `}
  }
`;
