import React, { useState } from "react";
import styled from "styled-components";

import { precisionSelectionRange } from "assets/constants/precisionSelection";
import { Color } from "ts/enums/color";

import { DiscreteSlider } from "components/DiscreteSlider";
import { Icon, IconType } from "components/_icons/Icon";
import useClickOutside from "hooks/useClickOutside";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { Text } from "components/Text";

type Props = {
  selectedPrecision: number;
  handleSelectPrecision: (number) => void;
};

export const PrecisionSelection = ({ selectedPrecision, handleSelectPrecision }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const modelSelectionRef = useClickOutside(() => {
    setIsMenuOpen(false);
  });
  const precisionText = precisionSelectionRange[selectedPrecision - 5]?.label;

  return (
    <StyledPrecisionSelection ref={modelSelectionRef}>
      <StyledSelectionButton onClick={() => setIsMenuOpen((isOpen) => !isOpen)}>
        <span>
          <Text resource={`quickAnalysis.precision.option.${precisionText}`} />
        </span>
        <Icon type={IconType.range} color={Color.gray50} size={16} />
      </StyledSelectionButton>
      {isMenuOpen && (
        <StyledPrecisionMenu>
          <p>
            <Text resource="quickAnalysis.precision.description" />
          </p>

          <DiscreteSlider
            range={precisionSelectionRange.map((x) => x.value)}
            selectedValue={selectedPrecision}
            onChange={(value) => handleSelectPrecision(value)}
          />
        </StyledPrecisionMenu>
      )}
    </StyledPrecisionSelection>
  );
};

const StyledSelectionButton = styled.button`
  background-color: ${Color.white};
  border: 1px solid ${Color.sky50};
  padding: 7px 10px 7px 8px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  height: 34px;
  font-size: 15px;
  width: 180px;

  svg {
    margin-left: auto;
    margin-top: 1px;
  }
`;

const StyledPrecisionSelection = styled.div`
  position: relative;
`;

const StyledPrecisionMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background-color: ${Color.white};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  position: absolute;
  right: -8px;
  margin-top: 2px;
  width: 300px;
  z-index: ${ZIndexStackingContext.low};

  p {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 14px;
    color: ${Color.gray30};
    b {
      color: ${Color.gray50};
    }
  }
`;
