import { routes } from "routes";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

class ErrorHandlerBase extends React.Component<RouteComponentProps> {
  componentDidCatch() {
    this.props.history.push(routes.error);
  }

  render() {
    return (this.props as any).children;
  }
}

export const ErrorHandler = withRouter(ErrorHandlerBase);
