import React from "react";
import styled from "styled-components";
import { flatten, isEmpty } from "lodash";

import { CategorizedTopicPill } from "components/_pills/CategorizedTopicPill";

import { CategorizedTopic } from "ts/enums/categorizedTopic";
import { RecommendationType, SentimentType } from "@explorance/mly-types";

type Props = {
  topics: Record<SentimentType & RecommendationType, string[]>;
  pillStyling?: Record<string, string>;
  isCustomModel?: boolean;
};

export const CategorizedTopicPillList = ({ topics, pillStyling, isCustomModel }: Props) => {
  if (isEmpty(topics)) return null;

  const topicPillData = flatten(
    Object.keys(topics).map((key) =>
      topics[key].map((topic) => {
        return { label: topic, type: key as CategorizedTopic };
      })
    )
  );

  return (
    <StyledPillListContainer>
      {topicPillData.map((props, index) => (
        <CategorizedTopicPill
          key={`${props.label}-${index}`}
          style={pillStyling}
          isCustomModel={isCustomModel}
          {...props}
        />
      ))}
    </StyledPillListContainer>
  );
};

const StyledPillListContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 2px;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;

  @media (max-width: 1365px) {
    max-width: 920px;
  }
`;
