import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";

import { RangeSlider } from "components/RangeSlider";
import { ApplyFiltersButton } from "components/_buttons/ApplyFilterButton";

import { setSelectedAlertRange } from "context/AnalysisContext/actions";
import { AnalysisContext } from "context/AnalysisContext";

import { Range } from "ts/range";
import { Color } from "ts/enums/color";
import { DEFAULT_ALERT_RANGE } from "assets/constants/defaultAlertRange";
import { Text } from "components/Text";

type Props = {
  resetLocalState: boolean;
  onSubmit: () => void;
};

export const AlertRangeMenu = ({ resetLocalState, onSubmit }: Props) => {
  const [state, dispatch] = useContext(AnalysisContext);

  const [localAlertRange, setLocalAlertRange] = useState<Range>();

  useEffect(() => {
    state.selectedAlertRange
      ? setLocalAlertRange(state.selectedAlertRange)
      : setLocalAlertRange(DEFAULT_ALERT_RANGE);
  }, [state.selectedAlertRange, resetLocalState]);

  const applyAlertRangeFilter = () => {
    dispatch(setSelectedAlertRange(localAlertRange));
    onSubmit();
  };

  return (
    <StyledAlertRangeMenu>
      <StyledMenuInstructions>
        <Text resource="filter.alertRangeMenu.title" />
      </StyledMenuInstructions>
      {localAlertRange && (
        <RangeSlider
          currentMin={localAlertRange?.min || DEFAULT_ALERT_RANGE.min}
          currentMax={localAlertRange?.max || DEFAULT_ALERT_RANGE.max}
          onChange={setLocalAlertRange}
        />
      )}
      <ApplyFiltersButton handleClick={applyAlertRangeFilter} />
    </StyledAlertRangeMenu>
  );
};

const StyledAlertRangeMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 398px;
  padding: 16px;
  row-gap: 20px;
`;

const StyledMenuInstructions = styled.div`
  font-weight: 400;
  font-size: 15px;
  color: ${Color.gray50};
`;
