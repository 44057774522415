import React from "react";
import styled from "styled-components";

import { Text } from "components/Text";
import { Icon, IconType } from "components/_icons/Icon";
import { openModal } from "store/widgets/widgetGroupsSlice";

import { useAppDispatch } from "store";

import { Color } from "ts/enums/color";
import { WidgetGroupModalType } from "ts/enums/widgetGroupModalType";

export const CreateNewGroupButton = () => {
  const dispatch = useAppDispatch();

  return (
    <StyledCreateNewGroupButton onClick={() => dispatch(openModal(WidgetGroupModalType.newGroup))}>
      <Icon
        type={IconType.plusCircle}
        size={14}
        style={{ marginRight: "5px" }}
        color={Color.blue50}
      />
      <Text resource="button.widgets.createGroup" />
    </StyledCreateNewGroupButton>
  );
};

const StyledCreateNewGroupButton = styled.button`
  height: 50px;
  width: 100%;
  color: ${Color.blue50};
  background-color: ${Color.sky15};
  border-radius: 5px;
  border: 1px solid ${Color.sky60};
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;
