import { mutateTree, TreeData, TreeItem } from "@atlaskit/tree";
import { cloneDeep, sortBy } from "lodash";
import { recursiveFindAllParents, recursiveFindAllTopicNodesWithChildren } from "utils/topics";
import { SetStateAction, Dispatch } from "react";
import { AnalysisModel } from "ts/filters/analysisModel";
import { ApiTopicNode } from "ts/topic";
import { CustomModelTopicUpdateMethod } from "ts/enums/customModelTopicUpdateMethod";
import { TopicFilter } from "ts/filters/topicFilter";
import { hashStringIntoNumber } from "utils/hashStringIntoNumber";
import { GetResourceFunction } from "hooks/useResource";
import { getRandomNumber } from "utils/getRandomNumber";

export const getTreeId = (topic) => {
  const prefix = topic.parentId; // for root topics, the id exists but aren't fixed (change with each model update)
  const suffix = topic.id === -1 ? "G" : topic.id;
  return topic.fullPath
    ? prefix + "-" + suffix + "-" + hashStringIntoNumber(topic.fullPath)
    : prefix + "-" + suffix;
};

const findParent = (arr, currentTopic) => {
  const parentTopicFullPath = currentTopic.fullPath
    .split(">")
    .slice(0, currentTopic.fullPath.split(">").length - 1)
    .join(">");
  return arr.find((x) => x.id === currentTopic.parentId && x.fullPath === parentTopicFullPath);
};

// Construct tree data structure for @atlaskit/tree
export const mapApiTopicsTreeForDragNDropTree = (
  topics: TopicFilter[],
  getResource?: GetResourceFunction // Pass to translate initial template topics
): TreeData => {
  const zeroLevelRootItem = {
    id: "root",
    parentId: null,
    hasChildren: true,
    isExpanded: true,
    isChildrenLoading: false,
    data: {
      title: "root",
    },
    children: topics.map(getTreeId),
  };

  const finalTree = {
    rootId: "root",
    items: { root: zeroLevelRootItem },
  };

  const allTopicsWithChildren = recursiveFindAllTopicNodesWithChildren(topics);
  allTopicsWithChildren.forEach((t) => {
    const id = getTreeId(t);
    const foundParent = findParent(allTopicsWithChildren, t);

    finalTree.items[id] = {
      id,
      parentId: t.parentId && foundParent ? getTreeId(foundParent) : "root",
      children: t.topics?.map((topic) => getTreeId(topic)) || [],
      hasChildren: t?.topics ? t.topics.length !== 0 : false,
      isExpanded: false,
      isChildrenLoading: false,
      data: {
        title: getResource ? getResource(`ML.topic.[${t.name}]`) : t.name,
        mappedTopics:
          t.mappings ||
          (t.topics.length === 0 ? [{ id: t.id, parentId: t.parentId, fullPath: t.fullPath }] : []),
      },
    };
  });

  return finalTree;
};

// Delete item from tree feature
const recursivelyRemoveChildren = (tree: TreeData, item, mappedItems = []) => {
  if (!item) return;
  item.children.forEach((childId) => {
    if (tree.items[childId].data?.mappedTopics?.length > 0) {
      tree.items[childId].data?.mappedTopics.forEach((mt) => mappedItems.push(mt));
    }
    recursivelyRemoveChildren(tree, tree.items[childId], mappedItems);
    delete tree.items[childId];
  });
  return mappedItems;
};

export const deleteItem = (
  item,
  tree: TreeData,
  setTree: Dispatch<SetStateAction<TreeData>>,
  baseModel: AnalysisModel,
  updateBaseModelTopics: (
    baseModel: AnalysisModel,
    selectedTopics: ApiTopicNode[],
    type: CustomModelTopicUpdateMethod
  ) => void
) => {
  const treeCopy = cloneDeep(tree);
  // Delete all children items (recursively) from the flat tree
  const childrenMappedItems = recursivelyRemoveChildren(treeCopy, item);
  // Update base model with any mapped topics for item
  if (item.data?.mappedTopics?.length > 0 || childrenMappedItems.length > 0) {
    const mappedTopicsArray = (
      item.data?.mappedTopics?.length > 0 ? item.data.mappedTopics : []
    ).concat(childrenMappedItems.length > 0 ? childrenMappedItems : []);
    // find all the parents of the mapped topics
    const parents = [];
    mappedTopicsArray.forEach((mt) =>
      recursiveFindAllParents(mt, baseModel.topics).forEach((p) => {
        if (
          !parents.some(
            (x) => x.id === p.id && x.parentId === p.parentId && x.fullPath === p.fullPath
          )
        )
          parents.push(p);
      })
    );
    updateBaseModelTopics(
      baseModel,
      [...mappedTopicsArray, ...parents],
      CustomModelTopicUpdateMethod.delete
    );
  }
  // Delete the item itself from the flat tree
  delete treeCopy.items[item.id];
  // Delete the item id from the parent
  const treeCopyWithoutItemIdInParent = mutateTree(treeCopy, item.parentId, {
    children: treeCopy.items[item.parentId].children.filter((childId) => childId !== item.id),
  });
  setTree(treeCopyWithoutItemIdInParent);
};

export const deleteMappedTopicFromItem = (
  item,
  tree: TreeData,
  deletedTopic: ApiTopicNode,
  setTree: Dispatch<SetStateAction<TreeData>>,
  baseModel: AnalysisModel,
  updateBaseModelTopics: (
    baseModel: AnalysisModel,
    selectedTopics: ApiTopicNode[],
    type: CustomModelTopicUpdateMethod
  ) => void
) => {
  const itemCopy = cloneDeep(item);
  const treeCopy = cloneDeep(tree);
  itemCopy.data.mappedTopics = itemCopy.data.mappedTopics.filter(
    (mt) => mt.fullPath !== deletedTopic.fullPath
  );
  treeCopy.items[item.id] = itemCopy;
  const allParents = recursiveFindAllParents(deletedTopic, baseModel.topics);
  updateBaseModelTopics(
    baseModel,
    [deletedTopic, ...allParents],
    CustomModelTopicUpdateMethod.delete
  );
  const updatedTreeCopy = mutateTree(treeCopy, item.id, itemCopy);
  setTree(updatedTreeCopy);
};

// Create child feature
export const createChild = (item, tree, setTree) => {
  const newItemId = getTreeId({ id: getRandomNumber(), parentId: item.id });
  const newItem = {
    id: newItemId,
    parentId: item.id,
    hasChildren: false,
    isExpanded: false,
    isChildrenLoading: false,
    data: {
      title: "",
    },
    children: [],
  };
  const treeCopy = cloneDeep(tree);
  treeCopy.items[newItemId] = newItem;
  const treeCopyWithChildIdInItem = mutateTree(treeCopy, item.id, {
    children: treeCopy.items[item.id].children.concat(newItemId),
    isExpanded: true,
    hasChildren: true,
    isChildrenLoading: false,
  });

  setTree(treeCopyWithChildIdInItem);
  return newItemId;
};

// Merge topic into tree node
export const mergeTopicsIntoItem = (
  item: TreeItem,
  tree: TreeData,
  mergedTopics: ApiTopicNode[],
  setTree: Dispatch<SetStateAction<TreeData>>
) => {
  const itemCopy = cloneDeep(item);
  const treeCopy = cloneDeep(tree);
  itemCopy.data.mappedTopics = (
    itemCopy.data?.mappedTopics?.length > 0 ? item.data.mappedTopics : []
  ).concat(mergedTopics);
  treeCopy.items[item.id] = itemCopy;
  const updatedTreeCopy = mutateTree(treeCopy, item.id, itemCopy);
  setTree(updatedTreeCopy);
  return item.id;
};

// Insert topics
export const insertTopicsIntoTree = (
  tree: TreeData,
  setTree: Dispatch<SetStateAction<TreeData>>,
  baseModel: AnalysisModel,
  selectedTopic: TreeItem,
  selectedTopics: ApiTopicNode[],
  getResource: GetResourceFunction
) => {
  let treeCopy = cloneDeep(tree);
  const allTopicsWithChildren = sortBy(
    recursiveFindAllTopicNodesWithChildren(baseModel),
    (t) => t.id
  );

  selectedTopics.forEach((st) => {
    let newItemId = getTreeId({
      id: st.id,
      parentId: st.parentId,
      fullPath: st.fullPath,
    });
    // this prevents the issue of unmapping an existing topic and then inserting the same topic as a new item for models with template
    const isDuplicateId = Object.keys(treeCopy.items).find((i) => i === newItemId);
    if (isDuplicateId) newItemId = newItemId + "-" + getRandomNumber();

    const foundParent = findParent(allTopicsWithChildren, st);
    const foundSelectedTopicWithChildren = allTopicsWithChildren.find(
      (topic) => topic.id === st.id && topic.fullPath === st.fullPath
    );

    // need to check for full path to distinguish between SLC knowledge topics
    const newItem = {
      id: newItemId,
      parentId: findParent(selectedTopics, st) ? getTreeId(foundParent) : selectedTopic.id,
      hasChildren: foundSelectedTopicWithChildren?.topics
        ? foundSelectedTopicWithChildren.topics.length !== 0
        : false,
      isExpanded: false,
      isChildrenLoading: false,
      data: {
        title: getResource(`ML.topic.[${st.fullPath.slice(st.fullPath.lastIndexOf(">") + 1)}]`),
        ...(foundSelectedTopicWithChildren.topics.length === 0 && {
          mappedTopics: [st],
        }),
      },
      children: foundSelectedTopicWithChildren.topics?.map((topic) => getTreeId(topic)) || [],
    };
    treeCopy.items[newItemId] = newItem;

    if (!selectedTopics.find((t) => t.id === st.parentId)) {
      treeCopy = mutateTree(treeCopy, selectedTopic.id, {
        children: treeCopy.items[selectedTopic.id].children.concat(newItemId),
        isExpanded: true,
        hasChildren: true,
        isChildrenLoading: false,
      });
    }
  });

  setTree(treeCopy);
};

export const getCurrentCount = (selectedTopics: ApiTopicNode[]): number => {
  const copy = [...selectedTopics];
  const parents = [];
  copy.forEach((cst) => {
    if (copy.some((c) => c.parentId === cst.id)) parents.push(cst);
  });
  return copy.filter((i) => !parents.find((x) => x.id === i.id && x.parentId === i.parentId))
    .length;
};

// Note the props are not typed as they are a combination of Atlaskit types + added info such as parentId, etc.
export const recursiveFindAllTreeItemParentIds = (treeItem, tree): string[] => {
  const parentIds = [];
  let parentId = treeItem.parentId;

  while (parentId !== "root") {
    parentIds.push(parentId);
    parentId = tree.items[parentId].parentId;
  }
  return parentIds;
};
