import styled from "styled-components";
import React, { Fragment } from "react";

import { WidgetWidth } from "@explorance/mly-types";
import { Color } from "ts/enums/color";

type Props = {
  topics: string[];
  widgetWidth?: WidgetWidth;
};

export const TopicPathLabel = ({ topics, widgetWidth }: Props) => {
  const styledChildTopics = topics.map((topic, index) => (
    <Fragment key={topic}>
      <StyledTopicName widgetWidth={widgetWidth}>
        {/* add space around slashes to avoid splitting long words into multiple lines */}
        {topic.replaceAll("/", " / ")}
      </StyledTopicName>
      {index !== topics.length - 1 && <StyledTopicSeparator>&#x25B6;</StyledTopicSeparator>}
    </Fragment>
  ));

  return <>{styledChildTopics}</>;
};

const StyledTopicName = styled.span<{ widgetWidth: WidgetWidth }>`
  max-width: ${({ widgetWidth }) => widgetWidth === WidgetWidth.OneThird && "230px"};
`;

const StyledTopicSeparator = styled.span`
  font-size: 8px;
  color: ${Color.indigo50};
  padding: 0 4px;
  position: relative;
  top: -3px;
`;
