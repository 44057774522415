import styled from "styled-components";
import { Color } from "ts/enums/color";

export const StyledFooter = styled.div`
  position: absolute;
  width: 100%;
  height: 92px;
  bottom: 0;
  padding: 25px 30px;
  display: flex;
  align-items: center;
  background-color: ${Color.white};

  button {
    font-size: 0.875em;
    font-weight: bold;
    padding: 12px 20px;
    margin-left: 12px;
  }
`;
