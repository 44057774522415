import { Icon, IconType } from "components/_icons/Icon";
import React from "react";
import styled from "styled-components";
import { Color } from "ts/enums/color";
import { isArray } from "lodash";

type Props = {
  message: string | string[];
  index: number;
};

export const ErrorMessage = ({ message, index }: Props) => {
  const ssoMappingErrors = isArray(message) && message.find((text) => !!text);
  const getErrorText = (rawMessage: string | string[]) => {
    return isArray(rawMessage) ? ssoMappingErrors : rawMessage;
  };
  if (isArray(message) && !ssoMappingErrors) return null; // if it's the ssoMappings error array and there are no errors in it, don't show anything

  return (
    <StyledErrorMessage key={index}>
      <Icon
        type={IconType.alert}
        color={Color.red30}
        style={{ marginRight: 5, minWidth: 16 }}
        size={16}
      />
      {getErrorText(message)}
    </StyledErrorMessage>
  );
};

const StyledErrorMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${Color.red30};

  svg {
    margin-right: 5px;
    height: 16px;
    width: 16px;
  }
`;
