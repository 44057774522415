import { Icon, IconType } from "components/_icons/Icon";
import React from "react";
import styled from "styled-components";
import { Color } from "ts/enums/color";
import { RecommendationType } from "@explorance/mly-types";

type Props = {
  type: RecommendationType;
  color?: Color;
};

export const RecommendationIcon = ({ type, color }: Props) => {
  const icons = {
    [RecommendationType.generalRecommendation]: {
      icon: <Icon type={IconType.recommendation} size={12} color={color} />,
      bgColor: undefined,
    },
    [RecommendationType.recommendation]: {
      icon: <Icon type={IconType.recommendation} size={12} color={color} />,
      bgColor: undefined,
    },
    [RecommendationType.doMore]: {
      icon: <Icon type={IconType.doMore} size={6} />,
      bgColor: Color.sky20,
    },
    [RecommendationType.doLess]: {
      icon: <Icon type={IconType.doLess} size={6} />,
      bgColor: Color.indigo20,
    },
    [RecommendationType.start]: {
      icon: <Icon type={IconType.start} size={6} />,
      bgColor: Color.green10,
    },
    [RecommendationType.stop]: {
      icon: <Icon type={IconType.stop} size={4} />,
      bgColor: Color.red10,
    },
    [RecommendationType.continue]: {
      icon: <Icon type={IconType.continue} size={6} />,
      bgColor: Color.sky30,
    },
    [RecommendationType.change]: {
      icon: <Icon type={IconType.change} size={6} />,
      bgColor: Color.orange10,
    },
  };
  if (!icons[type]) return null;

  return (
    <StyledRecommendationIcon bgColor={icons[type].bgColor}>
      {icons[type].icon}
    </StyledRecommendationIcon>
  );
};

const StyledRecommendationIcon = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  background-color: ${({ bgColor }) => bgColor};
`;
