import { useEffect, useRef } from "react";

type Options = {
  stopPropagation?: boolean;
  preventDefault?: boolean;
};

const useClickOutside = (func: () => void, { stopPropagation, preventDefault }: Options = {}) => {
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      if (stopPropagation) event.stopPropagation();
      if (preventDefault) event.preventDefault();
      func();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  });

  return ref;
};

export default useClickOutside;
