import React, { useState } from "react";
import styled from "styled-components";
import ReactModal from "react-modal";
import { useAppSelector } from "store";

import { Modal } from "../Modal";
import mlyLogo from "assets/images/mly-logo.svg";
import onboardingRobot from "assets/images/onboarding-robot.svg";
import fileRobot from "assets/images/file-robot.svg";
import basicRobot from "assets/images/basic-robot.svg";
import mlyAnalysis from "assets/images/mly-analysis.svg";
import { Icon, IconType } from "components/_icons/Icon";
import { Button } from "components/_buttons/Button";

import { Color } from "ts/enums/color";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { RoleType } from "@explorance/mly-types";
import { Text } from "components/Text";

const MODAL_CONTENT_WIDTH = 415;

type Slide = {
  index: number;
  title: string;
  description: string;
  imgSrc: string;
  imgAlt: string;
};

const viewerWelcomeSlides: Slide[] = [
  {
    index: 0,
    title: "modal.welcome.viewer.slide1.title",
    description: "modal.welcome.viewer.slide1.description",
    imgSrc: mlyLogo,
    imgAlt: "mly-logo",
  },
  {
    index: 1,
    title: "modal.welcome.viewer.slide2.title",
    description: "modal.welcome.viewer.slide2.description",
    imgSrc: onboardingRobot,
    imgAlt: "onboarding-robot",
  },
];

const nonViewerWelcomeSlides: Slide[] = [
  {
    index: 0,
    title: "modal.welcome.viewer.slide1.title",
    description: "modal.welcome.viewer.slide1.description",
    imgSrc: mlyLogo,
    imgAlt: "mly-logo",
  },
  {
    index: 1,
    title: "modal.welcome.nonViewer.slide2.title",
    description: "modal.welcome.nonViewer.slide2.description",
    imgSrc: fileRobot,
    imgAlt: "file-robot",
  },
  {
    index: 2,
    title: "modal.welcome.nonViewer.slide3.title",
    description: "modal.welcome.nonViewer.slide3.description",
    imgSrc: basicRobot,
    imgAlt: "basic-robot",
  },
  {
    index: 3,
    title: "modal.welcome.nonViewer.slide4.title",
    description: "modal.welcome.nonViewer.slide4.description",
    imgSrc: mlyAnalysis,
    imgAlt: "robot-analysis",
  },
];

type Props = {
  isOpen: boolean;
  completeOnboarding: () => Promise<void>;
};

export const WelcomeModal = ({ isOpen, completeOnboarding }: Props) => {
  // hooks
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [translate3dXValue, setTranslate3dXValue] = useState<string>("0px");

  // redux
  const { currentUser } = useAppSelector((state) => state.auth);

  // variables
  const isViewer = currentUser.roleType === RoleType.Viewer;
  const slides = isViewer ? viewerWelcomeSlides : nonViewerWelcomeSlides;
  const isLastSlide = currentStep === slides.length - 1;
  const modalStyle: ReactModal.Styles = {
    content: {
      borderRadius: "10px",
      boxShadow: "0px 4px 16px #B6BACB29",
      backgroundColor: Color.white,
      width: "500px",
      height: "418px",
      border: `1px solid ${Color.neutral30}`,
      left: "0px",
      right: "0px",
      margin: "0 auto",
      padding: "36px",
      top: "25%",
    },
  };

  // functions
  const handleClickDot = (index: number) => {
    setCurrentStep(index);
    setTranslate3dXValue(index * MODAL_CONTENT_WIDTH * -1 + "px");
  };

  const handleClickNext = () => {
    const newStep = currentStep + 1;
    setCurrentStep(newStep);
    setTranslate3dXValue(newStep * MODAL_CONTENT_WIDTH * -1 + "px");
  };

  return (
    <Modal isOpen={isOpen} styles={modalStyle} closeOnOverlayClick={false}>
      <StyledModalContentContainer>
        <StyledImageContainer
          className="fade-in"
          translate3dXValue={translate3dXValue}
          width={isViewer ? "1045px" : "1690px"}
        >
          {slides.map(({ imgSrc, imgAlt, index }) => (
            <div key={index}>
              <img src={imgSrc} alt={imgAlt} />
            </div>
          ))}
        </StyledImageContainer>
        <div>
          <StyledSlideTextContainer className="fade-in">
            <h2>
              <Text resource={slides[currentStep].title} />
            </h2>
            <p>
              <Text resource={slides[currentStep].description} />
            </p>
          </StyledSlideTextContainer>
          <StyledDotContainer>
            {slides.map(({ index }) => (
              <span
                key={index}
                className={index === currentStep ? "active" : undefined}
                onClick={() => handleClickDot(index)}
              />
            ))}
          </StyledDotContainer>
        </div>
        <StyledButtonContainer justifyContent={isLastSlide ? "center" : "space-between"}>
          <Button
            variant={ButtonVariant.secondary}
            size={ButtonSize.sm}
            onClick={completeOnboarding}
          >
            <StyledButtonContentContainer>
              <span>
                <Text resource="button.bringMeToHomepage" />
              </span>
              <Icon type={IconType.chevronRight} size={14} />
            </StyledButtonContentContainer>
          </Button>
          {!isLastSlide && (
            <Button variant={ButtonVariant.primary} size={ButtonSize.sm} onClick={handleClickNext}>
              <StyledButtonContentContainer>
                <span>
                  <Text resource="button.next" />
                </span>
                <Icon type={IconType.chevronRight} size={14} />
              </StyledButtonContentContainer>
            </Button>
          )}
        </StyledButtonContainer>
      </StyledModalContentContainer>
    </Modal>
  );
};

const StyledModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: ${MODAL_CONTENT_WIDTH}px;
  overflow: hidden;
`;

const StyledImageContainer = styled.div<{ translate3dXValue: string; width: string }>`
  display: flex;
  width: ${({ width }) => width};
  height: 190px;
  transform: ${({ translate3dXValue }) => `translate3d(${translate3dXValue}, 0px, 0)`};
  transition: transform 0.2s linear;
  will-change: transform;

  div {
    float: left;
    width: 40%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledSlideTextContainer = styled.div`
  margin: 0px auto;
  width: 368px;

  h2,
  p {
    text-align: center;
    margin: 0px;
  }

  h2 {
    font-size: 16px;
    margin-bottom: 8px;
  }

  p {
    font-size: 14px;
    height: 40px;
  }
`;

const StyledDotContainer = styled.div`
  text-align: center;

  span {
    display: inline-block;
    background-color: ${Color.blue20};
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 0 8px;
  }

  .active {
    background-color: ${Color.gray50};
  }
`;

const StyledButtonContainer = styled.div<{ justifyContent: string }>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  margin-top: 4px;
`;

const StyledButtonContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
