import { Text } from "components/Text";
import React from "react";
import styled from "styled-components";
import { Color } from "ts/enums/color";

type SectionTitleProps = {
  mb?: number;
  mt?: number;
  titleKey?: string;
  color?: Color;
  disabled?: boolean;
};

export const SectionTitle = ({ mb, mt, titleKey, color, disabled }: SectionTitleProps) => (
  <StyledSectionTitle mb={mb} mt={mt} color={color} disabled={disabled}>
    <Text resource={titleKey} />
  </StyledSectionTitle>
);

const StyledSectionTitle = styled.h2<{
  mt?: number;
  mb?: number;
  color?: Color;
  disabled?: boolean;
}>`
  font-size: 12px;
  color: ${({ color }) => color ?? Color.gray20};
  font-weight: bold;
  margin-bottom: ${({ mb }) => mb ?? 10}px;
  margin-top: ${({ mt }) => mt ?? 16}px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
