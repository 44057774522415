import { WidgetWidth } from "@explorance/mly-types";
import { Button } from "components/_buttons/Button";
import React from "react";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Widget } from "ts/widget";

type Props = {
  widget: Widget;
  onClick: (width: WidgetWidth) => void;
  widthOption: WidgetWidth;
  label: string;
  disabled?: boolean;
};

export const WidgetSizeButton = ({ widget, onClick, widthOption, label, disabled }: Props) => (
  <Button
    variant={
      widget.configuration.size?.width === widthOption
        ? ButtonVariant.primary
        : ButtonVariant.outline
    }
    size={ButtonSize.sm}
    style={{ marginRight: 8, marginBottom: 8 }}
    onClick={() => onClick(widthOption)}
    disabled={disabled}
  >
    {label}
  </Button>
);
