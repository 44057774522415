import { TaskStatus } from "@explorance/mly-types";
import { IconType } from "components/_icons/Icon";
import { Color } from "ts/enums/color";

type NotificationIcon = {
  color: Color.green50 | Color.red50 | null;
  type: IconType.checkCircle | IconType.xCircle | null;
};

export const getNotificationIcon = (status: TaskStatus): NotificationIcon => {
  if (status == TaskStatus.Success) {
    return {
      color: Color.green50,
      type: IconType.checkCircle,
    };
  }
  if (status == TaskStatus.Failure) {
    return {
      color: Color.red50,
      type: IconType.xCircle,
    };
  }
  return {
    color: null,
    type: null,
  };
};
