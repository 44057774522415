import React, { useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { useAppSelector } from "store";
import { AnalysisContext } from "context/AnalysisContext";

import { routes } from "routes";

import { BarChartLegend } from "pages/analysis/[id]/overview/_layouts/charts/_layouts/BarChartLegend";
import { Tooltip } from "components/Tooltip";

import { useResource } from "hooks/useResource";
import { setSelectedTopicNodes } from "context/AnalysisContext/actions";
import { recursiveFindAllChildTopicNodes } from "utils/topics";
import { isModelVirtual } from "utils/isModelVirtual";
import { useQueryParams } from "hooks/useQueryParams";
import { getUrlQueryString } from "utils/getUrlQueryString";
import { isAnyAdmin } from "utils/isAnyAdmin";

import { PreviewUser } from "ts/analysis";
import { Color } from "ts/enums/color";
import { sentimentStyles } from "assets/constants/sentimentStyles";
import { Topic } from "ts/topic";
import {
  RecommendationType,
  SentimentType,
  SharingCommentExplorerAccess,
  TopicsListView,
} from "@explorance/mly-types";

type Props = {
  topic: Topic;
  attributeType: TopicsListView;
  maxWidth: number;
  sharedUser: PreviewUser;
};

export const BarChart = ({ topic, attributeType, maxWidth, sharedUser }: Props) => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);

  // hooks
  const [state, dispatch] = useContext(AnalysisContext);

  const { getResource } = useResource();
  const history = useHistory();
  const { sharingPreview, expandedRowId, sharedWithPage, sharingId, step } = useQueryParams();

  // variables
  const counts: Array<any> = Object.entries(topic)
    .filter((x) => {
      if (attributeType === TopicsListView.Sentiments) {
        return Object.values(SentimentType).includes(x[0] as SentimentType);
      }
      return Object.values(RecommendationType).includes(x[0] as RecommendationType);
    })
    .filter(Boolean);

  const resourceLabel = attributeType.slice().substring(0, attributeType.length - 1) + "Pill";

  const topicCount =
    attributeType === TopicsListView.Sentiments ? topic.insights : topic.recommendations;

  const userCanAccessComments = sharingPreview
    ? sharedUser?.commentExplorerAccess === SharingCommentExplorerAccess.Shared
    : isAnyAdmin(currentUser.roleType) ||
      !state.analysisDetails?.sharing ||
      state.analysisDetails?.sharing?.commentExplorerAccess === SharingCommentExplorerAccess.Shared;

  const userCanClickLegend = topicCount !== 0 && userCanAccessComments;

  // functions
  const handleClick = () => {
    const commentsView =
      attributeType === TopicsListView.Sentiments
        ? "categorizedComments"
        : "categorizedRecommendations";
    applyTopicFilters();
    history.push(
      routes.commentsPage(
        state.analysisDetails.id,
        getUrlQueryString({
          view: commentsView,
          sharingPreview,
          expandedRowId,
          sharedWithPage,
          sharingId,
          step,
        })
      )
    );
  };

  const applyTopicFilters = () => {
    if (!state.selectedModelId) {
      const treeData = state.analysisModels.filter((am) => !am.isVirtual)[0].topics;
      const selectedTopic = treeData.filter((td) => td.fullPath === topic.fullPath);
      const currentNodeLeaves = recursiveFindAllChildTopicNodes(selectedTopic);
      dispatch(setSelectedTopicNodes(currentNodeLeaves));
      return;
    }
    const treeData = state.analysisModels.filter((am) => am.modelId === state.selectedModelId)[0]
      .topics;
    const selectedTopic = treeData.filter((td) => td.id === topic.id);
    const currentNodeLeaves = recursiveFindAllChildTopicNodes(selectedTopic);
    dispatch(setSelectedTopicNodes(currentNodeLeaves));
  };

  return (
    <div>
      <StyledChartContainer maxWidth={maxWidth}>
        {counts.map(
          (td) =>
            td[1] > 0 && (
              <StyledBarContainer key={td[0]}>
                <StyledBar
                  height={Math.round((td[1] / topicCount) * 100)}
                  color={sentimentStyles[td[0]].color}
                  data-tip
                  data-for={`${td[1]}-${td[0]}`}
                />
                <Tooltip
                  tooltipId={`${td[1]}-${td[0]}`}
                  content={td[1] + " - " + getResource(`${resourceLabel}.${td[0]}`)}
                  fontSize="0.785em"
                />
              </StyledBarContainer>
            )
        )}
      </StyledChartContainer>
      {!state.loadingAnalysisModels && (
        <StyledLegendContainer onClick={userCanClickLegend ? handleClick : undefined}>
          <BarChartLegend
            count={topicCount}
            label={
              isModelVirtual(state.selectedModelId, state.analysisModels)
                ? topic.name
                : getResource(`ML.topic.[${topic.name}]`)
            }
            cursorStyle={userCanClickLegend ? "pointer" : "default"}
          />
        </StyledLegendContainer>
      )}
    </div>
  );
};

const StyledChartContainer = styled.div<{ maxWidth: number }>`
  width: ${({ maxWidth }) => maxWidth}px;
  height: 65px;
  border-bottom: 1px solid ${Color.indigo40};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  column-gap: 6px;
`;

const StyledBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  height: 65px;

  /* this prevents the tooltip from cutting off the label with a before selector */
  .place-top::before {
    background-color: transparent !important;
  }
`;

const StyledBar = styled.div<{ height: number; color: string }>`
  height: ${({ height }) => height}px;
  background-color: ${({ color }) => color};
  width: 12px;
  min-height: 8px;
  border-radius: 5px 5px 0px 0px;
`;

const StyledLegendContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
`;
