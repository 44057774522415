import { RecommendationType, SentimentType, EmptyComment } from "@explorance/mly-types";
import { Color } from "./enums/color";

export type SentimentCounts = {
  total: number;
  positive: number;
  negative: number;
  neutral: number;
  notExplicit: number;
  mixed: number;
};

export type RecommendationCounts = {
  total: number;
  doMore: number;
  doLess: number;
  start: number;
  stop: number;
  continue: number;
  change: number;
  general: number;
};

export type GlobalSentiment = {
  type: SentimentType;
  percentage: number;
};

export type StackedBarChartCount = {
  attribute: SentimentType | RecommendationType;
  percentage: number;
  color: Color;
  recommendationCount?: number;
};

export type ExcludedData = {
  totalColumns: number;
  totalEmptyCells: number;
  emptyCommentCells: EmptyComment[];
  excludedComments: EmptyComment[];
};

export const getSentimentCountsFromData = (data: any): SentimentCounts => {
  const total = data.total || 0; // handles null from BE in the rare case model hasn't run
  const divisor = total || 1;
  return {
    total,
    positive: data.positive / divisor,
    negative: data.negative / divisor,
    neutral: data.neutral / divisor,
    notExplicit: data.notExplicit / divisor,
    mixed: data.mixed / divisor,
  };
};

export const getRecommendationCountsFromData = (
  data: RecommendationCounts
): RecommendationCounts => {
  const output = {};
  Object.keys(data).forEach((key) => (output[key] = data[key] || 0));
  return output as RecommendationCounts;
};
