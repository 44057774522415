import { FileUploadStatus } from "@explorance/mly-types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RECENT_ANALYSIS_LIMIT } from "assets/constants/listLimits";
import { getRecentAnalyses } from "services/analysis";
import { RecentAnalysis } from "ts/analysis";

export const fetchRecentAnalyses = createAsyncThunk<RecentAnalysis[], number | void>(
  "homePage/fetchRecentAnalyses",
  async (uploadingId): Promise<RecentAnalysis[]> => {
    const { data } = await getRecentAnalyses(RECENT_ANALYSIS_LIMIT);

    if (!data.analysis) {
      throw new Error("Failed to fetch recent analysis");
    }

    if (uploadingId !== undefined) {
      return data.analysis.map((analysis: RecentAnalysis) =>
        analysis.analysisId === uploadingId
          ? { ...analysis, fileUploadStatus: FileUploadStatus.Uploading }
          : analysis
      );
    }

    return data.analysis;
  }
);
