import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";

import { AnalysisContext } from "context/AnalysisContext";
import { setSelectedColumnFilters } from "context/AnalysisContext/actions";
import { useResource } from "hooks/useResource";

import { ColumnFilterSection } from "../ColumnFilterSection";
import { ApplyFiltersButton } from "components/_buttons/ApplyFilterButton";
import { FilterMenuTitle } from "components/FilterMenuTitle";

type Props = {
  resetLocalState: boolean;
  onSubmit: () => void;
};

export const ColumnMenu = ({ resetLocalState, onSubmit }: Props) => {
  const [state, dispatch] = useContext(AnalysisContext);

  const [localSelectedColumnFilters, setLocalSelectedColumnFilters] = useState<string[]>([]);

  const { getResource } = useResource();

  useEffect(() => {
    if (state.selectedColumnFilters) {
      setLocalSelectedColumnFilters(state.selectedColumnFilters);
    }
  }, [state.selectedColumnFilters, resetLocalState]);

  const applyColumnFilters = () => {
    dispatch(setSelectedColumnFilters(localSelectedColumnFilters));
    onSubmit();
  };

  return (
    <StyledColumnMenu>
      <FilterMenuTitle title={getResource("sideDrawer.filters.feedback")} />
      <StyledMenuContent>
        <ColumnFilterSection
          options={state.analysisDetails.selectedColumns.map((column) => ({
            label: column,
            value: column,
          }))}
          currentFilters={localSelectedColumnFilters.map((column) => ({
            label: column,
            value: column,
          }))}
          updateFilters={(columns) =>
            setLocalSelectedColumnFilters(columns.map((column) => column.value))
          }
        />
      </StyledMenuContent>
      <ApplyFiltersButton handleClick={applyColumnFilters} />
    </StyledColumnMenu>
  );
};

const StyledColumnMenu = styled.div`
  padding: 16px;
  max-height: 438px;
  max-width: 325px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const StyledMenuContent = styled.div`
  overflow: auto;
`;
