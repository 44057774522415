import { combineReducers } from "@reduxjs/toolkit";
import analysisListSlice from "store/analysis/analysisListSlice";
import authSlice from "./auth/authSlice";
import customModelListSlice from "./customModels/customModelListSlice";
import exportedFilesListSlice from "./exportedFiles/exportedFilesListSlice";
import glossariesListSlice from "./glossaries/glossariesListSlice";
import userListSlice from "./users/usersListSlice";
import userListHeaderSlice from "./users/usersListHeaderSlice";
import importUsersSlice from "./users/importUsersSlice";
import editVariableMappingSlice from "./editVariableMapping/editVariableMappingSlice";
import settingsSlice from "./analysisSettings/settingsSlice";
import dataSourceSlice from "./analysisSettings/dataSourceSlice";
import homePageSlice from "./home/homePageSlice";
import widgetGroupsSlice from "./widgets/widgetGroupsSlice";
import searchbarSlice from "./search/searchbarSlice";
import toastSlice from "./toast/toastSlice";
import quickAnalysisSlice from "./analysis/quickAnalysisSlice";
import widgetOrderingModal from "./widgetOrdering/widgetOrderingModal";
import resourceSlice from "./resources/resourceSlice";

const appReducer = combineReducers({
  analysisList: analysisListSlice,
  auth: authSlice,
  customModelList: customModelListSlice,
  editVariableMapping: editVariableMappingSlice,
  dataSource: dataSourceSlice,
  exportedFilesList: exportedFilesListSlice,
  glossariesList: glossariesListSlice,
  settings: settingsSlice,
  importUsers: importUsersSlice,
  home: homePageSlice,
  toast: toastSlice,
  userList: userListSlice,
  userListHeader: userListHeaderSlice,
  resourcesSlice: resourceSlice,
  widgetGroups: widgetGroupsSlice,
  search: searchbarSlice,
  quickAnalysis: quickAnalysisSlice,
  widgetOrdering: widgetOrderingModal,
});

export type AppState = ReturnType<typeof appReducer>;

export default appReducer;
