import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAnalysisDataSource,
  getAnalysisAvailableModels,
  getPreFilteringWordSuggestions,
} from "services/analysis";
import { AppState } from "store/appReducer";

import { AnalysisDataSource, PreFilteringSuggestedWords } from "ts/analysis";
import { ModelSelectionOptions } from "ts/models";

export const fetchUsedModels = createAsyncThunk<ModelSelectionOptions, string>(
  "usedModels/getAnalysisAvailableModels",
  async (analysisId): Promise<ModelSelectionOptions> => {
    const { data: modelsApiData } = await getAnalysisAvailableModels(parseInt(analysisId));
    if (!modelsApiData) {
      throw new Error("Failed to fetch used models.");
    }
    return modelsApiData;
  }
);

export const fetchDataSources = createAsyncThunk<AnalysisDataSource, number>(
  "dataSource/fetchDataSources",
  async (analysisId): Promise<AnalysisDataSource> => {
    const { data: analysisDataSource } = await getAnalysisDataSource(analysisId);

    if (!analysisDataSource) {
      throw new Error("Failed to fetch analysis data source.");
    }
    return analysisDataSource;
  }
);

export const fetchPreFilteringWordSuggestions = createAsyncThunk<
  PreFilteringSuggestedWords,
  void,
  { state: AppState }
>(
  "prefiltering/fetchWordSuggestions",
  async (_, { getState }): Promise<PreFilteringSuggestedWords> => {
    const state = getState();

    const {
      analysisDetails,
      selectedColumns: initialSelectedColumns,
      commentExclusionCount,
      excludedCommentLimit,
    } = state.settings;

    const selectedColumns = initialSelectedColumns ?? analysisDetails.selectedColumns;

    if (selectedColumns.length === 0) {
      return null;
    }

    const { data: preFilteringSuggestedWords } = await getPreFilteringWordSuggestions({
      analysisId: analysisDetails?.id,
      selectedColumns,
      wordCountThreshold: commentExclusionCount,
      limit: excludedCommentLimit,
      page: 1,
    });

    return preFilteringSuggestedWords;
  }
);
