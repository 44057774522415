import React, { useContext, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { cloneDeep } from "lodash";
import { AxiosError } from "axios";
import { useAppSelector } from "store";
import { AnalysisContext } from "context/AnalysisContext";
import { setWidgetsApiData, handleAxiosError } from "context/AnalysisContext/actions";

import { createNewWidgetByGroupId } from "services/analysis";
import { deleteWidgetById, updateWidget } from "services/widgets";
import { mapDemographicFiltersForBackend, mapDemographicFiltersForFrontend } from "utils/filters";
import { useResource } from "hooks/useResource";
import { findOneTopicWithFullPath } from "utils/topics";
import { isAnyAdmin } from "utils/isAnyAdmin";

import { FilterSelectionSection } from "../FilterSelectionSection";
import { BaseWidgetSettingsSection } from "./BaseWidgetSettingsSection";
import { WidgetStatsSettingsSection } from "components/Widget/WidgetStatsSettingsSection";
import { StyledFooter } from "components/SideDrawer/StyledFooter";
import { Header } from "components/SideDrawer/Header";
import { Button } from "components/_buttons/Button";
import { ButtonVariant } from "ts/enums/button";
import { Icon, IconType } from "components/_icons/Icon";
import { Color } from "ts/enums/color";
import {
  BreakdownTypes,
  PermissionLevel,
  RecommendationType,
  RoleType,
  SentimentType,
  WidgetHeight,
  WidgetType,
  WidgetWidth,
} from "@explorance/mly-types";

import { Widget } from "ts/widget";
import { DemographicFilter } from "ts/filters/demographicFilter";
import { AnalysisModel } from "ts/filters/analysisModel";
import { ApiTopicNode } from "ts/topic";
import { Text } from "components/Text";
import { Range } from "ts/range";
import {
  isRecommendationTypeFilterAvailable,
  isSentimentFilterAvailable,
  isTopicFilterAvailable,
} from "utils/getBreakdownOptions";

type Props = {
  isOpen: boolean;
  selectedWidget: Widget;
  groupId: number;
  onClose: () => void;
  onSave: (widgetId: number) => void;
  onDelete?: () => void;
  isCreate?: boolean;
};

export const WidgetSettingsDrawer = ({
  isOpen,
  selectedWidget,
  groupId,
  onClose,
  onSave,
  onDelete,
  isCreate,
}: Props) => {
  // useContext
  const [state, dispatch] = useContext(AnalysisContext);

  // redux
  const { currentUser } = useAppSelector((state) => state.auth);

  // useState
  const [widget, setWidget] = useState(selectedWidget);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [hasErrors, setHasErrors] = useState(false);
  const isAdmin = isAnyAdmin(currentUser.roleType);
  const restrictEditAccess =
    (currentUser.roleType === RoleType.Viewer && !state.analysisDetails?.sharing) ||
    (!isAdmin && state.analysisDetails?.sharing?.permissionLevel === PermissionLevel.View);

  const [localSelectedTopicNodes, setLocalSelectedTopicNodes] = useState<ApiTopicNode[]>([]);
  const [localSelectedModel, setLocalSelectedModel] = useState<AnalysisModel>(null);
  const [thresholdInputVal, setThresholdInputVal] = useState<number>(
    selectedWidget?.filters?.threshold || null
  );
  const [currentDemographicFilters, setCurrentDemographicFilters] = useState<DemographicFilter[]>(
    []
  );
  const [localSelectedColumnFilters, setLocalSelectedColumnFilters] = useState<string[]>();
  const [localSelectedRecommendationTypes, setLocalSelectedRecommendationTypes] = useState<
    RecommendationType[]
  >([]);
  const [localSelectedSentimentTypes, setLocalSelectedSentimentTypes] = useState<SentimentType[]>(
    []
  );
  const [localAlertRange, setLocalAlertRange] = useState<Range>({ min: 0, max: 100 });

  // custom hooks
  const { getResource } = useResource();

  // useEffects
  useEffect(() => {
    if (state.analysisModels && isOpen && selectedWidget.filters?.modelId) {
      setLocalSelectedModel(
        state.analysisModels?.find((model) => model.modelId === selectedWidget.filters?.modelId)
      );
    }
  }, [state.analysisModels, isOpen, selectedWidget.filters?.modelId]); // eslint-disable-line

  useEffect(() => {
    if (isOpen) {
      if (widget?.filters?.topics && localSelectedModel) {
        const topics: ApiTopicNode[] = widget?.filters?.topics
          ?.map((x) => {
            return findOneTopicWithFullPath(localSelectedModel.topics, x);
          })
          .filter(Boolean);
        setLocalSelectedTopicNodes(topics);
      }

      if (widget?.filters?.selectedColumns) {
        setLocalSelectedColumnFilters(widget.filters.selectedColumns);
      }

      if (widget?.filters?.demographics) {
        setCurrentDemographicFilters(mapDemographicFiltersForFrontend(widget.filters.demographics));
      }

      if (widget?.filters?.statistics) {
        Object.keys(RecommendationType).some((key) => widget.filters.statistics.includes(key)) &&
          setLocalSelectedRecommendationTypes(widget.filters.statistics as RecommendationType[]);
        Object.keys(SentimentType).some((key) => widget.filters.statistics.includes(key)) &&
          setLocalSelectedSentimentTypes(widget.filters.statistics as SentimentType[]);
      }
      if (
        widget.type === WidgetType.MostAlerts &&
        widget.filters?.alertRange &&
        localAlertRange.min === 0 &&
        localAlertRange.max === 100
      ) {
        setLocalAlertRange(widget.filters?.alertRange);
      }
    }
  }, [isOpen]); //eslint-disable-line

  // functions
  const discardFilters = () => {
    setLocalSelectedModel(
      state.analysisModels?.find((model) => model.modelId === selectedWidget.filters?.modelId) ??
        state.analysisModels?.[0]
    );
    setThresholdInputVal(selectedWidget?.filters?.threshold || null);
    setLocalSelectedTopicNodes(widget?.filters?.topics || []);
    setCurrentDemographicFilters(
      mapDemographicFiltersForFrontend(widget?.filters?.demographics || [])
    );
    setLocalSelectedColumnFilters([]);
    setLocalSelectedRecommendationTypes([]);
    setLocalSelectedSentimentTypes([]);
  };

  const discardChangesHandler = useCallback(() => {
    setWidget(selectedWidget);
    discardFilters();
    setHasErrors(false);
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWidget]);

  useEffect(() => {
    if (!isOpen) discardChangesHandler();
  }, [isOpen, discardChangesHandler]);

  // Callbacks & helpers
  const onUpdateWidget = (updatedWidget: Widget, hasErrors = false, resetTopicFilters = false) => {
    setWidget({ ...updatedWidget });
    setHasErrors(hasErrors);

    // If we change widget type, reset the alert range
    if (updatedWidget.type !== widget.type) {
      setLocalAlertRange({ min: 0, max: 100 });
    }

    // If we change L1 breakdown type, reset the threshold
    if (
      updatedWidget.configuration.breakdowns[0]?.type !== widget.configuration.breakdowns[0]?.type
    ) {
      setThresholdInputVal(null);
    }

    if (resetTopicFilters) setLocalSelectedTopicNodes([]);
    setLocalSelectedRecommendationTypes([]);
    setLocalSelectedSentimentTypes([]);
  };

  const handleChangeSelectedModel = (id: number) => {
    setLocalSelectedModel(state.analysisModels.find((am) => am.modelId === id));
    setLocalSelectedTopicNodes([]);
  };

  const createNewWidget = async () => {
    if (isSaving) return;
    setIsSaving(true);
    try {
      const newWidget: Widget = {
        id: null,
        size: {
          height: WidgetHeight.Full,
          width: WidgetWidth.OneThird,
        },
        title: widget.title,
        type: widget.type,
        configuration: {
          ...widget.configuration,
          breakdowns: widget.configuration.breakdowns
            .filter((bd) => bd.type !== null)
            .filter(Boolean),
        },
        filters: {
          topics: localSelectedTopicNodes,
          demographics: mapDemographicFiltersForBackend(currentDemographicFilters),
          modelId: localSelectedModel?.modelId ?? null,
          isVirtual:
            state?.analysisModels?.find((am) => am.modelId === localSelectedModel?.modelId)
              ?.isVirtual ?? false,
          statistics: [...localSelectedSentimentTypes, ...localSelectedRecommendationTypes],
          threshold: thresholdInputVal ?? null,
          ...(localSelectedColumnFilters.length > 0 && {
            selectedColumns: localSelectedColumnFilters,
          }),
          ...(widget.type === WidgetType.MostAlerts &&
            (localAlertRange.min !== 0 || localAlertRange.max !== 100) && {
              alertRange: localAlertRange,
            }),
        },
      };

      const { data } = await createNewWidgetByGroupId(groupId, newWidget);
      newWidget.id = data.id;

      dispatch(
        setWidgetsApiData({
          ...state.widgetsApiData,
          widgetGroups: state.widgetsApiData.widgetGroups.map((group) =>
            group.id === groupId ? { ...group, widgets: [...group.widgets, newWidget] } : group
          ),
        })
      );
      onSave(data.id);
      onClose();
    } catch (err) {
      dispatch(handleAxiosError(err as AxiosError));
    } finally {
      setTimeout(() => {
        setIsSaving(false);
      }, 250);
    }
  };

  const onClickSave = async () => {
    if (isSaving) return;
    setIsSaving(true);
    if (!selectedWidget.id) {
      createNewWidget();
    } else {
      try {
        const { data } = await updateWidget(widget.id, {
          title: widget.title,
          type: widget.type,
          configuration: {
            ...widget.configuration,
            breakdowns: widget.configuration.breakdowns
              .filter((bd) => bd.type !== null)
              .filter(Boolean),
          },
          filters: {
            topics: localSelectedTopicNodes.map((tn) => tn.fullPath),
            demographics: mapDemographicFiltersForBackend(currentDemographicFilters),
            modelId: localSelectedModel?.modelId ?? null,
            isVirtual:
              state?.analysisModels?.find((am) => am.modelId === localSelectedModel?.modelId)
                ?.isVirtual ?? false,
            statistics: [...localSelectedRecommendationTypes, ...localSelectedSentimentTypes],
            threshold: thresholdInputVal ? Number(thresholdInputVal) : null,
            selectedColumns: localSelectedColumnFilters,
            ...(widget.type === WidgetType.MostAlerts &&
              (localAlertRange.min !== 0 || localAlertRange.max !== 100) && {
                alertRange: localAlertRange,
              }),
          },
        });
        const widgetGroupIndex = state.widgetsApiData.widgetGroups.findIndex(
          (g) => g.id === groupId
        );
        const groupStateCopy = cloneDeep(state.widgetsApiData.widgetGroups.at(widgetGroupIndex));
        const indexOfEditedWidget = state.widgetsApiData.widgetGroups
          .at(widgetGroupIndex)
          .widgets.findIndex((w) => w.id === selectedWidget.id);

        const updatedWidget = {
          size: {
            height: WidgetHeight.Full,
            width: WidgetWidth.OneThird,
          },
          configuration: data.configuration,
          filters: { ...data.filters, topics: localSelectedTopicNodes },
          title: data.title,
          type: data.type,
          id: data.id,
        };
        groupStateCopy.widgets[indexOfEditedWidget] = updatedWidget;
        onSave(data.id);
        dispatch(
          setWidgetsApiData({
            ...state.widgetsApiData,
            widgetGroups: state.widgetsApiData.widgetGroups.map((group) =>
              group.id === groupStateCopy.id ? groupStateCopy : group
            ),
          })
        );

        onClose();
      } catch (err) {
        dispatch(handleAxiosError(err as AxiosError));
      } finally {
        setTimeout(() => {
          setIsSaving(false);
        }, 250);
      }
    }
  };

  const handleDeleteWidget = async () => {
    try {
      const response = await deleteWidgetById(selectedWidget.id);
      if (response.status >= 200 && response.status < 300) {
        const groupIndex = state.widgetsApiData.widgetGroups.findIndex((group) =>
          group.widgets.some((widget) => widget.id === selectedWidget.id)
        );
        const groupStateCopy = cloneDeep(state.widgetsApiData.widgetGroups[groupIndex]);
        groupStateCopy.widgets = groupStateCopy.widgets.filter(
          (widget) => widget.id !== selectedWidget.id
        );

        dispatch(
          setWidgetsApiData({
            ...state.widgetsApiData,
            widgetGroups: state.widgetsApiData.widgetGroups.map((group) =>
              group.id === groupStateCopy.id ? groupStateCopy : group
            ),
          })
        );
      } else {
        throw new Error(
          `Got status code ${response.status} when trying method ${response.config.method} @ ${
            response.config.baseURL + response.config.url
          }`
        );
      }
    } catch (err) {
      dispatch(handleAxiosError(err as AxiosError));
    }
    onClose();
    onDelete();
  };

  const filterSelectionSectionProps = {
    thresholdInputVal,
    setThresholdInputVal,
    localSelectedModel,
    handleChangeSelectedModel,
    currentDemographicFilters,
    setCurrentDemographicFilters,
    localSelectedTopicNodes,
    setLocalSelectedTopicNodes,
    localSelectedColumnFilters,
    setLocalSelectedColumnFilters,
    localSelectedRecommendationTypes,
    setLocalSelectedRecommendationTypes,
    localSelectedSentimentTypes,
    setLocalSelectedSentimentTypes,
    localAlertRange,
    setLocalAlertRange,
    isOpen,
  };

  return (
    <>
      <Header
        headerTitle={getResource("sideDrawer.widget.title")}
        closeHandler={discardChangesHandler}
      />
      <StyledMenuBody>
        <BaseWidgetSettingsSection
          widget={widget}
          restrictedAccess={restrictEditAccess}
          onChange={onUpdateWidget}
          isTopicFilterAvailable={isTopicFilterAvailable}
          discardFilters={discardFilters}
        />
        <WidgetStatsSettingsSection
          widget={widget}
          restrictedAccess={restrictEditAccess}
          onChange={onUpdateWidget}
          isCreate={isCreate}
        />
        <FilterSelectionSection
          isThresholdDisabled={widget.configuration.breakdowns[0]?.type !== BreakdownTypes.Topics}
          restrictedAccess={restrictEditAccess}
          hasTopicsFilter={isTopicFilterAvailable(
            widget.type,
            widget.configuration.breakdowns[0]?.type,
            widget.configuration.breakdowns[1]?.type ?? null
          )}
          hasRecommendationFilter={isRecommendationTypeFilterAvailable(
            widget.type,
            widget.configuration.breakdowns[0]?.type,
            widget.configuration.breakdowns[1]?.type ?? null
          )}
          hasSentimentFilter={isSentimentFilterAvailable(
            widget.type,
            widget.configuration.breakdowns[0]?.type,
            widget.configuration.breakdowns[1]?.type ?? null
          )}
          hasAlertRange={widget.type === WidgetType.MostAlerts}
          {...filterSelectionSectionProps}
        />
      </StyledMenuBody>

      <StyledFooter>
        {selectedWidget.id && !restrictEditAccess && (
          <StyledDeleteWidgetButton onClick={handleDeleteWidget}>
            <Icon type={IconType.trash} color={Color.red30} />
            <Text resource="button.removeWidget" />
          </StyledDeleteWidgetButton>
        )}
        {!restrictEditAccess && (
          <>
            <Button
              variant={ButtonVariant.light}
              onClick={discardChangesHandler}
              style={{ marginLeft: "auto" }}
            >
              <Text resource="button.cancel" />
            </Button>
            <Button
              variant={ButtonVariant.primaryPurple}
              onClick={isSaving ? undefined : () => onClickSave()}
              disabled={hasErrors || isSaving}
            >
              <Text resource="button.save" />
            </Button>
          </>
        )}
      </StyledFooter>
    </>
  );
};

const StyledDeleteWidgetButton = styled.div`
  display: flex;
  align-items: center;
  color: ${Color.red40};
  font-size: 0.875em;
  font-weight: bold;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
    margin-right: 6px;
  }
`;

const StyledMenuBody = styled.div`
  height: calc(100% - 25px - 50px - 92px);
  padding: 0 24px;
  overflow-y: auto;
  overflow-x: hidden;
`;
