import React, { ReactNode } from "react";
import ReactTooltip, { Place } from "react-tooltip";
import styled from "styled-components";
import { TextType } from "ts/TextType";

import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";

type Props = {
  tooltipId: string;
  content?: number | TextType;
  children?: ReactNode;
  className?: string;
  arrowColor?: Color;
  backgroundColor?: Color;
  delayHide?: number;
  place?: Place;
  fontSize?: string;
  disable?: boolean;
  zIndexPriority?: boolean;
};

export const Tooltip = ({
  tooltipId,
  content,
  children,
  className,
  arrowColor,
  backgroundColor,
  delayHide,
  place,
  fontSize,
  disable,
  zIndexPriority,
}: Props) => {
  return (
    <StyledTooltipContainer zIndexPriority={zIndexPriority}>
      <StyledTooltip
        id={tooltipId}
        className={className}
        place={place ?? "bottom"}
        arrowColor={arrowColor ?? "transparent"}
        backgroundColor={backgroundColor}
        delayShow={100}
        delayHide={delayHide ?? 50}
        aria-haspopup={true}
        disable={disable}
        fontSize={fontSize}
      >
        {content ?? children}
      </StyledTooltip>
    </StyledTooltipContainer>
  );
};

const StyledTooltipContainer = styled.div<{ zIndexPriority: boolean }>`
  position: absolute;

  .__react_component_tooltip {
    transition: opacity 200ms ease-in-out !important;
    visibility: visible;
    max-width: 300px;
    overflow-wrap: break-word;
    overflow: hidden;
    white-space: wrap;
    z-index: ${({ zIndexPriority }) =>
      zIndexPriority ? ZIndexStackingContext.high : ZIndexStackingContext.medium};
  }

  .__react_component_tooltip.show {
    opacity: 1;
  }
`;

const StyledTooltip = styled(ReactTooltip)<{ fontSize: string }>`
  font-size: ${({ fontSize }) => fontSize ?? "12px"} !important;
  font-family: Lato, sans-serif;

  /* this prevents the tooltip from cutting off the label with a before selector */
  ::before {
    background-color: transparent !important;
  }
`;
