import * as download from "downloadjs";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { BlobServiceClient } from "@azure/storage-blob";
import { useAppSelector, useAppDispatch } from "store";
import { routes } from "routes";

import {
  StyledTablePageFilterSection,
  StyledTablePageHeader,
  StyledTablePageTitle,
} from "components/Table/TablePageHeader";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";

import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { SearchRow } from "components/SearchRow";
import { Pagination } from "components/Pagination";
import { ImportUsers } from "./ImportUsers";

import { exportUserList, getUserRolesList } from "services/users";
import { getStorageConfig } from "config/configProviders";

import { RoleType } from "@explorance/mly-types";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { setCurrentPage, setCurrentRoleFilters } from "store/users/usersListSlice";
import { showToastError, showToastSuccess } from "store/toast/toastSlice";
import { fetchUserList } from "store/users/thunks";
import { setAllRoleFilters, setExportInProgress } from "store/users/usersListHeaderSlice";
import { TableFilterDropdown } from "components/TableFilterDropdown";
import { Text } from "components/Text";

const blobStorageConfig = getStorageConfig();
const USER_LIST_EXPORT_CONTAINER_NAME = "userexports";
const USER_LIST_EXPORT_BLOB_NAME = "userList.xlsx";

export const UserListPageHeader = () => {
  //hooks
  const dispatch = useAppDispatch();
  const history = useHistory();

  // redux
  const { currentUser } = useAppSelector((state) => state.auth);
  const state = useAppSelector((state) => state.userListHeader);
  const userListState = useAppSelector((state) => state.userList);

  //EFFECTS
  useEffect(() => {
    getUserRolesList(currentUser.roleType === RoleType.SuperAdmin)
      .then(({ data }) => dispatch(setAllRoleFilters(data.roles as RoleType[])))
      .catch(() => console.error("Error fetching user roles list"));
  }, []); // eslint-disable-line

  //FUNCTIONS
  const onClickCreateUser = () => history.push(routes.createProfilePage);

  const handleChangePage = (page: number) => {
    dispatch(setCurrentPage(page));
    dispatch(fetchUserList());
  };

  const handleChangeRoleFilters = (roleFilters: RoleType[]) => {
    dispatch(setCurrentRoleFilters(roleFilters));
    dispatch(setCurrentPage(1));
    dispatch(fetchUserList());
  };

  const exportUsers = async () => {
    dispatch(setExportInProgress(true));
    try {
      const { data } = await exportUserList(false);
      const blobServiceClient = new BlobServiceClient(
        `${blobStorageConfig.account}?${data.sharedAccessSignature}`
      );
      const containerClient = blobServiceClient.getContainerClient(USER_LIST_EXPORT_CONTAINER_NAME);
      const blobClient = containerClient.getBlockBlobClient(USER_LIST_EXPORT_BLOB_NAME);
      blobClient.download().then((blob) => {
        blob.blobBody.then((body) => {
          download(
            body,
            USER_LIST_EXPORT_BLOB_NAME,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        });
      });
      dispatch(showToastSuccess("toast.userListExported"));
    } catch (err) {
      dispatch(showToastError("toast.defaultError"));
    } finally {
      dispatch(setExportInProgress(false));
    }
  };

  const updateSearchField = () => {
    dispatch(setCurrentPage(1));
    dispatch(fetchUserList());
  };

  const updateFilters = (newFilter: string) => {
    const roleType = newFilter as RoleType;
    if (!userListState.currentRoleFilters.includes(roleType)) {
      const copy = [...userListState.currentRoleFilters];
      copy.push(roleType);
      handleChangeRoleFilters(copy);
    } else {
      handleChangeRoleFilters(userListState.currentRoleFilters.filter((f) => f !== roleType));
    }
  };

  return (
    <StyledUserPageHeaderContainer>
      <StyledUserPageHeader>
        <StyledTablePageTitle>
          <Text resource="userList.title" />
        </StyledTablePageTitle>
        <SearchRow
          onSearch={updateSearchField}
          currentCount={userListState.currentUserCount}
          totalCount={userListState.totalUserCount}
          dataType="searchBar.dataType.userList"
        />
        <StyledHeaderButtonContainer>
          <Button
            variant={ButtonVariant.secondary}
            style={{ marginRight: 12 }}
            onClick={onClickCreateUser}
          >
            <Text resource="button.createNewUser" />
          </Button>
          <ImportUsers />
        </StyledHeaderButtonContainer>
      </StyledUserPageHeader>
      <StyledUserFilters>
        <TableFilterDropdown
          currentFilters={userListState.currentRoleFilters}
          allFilters={state.allRoleFilters}
          nameKey="button.filter.byRole"
          updateFilters={updateFilters}
          deleteFilters={() => handleChangeRoleFilters([])}
          valueLabelWrapper={(value) => <Text resource={`roleType.${value}`} />}
        />
        <StyledRightContainer>
          <Button
            variant={ButtonVariant.secondary}
            size={ButtonSize.sm}
            loading={state.isExportInProgress}
            onClick={exportUsers}
          >
            <StyledExportUserButtonContent>
              <Text resource="userList.button.exportUser" />
              <Icon type={IconType.export} size={12} />
            </StyledExportUserButtonContent>
          </Button>
          {userListState.currentUserCount > 0 && (
            <Pagination
              currentPage={userListState.currentPage}
              currentItemsCount={userListState.currentUserCount}
              handlePageSelection={handleChangePage}
            />
          )}
        </StyledRightContainer>
      </StyledUserFilters>
    </StyledUserPageHeaderContainer>
  );
};

const StyledUserPageHeaderContainer = styled.div`
  position: sticky;
  background-color: ${Color.white};
  z-index: ${ZIndexStackingContext.medium};
  top: 0;
`;

const StyledUserPageHeader = styled(StyledTablePageHeader)`
  display: flex;
  align-items: center;
  padding: 36px 0 24px;
`;

const StyledHeaderButtonContainer = styled.div`
  margin-left: auto;
  display: flex;
`;

const StyledUserFilters = styled(StyledTablePageFilterSection)`
  padding-bottom: 16px;
`;

const StyledRightContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

const StyledExportUserButtonContent = styled.span`
  display: flex;
  align-items: center;
  gap: 6px;
`;
