export type VariablePresetDetail = {
  id: number;
  name: string;
  variableCount: number;
  lastUpdated: string;
  createdDate: string;
};

export type VariableMappingApiData = {
  mappings: VariablePresetDetail[];
  totalCount: number;
  itemCount: number;
};

export type VariableMappingGroup = {
  name: string;
  mappings: VariableMappingGroupItem[];
};

export type BackendVariableMapping = {
  title: string;
  mappings: BackendSingleVariableMapping[];
};

export type BackendSingleVariableMapping = {
  [key: string]: string;
};

export type VariableMappingGroupItem = {
  variable: string;
  definition: string;
};

export const mapDataToVariableMapping = (
  data: BackendSingleVariableMapping
): VariableMappingGroupItem => ({
  variable: Object.keys(data)[0],
  definition: Object.values(data)[0],
});

export const formatVariableMappingsForBackend = (mappingItems: VariableMappingGroupItem[]) =>
  mappingItems.map((m) => ({
    [m.variable.toString()]: m.definition,
  }));
