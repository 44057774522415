import React, { useState } from "react";
import styled from "styled-components";
import { ExpandableFilterPillWrapper } from "common-layouts/FilterSelectionSection/FilterPillList/ExpandableFilterPillWrapper";
import { StyledDropdownTransition } from "components/DropdownMenu/StyledDropdownTransition";
import { Text } from "components/Text";
import { Button } from "components/_buttons/Button";
import { Pill, PillSize } from "components/_pills/Pill";
import useClickOutside from "hooks/useClickOutside";
import { TextType } from "ts/TextType";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";

type CustomStyles = {
  zIndex?: ZIndexStackingContext;
  top?: number;
};

type Props = {
  nameKey: string;
  currentFilters: string[];
  allFilters: string[];
  customStyles?: CustomStyles;
  updateFilters: (filter: string) => void;
  deleteFilters: () => void;
  valueLabelWrapper?: (value: string) => TextType;
};

export const TableFilterDropdown = ({
  currentFilters,
  allFilters,
  customStyles,
  nameKey,
  updateFilters,
  deleteFilters,
  valueLabelWrapper,
}: Props) => {
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const filterDropdownRef = useClickOutside(() => setShowFilterDropdown(false));

  return (
    <ExpandableFilterPillWrapper
      showDeleteFilters={currentFilters.length > 0}
      deleteFilters={deleteFilters}
    >
      <div ref={filterDropdownRef}>
        <Button
          variant={ButtonVariant.secondary}
          size={ButtonSize.md}
          style={{ height: 30 }}
          onClick={() => setShowFilterDropdown((prev) => !prev)}
        >
          <Text resource={nameKey} />
        </Button>
        <StyledFilterDropdown show={showFilterDropdown} customStyles={customStyles}>
          <ul>
            {allFilters.map((filter) => (
              <li
                key={filter}
                className={currentFilters.includes(filter) ? "selected" : ""}
                onClick={() => updateFilters(filter)}
              >
                <span>{valueLabelWrapper ? valueLabelWrapper(filter) : filter}</span>
              </li>
            ))}
          </ul>
        </StyledFilterDropdown>
      </div>
      <StyledPillList>
        {currentFilters?.map((pill) => (
          <Pill
            key={pill}
            onDeletePill={() => updateFilters(pill)}
            size={PillSize.lg}
            style={{ height: 30, fontSize: "12px", marginRight: 8 }}
          >
            {pill}
          </Pill>
        ))}
      </StyledPillList>
    </ExpandableFilterPillWrapper>
  );
};

export const StyledPillList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledFilterDropdown = styled(StyledDropdownTransition)<{
  show: boolean;
  customStyles: CustomStyles;
}>`
  position: absolute;
  top: ${({ customStyles }) => customStyles?.top};
  z-index: ${({ customStyles }) => customStyles?.zIndex};
  left: 0px;
  min-width: 150px;
  margin-top: 5px;
  border: 1px solid ${Color.sky50};
  border-radius: 2px;
  box-shadow: 0px 3px 6px #00000029;
  background: ${Color.white};

  ul {
    font-size: 0.8em;
    list-style: none;
    padding: 5px 3px;
    text-align: left;
    margin: 0 2px;

    li {
      padding: 3px 10px;
      color: ${Color.gray40};
      height: 30px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        color: ${Color.blue50};
        background-color: ${Color.sky15};
      }

      &:active {
        color: ${Color.blue50};
        background: ${Color.sky60};
      }

      &.selected {
        color: ${Color.blue50};
        background-color: ${Color.sky40};
      }

      span {
        margin: 7px 5px;
        font-weight: normal;
        float: left;
      }
    }
  }
`;
