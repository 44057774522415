import React, { RefObject } from "react";
import styled from "styled-components";

import { Color } from "ts/enums/color";
import { SharingView } from "ts/enums/sharing";

import { GroupsSharedWith } from "./GroupsSharedWith";
import { UsersSharedWith } from "./UsersSharedWith";
import { useSharingContext } from "context/SharingContext";
import { useResource } from "hooks/useResource";

import { StyledHorizontalField } from "./PermissionForm/PermissionFormContainer";
import { ShareTo } from "./ShareTo";
import { Text } from "components/Text";

const CHAR_LIMIT = 100;

type ShareBuilderProps = {
  groupNameInputRef?: RefObject<HTMLInputElement>;
  groupNameButtonRef?: RefObject<HTMLInputElement>;
};

export const SharingBuilder = ({ groupNameInputRef, groupNameButtonRef }: ShareBuilderProps) => {
  const { sharingState, updateSharingState } = useSharingContext();
  const { getResource } = useResource();

  const getSharingBuilderContent = () => {
    if ([SharingView.ShareToUsers, SharingView.ShareToGroups].includes(sharingState.view)) {
      return <ShareTo />;
    }
    if (sharingState.view === SharingView.UsersSharedWith) {
      return <UsersSharedWith />;
    }
    if (sharingState.view === SharingView.GroupsSharedWith) {
      return <GroupsSharedWith />;
    }
  };

  const handleUpdateGroupName = (e) => {
    const groupNameButtonClick = groupNameButtonRef.current?.contains(e.relatedTarget);
    const isGroupNameEmpty = groupNameInputRef.current.value.trim() === "";

    if (isGroupNameEmpty && !groupNameButtonClick) {
      groupNameInputRef.current.value = getResource("sharing.groupName.default");
    }
    if (isGroupNameEmpty && groupNameButtonClick) {
      updateSharingState("showGroupNameError", true);
    }
    updateSharingState(
      "permissionFields",
      {
        ...sharingState.permissionFields,
        groupName:
          !groupNameButtonClick && isGroupNameEmpty
            ? getResource("sharing.groupName.default")
            : groupNameInputRef.current.value,
      },
      { save: true }
    );
    updateSharingState("showGroupNameError", false);
  };

  return (
    <>
      {sharingState.view === SharingView.ShareToGroups && (
        <StyledHorizontalField hasError={sharingState.showGroupNameError}>
          <label htmlFor="group-name">
            <Text resource="sharing.permissionForm.groupName.label" />
            {""}
          </label>
          <GroupNameInput
            id="group-name"
            type="text"
            ref={groupNameInputRef}
            defaultValue={sharingState.permissionFields.groupName}
            maxLength={CHAR_LIMIT}
            onBlur={handleUpdateGroupName}
          />
        </StyledHorizontalField>
      )}

      <StyledSharingBuilder>{getSharingBuilderContent()}</StyledSharingBuilder>
    </>
  );
};

const StyledSharingBuilder = styled.div`
  padding: 16px;
  border-radius: 5px;
  border: 1px solid ${Color.sky50};
`;

const GroupNameInput = styled.input`
  width: 450px;
`;
