import { NotificationMessage, TaskStatus } from "@explorance/mly-types";
import { isObject } from "lodash";
import { formatArrayStrings } from "./formatArrayStrings";
import { GetResourceFunction } from "hooks/useResource";

export const getImportNotificationSentence = (
  message: NotificationMessage,
  getResource: GetResourceFunction
): string => {
  const errDetails = message.errDetails;
  const errNumericValueInfo =
    isObject(errDetails) && Object.keys(errDetails).map((k) => errDetails[k]);

  if (message.status === TaskStatus.Success || message.status === TaskStatus.Failure) {
    const part1 = getResource("notificationCard.sentence.importForAnalysis");
    const part2 = `"<b>${message.refName}</b>"`;
    const part3 = getResource(
      message.status === TaskStatus.Success
        ? "notificationCard.sentence.importSuccess"
        : "notificationCard.sentence.importFailure"
    );
    const part4 =
      errNumericValueInfo &&
      getResource(
        `notificationCard.sentence.[${message.details}]`,
        errNumericValueInfo[0],
        errNumericValueInfo[1]
      );

    const successMsg = `${part1} ${part2} ${part3}`;
    const failMsg = `${part1} ${part2} ${part3} ${part4}`;

    return message.status === TaskStatus.Success ? successMsg : failMsg;
  }
  return "";
};

export const getAnalysisNotificationSentence = (
  message: NotificationMessage,
  getResource: GetResourceFunction
): string => {
  if (message.status === TaskStatus.Success || message.status === TaskStatus.Failure) {
    const part1 = getResource("notificationCard.sentence.importForAnalysis");
    const part2 = `<b>${message.refName}</b>`;
    const part3 = getResource(
      message.status === TaskStatus.Success
        ? "notificationCard.sentence.analysisSuccess"
        : "notificationCard.sentence.analysisFailure"
    );
    return `${part1} ${part2} ${part3}`;
  }
  return "";
};

export const getExportNotificationPhraseKeys = (
  message: NotificationMessage,
  getResource: GetResourceFunction
): string => {
  if (message.status === TaskStatus.Success || message.status === TaskStatus.Failure) {
    const part1 = getResource("notificationCard.sentence.yourExportOf");
    const part2 = `<b>${message.refName}</b>`;
    const part3 = getResource(
      message.status === TaskStatus.Success
        ? "notificationCard.sentence.exportSuccess"
        : "notificationCard.sentence.exportFailure"
    );

    return `${part1} ${part2} ${part3}`;
  }

  return "";
};

export const getUserImportNotificationPhraseKeys = (
  message: NotificationMessage,
  getResource: GetResourceFunction
): string => {
  const errDetails = message.errDetails;
  const errNumericValueInfo =
    isObject(errDetails) && Object.keys(errDetails).map((k) => errDetails[k]);

  if (message.status === TaskStatus.Success || message.status === TaskStatus.Failure) {
    const part1 = getResource("notificationCard.sentence.importForUserList");
    const part2 = `"<b>${message.refName}</b>"`;
    const part3 = getResource(
      message.status === TaskStatus.Success
        ? "notificationCard.sentence.importSuccess"
        : "notificationCard.sentence.importFailure"
    );
    const part4 =
      errNumericValueInfo &&
      getResource(
        `notificationCard.sentence.[${message.details}]`,
        errNumericValueInfo[0],
        errNumericValueInfo[1]
      );

    const successMsg = `${part1} ${part2} ${part3}`;
    const failMsg = `${part1} ${part2} ${part3} ${part4}`;

    return message.status === TaskStatus.Success ? successMsg : failMsg;
  }

  return "";
};

export const getUserDemographicInUseSentence = (
  message: NotificationMessage,
  getResource: GetResourceFunction
) => {
  const part1 = getResource("notificationCard.sentence.importForUserList");
  const part2 = getResource("notificationCard.sentence.importFailure");

  const demoErrorData = message.errDetails.ErrorValue.map((ev) => {
    const demographicsLabel = ev.demographics?.length > 1 ? "demographics" : "demographic";
    const subPart1 = getResource(`notificationCard.sentence.demoInUse.${demographicsLabel}`);
    const subPart2 = ev.demographics?.map((d) => `<b>"${d}"</b>`).join(", ");
    const subPart3 = getResource("notificationCard.sentence.demoInUse.usedInAnalysis");
    const subPart4 = `<b>"${ev.analysisId}"</b>`;
    return `${subPart1} ${subPart2} ${subPart3} ${subPart4}`;
  });

  const part3 = `${formatArrayStrings(demoErrorData, getResource)}.`;

  return `${part1} ${part2} ${part3}`;
};

export const getSharedAnalysisNotificationPhraseKeys = (
  message: NotificationMessage,
  getResource: GetResourceFunction
): string => {
  if (message.status === TaskStatus.Success || message.status === TaskStatus.Failure) {
    const part1 = getResource("notificationCard.sentence.theAnalysis");
    const part2 = `<b>${message.refName}</b>`;
    const part3 = getResource(
      message.status === TaskStatus.Success
        ? "notificationCard.sentence.sharedAnalysisSuccess"
        : "notificationCard.sentence.sharedAnalysisFailed"
    );

    return `${part1} ${part2} ${part3}`;
  }

  return "";
};
