import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import { useResource } from "hooks/useResource";
import { FeedbackTypePill } from "./FeedbackTypePill";
import { FeedbackCategory } from "ts/enums/feedbackCategory";
import { sendFeedback } from "services/comments";
import { Comment } from "ts/comments";
import { Button } from "components/_buttons/Button";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Icon, IconType } from "components/_icons/Icon";
import { Color } from "ts/enums/color";
import { CollapsibleName } from "components/CollapsibleName";
import { SectionTitle } from "components/SectionTitle";
import { Text } from "components/Text";

type CommentFeedbackFormProps = {
  comment: Comment;
};

const FEEDBACK_CHAR_LIMIT = 1000;

export const CommentFeedbackForm = ({ comment }: CommentFeedbackFormProps) => {
  const [selectedTypes, setSelectedTypes] = useState<FeedbackCategory[]>([]);
  const [feedbackInputValue, setFeedbackInputValue] = useState<string>("");
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [isSectionOpen, setIsSectionOpen] = useState<boolean>(true);
  const [showCharacterCount, setShowCharacterCount] = useState<boolean>(false);
  const [inputHeight, setInputHeight] = useState<number>(90);

  const inputContainerRef = useRef<HTMLDivElement>();
  const { getResource } = useResource();

  useEffect(() => {
    setCanSubmit(feedbackInputValue?.trim().length > 0 && selectedTypes.length > 0);
  }, [feedbackInputValue, selectedTypes]);

  const disabledFeedbackTypes = [];
  if (!comment.hasRecommendation) {
    disabledFeedbackTypes.push(FeedbackCategory.recommendations);
  }
  if (!comment.commentPolarity) {
    disabledFeedbackTypes.push(FeedbackCategory.commentSentiment);
  }
  if (
    !comment.categorizedTopics ||
    (comment.categorizedTopics && Object.keys(comment.categorizedTopics).length === 0)
  ) {
    disabledFeedbackTypes.push(FeedbackCategory.topicInsights);
  }

  const handleClickType = (type: FeedbackCategory) => {
    if (selectedTypes.includes(type)) {
      setSelectedTypes(selectedTypes.filter((st) => st !== type));
    } else {
      setSelectedTypes(selectedTypes.concat(type));
    }
  };

  const handleSubmit = () => {
    sendFeedback(comment.id, feedbackInputValue, selectedTypes)
      .then(() => setIsSuccess(true))
      .catch((error) => console.error("Error submitting feedback", error.message));
  };

  const handleBlur = () => {
    setShowCharacterCount(false);
    setInputHeight(76);
  };

  const handleFocus = () => {
    setShowCharacterCount(true);
    setInputHeight(58);
  };

  return (
    <CollapsibleName
      name={
        <SectionTitle
          mt={10}
          color={Color.gray30}
          titleKey="comments.detailsPopup.feedbackForm.header"
        />
      }
      isExpanded={isSectionOpen}
      onClick={() => setIsSectionOpen(!isSectionOpen)}
      customStyles={{ labelMargin: 6, arrowColor: Color.gray30, marginBottom: 0 }}
    >
      <StyledFeedbackContainer>
        <StyledTitle>
          <Text resource="comments.detailsPopup.feedbackForm.title" />
        </StyledTitle>
        <StyledSubtitle>
          <Text resource="comments.detailsPopup.feedbackForm.caption" />
        </StyledSubtitle>
        <StyledFeedbackTypePillList>
          {Object.keys(FeedbackCategory).map((ft) => (
            <FeedbackTypePill
              key={ft}
              isSelected={selectedTypes.includes(FeedbackCategory[ft])}
              isDisabled={disabledFeedbackTypes.includes(FeedbackCategory[ft])}
              type={FeedbackCategory[ft]}
              handleClick={handleClickType}
              isSuccess={isSuccess}
            />
          ))}
        </StyledFeedbackTypePillList>
        <StyledInputContainer ref={inputContainerRef}>
          <StyledInputField
            maxLength={FEEDBACK_CHAR_LIMIT}
            value={feedbackInputValue}
            height={inputHeight}
            onChange={(e) => setFeedbackInputValue(e.target.value)}
            placeholder={getResource("comments.detailsPopup.feedbackForm.placeholder")}
            isSuccess={isSuccess}
            disabled={isSuccess}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
          {showCharacterCount && (
            <StyledCharacterCount>
              <span>{feedbackInputValue.length}</span>
              <Text
                resource={{
                  key: "input.validation.characterCount",
                  args: [`${FEEDBACK_CHAR_LIMIT}`],
                }}
              />
            </StyledCharacterCount>
          )}
        </StyledInputContainer>
        {isSuccess ? (
          <StyledSuccessMessage>
            <Icon
              type={IconType.checkCircle}
              color={Color.green50}
              size={16}
              style={{ marginRight: 10, minWidth: 16 }}
            />
            <Text resource="comments.detailsPopup.feedbackSuccess.message" />
          </StyledSuccessMessage>
        ) : (
          <Button
            variant={ButtonVariant.primaryPurple}
            size={ButtonSize.sm}
            onClick={handleSubmit}
            disabled={!canSubmit}
          >
            <Text resource="button.sendFeedback" />
          </Button>
        )}
      </StyledFeedbackContainer>
    </CollapsibleName>
  );
};

const StyledFeedbackContainer = styled.div`
  padding: 8px 14px;
`;

const StyledTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
  color: ${Color.gray50};
`;

const StyledSubtitle = styled.div`
  font-size: 14px;
  color: ${Color.gray50};
  margin-bottom: 8px;
`;

const StyledFeedbackTypePillList = styled.div`
  display: flex;
`;

const StyledInputContainer = styled.div`
  width: 100%;
  height: 90px;
  border: 1px solid ${Color.sky50};
  margin: 4px 0;
  position: relative;
  padding-bottom: 80px;

  :focus-within {
    border: 1px solid ${Color.blue50};
  }
`;

const StyledInputField = styled.textarea<{ isSuccess: boolean; height: number }>`
  width: 100%;
  height: ${({ height }) => height}px;
  border-radius: 2px;
  padding: 8px 12px 8px 12px;
  font-size: 0.875em;
  font-family: Lato;
  color: ${Color.gray50};
  opacity: 0.75;
  margin: 4px 0 20px 0;
  resize: none;
  border: none;

  ::placeholder {
    color: ${Color.gray20};
  }
  :focus {
    outline: none;
  }
`;

const StyledCharacterCount = styled.div`
  color: ${Color.gray40};
  font-size: 0.875em;
  position: absolute;
  left: 12px;
  background-color: ${Color.white};
  width: calc(100% - 24px);
  bottom: 8px;
  span {
    font-weight: bold;
  }
`;

const StyledSuccessMessage = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875em;
  color: ${Color.gray50};
`;
