import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "store";

import { useSharingContext } from "context/SharingContext";
import { useResource } from "hooks/useResource";
import { GetRecipientsUsersParams, unshare } from "services/analysis/sharing";
import { formatSearchForFetchRequest } from "utils/formatters";

import { PermissionLevelCell } from "pages/analysis/[id]/sharing/_layouts/PermissionLevelCell";
import { FirstNameCell } from "pages/analysis/[id]/sharing/_layouts/SharingBuilder/FlexTableCells/FirstNameCell";
import { FlexTable, FlexTableSorting } from "components/FlexTable";
import { Header } from "./Header";
import { EmptyState } from "components/EmptyState";
import { LastNameCell } from "./FlexTableCells/LastNameCell";
import { EmailCell } from "./FlexTableCells/EmailCell";
import { EditSharingBlock } from "./EditSharingBlock";
import { routes } from "routes";

import { EmptyStateType } from "ts/enums/emptyStateType";
import { ShareStep, SharingView } from "ts/enums/sharing";
import { RecipientUsersSortingParameter, SortingOrder } from "@explorance/mly-types";
import { Color } from "ts/enums/color";
import { useQueryParams } from "hooks/useQueryParams";
import { getUrlQueryString } from "utils/getUrlQueryString";
import { DEFAULT_LIST_LIMIT } from "assets/constants/listLimits";
import { FlexTableType } from "ts/enums/flexTable";
import { setSearchTerm } from "store/search/searchbarSlice";

const USERS_SHARED_WITH_HEADERS = [
  { columnNameKey: "firstName", sortingParameter: RecipientUsersSortingParameter.FirstName },
  { columnNameKey: "lastName", sortingParameter: RecipientUsersSortingParameter.LastName },
  { columnNameKey: "email", sortingParameter: RecipientUsersSortingParameter.Email },
  {
    columnNameKey: "permissionLevel",
    sortingParameter: RecipientUsersSortingParameter.PermissionLevel,
  },
];

type RefetchParams = {
  updatedPage?: number;
  order?: SortingOrder;
  column?: RecipientUsersSortingParameter;
};

export const UsersSharedWith = () => {
  const searchTerm = useAppSelector((state) => state.search.searchTerm);
  const dispatch = useAppDispatch();
  const [sortingColumn, setSortingColumn] = useState<RecipientUsersSortingParameter>(
    RecipientUsersSortingParameter.FirstName
  );
  const [sortOrder, setSortOrder] = useState<SortingOrder>(SortingOrder.DESC);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [expandedRowId, setExpandedRowId] = useState<number>(null);
  const [returningFromPreview, setReturningFromPreview] = useState<boolean>(false);

  const { sharingState, refetchSharedUsersData, updateSharingState } = useSharingContext();

  const { getResource } = useResource();
  const history = useHistory();

  const analysisId = parseInt(useParams<{ analysisId: string }>().analysisId);
  const { expandedRowId: expandedRowIdFromUrl, sharedWithPage } = useQueryParams();

  useEffect(() => {
    if (expandedRowIdFromUrl) {
      setExpandedRowId(parseInt(expandedRowIdFromUrl));
      setCurrentPage(parseInt(sharedWithPage));
      refetch({ updatedPage: parseInt(sharedWithPage) });
      setReturningFromPreview(true);
      //remove &expandedRowId from URL
      const params = new URLSearchParams(history.location.search);
      params.delete("expandedRowId");
      params.delete("sharedWithPage");
      history.replace(history.location.pathname + "?" + params.toString());
    }

    return () => {
      setSortingColumn(RecipientUsersSortingParameter.FirstName);
      setSortOrder(SortingOrder.DESC);
      setCurrentPage(1);
      setExpandedRowId(null);
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    refetch({ order: sortOrder, column: sortingColumn, updatedPage: 1 });
  }, [sortOrder, sortingColumn, searchTerm]); // eslint-disable-line

  const refetch = (options: RefetchParams = {}) => {
    const { updatedPage, order, column } = options;
    const fetchParams: GetRecipientsUsersParams = {
      analysisId,
      page: updatedPage ?? currentPage,
      search: formatSearchForFetchRequest(searchTerm),
      sort_column: column,
      sort_order: order,
      limit: DEFAULT_LIST_LIMIT,
    };
    refetchSharedUsersData(fetchParams);
  };

  const navigateToShareEditPage = () => {
    updateSharingState("step", ShareStep.UserSelection);
    updateSharingState("view", SharingView.ShareToUsers);
    history.push(
      routes.sharingPage(analysisId) +
        getUrlQueryString({ view: SharingView.ShareToUsers, step: ShareStep.UserSelection })
    );
  };

  const deleteSharedUser = async (sharingId: number) => {
    await unshare(sharingId)
      .then(() => {
        if (expandedRowId) setExpandedRowId(null);
        setReturningFromPreview(false);
        refetch({});
      })
      .catch((err) => console.error(err));
  };

  const updateSorting = (updatedSorting: FlexTableSorting) => {
    const { columnIndex, order } = updatedSorting;
    setSortingColumn(USERS_SHARED_WITH_HEADERS[columnIndex].sortingParameter);
    setSortOrder(order);
    setCurrentPage(1);
  };

  const expandCollapseEditBlock = (userId: number) => {
    setExpandedRowId(expandedRowId === userId ? null : userId);
    setReturningFromPreview(false);
  };

  return (
    <>
      <Header
        pageTitle={getResource("sharing.title.users")}
        searchCountDataType="searchBar.dataType.userList"
        placeholderText={getResource("sharing.searchBar.placeholder.users")}
        currentPage={currentPage}
        updatePage={(updatedPage) => {
          setCurrentPage(updatedPage);
          refetch({ updatedPage });
        }}
      />
      {sharingState.sharedUsersApiData.totalCount === 0 ? (
        <EmptyState
          type={searchTerm ? EmptyStateType.noSearchResults : EmptyStateType.noSharedUsers}
          customStyles={{ marginTop: "85px", marginBottom: "85px" }}
          handleClickCaptionLink={
            searchTerm.length > 0 ? () => dispatch(setSearchTerm("")) : navigateToShareEditPage
          }
        />
      ) : (
        <FlexTable
          type={FlexTableType.Card}
          data={{
            headers: USERS_SHARED_WITH_HEADERS.map((header) =>
              getResource(`sharing.table.${header.columnNameKey}`)
            ),
            rows: sharingState.sharedUsersApiData.users.map((user) => ({
              contentId: user.id,
              data: [
                <FirstNameCell key={user.id} user={user} />,
                <LastNameCell key={user.id} user={user} />,
                <EmailCell key={user.id} user={user} />,
                <PermissionLevelCell
                  key={user.id}
                  permissionLevel={user.permissionLevel}
                  editButtonStyle={
                    expandedRowId === user.id
                      ? { backgroundColor: Color.gray50, color: Color.white }
                      : null
                  }
                  disabled={expandedRowId && expandedRowId !== user.id}
                  handleClickEdit={() => expandCollapseEditBlock(user.id)}
                  handleClickDelete={() => deleteSharedUser(user.sharingId)}
                />,
              ],
              expandedContent: (
                <EditSharingBlock
                  show={expandedRowId === user.id}
                  expandedRowId={expandedRowId}
                  sharedWithPage={currentPage}
                  sharingId={user.sharingId}
                  closeShowEditSharing={() => expandCollapseEditBlock(user.id)}
                  returningFromPreview={returningFromPreview}
                  refetch={() => refetch({ order: sortOrder, column: sortingColumn })}
                />
              ),
              isDisabled: expandedRowId && expandedRowId !== user.id,
              isSelected: expandedRowId === user.id,
            })),
            columnWidths: ["20%", "20%", "20%", "35%"],
          }}
          customStyles={{
            rows: {
              backgroundColor: Color.white,
              flexWrap: "wrap",
            },
          }}
          initialSorting={{
            columnIndex: USERS_SHARED_WITH_HEADERS.findIndex(
              (h) => h.sortingParameter === sortingColumn
            ),
            order: sortOrder,
          }}
          onSortingChange={updateSorting}
        />
      )}
    </>
  );
};
