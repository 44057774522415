import { mlyApi, getHeaders } from "config/mlyApi";
import { FeedbackCategory } from "ts/enums/feedbackCategory";

export async function sendFeedback(
  commentId: number,
  comment: string,
  feedbackType: FeedbackCategory[]
) {
  const requestConfig = await getHeaders();
  const requestBody = {
    comment,
    topic: feedbackType.includes(FeedbackCategory.topicInsights),
    sentiment: feedbackType.includes(FeedbackCategory.commentSentiment),
    recommendation: feedbackType.includes(FeedbackCategory.recommendations),
  };
  const url = `/comments/${commentId}/feedback`;
  return mlyApi.post<any>(url, requestBody, requestConfig);
}
