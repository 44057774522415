import React from "react";
import styled from "styled-components";
import { Color } from "ts/enums/color";
import { validatePasswordIndividualRequirements } from "utils/validation";
import { Tooltip } from "components/Tooltip";
import { Text } from "components/Text";

type Props = {
  id: string;
  password: string;
};

export const PasswordHelpTooltip = ({ id, password }: Props) => {
  const passwordValidation = password && validatePasswordIndividualRequirements(password);

  return (
    <StyledTooltipContainer>
      <Tooltip tooltipId={id} place="top" backgroundColor={Color.white} className="tooltip">
        <div>
          <Text resource="userProfile.passwordTitle.tooltip" />
        </div>
        <ul>
          <li className={password && passwordValidation.hasUppercase ? "valid" : undefined}>
            <span>
              <Text resource="userProfile.passwordUpperCase.tooltip" />
            </span>
          </li>
          <li className={password && passwordValidation.hasLowercase ? "valid" : undefined}>
            <span>
              <Text resource="userProfile.passwordLowerCase.tooltip" />
            </span>
          </li>
          <li className={password && passwordValidation.hasSpecialCharacter ? "valid" : undefined}>
            <span>
              <Text resource="userProfile.passwordSpecialChar.tooltip" />
            </span>
          </li>
          <li className={password && passwordValidation.hasDigit ? "valid" : undefined}>
            <span>
              <Text resource="userProfile.passwordHelpDigit.tooltip" />
            </span>
          </li>
          <li className={password && passwordValidation.minimumLength ? "valid" : undefined}>
            <span>
              <Text resource="userProfile.passwordLength.tooltip" />
            </span>
          </li>
        </ul>
      </Tooltip>
    </StyledTooltipContainer>
  );
};

const StyledTooltipContainer = styled.div`
  .tooltip {
    box-shadow: 0px 2px 8px #00000026;
    padding: 20px 30px;
  }

  div {
    color: ${Color.black};
    font-weight: bold;
    font-family: Lato, sans-serif;
    font-size: 16px;
  }

  ul {
    padding-left: 15px;
  }

  li {
    color: ${Color.red50};
    font-size: 1.125em;
    margin-bottom: 3px;

    &.valid {
      color: ${Color.green50};
    }
  }

  li span {
    color: ${Color.gray40};
    font-family: Lato, sans-serif;
    font-size: 13px;
    display: inline-block;
  }
`;
