import React, { useState, useMemo, useEffect, ReactNode } from "react";
import styled from "styled-components";

import { StyledTablePageTitle } from "components/Table/TablePageHeader";
import { SearchRow } from "components/SearchRow";
import { Pagination } from "components/Pagination";
import { UserDemographicFilterMenu } from "pages/analysis/[id]/sharing/_layouts/UserDemographicFilterMenu";
import { ExpandableFilterPillWrapper } from "common-layouts/FilterSelectionSection/FilterPillList/ExpandableFilterPillWrapper";
import { Button } from "components/_buttons/Button";
import { StyledDropdownTransition } from "components/DropdownMenu/StyledDropdownTransition";
import { FilterPillContainer } from "common-layouts/FilterSelectionSection/FilterPillList/FilterPillContainer";

import { useResource } from "hooks/useResource";
import { useSharingContext } from "context/SharingContext";
import useClickOutside from "hooks/useClickOutside";
import { getRandomNumber } from "utils/getRandomNumber";

import { Color } from "ts/enums/color";
import { UserDemographic } from "context/UserProfileContext";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { FilterType } from "ts/filters/filterType";
import { FilterPillStyle } from "ts/enums/filterPillStyle";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { SharingView } from "ts/enums/sharing";
import { Text } from "components/Text";

const SHARING_PAGE_LIMIT = 30;

type Props = {
  pageTitle: string;
  searchCountDataType: string;
  placeholderText?: string;
  currentPage: number;
  showUserDemographicFilters?: boolean;
  isUserDemographicFiltersReset?: boolean;
  totalItemsCount?: number;
  currentItemsCount?: number;
  isModal?: boolean;
  updatePage: (updatedPage: number) => void;
  refetchList?: (demographicFilters: UserDemographic[]) => void;
};

export const Header = ({
  pageTitle,
  searchCountDataType,
  placeholderText,
  currentPage,
  showUserDemographicFilters = false,
  isUserDemographicFiltersReset,
  totalItemsCount = null,
  currentItemsCount = null,
  isModal = false,
  updatePage,
  refetchList,
}: Props) => {
  const [localSelectedDemographicFilters, setLocalSelectedDemographicFilters] = useState<
    UserDemographic[]
  >([]);
  const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);
  const { sharingState, updateSharingState } = useSharingContext();

  const userDemographicFilterRef = useClickOutside(() => setShowFilterDropdown(false));

  const selectedFilterIds = useMemo(() => {
    return sharingState.selectedUserDemographicFilters.map((f) => ({
      filterName: f.name,
      id: getRandomNumber(),
    }));
  }, [sharingState.selectedUserDemographicFilters]);

  useEffect(() => {
    if (!showFilterDropdown) resetLocalFilters();
  }, [showFilterDropdown]); // eslint-disable-line

  const { getResource } = useResource();

  if (currentItemsCount === null) {
    currentItemsCount =
      sharingState.view === SharingView.UsersSharedWith
        ? sharingState.sharedUsersApiData.itemCount
        : sharingState.view === SharingView.GroupsSharedWith
        ? sharingState.groupsApiData.itemCount
        : sharingState.shareableUsersApiData.itemCount;
  }

  if (totalItemsCount === null) {
    totalItemsCount =
      sharingState.view === SharingView.UsersSharedWith
        ? sharingState.sharedUsersApiData.totalCount
        : sharingState.view === SharingView.GroupsSharedWith
        ? sharingState.groupsApiData.totalCount
        : sharingState.shareableUsersApiData.totalCount;
  }

  useEffect(() => {
    if (isUserDemographicFiltersReset) setLocalSelectedDemographicFilters([]);
  }, [isUserDemographicFiltersReset]);

  const applyFilters = () => {
    const filters = localSelectedDemographicFilters.filter((df) => df.values.length > 0);
    updateSharingState("selectedUserDemographicFilters", filters);
    setLocalSelectedDemographicFilters(filters);
    refetchList(filters);
  };

  const deleteAllFilters = () => {
    updateSharingState("selectedUserDemographicFilters", []);
    setLocalSelectedDemographicFilters([]);
    refetchList([]);
  };

  const deletePill = (filterName: string) => {
    const updatedFilters = localSelectedDemographicFilters.filter((sf) => sf.name !== filterName);
    setLocalSelectedDemographicFilters(updatedFilters);
    updateSharingState("selectedUserDemographicFilters", updatedFilters);
    refetchList(updatedFilters);
  };

  const resetLocalFilters = () => {
    const areFiltersSynced =
      sharingState.selectedUserDemographicFilters.every((sud) =>
        localSelectedDemographicFilters
          .find((lsud) => lsud.name === sud.name)
          ?.values.every((v) => sud.values.includes(v))
      ) &&
      localSelectedDemographicFilters.every((lsud) =>
        sharingState.selectedUserDemographicFilters
          .find((sud) => lsud.name === sud.name)
          ?.values.every((v) => lsud.values.includes(v))
      );

    if (
      !areFiltersSynced ||
      (sharingState.selectedUserDemographicFilters.length === 0 &&
        localSelectedDemographicFilters.length > 0)
    ) {
      setLocalSelectedDemographicFilters(sharingState.selectedUserDemographicFilters);
    }
  };

  const renderAltMenu = (
    filter: UserDemographic,
    resetLocalState: boolean,
    onSubmit: () => void
  ): ReactNode => {
    if (resetLocalState) resetLocalFilters();

    return (
      <UserDemographicFilterMenu
        selectedFilterId={selectedFilterIds.find((i) => i.filterName === filter.name)?.id}
        selectedFilter={sharingState.allUserDemographics.find((ud) => ud.name === filter.name)}
        currentlySelectedFilters={localSelectedDemographicFilters}
        allFiltersList={sharingState.allUserDemographics}
        sectionTitle={filter.name}
        selectedFilterCount={
          localSelectedDemographicFilters.find((sf) => sf.name === filter.name)?.values.length
        }
        parentCheckboxLabel={getResource("columnFilterSection.selectAll")}
        updateFilters={(filters) => setLocalSelectedDemographicFilters(filters)}
        applyFilters={() => {
          applyFilters();
          onSubmit();
        }}
      />
    );
  };

  return (
    <StyledHeaderContainer>
      <StyledHeader>
        <StyledTablePageTitle>{pageTitle}</StyledTablePageTitle>
        <SearchRow
          currentCount={currentItemsCount}
          totalCount={totalItemsCount}
          dataType={searchCountDataType}
          isModal={isModal}
          customStyles={{
            border: `1px solid ${Color.sky50}`,
            width: "480px",
          }}
          placeholderText={placeholderText}
        />
        {currentItemsCount > 0 && (
          <StyledPaginationContainer>
            <Pagination
              currentPage={currentPage}
              currentItemsCount={currentItemsCount}
              pageSize={SHARING_PAGE_LIMIT}
              handlePageSelection={updatePage}
            />
          </StyledPaginationContainer>
        )}
      </StyledHeader>
      {showUserDemographicFilters && (
        <ExpandableFilterPillWrapper
          showDeleteFilters={sharingState.selectedUserDemographicFilters.length > 0}
          deleteFilters={deleteAllFilters}
        >
          <StyledUserDemographicContainer ref={userDemographicFilterRef}>
            <Button
              variant={ButtonVariant.secondary}
              size={ButtonSize.md}
              style={{ height: 30 }}
              onClick={() => setShowFilterDropdown((show) => !show)}
            >
              <Text resource="button.filter.byUserDemographic" />
            </Button>
            <StyledDropdownTransition show={showFilterDropdown}>
              <UserDemographicFilterMenu
                allFiltersList={sharingState.allUserDemographics}
                currentlySelectedFilters={localSelectedDemographicFilters}
                sectionTitle={getResource("userList.detailsPopup.title")}
                selectAllLabel={getResource("columnFilterSection.selectAll")}
                isMenuClosed={!showFilterDropdown}
                updateFilters={(filters) => setLocalSelectedDemographicFilters(filters)}
                applyFilters={() => {
                  applyFilters();
                  setShowFilterDropdown(false);
                }}
              />
            </StyledDropdownTransition>
          </StyledUserDemographicContainer>
          {sharingState.selectedUserDemographicFilters.map((filter) => (
            <FilterPillContainer
              key={selectedFilterIds.find((i) => i.filterName === filter.name)?.id}
              filterType={FilterType.UserDemographic}
              styleType={FilterPillStyle.outlineBlue}
              demographicFilterName={filter.name}
              demographicFilterId={selectedFilterIds.find((i) => i.filterName === filter.name)?.id}
              filterCount={
                sharingState.selectedUserDemographicFilters.find((ud) => ud.name === filter.name)
                  .values.length
              }
              renderAltMenu={(resetLocalState, onSubmit) =>
                renderAltMenu(filter, resetLocalState, onSubmit)
              }
              deletePill={() => deletePill(filter.name)}
            />
          ))}
        </ExpandableFilterPillWrapper>
      )}
    </StyledHeaderContainer>
  );
};

const StyledUserDemographicContainer = styled.div`
  z-index: ${ZIndexStackingContext.medium};
`;

const StyledHeaderContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: ${Color.white};
  z-index: ${ZIndexStackingContext.medium};
  cursor: default;
`;

const StyledPaginationContainer = styled.div`
  margin-left: auto;
`;
