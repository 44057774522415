import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Checkbox } from "components/_inputs/Checkbox";
import { recursiveFindAllChildTopicNodes } from "utils/topics";
import { TopicTreeNode, ApiTopicNode } from "ts/topic";
import { find } from "lodash";
import { CheckboxCheckedAppearance } from "ts/enums/checkboxCheckedAppearance";
import { Icon, IconType } from "components/_icons/Icon";
import { Color } from "ts/enums/color";
import { Tooltip } from "components/Tooltip";
import { useResource } from "hooks/useResource";

type Props = {
  definition: TopicTreeNode;
  selectAll?: boolean;
  allSelectedNodes?: ApiTopicNode[];
  onChangeFilter: (topicFilter: ApiTopicNode) => void;
  localSelectedTopicNodes?: ApiTopicNode[];
  includeParentsForInsert?: boolean;
  isCustomModel?: boolean;
  disabled?: boolean;
};

export const FilterTopicTree = ({
  definition,
  selectAll,
  allSelectedNodes,
  onChangeFilter,
  localSelectedTopicNodes,
  includeParentsForInsert,
  isCustomModel,
  disabled,
}: Props) => {
  const [isChildVisible, setIsChildVisible] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const { getResource } = useResource();

  const currentNodeLeaves = recursiveFindAllChildTopicNodes(definition);
  const nbCurrentNodeSelectedChildren = currentNodeLeaves.filter((child) =>
    find(localSelectedTopicNodes, child)
  ).length;
  const allChildrenSelected = nbCurrentNodeSelectedChildren === currentNodeLeaves.length;

  const isInLocalSelectionArray = !!localSelectedTopicNodes.find(
    (tn) =>
      tn.id === definition.id &&
      tn.parentId === definition.id &&
      tn.fullPath === definition.fullPath
  );
  const someChildrenSelected = nbCurrentNodeSelectedChildren !== 0;
  const isDisabled =
    disabled ||
    (includeParentsForInsert &&
      (definition.isHidden ||
        currentNodeLeaves.every((cnl) =>
          allSelectedNodes.find(
            (x) => x.id === cnl.id && x.parentId === cnl.parentId && x.fullPath === cnl.fullPath
          )
        )));

  useEffect(() => {
    definition.isSelected = someChildrenSelected;
  }, [definition, someChildrenSelected]);

  const handleHoverModelName = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) setShowTooltip(true);
  };

  const getTopicName = () => {
    return isCustomModel ? definition.name : getResource(`ML.topic.[${definition.name}]`);
  };

  return (
    <StyledFilterTopicsTree isDisabled={isDisabled}>
      <div className="container">
        <div className="list-item-container">
          <StyledPlusMinusIcon
            hasChildren={definition.topics?.length > 0}
            onClick={() => setIsChildVisible(!isChildVisible)}
          >
            <Icon
              type={isChildVisible ? IconType.minus : IconType.plus}
              size={14}
              color={Color.gray30}
            />
          </StyledPlusMinusIcon>

          <Checkbox
            checked={
              (includeParentsForInsert && definition.isHidden) ||
              isInLocalSelectionArray ||
              someChildrenSelected
            }
            checkedAppearance={
              allChildrenSelected
                ? CheckboxCheckedAppearance.Default
                : CheckboxCheckedAppearance.Partial
            }
            disabled={isDisabled}
            includeParentsForInsert={includeParentsForInsert}
            onChange={() =>
              onChangeFilter({
                id: definition.id,
                parentId: definition.parentId,
                fullPath: definition.fullPath,
              })
            }
            size={14}
          />
          <div
            className="name"
            data-tip
            data-for={`${definition.id}-${definition.name}`}
            onMouseOver={handleHoverModelName}
            onMouseOut={() => setShowTooltip(false)}
            onClick={
              disabled || (includeParentsForInsert && definition.isHidden)
                ? undefined
                : () =>
                    onChangeFilter({
                      id: definition.id,
                      parentId: definition.parentId,
                      fullPath: definition.fullPath,
                    })
            }
          >
            {getTopicName()}

            {showTooltip && (
              <Tooltip tooltipId={`${definition.id}-${definition.name}`} content={getTopicName()} />
            )}
          </div>
        </div>
      </div>
      <div className="child">
        {isChildVisible &&
          definition.topics?.map((item) => (
            <FilterTopicTree
              key={`${item.id}-${item.fullPath}`}
              definition={item}
              selectAll={selectAll}
              includeParentsForInsert={includeParentsForInsert}
              disabled={disabled}
              onChangeFilter={onChangeFilter}
              allSelectedNodes={allSelectedNodes}
              localSelectedTopicNodes={localSelectedTopicNodes}
              isCustomModel={isCustomModel}
            />
          ))}
      </div>
    </StyledFilterTopicsTree>
  );
};

const StyledFilterTopicsTree = styled.div<{ isDisabled: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 6px;

  .container {
    display: flex;
    align-items: center;
  }

  .list-item-container {
    display: flex;
    align-items: center;
    max-width: 100%;

    .name {
      font-size: 14px;
      color: ${({ isDisabled }) => (isDisabled ? Color.gray30 : Color.black)};
      margin-left: 10px;
      cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  svg {
    pointer-events: none;
  }

  .child {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-left: 43px;
  }
`;

const StyledPlusMinusIcon = styled.div<{ hasChildren: boolean }>`
  cursor: ${({ hasChildren }) => (hasChildren ? "pointer" : "default")};
  visibility: ${({ hasChildren }) => (hasChildren ? "visible" : "hidden")};
`;
