import React, { useState } from "react";
import styled, { css } from "styled-components";
import { snakeCase } from "lodash";

import { SentimentIcon } from "components/_icons/SentimentIcon";
import { RecommendationIcon } from "components/_icons/RecommendationIcon";
import { RedFlagIcon } from "components/_icons/RedFlagIcon";

import {
  StatisticsType,
  RecommendationType,
  SentimentType,
  CommentsView,
} from "@explorance/mly-types";
import { StatType, RedFlag } from "ts/enums/statsMenu";
import { Color } from "ts/enums/color";
import { Icon, IconType } from "components/_icons/Icon";

import { formatStat } from "./helpers";
import { useResource } from "hooks/useResource";
import useClickOutside from "hooks/useClickOutside";

import { Checkbox } from "components/_inputs/Checkbox";
import { DropdownMenu } from "components/DropdownMenu";
import { DropdownMenuItem } from "ts/dropdown";

const StatTabIcon: React.FC<{ name: StatisticsType }> = ({ name }) => {
  const sentimentId = SentimentType[name];
  const recommendationId =
    name === StatisticsType.recommendations
      ? RecommendationType.recommendation
      : RecommendationType[name];
  const redFlagId = RedFlag[snakeCase(name).toUpperCase()];

  if (sentimentId) return <SentimentIcon type={sentimentId} />;
  if (recommendationId) return <RecommendationIcon type={recommendationId} />;
  if (redFlagId) return <RedFlagIcon type={redFlagId} />;

  return <Icon type={IconType.comment} color={Color.gray50} size={12} />;
};

type Props = {
  value: number;
  name: StatisticsType;
  type: StatType;
  isChecked: boolean;
  commentsLoading: boolean;
  originalValue: StatisticsType;
  commentsViewOptions: DropdownMenuItem[];
  handleToggleStat: (arg: StatisticsType) => void;
  handleOverrideStat: (arg: StatisticsType) => void;
  handleSelectView: (view: CommentsView) => void;
};

export const StatTab = ({
  value,
  name,
  type,
  isChecked,
  commentsLoading,
  originalValue,
  commentsViewOptions,
  handleToggleStat,
  handleOverrideStat,
  handleSelectView,
}: Props) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { getResource } = useResource();

  const statTabRef = useClickOutside(() => setShowMenu(false));

  const maxWidth =
    name === StatisticsType.generalRecommendations
      ? "85px"
      : name === StatisticsType.noRecommendations
      ? "60px"
      : "";

  const isTotal = originalValue === StatisticsType.total;
  const showCheckbox = !isTotal && (isHovered || isChecked);

  const handleStatTabClick = (e) => {
    const isCheckboxClick = (e.target as any)?.closest("input[type='checkbox']");
    if (!isCheckboxClick) {
      e.stopPropagation();
      handleOverrideStat(originalValue);
    }
  };

  const handleHeaderClick = (e) => {
    if (!(e.target instanceof HTMLInputElement)) {
      e.stopPropagation();
      isTotal ? setShowMenu((show) => !show) : handleToggleStat(originalValue);
    }
  };

  const getStatTabName = (): string => {
    if (!isTotal) return getResource(`statTab.${name}`);
    const activeTab = commentsViewOptions.filter((cvo) => cvo.isActive)[0];
    return getResource(`comments.viewDropdown.${activeTab.value}`);
  };

  const handleSelectViewOption = (view: CommentsView) => {
    if (!commentsViewOptions.find((cvo) => cvo.value === view).isActive) handleSelectView(view);
    setShowMenu(false);
  };

  const getStatCount = (): string => {
    if (name === StatisticsType.insights) {
      return value === 1
        ? getResource("statTab.count.insightsSingular")
        : getResource("statTab.count.insights", value);
    }
    return formatStat(value, type);
  };

  return (
    <StyledStatTabContainer ref={statTabRef}>
      <StyledStatTab
        onClick={handleStatTabClick}
        isChecked={isChecked}
        isLoading={commentsLoading}
        disabled={commentsLoading}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <StyledHeader maxWidth={maxWidth} showHoverEffect={isTotal} onClick={handleHeaderClick}>
          <StyledIcon>
            {showCheckbox ? (
              <Checkbox
                checked={isChecked}
                onChange={() => null}
                disabled={false}
                size={12}
                horizontalOffset={4}
              />
            ) : (
              <StatTabIcon name={name} />
            )}
          </StyledIcon>
          <span className="stat-name">{getStatTabName()}</span>
          {isTotal && (
            <Icon type={IconType.triangleFilledDown} size={8} style={{ marginRight: "2px" }} />
          )}
        </StyledHeader>
        <StyledValue>{getStatCount()}</StyledValue>
      </StyledStatTab>
      <StyledBlueLine isChecked={isChecked} />
      {isTotal && (
        <DropdownMenu
          items={commentsViewOptions}
          width={"279px"}
          show={showMenu}
          onChange={handleSelectViewOption}
        />
      )}
    </StyledStatTabContainer>
  );
};

const StyledStatTabContainer = styled.div`
  padding-right: 12px;

  &:first-of-type {
    padding-right: 8px;
  }
`;

const StyledStatTab = styled.button<{ isChecked: boolean; isLoading: boolean }>`
  position: relative;
  border-radius: 4px;
  padding: 6px 8px 3px 8px;
  margin-bottom: 4px;
  height: 62px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  background-color: ${({ isChecked }) => (isChecked ? Color.indigo10 : Color.white)};
  border: none;

  &:hover {
    cursor: ${({ isLoading, isChecked }) => (isLoading && !isChecked ? "wait" : "pointer")};
    background-color: ${Color.sky15};
  }
`;

const StyledIcon = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledHeader = styled.div<{ maxWidth: string; showHoverEffect: boolean }>`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${Color.gray50};
  border-radius: 4px;
  gap: 4px;
  transition: background-color 150ms ease-in;

  ${({ showHoverEffect }) =>
    showHoverEffect &&
    css`
      :hover {
        background-color: ${Color.blue20};
      }
    `}

  @media (max-width: 1366px) {
    .stat-name {
      max-width: ${({ maxWidth }) => maxWidth};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;

const StyledValue = styled.div`
  color: ${Color.gray50};
  font-size: 22px;
  font-family: Helvetica;
  font-weight: bold;
  padding-left: 2px;
`;

const StyledBlueLine = styled.div<{ isChecked: boolean }>`
  width: 100%;
  background-color: ${Color.indigo50};
  height: 3px;
  border-radius: 10px;
  opacity: ${({ isChecked }) => (isChecked ? 1 : 0)};
  transition: 0.3s opacity;
`;
