import { useState, useEffect } from "react";
import { FlexTableSize } from "ts/enums/flexTable";

const useTableSize = () => {
  const newWindowWidth = window.innerWidth;
  const [tableSize, setTableSize] = useState<FlexTableSize>(
    newWindowWidth < 1366 ? FlexTableSize.Limited : FlexTableSize.Full
  );

  useEffect(() => {
    const handleResize = () => {
      const newWindowWidth = window.innerWidth;

      setTableSize(newWindowWidth < 1366 ? FlexTableSize.Limited : FlexTableSize.Full);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [tableSize]);

  return tableSize;
};

export default useTableSize;
