import React, { useState } from "react";
import styled from "styled-components";

import useClickOutside from "hooks/useClickOutside";

import { Color } from "ts/enums/color";
import { Model, ModelSelectionOptions } from "ts/models";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";

import { Icon, IconType } from "components/_icons/Icon";
import { ModelOption } from "./ModelOption";
import { StyledDropdownTransition } from "components/DropdownMenu/StyledDropdownTransition";
import { Text } from "components/Text";
import { CategorizationTypeSymbol } from "components/_icons/CategorizationTypeSymbol";

const DROPDOWN_MAX_HEIGHT = 350;

type ButtonStyling = {
  borderRadius?: number;
  height?: number;
  width?: number;
};

type Props = {
  selectedModel: Model | null;
  options: ModelSelectionOptions;
  hideDefaultOption?: boolean;
  buttonStyling?: ButtonStyling;
  handleSelectModel: (selectedModel: Model | null) => void;
  isDisabled?: boolean;
  customModelSelection?: boolean;
  selectedEnv?: string;
};

export const ModelSelection = ({
  selectedModel,
  options,
  hideDefaultOption,
  buttonStyling,
  handleSelectModel,
  customModelSelection,
  isDisabled,
  selectedEnv,
}: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [openMenuFromTop, setOpenMenuFromTop] = useState<boolean>(true);
  let modelList = customModelSelection ? options.customModels : options.models;

  // Filter custom models by selected environment
  if (customModelSelection && modelList && selectedEnv) {
    const customModelListFilteredBySelectedEnv = {};
    Object.keys(modelList)?.forEach((key) => {
      customModelListFilteredBySelectedEnv[key] = modelList[key].filter(
        (model: Model) => model?.env === selectedEnv
      );
    });
    modelList = customModelListFilteredBySelectedEnv;
  }

  const modelSelectionRef = useClickOutside(() => {
    setIsMenuOpen(false);
  });

  const getButtonName = () => {
    if (selectedModel) {
      return (
        <ModelOption
          modelOption={selectedModel}
          isButton={true}
          isActive={false}
          handleSelectModel={() => undefined}
        />
      );
    }

    return customModelSelection ? (
      <Text resource="customModelSelection.placeholder" />
    ) : (
      <StyledDefaultSelectedOption>
        <CategorizationTypeSymbol categorizationType={null} />
        <Text resource="modelSelection.defaultOption" />
      </StyledDefaultSelectedOption>
    );
  };

  const handleClickModelOption = (selectedModel: Model | null) => {
    setIsMenuOpen(false);
    handleSelectModel(selectedModel as Model | null);
  };

  const handleClickModelButton = () => {
    setIsMenuOpen((isOpen) => !isOpen);
    const containerBottom = modelSelectionRef.current.getBoundingClientRect().bottom;
    setOpenMenuFromTop(containerBottom > window.innerHeight);
  };

  return (
    <StyledModelSelection ref={modelSelectionRef}>
      <StyledModelSelectionButton
        buttonStyling={buttonStyling}
        onClick={isDisabled ? undefined : handleClickModelButton}
        isDisabled={isDisabled}
        isError={!customModelSelection && selectedModel?.isOutdated}
      >
        {getButtonName()}
        <Icon type={IconType.triangleFilledDown} color={Color.gray20} size={8} />
      </StyledModelSelectionButton>
      <StyledModelDropdown
        removePaddingTop={hideDefaultOption}
        width={buttonStyling?.width ?? 340}
        show={isMenuOpen}
        openMenuFromTop={openMenuFromTop}
      >
        {hideDefaultOption || customModelSelection ? (
          !hideDefaultOption && (
            <StyledDefaultOption
              isDefaultActive={!selectedModel}
              onClick={() => {
                handleClickModelOption(null);
              }}
            >
              <Text resource="dropdown.none" />
            </StyledDefaultOption>
          )
        ) : (
          <StyledDefaultOption
            isDefaultActive={!selectedModel}
            onClick={() => {
              handleClickModelOption(null);
            }}
          >
            <CategorizationTypeSymbol categorizationType={null} />
            <Text resource="modelSelection.defaultOption" />
          </StyledDefaultOption>
        )}

        {modelList &&
          Object.keys(modelList)?.map((cat) => (
            <div key={cat}>
              {modelList?.[cat]?.map((model, i) => (
                <div key={i}>
                  <ModelOption
                    key={customModelSelection ? model.customModelId : model.graphId}
                    modelOption={model}
                    isActive={
                      customModelSelection
                        ? selectedModel?.customModelId === model.customModelId
                        : selectedModel?.graphId === model.graphId
                    }
                    handleSelectModel={handleClickModelOption}
                  />
                </div>
              ))}
            </div>
          ))}
      </StyledModelDropdown>
    </StyledModelSelection>
  );
};

const StyledModelSelection = styled.div`
  position: relative;
  width: 340px;
`;

const StyledModelSelectionButton = styled.button<{
  buttonStyling: ButtonStyling;
  isDisabled?: boolean;
  isError?: boolean;
}>`
  background-color: ${({ isDisabled }) => (isDisabled ? Color.neutral10 : Color.white)};
  color: ${({ isDisabled }) => (isDisabled ? Color.gray20 : Color.gray50)};
  border: 1px solid ${({ isError }) => (isError ? Color.red50 : Color.sky50)};
  padding: 7px 10px;
  border-radius: ${({ buttonStyling: buttonStyling }) => buttonStyling?.borderRadius ?? 2}px;
  display: flex;
  align-items: center;
  height: ${({ buttonStyling: buttonStyling }) => buttonStyling?.height ?? 34}px;
  font-size: 15px;
  width: ${({ buttonStyling: buttonStyling }) => buttonStyling?.width ?? 340}px;
  cursor: ${({ isDisabled }) => (isDisabled ? "default" : "inset")};

  svg {
    margin-left: auto;
  }
  * :not(span) {
    color: ${({ isDisabled }) => (isDisabled ? Color.gray20 : "inset")};
  }
`;

const StyledModelDropdown = styled(StyledDropdownTransition)<{
  removePaddingTop: boolean;
  width: number;
  show: boolean;
  openMenuFromTop: boolean;
}>`
  position: absolute;
  width: ${({ width }) => width ?? 340}px;
  background: ${Color.white};
  border: 1px solid ${Color.sky50};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin: ${({ openMenuFromTop }) => (openMenuFromTop ? "0 0 2px 0" : "2px 0 0 0")};
  color: ${Color.gray50};
  font-size: 15px;
  padding: ${({ removePaddingTop }) =>
    removePaddingTop ? "0px 10px 12px 6px" : "12px 10px 12px 6px"};
  max-height: ${DROPDOWN_MAX_HEIGHT}px;
  overflow-y: auto;
  z-index: ${ZIndexStackingContext.low + 1};
  bottom: ${({ openMenuFromTop }) => openMenuFromTop && "100%"};

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${Color.gray20};
  }

  ::-webkit-scrollbar-track {
    background: ${Color.sky20};
    border-radius: 5px;
    margin: 2px 0px;
  }
`;

const StyledDefaultSelectedOption = styled.div`
  color: ${Color.gray50};
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 1px;
`;

const StyledDefaultOption = styled.div<{ isDefaultActive: boolean }>`
  color: ${({ isDefaultActive }) => (isDefaultActive ? Color.blue50 : Color.gray50)};
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 4px 8px;

  &:hover {
    color: ${({ isDefaultActive }) => !isDefaultActive && Color.blue50};
    background-color: ${({ isDefaultActive }) => !isDefaultActive && Color.neutral10};
    cursor: ${({ isDefaultActive }) => !isDefaultActive && "pointer"};
  }
`;
