import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { useResource } from "hooks/useResource";
import { AnalysisContext } from "context/AnalysisContext";
import { setThreshold } from "context/AnalysisContext/actions";

import { ApplyFiltersButton } from "components/_buttons/ApplyFilterButton";
import { FilterMenuTitle } from "components/FilterMenuTitle";
import { NumberInput } from "components/_inputs/NumberInput";

import { MAXIMUM_COMMENTS_THRESHOLD } from "assets/constants/maximumCommentsThreshold";
import { Color } from "ts/enums/color";
import { Text } from "components/Text";

type Props = {
  resetLocalState: boolean;
  onSubmit: () => void;
};

export const ThresholdMenu = ({ resetLocalState, onSubmit }: Props) => {
  const [state, dispatch] = useContext(AnalysisContext);

  const [localThreshold, setLocalThreshold] = useState<number>(null);

  useEffect(() => {
    setLocalThreshold(state.threshold);
  }, [state.threshold, resetLocalState]);

  const { getResource } = useResource();

  const applyThresholdHandler = () => {
    dispatch(setThreshold(localThreshold));
    onSubmit();
  };

  return (
    <StyledThresholdMenu>
      <FilterMenuTitle title={getResource("filter.threshold.label")} />
      <StyledThresholdCaption>
        <Text resource="filter.threshold.caption" />
      </StyledThresholdCaption>
      <NumberInput
        value={localThreshold}
        minimum={0}
        maximum={MAXIMUM_COMMENTS_THRESHOLD}
        allowNull
        updateOnType
        hideIncrementButtons={false}
        styling={{
          width: "120px",
          border: `1px solid ${Color.sky50}`,
          height: "36px",
          padding: "6.5px 9px",
          focusBorder: `1px solid ${Color.indigo50}`,
        }}
        handleChange={setLocalThreshold}
      />
      <ApplyFiltersButton handleClick={applyThresholdHandler} />
    </StyledThresholdMenu>
  );
};

const StyledThresholdMenu = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 10px;
  font-size: 0.875em;
  width: 140px;
  row-gap: 8px;
`;

const StyledThresholdCaption = styled.div`
  font-size: 12px;
`;
