import React from "react";
import styled from "styled-components";
import { Icon, IconType } from "components/_icons/Icon";
import { Color } from "ts/enums/color";
import { Text } from "components/Text";

type Props = {
  resourceKey: string;
  disabled?: boolean;
  onClick(): void;
};

export const BackButton = ({ resourceKey, disabled, onClick }: Props) => {
  return (
    <StyledBackButton disabled={disabled} onClick={disabled ? undefined : onClick}>
      <Icon type={IconType.chevronLeft} color={Color.blue50} size={14} />
      <span className="back-caption">
        <Text resource={resourceKey} />
      </span>
    </StyledBackButton>
  );
};

type StyledProps = {
  disabled: boolean;
};

const StyledBackButton = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  margin: 0 20px 0 -7px;
  color: ${(props) => (props.disabled ? Color.neutral40 : Color.blue50)};
  font-weight: bold;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  .back-caption {
    margin-bottom: 3px;
    font-size: 16px;
    min-width: 121px;
    margin-left: 5px;
  }
`;
