import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import { SortingOrder } from "@explorance/mly-types";
import { Icon, IconType } from "components/_icons/Icon";
import { Checkbox } from "components/_inputs/Checkbox";
import { Color } from "ts/enums/color";
import { CheckboxCheckedAppearance } from "ts/enums/checkboxCheckedAppearance";
import { FlexTableType } from "ts/enums/flexTable";
import useTableSize from "hooks/useTableSize";
import { Skeleton } from "components/Skeleton";
import { Row } from "./Row";
import { StyledBaseRow, StyledCell } from "./commonStyles";
import { PlaceholderRows } from "./PlaceholderRows";
import { DropdownMenuItem } from "ts/dropdown";

export const CHECKBOX_CELL_WIDTH = "32px";

export type RowDotsMenuParams = {
  hideDotsButton?: boolean;
  rowId: number;
  currentEditedRowId: number;
  isDeleting?: boolean;
  menuContents: DropdownMenuItem[];
  altDropdownContent?: ReactElement;
  onDotMenuClick?: () => void;
  handleHideUserDetails?: () => void;
  clickToClose?: boolean;
};

// /!\ For the component to work properly, ensure the shape of `data` is coherent:
// headers.length should equal rows[i].length (for i in [0, rows.length])
// columnWidths.length should be equal to headers.length
// selectedStatuses.length should be equal to headers.length

type FlexTableData = {
  headers: ReactNode[];
  rows: {
    data: ReactNode[];
    isSelected?: boolean;
    contentId?: number;
    expandedContent?: ReactNode;
    isDisabled?: boolean;
    dotsMenuParams?: RowDotsMenuParams;
  }[];
  columnWidths: string[];
};

export type FlexTableSorting = {
  columnIndex: number;
  order: SortingOrder;
};

type FlexTableCustomStyles = {
  rows: {
    backgroundColor?: string;
    padding?: string;
    flexWrap?: string;
    rowHeight?: string;
  };
};

type Props = {
  data: FlexTableData;
  onSortingChange?: (updatedSorting: FlexTableSorting) => void;
  onSelectionChange?: (contentId?: number) => void;
  handleSelectAll?: () => void;
  handleClickOutside?: () => void;
  handleDoubleClickRow?: (contentId: number) => void;
  initialSorting?: FlexTableSorting;
  isSelectable?: boolean;
  customStyles?: FlexTableCustomStyles;
  maxHeight?: string;
  isLoading?: boolean;
  disableSorting?: boolean;
  type: FlexTableType;
};

export const FlexTable = ({
  data,
  initialSorting,
  onSortingChange,
  onSelectionChange,
  handleSelectAll,
  handleClickOutside,
  handleDoubleClickRow,
  maxHeight,
  disableSorting = false,
  isSelectable,
  customStyles,
  isLoading,
  type,
}: Props) => {
  const [localSorting, setLocalSorting] = useState<FlexTableSorting>(
    initialSorting || { columnIndex: 0, order: SortingOrder.DESC }
  );

  const nbSelectedRows = data.rows?.filter((r) => r.isSelected).length;
  const tableSize = useTableSize();
  const [tableRowContainerYPos, setTableRowContainerYPos] = useState<number | null>(null);
  const [tableRowContainerRef, setTableRowContainerRef] = useState<HTMLDivElement | null>(null);
  const [tableWidth, setTableWidth] = useState<number | undefined>(0);

  // If maxHeight is not provided, the table will take the remaining space and 100px left for padding
  const tableMaxHeight =
    maxHeight || (tableRowContainerYPos && `calc(100vh - ${tableRowContainerYPos}px - 100px)`);

  useEffect(() => {
    if (tableRowContainerRef) {
      setTableRowContainerYPos(tableRowContainerRef.getBoundingClientRect().top);
    }
  }, [tableRowContainerRef]);

  useEffect(() => {
    setLocalSorting(initialSorting || { columnIndex: 0, order: SortingOrder.DESC });
  }, [tableSize, initialSorting]);

  const updateSorting = (columnIndex: number, newOrder: SortingOrder) => {
    const newLocalSorting = { columnIndex, order: newOrder };
    setLocalSorting(newLocalSorting);
    onSortingChange && onSortingChange(newLocalSorting);
  };

  const toggleSorting = (columnIndex: number) => {
    const newOrder =
      localSorting.columnIndex === columnIndex && localSorting.order === SortingOrder.DESC
        ? SortingOrder.ASC
        : SortingOrder.DESC;
    updateSorting(columnIndex, newOrder);
  };

  const renderHeaderCell = (header, index) => (
    <StyledHeaderCell
      key={index}
      width={data.columnWidths[index]}
      onClick={() => !isLoading && !disableSorting && toggleSorting(index)}
      isLoading={isLoading}
      isSortable={!disableSorting}
    >
      {isLoading ? (
        <Skeleton
          width={(tableWidth * (parseFloat(data.columnWidths[index]) * 0.7)) / 100}
          height={10}
        />
      ) : (
        header
      )}
      {localSorting.columnIndex === index && !isLoading && !disableSorting && (
        <Icon
          type={IconType.arrowDown}
          size={12}
          color={Color.gray50}
          style={{
            marginLeft: 10,
            transform: localSorting.order === SortingOrder.ASC ? "rotate(180deg)" : "none",
          }}
        />
      )}
    </StyledHeaderCell>
  );

  return (
    <StyledFlexTable type={type} ref={(ref) => setTableWidth(ref?.getBoundingClientRect().width)}>
      <StyledHeader type={type} isLoading={isLoading} className={isLoading ? "pulse" : ""}>
        {isSelectable && (
          <StyledHeaderCell width={CHECKBOX_CELL_WIDTH} isSortable={!disableSorting}>
            <Checkbox
              onChange={handleSelectAll}
              checked={nbSelectedRows > 0}
              checkedAppearance={
                nbSelectedRows < data.rows.length
                  ? CheckboxCheckedAppearance.Partial
                  : CheckboxCheckedAppearance.Default
              }
            />
          </StyledHeaderCell>
        )}
        {data.headers.map(renderHeaderCell)}
      </StyledHeader>

      <StyledTableRowContainer
        ref={(ref) => setTableRowContainerRef(ref)}
        type={type}
        maxHeight={tableMaxHeight}
      >
        {isLoading ? (
          <PlaceholderRows
            columnWidths={data.columnWidths}
            type={type}
            customStyles={customStyles?.rows}
            tableWidth={tableWidth || 0}
          />
        ) : (
          data.rows?.map((r) => (
            <Row
              key={r.contentId}
              type={type}
              row={r.data}
              contentId={r.contentId}
              isSelected={r.isSelected}
              isSelectable={isSelectable}
              customStyles={customStyles?.rows}
              columnWidths={data.columnWidths}
              isDisabled={r.isDisabled}
              expandedContent={r.expandedContent}
              handleClickOutside={handleClickOutside}
              onSelectionChange={onSelectionChange}
              dotsMenuParams={r.dotsMenuParams}
              isLoading={isLoading}
              handleDoubleClickRow={handleDoubleClickRow}
              tableWidth={tableWidth}
            />
          ))
        )}
      </StyledTableRowContainer>
    </StyledFlexTable>
  );
};

// TABLE
const StyledFlexTable = styled.div<{ type: FlexTableType }>`
  border: ${({ type }) => (type === FlexTableType.Card ? "none" : `1px solid ${Color.blue20}`)};
  border-bottom: 0;
`;

// TABLE ITEMS
const StyledHeader = styled(StyledBaseRow)<{ type: FlexTableType; isLoading: boolean }>`
  border: ${({ isLoading }) => isLoading && `1px solid ${Color.blue20}`};
  background-color: ${({ isLoading }) => (isLoading ? Color.sky10 : Color.neutral10)};
  user-select: none;
  font-weight: bold;
  font-size: 14px;
  position: sticky;
  top: 0;
  padding: ${({ type }) => type === FlexTableType.Table && "14px 18px 14px 10px "};
  border-bottom: ${({ type }) =>
    type === FlexTableType.Card ? "none" : `1px solid ${Color.blue20}`};
`;

const StyledHeaderCell = styled(StyledCell)<{ isLoading?: boolean; isSortable?: boolean }>`
  cursor: ${({ isLoading, isSortable }) => (isLoading || !isSortable ? "default" : "pointer")};
`;

const StyledTableRowContainer = styled.div<{
  maxHeight?: string;
  type: FlexTableType;
}>`
  overflow-y: auto;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
  max-height: ${({ maxHeight }) => maxHeight};
  gap: ${({ type }) => (type === FlexTableType.Card ? "10px" : "0px")};
  padding-top: ${({ type }) => (type === FlexTableType.Card ? "10px" : "0px")};
`;
