import { ExportsHistorySortingParameter, SortingOrder } from "@explorance/mly-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { fetchDownloadedFiles } from "./thunks";
import { DownloadedFile } from "ts/downloadedFiles";

type ExportedFilesListSorting = {
  sortColumn: ExportsHistorySortingParameter;
  sortOrder: SortingOrder;
};
type ExportedFilesListState = {
  exportedFilesList: DownloadedFile[];
  currentPage: number;
  totalFileCount: number;
  currentFileCount: number;
  sortColumn: ExportsHistorySortingParameter;
  sortOrder: SortingOrder;
  emptyStateType: EmptyStateType;
  showDeleteConfirmation: boolean;
  currentEditedRow: DownloadedFile;
  isLoading: boolean;
  initialLoadComplete: boolean;
  closeDropdown: boolean;
};
const initialState: ExportedFilesListState = {
  exportedFilesList: [],
  currentPage: 1,
  totalFileCount: 0,
  currentFileCount: 0,
  sortColumn: ExportsHistorySortingParameter.DateGenerated,
  sortOrder: SortingOrder.DESC,
  emptyStateType: null,
  showDeleteConfirmation: false,
  currentEditedRow: null,
  isLoading: false,
  initialLoadComplete: false,
  closeDropdown: false,
};
const ExportedFilesListSlice = createSlice({
  name: "exportedFilesListSlice",
  initialState,
  reducers: {
    setShowDeleteConfirmation: (state, action: PayloadAction<boolean>) => {
      state.showDeleteConfirmation = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setCurrentEditedRow: (state, action: PayloadAction<DownloadedFile>) => {
      state.currentEditedRow = action.payload;
    },
    updateExportedFilesListSorting: (state, action: PayloadAction<ExportedFilesListSorting>) => {
      state.currentPage = 1;
      state.sortOrder = action.payload.sortOrder;
      state.sortColumn = action.payload.sortColumn;
    },
    setEmptyStateType: (state, action: PayloadAction<EmptyStateType>) => {
      state.emptyStateType = action.payload;
    },
    setDropdownOpen: (state, action: PayloadAction<boolean>) => {
      state.closeDropdown = action.payload;
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDownloadedFiles.pending, (state) => {
      if (!state.initialLoadComplete) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchDownloadedFiles.fulfilled, (state, action) => {
      state.exportedFilesList = action.payload.exportsSummary;
      state.currentFileCount = action.payload.itemCount;
      state.totalFileCount = action.payload.totalCount;
      state.isLoading = false;
      state.initialLoadComplete = true;
    });
    builder.addCase(fetchDownloadedFiles.rejected, (state) => {
      state.isLoading = false;
      state.initialLoadComplete = true;
    });
  },
});
export const {
  setShowDeleteConfirmation,
  setCurrentPage,
  setCurrentEditedRow,
  updateExportedFilesListSorting,
  setEmptyStateType,
  setDropdownOpen,
  clearState,
} = ExportedFilesListSlice.actions;
export default ExportedFilesListSlice.reducer;
