import { CategorizationType } from "@explorance/mly-types";
import { ModelSelectionDropdownData, mapDataToCustomModels } from "ts/models";

// To be used at parent level
export const getModelSelectionDropdownData = (
  apiData,
  includeCustomModels?: boolean
): ModelSelectionDropdownData => {
  const categorizedModelList = Object.keys(apiData.models)
    .map((modelType) =>
      apiData.models[modelType].map((mo) => ({ ...mo, categorizationType: modelType }))
    )
    .flat();

  const categorizationModels =
    categorizedModelList.reduce(
      (acc, mod) => ({
        ...acc,
        [mod.categorizationType]: [...(acc[mod.categorizationType] || []), mod],
      }),
      {}
    ) || {};

  const customModels = includeCustomModels
    ? Object.keys(apiData?.customs || {}).reduce(
        (acc, k) => ({
          ...acc,
          [k]: apiData.customs[k].map((c) => mapDataToCustomModels(c, k as CategorizationType)),
        }),
        {}
      )
    : {};

  return {
    categorizedModelList,
    options: {
      models: categorizationModels,
      customModels,
    },
  };
};
