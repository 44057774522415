import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { cloneDeep } from "lodash";

import { useResource } from "hooks/useResource";

import { Kebab } from "components/_icons/Kebab";
import { PretextIcon } from "./PretextIcon";
import { MergedTopic } from "./MergedTopic";

import { ItemId, TreeItem } from "@atlaskit/tree";
import { ApiTopicNode } from "ts/topic";
import { CustomModelBuilderMode } from "ts/enums/customModelBuilderMode";
import { useCustomModelBuilder } from "..";
import { Button } from "components/_buttons/Button";
import { ButtonSize } from "ts/enums/button";
import { Icon, IconType } from "components/_icons/Icon";
import { Color } from "ts/enums/color";
import { Text } from "components/Text";

type Props = {
  item: TreeItem;
  depth: number;
  topicsSideDrawerOpen: boolean;
  onExpand: (itemId: ItemId) => void;
  onCollapse: (itemId: ItemId) => void;
  onCreateChild: (item: TreeItem) => void;
  onDeleteItem: (item: TreeItem) => void;
  onClickMapTopics: (item: TreeItem, rowName: string) => void;
  onChangeInput: (item: TreeItem) => void;
  onTreeChange: () => void;
  handleDeleteMappedTopic: (item: TreeItem, deletedTopic: ApiTopicNode) => void;
};

export const Row = ({
  item,
  depth,
  topicsSideDrawerOpen,
  onExpand,
  onCollapse,
  onDeleteItem,
  onCreateChild,
  onClickMapTopics,
  onChangeInput,
  onTreeChange,
  handleDeleteMappedTopic,
}: Props) => {
  const [inputValue, setInputValue] = useState("");
  const [hasError, setHasError] = useState(false);

  const inputRef = useRef<HTMLInputElement>();

  const ctx = useCustomModelBuilder();
  const { getResource } = useResource();

  useEffect(() => {
    setHasError(inputValue.trim().length === 0);
  }, [inputValue]);

  // Resets all values to original data when clicking "discard changes"
  useEffect(() => {
    if (item?.data?.title) setInputValue(item.data.title);
  }, [item?.data?.title]);

  useEffect(() => {
    if (ctx.mode === CustomModelBuilderMode.edit) {
      if (item.id === ctx.focusedId) {
        inputRef.current.focus();
      }
    }
  }, [ctx.focusedId, ctx.mode]); // eslint-disable-line

  const focus = () => {
    ctx.setFocusedId(item.id);
  };

  const unfocus = () => {
    ctx.setFocusedId(null);
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // > is used by the BE to split the topic name so it should not be allowed in the topic name
    if (e.target.value.includes(">")) return;
    const newItem = cloneDeep(item);
    newItem.data.title = e.target.value;
    onChangeInput(newItem);
    setInputValue(e.target.value);
    setHasError(e.target.value.length === 0);
    onTreeChange();
  };

  const handleClickRow = () => focus();

  const isFocused = item.id === ctx.focusedId;
  const PADDING_PER_LEVEL = ctx.mode === CustomModelBuilderMode.edit ? 36 : 32;

  return (
    <StyledRow isFocused={isFocused} mode={ctx.mode} onClick={handleClickRow}>
      <StyledLeftSection>
        {ctx.mode === CustomModelBuilderMode.edit ? <Kebab /> : <StyledInvisibleKebab />}
        <StyledPretextIconContainer offset={PADDING_PER_LEVEL * depth}>
          <PretextIcon item={item} onExpand={onExpand} onCollapse={onCollapse} />
        </StyledPretextIconContainer>
        {ctx.mode === CustomModelBuilderMode.edit ? (
          <StyledInputContainer>
            <StyledInput
              value={inputValue}
              maxLength={100}
              onChange={handleChangeInput}
              onFocus={focus}
              onBlur={unfocus}
              placeholder={getResource("customModels.insertTopic.placeholder")}
              ref={inputRef}
              hasError={ctx.showEmptyTopicNameError && hasError}
              disabled={topicsSideDrawerOpen}
            />
            <MergedTopic item={item} handleDeleteMappedTopic={handleDeleteMappedTopic} />
          </StyledInputContainer>
        ) : (
          <StyledViewModeTopicName>
            {inputValue || getResource("customModels.emptyTopicName")}
          </StyledViewModeTopicName>
        )}
      </StyledLeftSection>
      {ctx.mode === CustomModelBuilderMode.edit && (
        <StyledRightSection isFocused={isFocused} className="right-section">
          <StyledMapTopicsButton onClick={() => onClickMapTopics(item, inputValue)}>
            <Text resource="button.mapTopics" />
          </StyledMapTopicsButton>
          <StyledXButton onClick={() => onDeleteItem(item)}>
            <Icon type={IconType.x} color={Color.gray30} size={22} />
          </StyledXButton>
          <Button
            square
            size={ButtonSize.sm}
            onClickCapture={() => onCreateChild(item)}
            disabled={item.data?.mappedTopics?.length > 0 || depth >= 8}
          >
            <Icon type={IconType.enter} size={20} />
          </Button>
        </StyledRightSection>
      )}
    </StyledRow>
  );
};

const StyledLeftSection = styled.div`
  display: flex;
  align-items: center;
`;

const StyledRightSection = styled.div<{ isFocused: boolean }>`
  display: flex;
  align-items: center;
  opacity: ${({ isFocused }) => (isFocused ? 1 : 0)};
  transition: 0.1s opacity;
`;

const StyledRow = styled.div<{ isFocused: boolean; mode: CustomModelBuilderMode }>`
  padding: ${({ mode }) => (mode === CustomModelBuilderMode.edit ? "8px" : "4px")} 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ isFocused }) => (isFocused ? Color.sky15 : "none")};
  user-select: none;
  &:hover {
    background-color: ${Color.sky15};
  }
  &:hover ${StyledRightSection} {
    opacity: 1;
  }
`;

const StyledPretextIconContainer = styled.div<{ offset: number }>`
  padding-left: ${({ offset }) => offset}px;
`;

const StyledMapTopicsButton = styled.div`
  cursor: pointer;
  color: ${Color.blue50};
  font-weight: bold;
  font-size: 0.875em;
`;

const StyledXButton = styled.button`
  border: none;
  background: none;
  color: ${Color.gray30};
  margin: 0 8px 0px 24px;
  padding: 0;
  margin-bottom: -5px;
`;

const StyledInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledInvisibleKebab = styled.div`
  width: 8px;
`;

const StyledInput = styled.input<{ hasError: boolean }>`
  padding: 7.5px 8px;
  width: 350px;
  font: normal normal normal 14px/17px Lato;
  border: 1px solid ${({ hasError }) => (hasError ? Color.red30 : Color.blue20)};

  color: ${Color.gray40};
  border-radius: 2px;
  &::placeholder {
    color: ${Color.gray20};
  }
`;

const StyledViewModeTopicName = styled.div`
  font: normal normal normal 14px/17px Lato;
  color: ${Color.gray40};
`;
