import config from "../appSettings.json";

if (process.env.REACT_APP_TARGET_SERVER === "local") {
  config.server.baseApi = config.server.baseApiLocal;
}

export const getAppConfig = () => config.app;
export const getServerConfig = () => config.server;
export const getStorageConfig = () => config.blobStorage;
export { config };
