import { CategorizationType } from "@explorance/mly-types";
import React from "react";
import styled from "styled-components";
import { CategorizedTopic } from "ts/enums/categorizedTopic";
import { ResultTagSection } from "./ResultTagSection";

type Props = {
  data: Record<CategorizationType, string[]>;
  isCustomModel: boolean;
};

export const TopicInsights = ({ data, isCustomModel }: Props) => {
  const ORDERED_FILTERED_SENTIMENTS = [
    CategorizedTopic.positive,
    CategorizedTopic.negative,
    CategorizedTopic.neutral,
    CategorizedTopic.mixed,
    CategorizedTopic.notExplicit,
  ].filter((ct) => data[ct]);

  return (
    <StyledTopicInsightText>
      {ORDERED_FILTERED_SENTIMENTS.map((sentiment, i) => (
        <ResultTagSection
          key={i}
          sentiment={sentiment}
          topics={data[sentiment]}
          isCustomModel={isCustomModel}
        />
      ))}
    </StyledTopicInsightText>
  );
};

const StyledTopicInsightText = styled.div`
  margin-bottom: 20px;
  font-size: 12px;
`;
