import { ApplicationStatus, RoleType, SortingOrder } from "@explorance/mly-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { UserSortingParameter } from "ts/enums/sorting";
import { fetchUserList } from "./thunks";
import { User } from "ts/user";

type UserListSorting = {
  sortColumn: UserSortingParameter;
  sortOrder: SortingOrder;
};

type UserListState = {
  userList: User[];
  currentPage: number;
  totalUserCount: number;
  currentUserCount: number;
  sortColumn: UserSortingParameter;
  sortOrder: SortingOrder;
  emptyStateType: EmptyStateType;
  showDeleteConfirmation: boolean;
  isUserImportInProgress: ApplicationStatus;
  currentEditedRow: User;
  editedUserDetails: Record<string, string>;
  isRowDeleting: boolean;
  isLoading: boolean;
  currentRoleFilters: RoleType[];
  initialLoadComplete: boolean;
};

const initialState: UserListState = {
  userList: [],
  currentPage: 1,
  totalUserCount: 0,
  currentUserCount: 0,
  sortColumn: UserSortingParameter.LastLogin,
  sortOrder: SortingOrder.DESC,
  isUserImportInProgress: ApplicationStatus.NotInitialized,
  emptyStateType: null,
  showDeleteConfirmation: false,
  currentEditedRow: null,
  editedUserDetails: {},
  isRowDeleting: false,
  isLoading: false,
  currentRoleFilters: [],
  initialLoadComplete: false,
};

const UserListSlice = createSlice({
  name: "userListSlice",
  initialState,
  reducers: {
    setShowDeleteConfirmation: (state, action: PayloadAction<boolean>) => {
      state.showDeleteConfirmation = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setCurrentEditedRow: (state, action: PayloadAction<User>) => {
      state.currentEditedRow = action.payload;
    },
    setCurrentRoleFilters: (state, action: PayloadAction<RoleType[]>) => {
      state.currentRoleFilters = action.payload;
    },
    setEditedUserDetails: (state, action: PayloadAction<Record<string, string>>) => {
      state.editedUserDetails = action.payload;
    },
    setIsRowDeleting: (state, action: PayloadAction<boolean>) => {
      state.isRowDeleting = action.payload;
    },
    updateUserListSorting: (state, action: PayloadAction<UserListSorting>) => {
      state.currentPage = 1;
      state.sortOrder = action.payload.sortOrder;
      state.sortColumn = action.payload.sortColumn;
    },
    setEmptyStateType: (state, action: PayloadAction<EmptyStateType>) => {
      state.emptyStateType = action.payload;
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserList.pending, (state) => {
      if (!state.initialLoadComplete) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchUserList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.initialLoadComplete = true;
      state.userList = action.payload.users;
      state.totalUserCount = action.payload.totalCount;
      state.currentUserCount = action.payload.itemCount;
    });
    builder.addCase(fetchUserList.rejected, (state) => {
      state.isLoading = false;
      state.initialLoadComplete = true;
    });
  },
});
export const {
  setShowDeleteConfirmation,
  setCurrentPage,
  setCurrentEditedRow,
  clearState,
  setEmptyStateType,
  setCurrentRoleFilters,
  setEditedUserDetails,
  updateUserListSorting,
  setIsRowDeleting,
} = UserListSlice.actions;
export default UserListSlice.reducer;
