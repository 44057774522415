import React from "react";
import { Comment } from "pages/analysis/[id]/comments/_layouts/Comment";
import { CommentListPlaceholder } from "./Placeholder";
import { EmptyState } from "components/EmptyState";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { Comment as CommentModel } from "ts/comments";

type Props = {
  comments: CommentModel[];
  loading: boolean;
  searchField: string;
};

export const CommentList = ({ comments, loading, searchField }: Props) => {
  if (loading) return <CommentListPlaceholder />;
  if (comments.length === 0)
    return (
      <EmptyState
        type={EmptyStateType.noFilterResults}
        customStyles={{ marginTop: "0px", addGrayStyle: true }}
      />
    );
  return (
    <>
      {comments.map((comment) => (
        <Comment key={comment.id} comment={comment} searchField={searchField} />
      ))}
    </>
  );
};
