import React from "react";
import { RedFlag } from "ts/enums/statsMenu";
import { Icon, IconType } from "components/_icons/Icon";

type Props = {
  type: RedFlag;
};

export const RedFlagIcon = ({ type }: Props) => {
  switch (type) {
    case RedFlag.ALERTS:
      return <Icon type={IconType.redFlagAlert} size={13} />;
    case RedFlag.NOTHING_TO_REPORT:
      return <Icon type={IconType.nothingToReport} size={13} />;
    default:
      return null;
  }
};
