import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { WidgetGroupModalType } from "ts/enums/widgetGroupModalType";

type WidgetGroupsState = {
  modalType: WidgetGroupModalType;
  isOpen: boolean;
  userInput: string;
  currentlyEditedGroupId: number;
};

const initialState: WidgetGroupsState = {
  modalType: null,
  isOpen: false,
  userInput: "",
  currentlyEditedGroupId: null,
};

const widgetGroupsSlice = createSlice({
  name: "widgetGroupsSlice",
  initialState,
  reducers: {
    setUserInput: (state, action: PayloadAction<string>) => {
      state.userInput = action.payload;
    },
    openModal: (state, action: PayloadAction<WidgetGroupModalType>) => {
      state.isOpen = true;
      state.modalType = action.payload;
      if (action.payload === WidgetGroupModalType.newGroup) {
        state.userInput = "New Group";
      }
    },
    closeModal: (state) => {
      state.isOpen = false;
    },
    setCurrentlyEditedGroupId: (state, action: PayloadAction<number>) => {
      state.currentlyEditedGroupId = action.payload;
    },
  },
});

export const { openModal, setUserInput, closeModal, setCurrentlyEditedGroupId } =
  widgetGroupsSlice.actions;

export default widgetGroupsSlice.reducer;
