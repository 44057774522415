import React from "react";
import styled from "styled-components";

import { LoadingDots } from "components/LoadingDots";

import { Color } from "ts/enums/color";
import { Text } from "components/Text";

export const WidgetStatsLoadingPlaceholder = () => (
  <StyledWidgetStatsLoadingPlaceholder>
    <LoadingDots />
    <span className="loading-caption">
      <Text resource="widget.loading.caption" />
    </span>
  </StyledWidgetStatsLoadingPlaceholder>
);

const StyledWidgetStatsLoadingPlaceholder = styled.div`
  border: 1px solid ${Color.sky20};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 150px 0;
  width: 325px;
  height: 65px;

  .loading-caption {
    color: ${Color.neutral40};
    font-size: 0.85em;
    margin-left: 4px;
  }
`;
