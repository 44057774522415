import { Color } from "ts/enums/color";

export type PillStyle = {
  backgroundColor: Color;
  color: Color;
  border: string;
  hoverBackgroundColor: Color;
  deleteButtonHoverBackgroundColor?: Color;
  hoverColor?: Color;
  hoverBorder?: string;
};

export const outlineBluePillStyle: PillStyle = {
  backgroundColor: Color.sky15,
  color: Color.blue40,
  border: `1px solid ${Color.blue40}`,
  hoverBackgroundColor: Color.sky30,
  deleteButtonHoverBackgroundColor: Color.blue20,
};

export const outlineIndigoPillStyle: PillStyle = {
  backgroundColor: Color.indigo10,
  color: Color.indigo50,
  border: `1px solid ${Color.indigo50}`,
  hoverBackgroundColor: Color.indigo20,
  deleteButtonHoverBackgroundColor: Color.indigo40,
};

export const defaultTopicsPillStyle: PillStyle = {
  backgroundColor: Color.white,
  color: Color.gray20,
  border: `1px solid ${Color.gray20}`,
  hoverBackgroundColor: Color.sky15,
  hoverColor: Color.blue40,
  hoverBorder: `1px solid ${Color.blue40}`,
};
