import React from "react";

import { CustomToastContentType } from "ts/enums/customToastContentType";
import { ExportedFileToast } from "./ExportedFileToast";
import { QuickAnalysisRetry } from "./QuickAnalysisRetryToast";

type Props = { type: CustomToastContentType };

export const CustomToastContent = ({ type }: Props) => {
  const getCustomToastContent = () => {
    switch (type) {
      case CustomToastContentType.exportedFile:
        return <ExportedFileToast />;
      case CustomToastContentType.quickAnalysisRetry:
        return <QuickAnalysisRetry />;
    }
  };
  return <>{getCustomToastContent()}</>;
};
