import React from "react";
import styled from "styled-components";

import { Color } from "ts/enums/color";
import {
  PermissionLevel,
  SharingCommentExplorerAccess,
  SharingDashboardAccess,
  SharingTopicExplorerAccess,
} from "@explorance/mly-types";
import { DemographicFilter, DemographicFilterHeader } from "ts/filters/demographicFilter";
import { CustomConditionDemographics } from "ts/sharing";

import { PermissionForm } from ".";

import { useSharingContext } from "context/SharingContext";

export const PermissionFormContainer = () => {
  const { sharingState, updateSharingState } = useSharingContext();

  const showPermissionFields =
    sharingState.permissionFields.permissionLevel === PermissionLevel.View;

  // Functions
  const handleSelectPermissionLevel = (permissionLevel: PermissionLevel) => {
    updateSharingState(
      "permissionFields",
      { ...sharingState.permissionFields, permissionLevel },
      { save: true }
    );
  };

  const handleSelectDashboardAccess = () => {
    updateSharingState(
      "permissionFields",
      {
        ...sharingState.permissionFields,
        dashboardAccess:
          sharingState.permissionFields.dashboardAccess === SharingDashboardAccess.Hidden
            ? SharingDashboardAccess.Shared
            : SharingDashboardAccess.Hidden,
      },
      { save: true }
    );
  };

  const handleSelectTopicExplorerAccess = () => {
    updateSharingState(
      "permissionFields",
      {
        ...sharingState.permissionFields,
        topicExplorerAccess:
          sharingState.permissionFields.topicExplorerAccess === SharingTopicExplorerAccess.Hidden
            ? SharingTopicExplorerAccess.Shared
            : SharingTopicExplorerAccess.Hidden,
      },
      { save: true }
    );
  };

  const handleSelectCommentExplorerAccess = () => {
    updateSharingState(
      "permissionFields",
      {
        ...sharingState.permissionFields,
        commentExplorerAccess:
          sharingState.permissionFields.commentExplorerAccess ===
          SharingCommentExplorerAccess.Hidden
            ? SharingCommentExplorerAccess.Shared
            : SharingCommentExplorerAccess.Hidden,
        allowExportData:
          sharingState.permissionFields.commentExplorerAccess ===
          SharingCommentExplorerAccess.Shared
            ? false
            : sharingState.permissionFields.allowExportData,
      },
      { save: true }
    );
  };

  const handleChangeAllowExportData = () => {
    updateSharingState(
      "permissionFields",
      {
        ...sharingState.permissionFields,
        allowExportData: !sharingState.permissionFields.allowExportData,
        commentExplorerAccess:
          sharingState.permissionFields.commentExplorerAccess ===
            SharingCommentExplorerAccess.Hidden && !sharingState.permissionFields.allowExportData
            ? SharingCommentExplorerAccess.Shared
            : sharingState.permissionFields.commentExplorerAccess,
      },
      { save: true }
    );
  };

  const handleUpdateVisibleComments = (selectedDemographics: DemographicFilter[]) => {
    updateSharingState(
      "permissionFields",
      {
        ...sharingState.permissionFields,
        visibleCommentsByDemographicValues: selectedDemographics,
      },
      { save: true }
    );
  };

  const updateCustomConditions = (
    updatedCustomConditions: CustomConditionDemographics[],
    noSave?: boolean
  ) => {
    updateSharingState(
      "permissionFields",
      {
        ...sharingState.permissionFields,
        customConditions: updatedCustomConditions,
      },
      { save: noSave ?? true }
    );
  };

  const updateSavedDemHeaders = (updatedHeaders: DemographicFilterHeader[]) => {
    updateSharingState(
      "permissionFields",
      {
        ...sharingState.permissionFields,
        visibleDemographicsFilters: updatedHeaders,
      },
      { save: true }
    );
  };

  const permissionLevelProps = {
    selectedPermissionLevel: sharingState.permissionFields.permissionLevel,
    handleSelectPermissionLevel,
  };

  const visibleAnalysisDataProps = {
    selectedDemographics: sharingState.permissionFields.visibleCommentsByDemographicValues,
    selectedCustomConditions: sharingState.permissionFields.customConditions,
    showCustomConditionError: sharingState.showCustomConditionError,
    userDemographics: sharingState.allUserDemographics,
    updateSelectedDemographics: handleUpdateVisibleComments,
    updateCustomConditions,
    handleHideCustomConditionError: () => updateSharingState("showCustomConditionError", false),
  };

  const visibleDemographicsDataProps = {
    savedDemHeaders: sharingState.permissionFields.visibleDemographicsFilters,
    updateSavedDemHeaders,
  };

  const dashboardSettingsDataProps = {
    dashboardAccess: sharingState.permissionFields.dashboardAccess,
    topicExplorerAccess: sharingState.permissionFields.topicExplorerAccess,
    commentExplorerAccess: sharingState.permissionFields.commentExplorerAccess,
    allowExportData: sharingState.permissionFields.allowExportData,
    handleSelectDashboardAccess,
    handleSelectTopicExplorerAccess,
    handleSelectCommentExplorerAccess,
    handleToggleExportData: handleChangeAllowExportData,
  };

  return (
    <PermissionForm
      showPermissionFields={showPermissionFields}
      analysisDemographicHeaders={sharingState.analysisDemographicHeaders}
      // props for each section of the form have the following info: state, handler functions
      permissionLevelData={permissionLevelProps}
      visibleAnalysisData={visibleAnalysisDataProps}
      visibleDemographicsData={visibleDemographicsDataProps}
      dashboardSettingsData={dashboardSettingsDataProps}
    />
  );
};

export const StyledHorizontalField = styled.div<{
  marginBottom?: string;
  editSharing?: boolean;
  hasError?: boolean;
}>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => marginBottom || "16px"};
  font-size: 15px;
  width: 100%;

  label {
    font-weight: bold;
    min-width: 120px;
    user-select: none;
    margin-left: ${({ editSharing }) => (editSharing ? "auto" : "0px")};
  }

  input {
    padding: 8px 10px;
    border: ${({ hasError }) => `1px solid ${hasError ? Color.red50 : Color.sky50}`};
    background-color: ${({ hasError }) => (hasError ? `${Color.red10}` : "initial")};
    border-radius: 2px;
  }
`;
