import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";

import { Color } from "ts/enums/color";
import { Tooltip } from "components/Tooltip";

type Props = {
  count: number;
  label: string;
  cursorStyle: string;
};

export const BarChartLegend = ({ count, label, cursorStyle }: Props) => {
  const [showTooltip, setShowTooltip] = useState<boolean>();

  const labelRef = useRef<HTMLSpanElement>();

  useEffect(() => {
    if (labelRef.current) {
      return setShowTooltip(labelRef.current.offsetWidth < labelRef.current.scrollWidth);
    }
  }, []);

  return (
    <StyledBarChartLegend isCountZero={count === 0} cursorStyle={cursorStyle}>
      <b>{count}</b>
      <span ref={labelRef} data-tip data-for={`${label}-tooltip`}>
        {label}
      </span>
      {showTooltip && <Tooltip tooltipId={`${label}-tooltip`} content={label} />}
    </StyledBarChartLegend>
  );
};

const StyledBarChartLegend = styled.div<{ isCountZero: boolean; cursorStyle: string }>`
  background-color: ${({ isCountZero }) => (isCountZero ? Color.white : Color.neutral10)};
  padding: 6px 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  font-size: 14px;
  width: 150px;
  cursor: ${({ cursorStyle }) => cursorStyle};

  b {
    margin: 0 3px;
  }

  b,
  span {
    opacity: ${({ isCountZero }) => (isCountZero ? 0.5 : 1)};
  }

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 142px;
  }
`;
