import { Text } from "components/Text";
import React from "react";
import styled from "styled-components";
import { Color } from "ts/enums/color";

export const BaseModelPill = () => (
  <StyledBaseModelPill>
    <Text resource="categorizationType.baseModel.acronym" />
  </StyledBaseModelPill>
);

const StyledBaseModelPill = styled.div`
  background-color: ${Color.neutral50};
  color: ${Color.white};
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 3px;
  display: inline-block;
`;
