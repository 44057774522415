import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { useAppSelector, useAppDispatch } from "store";
import { useResource } from "hooks/useResource";

import { closeModal, setUserInput } from "store/widgets/widgetGroupsSlice";
import { showToastError, showToastSuccess } from "store/toast/toastSlice";
import { AnalysisContext } from "context/AnalysisContext";

import { Color } from "ts/enums/color";
import { ButtonVariant } from "ts/enums/button";
import { WidgetGroupModalType } from "ts/enums/widgetGroupModalType";

import { Button } from "components/_buttons/Button";
import { Modal } from "components/_modals/Modal";
import { Text } from "components/Text";
import { TextInput } from "components/_inputs/TextInput";

import { createWidgetGroup, renameWidgetGroup } from "services/widgets";

export const WidgetGroupModal = ({ refetch }) => {
  const dispatch = useAppDispatch();
  const [analysisState] = useContext(AnalysisContext);
  const state = useAppSelector((state) => state.widgetGroups);
  const groupNameRef = useRef<HTMLInputElement>(null);
  const { getResource } = useResource();

  useEffect(() => {
    if (groupNameRef?.current && state.isOpen) {
      groupNameRef.current.value =
        state.modalType === WidgetGroupModalType.newGroup
          ? getResource("modal.widgets.newGroup.defaultTitle")
          : state.userInput;
    }
  }, [groupNameRef, state.isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const getModalTitle = () => {
    if (state.modalType === WidgetGroupModalType.newGroup) {
      return {
        title: <Text resource="modal.widgetGroups.newGroup.title" />,
        button: <Text resource="button.widgets.modal.createGroup" />,
      };
    } else if (state.modalType === WidgetGroupModalType.renameGroup) {
      return {
        title: <Text resource="modal.widgetGroups.renameGroup.title" />,
        button: <Text resource="button.widgets.modal.renameGroup" />,
      };
    }
  };

  const handleCreateUpdateGroup = async () => {
    try {
      if (state.modalType === WidgetGroupModalType.newGroup) {
        await createWidgetGroup(analysisState.analysisDetails.id, state.userInput);
        dispatch(showToastSuccess("widgetGroups.toast.success.add"));
      } else {
        await renameWidgetGroup(state.currentlyEditedGroupId, state.userInput);
      }
    } catch (e) {
      dispatch(showToastError("toast.defaultError"));
    }

    dispatch(closeModal());
    refetch();
  };

  return (
    <Modal isOpen={state.isOpen} width="500px" height="224px" margin="200px auto">
      <StyledModalContent>
        <div>
          <StyledModalTitle>{getModalTitle()?.title}</StyledModalTitle>
          <StyledInputTitle>
            <Text resource="modal.widgetGroup.inputTitle" />
          </StyledInputTitle>
          <TextInput
            value={state.userInput}
            onChange={(e) => dispatch(setUserInput(e.target.value))}
            customStyles={{
              fontSize: "15px",
              borderRadius: "2px",
              border: `1px solid ${Color.blue50}`,
              padding: "8px 10px",
            }}
          />
        </div>
        <StyledButtonContainer>
          <Button
            variant={ButtonVariant.primary}
            style={{ width: state.modalType === WidgetGroupModalType.newGroup ? "152px" : "162px" }}
            onClick={handleCreateUpdateGroup}
          >
            {getModalTitle()?.button}
          </Button>
          <Button
            variant={ButtonVariant.light}
            style={{ width: "96px" }}
            onClick={() => dispatch(closeModal())}
          >
            <Text resource="button.cancel" />
          </Button>
        </StyledButtonContainer>
      </StyledModalContent>
    </Modal>
  );
};

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 182px;
`;

const StyledModalTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: ${Color.gray50};
  margin-bottom: 12px;
`;

const StyledInputTitle = styled.div`
  font-weight: bold;
  color: ${Color.gray50};
  font-size: 15px;
  margin-bottom: 10px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 12px;
`;
