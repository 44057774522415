// library
import React, { useEffect, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "store";
import styled from "styled-components";

import { AnalysisContext } from "context/AnalysisContext";
import { setAvailableDemographicFilters } from "context/AnalysisContext/actions";

// components
import { CheckboxField } from "components/_inputs/CheckboxField";

import { useResource } from "hooks/useResource";
import { getDemographicById } from "services/demographics";
import { getAnalysisPageType } from "common-layouts/AnalysisLayout/helpers";
import { isAnyAdmin } from "utils/isAnyAdmin";

import { DemographicFilter } from "ts/filters/demographicFilter";
import { DemographicFilterMethod, PermissionLevel, RoleType } from "@explorance/mly-types";
import { CheckboxCheckedAppearance } from "ts/enums/checkboxCheckedAppearance";
import { Color } from "ts/enums/color";
import { AnalysisPageType } from "ts/enums/analysisPageType";
import { DEMOGRAPHIC_LIST_LIMIT } from "assets/constants/demographicLimit";
import { getUpdatedDemographicFilters } from "./helpers";
import { Text } from "components/Text";

type Props = {
  demographic: DemographicFilter;
  currentFilterState: DemographicFilter[];
  filterType: DemographicFilterMethod.List | DemographicFilterMethod.Search;
  isFilterPill: boolean;
  handleCurrentFilterState: (currentFilterState: DemographicFilter[]) => void;
  handleFilterCount: (count: string) => void;
};

export const DemographicFilterSelection = ({
  demographic,
  currentFilterState,
  filterType,
  isFilterPill,
  handleCurrentFilterState,
  handleFilterCount,
}: Props) => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);

  // hooks
  const [state, dispatch] = useContext(AnalysisContext);
  const searchInputRef = useRef<HTMLInputElement>();
  const { getResource } = useResource();

  const location = useLocation();

  // Sharing variables
  const isAdmin = isAnyAdmin(currentUser.roleType);
  const isAnalysisOwner = !state.analysisDetails?.sharing;
  const isViewer = currentUser.roleType === RoleType.Viewer;
  const isContributor =
    state.analysisDetails?.sharing?.permissionLevel === PermissionLevel.Contribution;
  const areFiltersAvailable = (isAnalysisOwner && !isViewer) || isAdmin || isContributor;
  const isDashboardPage = getAnalysisPageType(location.pathname) === AnalysisPageType.Dashboard;
  // variables
  const currentFilter: DemographicFilter = currentFilterState.filter(
    (cf) => cf.id === demographic.id
  )[0];

  const selectedFiltersCopy = [...currentFilterState];
  const currentFilterIndex = selectedFiltersCopy.indexOf(currentFilter);

  // update the count displayed in the filter section
  useEffect(() => {
    if (handleFilterCount && currentFilter) {
      switch (filterType) {
        case DemographicFilterMethod.Search:
          handleFilterCount(
            currentFilter.searchValue.length > 0 && ` (${currentFilter.searchValue.length})`
          );
          break;
        default:
          handleFilterCount(
            currentFilter.listValues.length > 0 && ` (${currentFilter.listValues.length})`
          );
      }
    }
  }, [currentFilter, filterType, handleFilterCount]);

  // functions
  const getBaseFilter = () => ({
    id: demographic.id,
    method: filterType,
    name: demographic.name,
    totalCount: demographic.totalCount,
    listValues: [],
    searchValue: [],
  });

  const onSelectAllValues = () => {
    if (!currentFilter) {
      return handleCurrentFilterState([
        ...currentFilterState,
        {
          ...getBaseFilter(),
          method: DemographicFilterMethod.List,
          listValues: currentDemographicState.listValues,
        },
      ]);
    }

    let currentFilterCopy = { ...currentFilter };

    if (currentFilterCopy.listValues.length === currentDemographicState.listValues.length) {
      selectedFiltersCopy.splice(currentFilterIndex, 1);
    } else {
      currentFilterCopy = {
        ...currentFilterCopy,
        method: DemographicFilterMethod.List,
        listValues: currentDemographicState.listValues,
        searchValue: [],
      };
      selectedFiltersCopy.splice(currentFilterIndex, 1, currentFilterCopy);
    }

    handleCurrentFilterState(selectedFiltersCopy);
  };

  const onSelectValue = (value: string) => {
    if (!currentFilter) {
      return handleCurrentFilterState([
        ...currentFilterState,
        { ...getBaseFilter(), method: DemographicFilterMethod.List, listValues: [value] },
      ]);
    }

    let currentFilterCopy = { ...currentFilter };

    const isChecked = currentFilterCopy.listValues.some((v) => v === value);

    if (!isChecked) {
      const listValuesCopy = [...currentFilterCopy.listValues];
      listValuesCopy.push(value);
      currentFilterCopy = {
        ...currentFilterCopy,
        method: DemographicFilterMethod.List,
        listValues: listValuesCopy,
        searchValue: [],
      };
      selectedFiltersCopy.splice(currentFilterIndex, 1, currentFilterCopy);
    } else {
      const listValuesCopy = [...currentFilterCopy.listValues.filter((v) => v !== value)];
      if (listValuesCopy.length === 0) selectedFiltersCopy.splice(currentFilterIndex, 1);
      else {
        currentFilterCopy = {
          ...currentFilterCopy,
          listValues: listValuesCopy,
          searchValue: [],
        };
        selectedFiltersCopy.splice(currentFilterIndex, 1, currentFilterCopy);
      }
    }

    handleCurrentFilterState(selectedFiltersCopy);
  };

  const onSearchInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      searchInputRef.current && searchInputRef.current.blur();
    }
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    const searchFields = value
      ? value
          .split(/ OR |[|]/g)
          .map((x) => x.trim())
          .filter((x) => x !== "")
      : [];

    if (currentFilter) {
      if (searchFields.length > 0) {
        let currentFilterCopy = { ...currentFilter };
        currentFilterCopy = {
          ...currentFilterCopy,
          method: DemographicFilterMethod.Search,
          searchValue: searchFields,
          listValues: [],
        };
        selectedFiltersCopy.splice(currentFilterIndex, 1, currentFilterCopy);
      } else selectedFiltersCopy.splice(currentFilterIndex, 1);

      handleCurrentFilterState(selectedFiltersCopy);
    } else if (!currentFilter && searchFields.length > 0) {
      handleCurrentFilterState([
        ...currentFilterState,
        { ...getBaseFilter(), method: DemographicFilterMethod.Search, searchValue: searchFields },
      ]);
    }
  };

  const currentDemographicState = state.availableDemographicFilters.find(
    (d) => d.id === demographic.id
  );

  const showLoadMore =
    currentDemographicState.totalCount > currentDemographicState.listValues.length;

  const handleClickLoadMore = async () => {
    const page = Math.ceil(currentDemographicState.listValues.length / DEMOGRAPHIC_LIST_LIMIT) + 1;

    const { data } = await getDemographicById(demographic.id, page, DEMOGRAPHIC_LIST_LIMIT);
    const updatedFilters = getUpdatedDemographicFilters(state.availableDemographicFilters, data);

    //If select all is checked, add the new values to the selected filter
    if (currentDemographicState.listValues.length === currentFilter?.listValues.length) {
      let currentFilterCopy = { ...currentFilter };
      const listValuesCopy = [...currentFilterCopy.listValues];

      updatedFilters.forEach((filter) => {
        if (filter.id === demographic.id) {
          filter.listValues.forEach((value) => {
            if (!listValuesCopy.includes(value)) {
              listValuesCopy.push(value);
            }
          });
        }
      });

      currentFilterCopy = {
        ...currentFilterCopy,
        listValues: listValuesCopy,
        searchValue: [],
      };
      selectedFiltersCopy.splice(currentFilterIndex, 1, currentFilterCopy);

      handleCurrentFilterState(selectedFiltersCopy);
    }

    dispatch(setAvailableDemographicFilters(updatedFilters));
  };

  return (
    <StyledDemographicFilterSelection
      isFilterPill={isFilterPill}
      isList={filterType === DemographicFilterMethod.List}
    >
      {filterType === DemographicFilterMethod.List ? (
        <>
          <CheckboxField
            variant={
              currentDemographicState.listValues.length === currentFilter?.listValues.length
                ? CheckboxCheckedAppearance.Default
                : CheckboxCheckedAppearance.Partial
            }
            checked={currentFilter?.listValues.length > 0}
            onChange={onSelectAllValues}
            label={getResource("columnFilterSection.selectAll")}
            disabled={isDashboardPage && !areFiltersAvailable}
          />
          {currentDemographicState.listValues.map((value) => (
            <div key={`${demographic.id}-${value}`}>
              <CheckboxField
                checked={currentFilter?.listValues.some((v) => v === value) ?? false}
                onChange={() => onSelectValue(value)}
                label={value}
                disabled={isDashboardPage && !areFiltersAvailable}
              />
            </div>
          ))}
          {showLoadMore && (
            <StyledLoadMoreButton onClick={handleClickLoadMore}>
              <Text resource="filter.demographics.loadMore" />
            </StyledLoadMoreButton>
          )}
        </>
      ) : (
        <StyledSearchContainer isFilterPill={isFilterPill}>
          <input
            ref={searchInputRef}
            placeholder={getResource("searchBar.placeholder")}
            onKeyDown={onSearchInputKeyDown}
            onChange={onSearch}
            onBlur={onSearch}
            value={currentFilterState.find((cf) => cf.id === demographic.id)?.searchValue[0] ?? ""}
            disabled={isDashboardPage && !areFiltersAvailable}
          />
        </StyledSearchContainer>
      )}
    </StyledDemographicFilterSelection>
  );
};

const StyledDemographicFilterSelection = styled.div<{ isFilterPill: boolean; isList: boolean }>`
  margin: ${({ isFilterPill }) => !isFilterPill && "0 0 5px 15px"};
  max-height: ${({ isFilterPill }) => isFilterPill && "300px"};
  overflow-y: ${({ isFilterPill, isList }) => isFilterPill && isList && "auto"};

  ::-webkit-scrollbar-thumb {
    border-left: 5px solid rgba(0, 0, 0, 0);
    border-right: 5px solid rgba(0, 0, 0, 0);
    border-top: none;
    border-bottom: none;
  }
`;

const StyledLoadMoreButton = styled.button`
  background: none;
  border: none;
  color: ${Color.blue50};
`;

const StyledSearchContainer = styled.div<{ isFilterPill: boolean }>`
  margin-top: ${({ isFilterPill }) => (isFilterPill ? "8px" : "5px")};
  margin-left: ${({ isFilterPill }) => (isFilterPill ? "2px" : "7px")};
  margin-bottom: ${({ isFilterPill }) => (isFilterPill ? "8px" : "15px")};

  input {
    border: 1px solid ${Color.blue20};
    border-radius: 2px;
    padding: ${({ isFilterPill }) => (isFilterPill ? "9px 20px 9px 13px" : "9px 13px")};
    font-size: 12px;
    width: ${({ isFilterPill }) => (isFilterPill ? "100%" : "401px")};

    :focus {
      outline-style: none;
      border: 1px solid ${Color.blue50};
      border-radius: 3px;
    }

    :disabled {
      background-color: ${Color.neutral10};
      color: ${Color.gray50};
    }
  }
`;
