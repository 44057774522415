import React from "react";
import styled from "styled-components";

import { WidgetStat } from "@explorance/mly-types";
import { Widget } from "ts/widget";

import { SectionTitle } from "../SectionTitle";
import { Text } from "components/Text";
import { CheckboxField } from "components/_inputs/CheckboxField";

import { getStatsForWidgetType } from "utils/getStatsForWidgetType";

type Props = {
  widget: Widget;
  restrictedAccess: boolean;
  onChange(updatedWidget: Widget): void;
  isCreate?: boolean;
};

export const WidgetStatsSettingsSection = ({
  widget,
  restrictedAccess,
  onChange,
  isCreate,
}: Props) => {
  const stats = getStatsForWidgetType(widget.type, widget.configuration.stats, isCreate);

  const onClickStat = (statType: WidgetStat) => () => {
    let updatedStats = widget.configuration?.stats ? [...widget.configuration.stats] : [];

    updatedStats = updatedStats.some((s) => s === statType)
      ? updatedStats.filter((s) => s !== statType)
      : [...updatedStats, statType];

    onChange({ ...widget, configuration: { ...widget.configuration, stats: updatedStats } });
  };

  return (
    <StyledStatsSettingsSection>
      <SectionTitle titleKey="sideDrawer.widget.displayedStats.label" />
      <StyledCheckboxesContainer>
        {stats.map((stat) => {
          const labelKey = `sideDrawer.widget.displayedStats.[${stat.type}]`;

          return (
            <CheckboxField
              key={stat.type}
              label={<Text resource={labelKey} />}
              checked={stat.isMandatory || widget.configuration?.stats.some((s) => s === stat.type)}
              onChange={onClickStat(stat.type)}
              disabled={stat.isMandatory || restrictedAccess}
            />
          );
        })}
      </StyledCheckboxesContainer>
    </StyledStatsSettingsSection>
  );
};

const StyledStatsSettingsSection = styled.div`
  margin: 16px 0;
`;

const StyledCheckboxesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 5px;
`;
