import styled from "styled-components";

import { Color } from "ts/enums/color";

export const StyledDataCustomizationContainer = styled.div`
  padding: 12px 24px;
  background-color: ${Color.neutral10};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 16px;
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  gap: 2px;
  font-size: 15px;
`;

export const StyledDropdownContentContainer = styled.div`
  max-height: 300px;
  overflow-y: scroll;

  .checkbox input {
    margin: 0px 0px 0px 1px;
  }
`;
