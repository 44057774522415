import React, { useRef } from "react";
import styled from "styled-components";
import parseHTML from "html-react-parser";
import * as download from "downloadjs";

import { Icon, IconType } from "components/_icons/Icon";
import { FileUploadInput } from "components/_inputs/FileUpload";

import { Color } from "ts/enums/color";
import {
  Notification,
  NotificationType,
  TaskStatus,
  TaskStatusDetails,
} from "@explorance/mly-types";

import { getNotificationIcon } from "./helpers";
import { uploadFile } from "services/analysis";
import {
  getAnalysisNotificationSentence,
  getExportNotificationPhraseKeys,
  getImportNotificationSentence,
  getUserDemographicInUseSentence,
  getUserImportNotificationPhraseKeys,
  getSharedAnalysisNotificationPhraseKeys,
} from "utils/notifications";
import { useResource } from "hooks/useResource";
import { deleteNotifications } from "services/notifications";
import { useTimeStamp } from "hooks/useTimeStamp";
import { ActionButtonContainer } from "./ActionButtonContainer";
import { getExport, retryDownloadedFileById } from "services/downloadedFiles";
import { BlobServiceClient } from "@azure/storage-blob";
import { getStorageConfig } from "config/configProviders";
import { getLocalTime } from "utils/getLocalTime";
import { useAppDispatch } from "store";
import { showToastError } from "store/toast/toastSlice";

const blobStorageConfig = getStorageConfig();

type Props = {
  notification: Notification;
  notificationCenterCloseHandler: () => void;
};

export const NotificationCard = ({ notification, notificationCenterCloseHandler }: Props) => {
  const retryImportRef = useRef<HTMLInputElement>();
  const { getResource } = useResource();
  const getTimeStamp = useTimeStamp();

  const dispatch = useAppDispatch();

  const icon = getNotificationIcon(notification.message.status);

  const onDeleteNotification = async () => {
    try {
      const notificationId = notification.notificationId;
      await deleteNotifications([notificationId]);
    } catch (err) {
      throw err;
    }
  };

  const getSentence = (): string => {
    const roleType =
      notification.message.details === "UserQuotaReached" &&
      getResource(`roleType.${notification.message.errDetails.ErrorValue}`);

    const message = roleType
      ? { ...notification.message, errDetails: { ErrorValue: roleType } }
      : notification.message;

    switch (notification.type) {
      case NotificationType.Import:
        return getImportNotificationSentence(message, getResource);

      case NotificationType.Analysis:
        return getAnalysisNotificationSentence(message, getResource);

      case NotificationType.Export:
        return getExportNotificationPhraseKeys(message, getResource);

      case NotificationType.UserImport:
        // Handle more complex notification message structure
        if (message.details === TaskStatusDetails.UserDemographicInUse) {
          return getUserDemographicInUseSentence(message, getResource);
        }
        return getUserImportNotificationPhraseKeys(message, getResource);

      case NotificationType.SharedAnalysis:
        return getSharedAnalysisNotificationPhraseKeys(message, getResource);
    }
  };

  const retryImportFile = async (file: File) => {
    try {
      const response = await uploadFile(notification.message.analysisId, file);
      if (response.status === 202) {
        await deleteNotifications([notification.notificationId]);
      }
    } catch (err) {
      dispatch(showToastError("toast.defaultError"));
    }
  };

  const retryExportFile = async () => {
    try {
      const response = await retryDownloadedFileById(notification.message.refId);
      if (response.status === 200) {
        await deleteNotifications([notification.notificationId]);
      }
    } catch (err) {
      dispatch(showToastError("toast.defaultError"));
    }
  };

  const getRetryImportFunction = () => {
    if (
      notification.type === NotificationType.Import &&
      notification.message.status === TaskStatus.Failure
    )
      return retryImportRef.current.click();
    return undefined;
  };

  const downloadExport = async () => {
    try {
      const { data } = await getExport(notification.message.refId);

      if (data) {
        const blobServiceClient = new BlobServiceClient(
          `${blobStorageConfig.account}?${data.exportsSummary.linkInfo.sharedAccessSignature}`
        );
        const containerClient = blobServiceClient.getContainerClient(
          data.exportsSummary.linkInfo.containerName
        );
        const blobClient = containerClient.getBlockBlobClient(
          data.exportsSummary.linkInfo.blobName
        );
        blobClient.download().then((blob) => {
          blob.blobBody.then((body) => {
            download(
              body,
              data.exportsSummary.linkInfo.blobName,
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            );
          });
        });
      }
    } catch (err) {
      throw err;
    }
  };

  return (
    <>
      <StyledNotificationCard>
        <StyledNotificationIconSection>
          <Icon type={icon.type} color={icon.color} size={14} />
        </StyledNotificationIconSection>
        <StyledNotificationBodySection>
          <StyledNotificationSentence>{parseHTML(getSentence())}</StyledNotificationSentence>
          <StyledTimeAgo>
            {getTimeStamp(getLocalTime(new Date(notification.createdDate)))}
          </StyledTimeAgo>
          <ActionButtonContainer
            notification={notification}
            retryImportFunc={getRetryImportFunction}
            retryExportFunc={retryExportFile}
            downloadExportFunc={downloadExport}
            notificationCenterCloseHandler={notificationCenterCloseHandler}
          />
        </StyledNotificationBodySection>
        <StyledNotificationDeleteSection>
          <StyledDeleteButton onClick={onDeleteNotification}>
            <Icon type={IconType.x} color={Color.gray20} size={16} />
          </StyledDeleteButton>
        </StyledNotificationDeleteSection>
      </StyledNotificationCard>
      <FileUploadInput
        inputFileRef={retryImportRef}
        onSelectFile={retryImportFile}
        resetFileRef={false}
      />
    </>
  );
};

const StyledNotificationCard = styled.div`
  display: flex;
  border: 1px solid ${Color.indigo20};
  border-radius: 5px;
  margin-bottom: 10px;
`;

const StyledNotificationIconSection = styled.div`
  width: 9%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledNotificationBodySection = styled.div`
  width: 82%;
  word-wrap: break-word;
  padding: 10px 0px 10px 6px;
  font-size: 14px;
`;

const StyledNotificationSentence = styled.div`
  margin: 0;
  margin-bottom: 2px;

  p {
    color: ${Color.red50};
    font-weight: bold;
    display: inline-block;
    margin: 0;
  }
`;

const StyledNotificationDeleteSection = styled.div`
  width: 9%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledDeleteButton = styled.button`
  background: none;
  border: none;
`;

const StyledTimeAgo = styled.div`
  color: ${Color.gray30};
  margin-bottom: 8px;
`;
