import React, { ReactNode, useState } from "react";
import styled from "styled-components";

import useClickOutside from "hooks/useClickOutside";

import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { StyledDropdownTransition } from "components/DropdownMenu/StyledDropdownTransition";

import { ButtonVariant, ButtonSize } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { Text } from "components/Text";

type Props = {
  buttonLabel: string;
  renderDropdownContent: (isOpen?: boolean) => ReactNode;
  handleApplyFilters: () => void;
  resetLocalChanges: () => void;
};

export const DropdownContainer = ({
  buttonLabel,
  renderDropdownContent,
  handleApplyFilters,
  resetLocalChanges,
}: Props) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const dropdownRef = useClickOutside(() => {
    setShowDropdown(false);
    resetLocalChanges();
  });

  return (
    <StyledDropdownContainer ref={dropdownRef}>
      <Button
        variant={ButtonVariant.outlineGray}
        size={ButtonSize.md}
        style={{
          width: "100%",
          height: "34px",
          justifyContent: "space-between",
          fontWeight: "normal",
        }}
        onClick={() => setShowDropdown((show) => !show)}
      >
        {buttonLabel}
        <Icon type={IconType.triangleFilledDown} size={8} />
      </Button>
      <StyledDropdown show={showDropdown}>
        {renderDropdownContent(showDropdown)}
        <StyledBottomSeparator />
        <StyledButtonContainer>
          <Button
            variant={ButtonVariant.primaryPurple}
            size={ButtonSize.md}
            onClick={() => {
              setShowDropdown(false);
              handleApplyFilters();
            }}
          >
            <Text resource="button.apply" />
          </Button>
        </StyledButtonContainer>
      </StyledDropdown>
    </StyledDropdownContainer>
  );
};

const StyledDropdownContainer = styled.div`
  position: relative;
`;

const StyledDropdown = styled(StyledDropdownTransition)`
  padding: 10px;
  position: absolute;
  border-radius: 2px;
  border: 1px solid ${Color.sky50};
  background-color: ${Color.white};
  box-shadow: 0px 3px 10px 0px rgba(53, 56, 77, 0.2);
  width: 100%;
  z-index: ${ZIndexStackingContext.medium + 2};
`;

const StyledBottomSeparator = styled.div`
  background: ${Color.blue20};
  width: 100%;
  height: 1px;
`;

const StyledButtonContainer = styled.div`
  float: right;
  display: flex;
  gap: 12px;
  margin-top: 10px;
`;
