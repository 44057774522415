import { CustomModel, SortingOrder } from "@explorance/mly-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { CustomModelsListSortingParameter } from "ts/enums/sorting";
import { fetchCustomModels } from "./thunks";
type CustomModelListSorting = {
  sortColumn: CustomModelsListSortingParameter;
  sortOrder: SortingOrder;
};
type CustomModelListState = {
  customModelList: CustomModel[];
  currentPage: number;
  totalModelCount: number;
  currentModelCount: number;
  sortColumn: CustomModelsListSortingParameter;
  sortOrder: SortingOrder;
  emptyStateType: EmptyStateType;
  showDeleteConfirmation: boolean;
  currentEditedRow: CustomModel | null;
  isLoading: boolean;
  initialLoadComplete: boolean;
};

const initialState: CustomModelListState = {
  customModelList: [],
  currentPage: 1,
  totalModelCount: 0,
  currentModelCount: 0,
  sortColumn: CustomModelsListSortingParameter.LastUpdated,
  sortOrder: SortingOrder.DESC,
  emptyStateType: null,
  showDeleteConfirmation: false,
  currentEditedRow: null,
  isLoading: false,
  initialLoadComplete: false,
};

const customModelListSlice = createSlice({
  name: "customModelList",
  initialState,
  reducers: {
    setShowDeleteConfirmation: (state, action: PayloadAction<boolean>) => {
      state.showDeleteConfirmation = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setCurrentEditedRow: (state, action: PayloadAction<CustomModel | null>) => {
      state.currentEditedRow = action.payload;
    },
    setEmptyStateType: (state, action: PayloadAction<EmptyStateType | null>) => {
      state.emptyStateType = action.payload;
    },
    updateCustomModelsListSorting: (state, action: PayloadAction<CustomModelListSorting>) => {
      state.currentPage = 1;
      state.sortOrder = action.payload.sortOrder;
      state.sortColumn = action.payload.sortColumn;
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomModels.pending, (state) => {
      if (!state.initialLoadComplete) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchCustomModels.fulfilled, (state, action) => {
      state.customModelList = action.payload.customizedModels;
      state.currentModelCount = action.payload.itemCount;
      state.totalModelCount = action.payload.totalCount;
      state.isLoading = false;
      state.initialLoadComplete = true;
    });
    builder.addCase(fetchCustomModels.rejected, (state) => {
      state.isLoading = false;
      state.initialLoadComplete = true;
    });
  },
});

export const {
  setShowDeleteConfirmation,
  setCurrentPage,
  setCurrentEditedRow,
  updateCustomModelsListSorting,
  setEmptyStateType,
  clearState,
} = customModelListSlice.actions;

export default customModelListSlice.reducer;
