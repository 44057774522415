import { ApplicationStatus, SpreadsheetImportMethod } from "@explorance/mly-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SelectOption } from "ts/select";

type ImportUsersState = {
  showDropdown: boolean;
  isPreimporting: boolean;
  importStatus: ApplicationStatus;
  selectedAppendReplaceOption: SelectOption;
};

const initialState: ImportUsersState = {
  showDropdown: false,
  isPreimporting: false,
  importStatus: ApplicationStatus.NotInitialized,
  selectedAppendReplaceOption: {
    label: "",
    value: SpreadsheetImportMethod.Append,
    data: { icon: undefined, description: "" },
  },
};

const ImportUsersSlice = createSlice({
  name: "importUsersSlice",
  initialState,
  reducers: {
    setShowDropdown: (state, action: PayloadAction<boolean>) => {
      state.showDropdown = action.payload;
    },
    setIsPreimporting: (state, action: PayloadAction<boolean>) => {
      state.isPreimporting = action.payload;
    },
    setImportStatus: (state, action: PayloadAction<ApplicationStatus>) => {
      state.importStatus = action.payload;
    },
    setSelectedAppendReplaceOption: (state, action: PayloadAction<SelectOption>) => {
      state.selectedAppendReplaceOption = action.payload;
    },
    handleCancelUpload: (state, action: PayloadAction<SelectOption>) => {
      state.showDropdown = false;
      state.selectedAppendReplaceOption = action.payload;
    },
    clearState: () => initialState,
  },
});

export const {
  setShowDropdown,
  setIsPreimporting,
  setImportStatus,
  setSelectedAppendReplaceOption,
  handleCancelUpload,
  clearState,
} = ImportUsersSlice.actions;

export default ImportUsersSlice.reducer;
