import React from "react";
import styled from "styled-components";
import { Skeleton } from "components/Skeleton";
import { Color } from "ts/enums/color";

export const TopicsTableRowsPlaceholder = () => {
  const tdMainColumn = [
    {
      width: 24,
      height: 24,
      backgroundColor: Color.sky50,
    },
    {
      width: 60,
      height: 10,
    },
    {
      width: 40,
      height: 10,
    },
    {
      width: 40,
      height: 10,
    },
    {
      width: 40,
      height: 10,
    },
    {
      width: 40,
      height: 10,
    },
    {
      width: 34,
      height: 10,
    },
    {
      width: 34,
      height: 10,
    },
    {
      width: 34,
      height: 10,
    },
    {
      width: 40,
      height: 18,
      backgroundColor: Color.sky50,
    },
    {
      width: 24,
      height: 24,
      backgroundColor: Color.sky50,
    },
  ];

  return (
    <>
      {[...Array(8)].map((_, i) => (
        <StyledTopicsTableRowsPlaceholder key={i} className="pulse">
          {tdMainColumn.map((placeholder, i) => (
            <td key={i} className="loading">
              <Skeleton
                width={placeholder.width}
                height={placeholder.height}
                backgroundColor={placeholder.backgroundColor}
              />
            </td>
          ))}
        </StyledTopicsTableRowsPlaceholder>
      ))}
    </>
  );
};

const StyledTopicsTableRowsPlaceholder = styled.tr`
  display: flex;
  align-items: center;
  height: 58px;
  border-bottom: 1px solid ${Color.sky20};

  td {
    display: flex;
    align-items: center;
    flex-direction: row;
    border-radius: 10px;

    :first-child {
      overflow: revert;
    }

    :nth-child(2) {
      border: none;
    }

    :nth-child(1),
    :nth-child(9),
    :nth-child(10) {
      border-radius: 3px;
    }

    :last-child {
      border-bottom: none;
    }
  }

  @media (min-width: 960px) {
    td {
      :nth-child(1) {
        margin-left: 4px;
        margin-right: 8px;
      }

      :nth-child(2) {
        margin-right: 124px;
      }

      :nth-child(3) {
        margin-right: 58px;
      }

      :nth-child(4) {
        margin-right: 37px;
      }

      :nth-child(7) {
        margin-right: 5px;
      }

      :nth-child(8) {
        margin-right: 10px;
      }
    }
  }

  @media (min-width: 1366px) {
    width: 100%;

    td {
      :first-child {
        margin-right: 4px;
      }

      :nth-child(2) {
        margin-right: 135px;
        margin-left: 5px;
      }

      :nth-child(3) {
        margin-right: 48px;
      }

      :nth-child(4) {
        margin-right: 60px;
      }

      :nth-child(5) {
        margin-right: 54px;
      }

      :nth-child(6) {
        margin-right: 46px;
      }

      :nth-child(7) {
        display: revert;
        margin-right: 43px;
      }

      :nth-child(8) {
        display: revert;
        margin-right: 52px;
      }

      :nth-child(9) {
        margin-right: 42px;
      }

      :nth-child(10) {
        margin-right: 0px;
      }
    }
  }
`;
