import { getUser } from "services/users";
import { authenticationManager } from "..";

export async function getCurrentUserId(): Promise<number> {
  const user = await authenticationManager.getUser();
  if (!user || user.expired) return;
  const userByExternalId = await getUser(user.profile.sub, true);
  const userId = userByExternalId.data.id;
  if (!userId) authenticationManager.logout();

  return userId;
}
