import { getStorageConfig } from "config/configProviders";
import axios from "axios";
import { LanguageCode } from "@explorance/mly-types";
import { Resources } from "ts/resources";
import { getTypedObjectKeys } from "utils/keys";
import resourcesEng from "assets/resources/en.json";
import resourcesFra from "assets/resources/fr.json";

export const getResourceFiles = (): Resources => ({
  [LanguageCode.Eng]: resourcesEng,
  [LanguageCode.Fra]: resourcesFra,
});

export const fetchRecentTopicsResourceFile = async (
  lang: LanguageCode
): Promise<Record<string, string>> => {
  const storageConfig = getStorageConfig();
  const url = `${storageConfig.account}/translations/topics_${lang.toLowerCase()}.json`;
  try {
    const { data } = await axios.get(url);
    return data;
  } catch (err: any) {
    throw new Error(`Could not fetch topic resource file from ${url}: ${err.message}`);
  }
};

export const getInitialTopicResources = () => {
  const initialTopicResources = {} as Resources;
  const resourceFiles = getResourceFiles();

  getTypedObjectKeys(resourceFiles).forEach((lang) => {
    const storedTopics = localStorage.getItem(`topics_${lang.toLowerCase()}`);
    initialTopicResources[lang] = storedTopics ? JSON.parse(storedTopics) : {};
  });

  return initialTopicResources;
};

export const getResources = (topicResources: Resources): Resources => {
  const resources = {} as Resources;
  const resourceFiles = getResourceFiles();

  getTypedObjectKeys(resourceFiles).forEach((lang) => {
    resources[lang] = { ...resourceFiles[lang], ...topicResources[lang] };
  });

  return resources;
};
