import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { routes } from "routes";
import { Text } from "components/Text";

export const ExportedFileToast = () => (
  <StyledExportedFileContainer>
    <Text resource="toast.downloadedFiles.description" />
    <Link to={routes.downloadedFilesPage}>
      <Text resource="sidebar.downloadedFiles" />
    </Link>
  </StyledExportedFileContainer>
);

const StyledExportedFileContainer = styled.div`
  font-size: 0.875em;
  font-weight: normal;
  margin-top: 5px;
  margin-right: 10px;
`;
