import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";

import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { Range } from "ts/range";

const SLIDER_MINIMUM_VALUE = 0;
const SLIDER_MAXIMUM_VALUE = 100;

type Props = {
  currentMin: number;
  currentMax: number;
  sliderMin?: number;
  sliderMax?: number;
  width?: number;
  onChange: (updatedAlertRange: Range) => void;
};

export const RangeSlider = ({
  currentMin,
  currentMax,
  sliderMin = SLIDER_MINIMUM_VALUE,
  sliderMax = SLIDER_MAXIMUM_VALUE,
  width,
  onChange,
}: Props) => {
  const range = useRef<HTMLInputElement>(null);

  const getPercent = useCallback(
    (value) => Math.round(((value - sliderMin) / (sliderMax - sliderMin)) * 100),
    [sliderMin, sliderMax]
  );

  const handleLeftThumbDrag = (event) => {
    const value = Math.min(Number(event.target.value), currentMax - 1);
    onChange({ min: value, max: currentMax });
  };

  const handleRightThumbDrag = (event) => {
    const value = Math.max(Number(event.target.value), currentMin + 1);
    onChange({ min: currentMin, max: value });
  };

  useEffect(() => {
    const minPercent = getPercent(currentMin);
    const maxPercent = getPercent(currentMax);
    const diff = maxPercent - minPercent;

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${diff - 1}%`;
    }
  }, [currentMin, currentMax, getPercent]);

  useEffect(() => {
    const minPercent = getPercent(currentMin);
    const maxPercent = getPercent(currentMax);
    const diff = maxPercent - minPercent;

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${diff}%`;
    }
  }, [currentMax, currentMin, getPercent]);

  return (
    <StyledSliderContainer>
      <StyledHiddenRangeInput
        type="range"
        min={sliderMin}
        max={sliderMax}
        value={currentMin}
        onChange={handleLeftThumbDrag}
        style={{ zIndex: ZIndexStackingContext.low + (currentMin > sliderMax - 100 ? 5 : 3) }}
        width={width}
      />
      <StyledHiddenRangeInput
        type="range"
        min={sliderMin}
        max={sliderMax}
        value={currentMax}
        onChange={handleRightThumbDrag}
        style={{ zIndex: ZIndexStackingContext.low + 4 }}
        width={width}
      />

      <StyledSlider width={width}>
        <StyledSliderLeftValue>{currentMin}%</StyledSliderLeftValue>
        <StyledSliderTrack />
        <StyledRangeContainer>
          <StyledSliderRange ref={range} />
        </StyledRangeContainer>
        <StyledSliderRightValue>{currentMax}%</StyledSliderRightValue>
      </StyledSlider>
    </StyledSliderContainer>
  );
};

const StyledSliderContainer = styled.div`
  position: relative;
  left: 50px;
  user-select: none;
  * {
    box-sizing: content-box;
  }
`;

const StyledSlider = styled.div<{ width?: number }>`
  position: relative;
  width: ${({ width }) => width || 256}px;
`;

const StyledSliderTrack = styled.div`
  position: absolute;
  border-radius: 3px;
  height: 6px;
  background-color: ${Color.blue20};
  width: 100%;
  z-index: ${ZIndexStackingContext.low + 1};
`;

const StyledRangeContainer = styled.div`
  width: calc(100% - 8px);
  margin: 0 auto;
`;

const StyledSliderRange = styled.div`
  position: relative;
  border-radius: 3px;
  height: 6px;
  background-color: ${Color.blue50};
  z-index: ${ZIndexStackingContext.low + 2};
`;

const StyledSliderValue = styled.div`
  background-color: ${Color.sky20};
  padding: 4px 6px;
  border-radius: 3px;
  position: absolute;
  color: ${Color.gray50};
  font-size: 14px;
  margin-top: 20px;
  font-weight: bold;
`;

const StyledSliderLeftValue = styled(StyledSliderValue)`
  left: -50px;
  top: -28px;
`;

const StyledSliderRightValue = styled(StyledSliderValue)`
  right: -60px;
  top: -28px;
`;

const StyledHiddenRangeInput = styled.input<{ width?: number }>`
  pointer-events: none;
  position: absolute;
  height: 0;
  width: ${({ width }) => width || 256}px;
  outline: none;

  /* For Chrome browsers */
  ::-webkit-slider-thumb {
    background-color: ${Color.white};
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 2.5px rgba(0, 0, 0, 0.09);
    border: 1px solid ${Color.indigo20};
    cursor: pointer;
    height: 14px;
    width: 14px;
    margin-top: 2px;
    pointer-events: all;
    position: relative;
    left: -2px;
  }

  /* For Firefox browsers */
  ::-moz-range-thumb {
    background-color: ${Color.white};
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 2.5px rgba(0, 0, 0, 0.09);
    border: 1px solid ${Color.indigo20};
    cursor: pointer;
    height: 14px;
    width: 14px;
    margin-top: 2px;
    pointer-events: all;
    position: relative;
  }

  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
`;

/* Source: https://codesandbox.io/s/multi-range-slider-react-js-forked-4uq1uo?file=/src/App.js */
