import { MouseSensor } from "@dnd-kit/core";

//This is a custom sensor for drag and drop that will prevent the drag and drop from happening
//if the element has the data-no-dnd attribute set to false
export class SmartMouseSensor extends MouseSensor {
  static activators = [
    {
      eventName: "onPointerDown" as any,
      handler: ({ nativeEvent: event }) => shouldHandleEvent(event.target as HTMLElement),
    },
  ];
}

const shouldHandleEvent = (element: HTMLElement | null) => {
  let cur = element;

  while (cur) {
    if (cur.dataset?.noDnd) {
      return cur.dataset?.noDnd === "false";
    }
    cur = cur.parentElement;
  }

  return true;
};
