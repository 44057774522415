import { ApiTopicNode } from "ts/topic";
import {
  DemographicFilter as ApiDemographicFilter,
  WidgetSize,
  WidgetStat,
  WidgetType,
  Ranking,
  BreakdownTypes,
} from "@explorance/mly-types";
import { DemographicFilter } from "./filters/demographicFilter";
import { Range } from "./range";

export const RANKING_LIMITS = [5, 10, 15];

export type StatsBreakdown = {
  type: BreakdownTypes;
  ranking: Ranking;
  demographic?: DemographicFilter;
  limit: number;
};

export type Widget = {
  id: number;
  type: WidgetType;
  title: string;
  configuration?: WidgetConfiguration;
  filters?: WidgetFilter;
  displayOrder?: number;
  size: WidgetSize;
};

export type WidgetFilter = {
  modelId: number;
  isVirtual: boolean;
  statistics?: string[];
  topics?: ApiTopicNode[];
  demographics?: ApiDemographicFilter[];
  threshold?: number | null;
  selectedColumns?: string[];
  alertRange?: Range;
};

export type WidgetConfiguration = {
  stats: WidgetStat[];
  breakdowns: StatsBreakdown[];
  size: WidgetSize;
};

export type WidgetGroup = {
  title: string;
  id: number;
  isExpanded: boolean;
  isGroupInModalExpanded?: boolean;
  displayOrder?: number;
  widgets: Widget[];
};

export type WidgetsApiData = {
  widgetGroups: WidgetGroup[];
  // available when analysis is shared
  lastPushed?: Date;
  // available when there are changes to push to sharees
  pushAvailable?: boolean;
};
