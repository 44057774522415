import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "store";
import { routes } from "routes";

import { AnalysisContext } from "context/AnalysisContext";
import { useSharingContext } from "context/SharingContext";

import { useResource } from "hooks/useResource";
import { getSlug } from "utils/getSlug";
import { saveSharing, updateSharingDraft } from "services/analysis/sharing";
import { getInitialState } from "context/SharingContext/getInitialState";
import { isAnyAdmin } from "utils/isAnyAdmin";

import { Notch } from "common-layouts/AnalysisLayout/Notch";
import { BackButton } from "components/_buttons/BackButton";
import { Select } from "components/_inputs/Select";
import { Tabs } from "components/Tabs";
import { SharingBuilder } from "pages/analysis/[id]/sharing/_layouts/SharingBuilder";
import { SelectedUserPills } from "./_layouts/SelectedUserPills";
import { SharingActionsSection } from "./_layouts/SharingActionsSection";
import { getVisibleCommentDemsForFetch } from "context/SharingContext/helpers";
import { getSharingMethodFromView } from "utils/getSharingMethodFromSharingView";
import { ErrorScreen } from "components/ErrorScreen";
import { LoadingDotsScreen } from "components/_screens/LoadingDotsScreen";

import { PageErrorType } from "ts/enums/pageErrorType";
import { Color } from "ts/enums/color";
import {
  DefaultWidgets,
  ShareByOption,
  ShareStep,
  SharingPageTab,
  SharingView,
} from "ts/enums/sharing";
import {
  AnalysisStatus,
  FileUploadStatus,
  RoleType,
  SharingOverviewAccess,
} from "@explorance/mly-types";
import { showToastError } from "store/toast/toastSlice";
import { Text } from "components/Text";

const getSharingPageTab = (slug: string) =>
  slug.includes("ShareTo") ? SharingPageTab.Edit : SharingPageTab.Show;

export const SharingPage = () => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);

  const history = useHistory();
  const location = useLocation();
  const { getResource } = useResource();

  const [state] = useContext(AnalysisContext);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { sharingState, updateSharingState, refetchSharedUsersData, refetchSharedGroupsData } =
    useSharingContext();

  const dispatch = useAppDispatch();

  const groupNameInputRef = useRef<HTMLInputElement>(null);
  const groupNameButtonRef = useRef<HTMLInputElement>(null);

  // Variables for the restrictions
  const isUserAnalystInHisAnalysis =
    currentUser.roleType === RoleType.Analyst && !state.analysisDetails.sharing;
  const sharingStepNotSetYet =
    !sharingState.step &&
    [SharingView.ShareToUsers, SharingView.ShareToGroups].includes(sharingState.view);
  const isUserViewer = currentUser.roleType === RoleType.Viewer;
  const isUserAdmin = isAnyAdmin(currentUser.roleType);
  const analysisFailed = state.analysisDetails.status === AnalysisStatus.Failed;
  const analysisNotAnalyzed =
    state.analysisDetails.uploadStatus === FileUploadStatus.Failed ||
    state.analysisDetails.status === AnalysisStatus.NotAnalyzed ||
    state.analysisDetails.status === AnalysisStatus.InProgress;

  const baseUrl = `/analysis/${state.analysisDetails.id}`;

  const [activeTab, setActiveTab] = useState<SharingPageTab>();

  const tabs = useMemo(() => {
    const counts = {
      [ShareByOption.Users]: sharingState.sharedUsersApiData.totalCount,
      [ShareByOption.Groups]: sharingState.groupsApiData.totalCount,
    };
    return [
      {
        id: SharingPageTab.Show,
        label: (
          <>
            <Text
              resource={{
                key: `sharing.tab.${sharingState.shareBy.value.toLowerCase()}SharedWith`,
              }}
            />
            <StyledShareWithCount>{counts[sharingState.shareBy.value]}</StyledShareWithCount>
          </>
        ),
        href: `${baseUrl}/sharing?view=${sharingState.shareBy.value}SharedWith`,
      },
      {
        id: SharingPageTab.Edit,
        label: <Text resource="sharing.tab.share" />,
        href: `${baseUrl}/sharing?view=ShareTo${sharingState.shareBy.value}&step=UserSelection`,
      },
    ];
  }, [
    sharingState.groupsApiData.totalCount,
    sharingState.sharedUsersApiData.totalCount,
    baseUrl,
    sharingState.shareBy,
  ]);

  useEffect(() => {
    if (!state.loadingAnalysisDetails) {
      setActiveTab(
        getSharingPageTab(getSlug(location.search.length > 0 ? location.search : location.pathname))
      );
      const queryParams = new URLSearchParams(location.search);
      const view = queryParams.get("view");
      if (!view) {
        updateSharingState("view", SharingView.ShareToUsers);
        updateSharingState("step", null);
      }
    }
  }, [location, state.loadingAnalysisDetails]); // eslint-disable-line

  // functions
  const handleSelectShareBy = (shareOption: ShareByOption) => {
    updateSharingState(
      "shareBy",
      sharingState.shareByDropdownOptions.find((o) => o.value === shareOption)
    );

    const queryParams = new URLSearchParams(history.location.search);
    if (shareOption === ShareByOption.Groups && activeTab === SharingPageTab.Edit) {
      updateSharingState("view", SharingView.ShareToGroups);
      queryParams.set("view", "ShareToGroups");
    } else if (shareOption === ShareByOption.Users && activeTab === SharingPageTab.Edit) {
      updateSharingState("view", SharingView.ShareToUsers);
      queryParams.set("view", "ShareToUsers");
    }

    if (shareOption === ShareByOption.Groups && activeTab === SharingPageTab.Show) {
      updateSharingState("view", SharingView.GroupsSharedWith);
      queryParams.set("view", "GroupsSharedWith");
    } else if (shareOption === ShareByOption.Users && activeTab === SharingPageTab.Show) {
      updateSharingState("view", SharingView.UsersSharedWith);
      queryParams.set("view", "UsersSharedWith");
    }

    // remove search query param
    queryParams.delete("search");

    history.push(`${baseUrl}/sharing?${queryParams.toString()}`);
  };

  const handleSave = async () => {
    if (sharingState.permissionFields.groupName.trim() === "") {
      updateSharingState("showGroupNameError", true);
      return;
    }
    if (
      sharingState.permissionFields.customConditions.some(
        (cc) => (cc.analysisDemographic.length === 0) !== (cc.userDemographic.length === 0)
      )
    ) {
      updateSharingState("showCustomConditionError", true);
      return;
    }

    try {
      setIsSaving(true);
      const requestBody = {
        method: getSharingMethodFromView(sharingState.view),
        groupName: sharingState.permissionFields.groupName,
        permissionLevel: sharingState.permissionFields.permissionLevel,
        demographicFilters: sharingState.permissionFields.visibleCommentsByDemographicValues.map(
          (d) => getVisibleCommentDemsForFetch(d)
        ),
        customFilters: sharingState.permissionFields.customConditions.filter(
          (cc) => cc.analysisDemographic.length > 0 && cc.userDemographic.length > 0
        ),
        demographicsToDisplay: sharingState.permissionFields.visibleDemographicsFilters.map(
          (cd) => cd.name
        ),
        allowExport: sharingState.permissionFields.allowExportData,
        overviewAccess: SharingOverviewAccess.Shared,
        defaultWidgets: sharingState.permissionFields.defaultWidgets || DefaultWidgets.None,
        dashboardAccess: sharingState.permissionFields.dashboardAccess,
        topicExplorerAccess: sharingState.permissionFields.topicExplorerAccess,
        commentExplorerAccess: sharingState.permissionFields.commentExplorerAccess,
        definition: undefined, // TODO: implement definitions?
      };

      // This is specifically useful to update the method right before committing
      await updateSharingDraft({ analysisId: state.analysisDetails.id, ...requestBody });
      await saveSharing(state.analysisDetails.id);

      await refetchSharedUsersData({ analysisId: state.analysisDetails.id });
      await refetchSharedGroupsData({ analysisId: state.analysisDetails.id });

      history.push(routes.sharingPage(state.analysisDetails.id));

      setTimeout(() => resetSharingValues(), 500);
    } catch (e) {
      dispatch(showToastError("toast.defaultError"));
    } finally {
      setIsSaving(false);
    }
  };

  const handleSwitchTab = (tabId: string) => {
    if (tabId === SharingPageTab.Show) {
      updateSharingState("permissionFields", getInitialState(getResource).permissionFields);
    }
    if (sharingState.shareBy.value === ShareByOption.Groups) {
      updateSharingState(
        "view",
        tabId === SharingPageTab.Show ? SharingView.GroupsSharedWith : SharingView.ShareToGroups
      );
      updateSharingState("showGroupNameError", false);
      updateSharingState("showCustomConditionError", false);
    } else {
      updateSharingState(
        "view",
        tabId === SharingPageTab.Show ? SharingView.UsersSharedWith : SharingView.ShareToUsers
      );
    }
  };

  const resetSharingValues = () => {
    updateSharingState("step", ShareStep.UserSelection);
    updateSharingState("selectedUsers", []);
    updateSharingState("selectedUsersCount", 0);
    updateSharingState("selectedUserDemographicFilters", []);
    updateSharingState("permissionFields", getInitialState(getResource).permissionFields);
    updateSharingState("showCustomConditionError", false);
    updateSharingState("showGroupNameError", false);
  };

  // Possible restrictions on the sharing view
  if (
    state.loadingAnalysisDetails ||
    state.loadingAvailableDemographicFilters ||
    sharingStepNotSetYet
  ) {
    return <LoadingDotsScreen />;
  }

  if (analysisFailed && (isUserAdmin || isUserAnalystInHisAnalysis)) {
    return <ErrorScreen errorType={PageErrorType.AnalysisFailed} />;
  } else if (analysisNotAnalyzed) {
    return <ErrorScreen errorType={PageErrorType.AnalysisNotAnalyzed} />;
  } else if (
    ((!isUserAnalystInHisAnalysis || isUserViewer) && !isUserAdmin) ||
    state.analysisError === PageErrorType.AnalysisInsufficientPermission
  ) {
    return <ErrorScreen errorType={PageErrorType.GeneralInsufficientPermission} />;
  }

  return (
    <StyledSharingPage className="fade-in">
      {/* HEADER */}
      <Notch />
      <StyledHeader>
        <BackButton
          resourceKey={"button.backToOverviewPage"}
          onClick={() => history.push(`${baseUrl}/overview`)}
        />
      </StyledHeader>
      <StyledShareByContainer>
        <span>
          <Text resource="sharing.shareByDropdown.label" />
        </span>
        <Select
          selectedOption={sharingState.shareBy}
          options={sharingState.shareByDropdownOptions}
          buttonStyle={{ padding: "8px 10px", width: "200px" }}
          dropdownWidth="200px"
          handleChange={handleSelectShareBy}
        />
      </StyledShareByContainer>
      <StyledTabsContainer>
        <Tabs tabs={tabs} activeTab={activeTab} onSelectTab={handleSwitchTab} />
      </StyledTabsContainer>

      {/* CONTENT */}
      <SharingBuilder
        groupNameInputRef={groupNameInputRef}
        groupNameButtonRef={groupNameButtonRef}
      />
      {activeTab === SharingPageTab.Edit &&
        sharingState.selectedUsers.length > 0 &&
        sharingState.step === ShareStep.UserSelection && (
          <div style={{ marginTop: "16px" }}>
            <SelectedUserPills />
          </div>
        )}

      {/* BOTTOM CTAs */}
      {[SharingView.ShareToGroups, SharingView.ShareToUsers].includes(sharingState.view) ? (
        <SharingActionsSection
          onSave={handleSave}
          isSaving={isSaving}
          groupNameInputRef={groupNameInputRef}
          groupNameButtonRef={groupNameButtonRef}
        />
      ) : (
        <StyledFullHeightUtilDiv />
      )}
    </StyledSharingPage>
  );
};

const StyledHeader = styled.div`
  padding: 30px 0px 16px 0px;
`;

const StyledTabsContainer = styled.div`
  margin-bottom: 16px;
`;

const StyledFullHeightUtilDiv = styled.div`
  height: 100%;
`;

const StyledShareByContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;

  span {
    color: ${Color.gray50};
    font-size: 15px;
    font-weight: bold;
  }
`;

const StyledShareWithCount = styled.span`
  font-size: 14px;
  color: ${Color.gray50};
  font-weight: normal;
  background-color: ${Color.neutral10};
  padding: 3px 10px;
  margin-left: 6px;
  border-radius: 5px;
`;

const StyledSharingPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`;
