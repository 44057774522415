import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import { SocketEvent } from "@explorance/mly-types";
import { config } from "config/configProviders";
import { useAppSelector } from "store";

const SocketContext = createContext(null);

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const [socket, setSocket] = useState(null);
  const socketInitializedRef = useRef(false);

  useEffect(() => {
    // Only initialize socket if currentUser is available and socket is not already initialized
    if (currentUser && !socketInitializedRef.current) {
      const newSocket = io(config.server.baseApi);
      newSocket.emit(SocketEvent.UserConnected, currentUser.id);
      setSocket(newSocket);
      socketInitializedRef.current = true;

      return () => {
        newSocket.disconnect();
        socketInitializedRef.current = false;
      };
    }
  }, [currentUser]);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
