import { RoleType } from "@explorance/mly-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type UserListHeaderState = {
  isExportInProgress: boolean;
  allRoleFilters: RoleType[];
  showFilterDropdown: boolean;
};

const initialState: UserListHeaderState = {
  isExportInProgress: false,
  allRoleFilters: [],
  showFilterDropdown: false,
};

const UserListHeaderSlice = createSlice({
  name: "userListHeaderSlice",
  initialState,
  reducers: {
    setExportInProgress: (state, action: PayloadAction<boolean>) => {
      state.isExportInProgress = action.payload;
    },
    setAllRoleFilters: (state, action: PayloadAction<RoleType[]>) => {
      state.allRoleFilters = action.payload;
    },
    toggleShowFilterDropdown: (state) => {
      state.showFilterDropdown = !state.showFilterDropdown;
    },
    setShowFilterDropdown: (state, action: PayloadAction<boolean>) => {
      state.showFilterDropdown = action.payload;
    },
  },
});

export const {
  setExportInProgress,
  setAllRoleFilters,
  toggleShowFilterDropdown,
  setShowFilterDropdown,
} = UserListHeaderSlice.actions;

export default UserListHeaderSlice.reducer;
