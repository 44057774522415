import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "store";
import styled, { css, keyframes } from "styled-components";
import { Icon, IconType } from "components/_icons/Icon";
import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { cancelFade, closeToast } from "store/toast/toastSlice";
import { useHistory } from "react-router-dom";
import { CustomToastContent } from "./CustomToastContent";
import { Text } from "components/Text";

export enum ToastType {
  Success,
  Error,
}

export const Toast = () => {
  const state = useAppSelector((state) => state.toast);
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (state.isOpen && !state.noFade) setTimeout(() => dispatch(closeToast()), state.delay);
  }, [state.isOpen, state.noFade, state.delay, dispatch]);

  useEffect(() => {
    if (state.noFade) {
      dispatch(closeToast());
      dispatch(cancelFade());
    }
  }, [history?.location.pathname, dispatch]); // eslint-disable-line

  return (
    <StyledToast isOpen={state.isOpen} type={state.toastType}>
      {state.toastType === ToastType.Success ? (
        <Icon type={IconType.checkCircle} color={Color.green50} size={16} />
      ) : (
        <Icon type={IconType.alert} color={Color.red55} size={16} />
      )}
      <div>
        <StyledTitleRow>
          <Text
            resource={{
              key: state.toastMessage?.titleKey,
              args:
                state.toastMessage.args && state.toastMessage.args.length > 0
                  ? [state.toastMessage?.args.find((arg) => arg.titleKeyArg)?.titleKeyArg]
                  : undefined,
            }}
          />
          <div onClick={() => dispatch(closeToast())}>
            <Icon type={IconType.x} color={Color.black} size={12} />
          </div>
        </StyledTitleRow>
        {state.toastMessage?.captionKey && (
          <StyledToastCaption>
            <Text
              resource={{
                key: state.toastMessage.captionKey,
                args:
                  state.toastMessage.args && state.toastMessage.args.length > 0
                    ? [state.toastMessage.args.find((arg) => arg.captionArg).captionArg]
                    : undefined,
              }}
            />
          </StyledToastCaption>
        )}
        {state.toastMessage.customToastContentType && (
          <CustomToastContent type={state.toastMessage.customToastContentType} />
        )}
      </div>
    </StyledToast>
  );
};

type StyledProps = {
  isOpen: boolean;
  type: ToastType;
};

const slideIn = keyframes`
  from {
    bottom: 0;
  }
  to {
    bottom: 20px;
  }
`;

const slideOut = keyframes`
  from {
    bottom: 20px;
  }
  to {
    bottom: 0;
  }
`;

const StyledToast = styled.div<StyledProps>`
  display: flex;
  z-index: ${ZIndexStackingContext.medium};

  position: fixed;
  bottom: 20px;
  right: 20px;

  font-size: 16px;
  font-weight: bold;
  font-family: Lato, sans-serif;

  ${(props) =>
    props.type === ToastType.Success
      ? css`
          color: ${Color.green50};
          background: ${Color.green30};
          border: 1px solid ${Color.green50};
        `
      : css`
          color: ${Color.red40};
          background: ${Color.red10};
          border: 1px solid ${Color.red40};
        `};

  box-shadow: 0px 3px 6px #00000029;

  padding: 15px;
  border-radius: 5px;

  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  animation: ${(props) =>
    props.isOpen
      ? css`
          ${slideIn} 0.7s linear
        `
      : css`
          ${slideOut} 0.7s linear
        `};
  transition: visibility linear 0.3s, opacity linear 0.3s;

  svg {
    display: block;
    height: 20px;
    margin-right: 10px;
  }
`;

const StyledTitleRow = styled.div`
  display: flex;
  align-items: center;
  min-width: 300px;

  div {
    position: relative;
    margin-left: auto;
    right: -10px;
    cursor: pointer;

    svg {
      display: block;
      height: 16px;
      width: 16px;
    }
  }
`;

const StyledToastCaption = styled.div`
  font-size: 0.875em;
  font-weight: normal;
  margin-top: 5px;
  margin-right: 10px;
`;
