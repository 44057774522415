import React from "react";
import { Feature } from "ts/enums/feature";
import { isFeatureActive } from "utils/isFeatureActive";

type Props = {
  feature: Feature;
  children: React.ReactElement;
};

export const FeatureFlag = ({ feature, children }: Props) => {
  if (isFeatureActive(feature)) return children;
  return null;
};
