import React, { useState } from "react";
import styled from "styled-components";

import { StyledTitleContainer } from "./StyledDataCustomizationContainer";
import { Icon, IconType } from "components/_icons/Icon";
import { Tooltip } from "components/Tooltip";
import { CustomCondition } from "./CustomCondition";

import { useResource } from "hooks/useResource";

import { CustomConditionDemographics } from "ts/sharing";
import { DemographicFilterHeader } from "ts/filters/demographicFilter";
import { UserDemographic } from "context/UserProfileContext";
import { Text } from "components/Text";

type Props = {
  savedCustomConditions: CustomConditionDemographics[];
  analysisDemographicHeaders: DemographicFilterHeader[];
  userDemographics: UserDemographic[];
  showCustomConditionError: boolean;
  // function props
  updateCustomConditions: (updatedCustomConditions: CustomConditionDemographics[]) => void;
  handleHideCustomConditionError: () => void;
};

export const CustomConditionSection = ({
  savedCustomConditions,
  analysisDemographicHeaders,
  userDemographics,
  showCustomConditionError,
  // function props
  updateCustomConditions,
  handleHideCustomConditionError,
}: Props) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const { getResource } = useResource();

  return (
    <div>
      <StyledTitleContainer>
        <b>
          <Text resource="sharing.permissionForm.customCondition.title" />
        </b>
        <div
          data-tip
          data-for="custom-condition-tooltip"
          onMouseOver={() => setShowTooltip(true)}
          onMouseOut={() => setShowTooltip(false)}
        >
          <Icon type={IconType.info} size={10} />
          {showTooltip && (
            <Tooltip
              tooltipId="custom-condition-tooltip"
              content={getResource("sharing.permissionForm.customCondition.tooltip")}
            />
          )}
        </div>
      </StyledTitleContainer>
      <StyledCustomConditionContainer>
        {savedCustomConditions.map((cc, index) => (
          <CustomCondition
            key={index}
            index={index}
            customConditionDemographics={cc}
            savedCustomConditions={savedCustomConditions}
            analysisDemographicHeaders={analysisDemographicHeaders}
            userDemographics={userDemographics}
            showCustomConditionError={showCustomConditionError}
            updateCustomConditions={updateCustomConditions}
            handleHideCustomConditionError={handleHideCustomConditionError}
          />
        ))}
      </StyledCustomConditionContainer>
    </div>
  );
};

const StyledCustomConditionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
