import { CustomModel } from "@explorance/mly-types";
import { AnalysisModel } from "./filters/analysisModel";

export const mapDataToAnalysisModel = (data: any): AnalysisModel => ({
  isVirtual: false,
  modelName: data.categorizationType,
  topics: data,
});

export type CustomModelsApiData = {
  customizedModels: CustomModel[];
  totalCount: number;
  itemCount: number;
};
