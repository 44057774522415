import { mlyApi, getHeaders } from "config/mlyApi";

export async function getModels() {
  const requestConfig = await getHeaders();
  const url = "/models";
  return mlyApi.get(url, requestConfig);
}

export async function getModelStructure(modelId: number) {
  const requestConfig = await getHeaders();
  const url = `/models/graph?id=${modelId}`;
  return mlyApi.get(url, requestConfig);
}
