import React, { ReactNode, useState } from "react";
import styled, { css } from "styled-components";

import { Color } from "ts/enums/color";
import { ExpandCollapseButton } from "components/_buttons/ExpandCollapseButton";
import { Skeleton } from "components/Skeleton";
import { Tooltip } from "components/Tooltip";
import { Icon, IconType } from "components/_icons/Icon";
import { Resource, Text } from "components/Text";

type CustomStyles = {
  backgroundColor?: string;
  removePaddingButtom?: boolean;
};

type Props = {
  titleLeftResource?: Resource;
  titleRightResource?: Resource;
  children: ReactNode;
  isLoading?: boolean;
  tooltipCaptionKey?: Resource;
  titleBottom?: ReactNode | string;
  hideBoxShadow?: boolean;
  hideHeaderBorder?: boolean;
  customStyles?: CustomStyles;
  isExpanded?: boolean;
};

export const CollapsibleSection = ({
  titleLeftResource,
  titleRightResource,
  children,
  isLoading,
  tooltipCaptionKey,
  titleBottom,
  hideBoxShadow,
  hideHeaderBorder,
  customStyles,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleExpand = () => {
    if (isLoading) return;
    setIsExpanded((expand) => !expand);
  };

  return (
    <StyledCollapsibleSection
      showBoxShadow={isExpanded && !hideBoxShadow}
      backgroundColor={customStyles?.backgroundColor}
    >
      <StyledHeaderContainer
        isExpanded={isExpanded}
        hideHeaderBorder={hideHeaderBorder}
        removePaddingButtom={customStyles?.removePaddingButtom}
      >
        <StyledClickableHeader data-no-dnd="true" onClick={handleExpand}>
          <ExpandCollapseButton
            isCollapsed={!isExpanded}
            customWidth={16}
            customHeight={16}
            isLoading={isLoading}
            onClick={() => undefined}
          />
          {isLoading ? (
            <StyledSkeletonContainer className="pulse">
              <Skeleton width={150} height={18} backgroundColor={Color.neutral10} />
            </StyledSkeletonContainer>
          ) : (
            <span>
              <Text resource={titleLeftResource} />
            </span>
          )}
        </StyledClickableHeader>
        {isLoading ? (
          <StyledSkeletonContainer className="pulse">
            <Skeleton width={150} height={18} backgroundColor={Color.neutral10} />
          </StyledSkeletonContainer>
        ) : (
          <StyledRightContainer>
            <span>
              <Text resource={titleRightResource} />
            </span>
            {tooltipCaptionKey && (
              <div data-tip data-for="empty-cell-info">
                <Icon type={IconType.info} color={Color.gray50} size={12} />
                <Tooltip
                  tooltipId="empty-cell-info"
                  content={<Text resource={tooltipCaptionKey} />}
                  fontSize="0.785em"
                />
              </div>
            )}
          </StyledRightContainer>
        )}
        {titleBottom}
      </StyledHeaderContainer>
      {!isLoading && (
        <StyledChildrenContainer isExpanded={isExpanded}>{children}</StyledChildrenContainer>
      )}
    </StyledCollapsibleSection>
  );
};

const StyledCollapsibleSection = styled.div<{
  showBoxShadow: boolean;
  backgroundColor?: string;
}>`
  padding: 16px;
  border-radius: 2px;
  border: 1px solid ${Color.blue20};
  background-color: ${Color.white};
  font-size: 15px;
  background-color: ${({ backgroundColor }) => backgroundColor || Color.white};

  ${({ showBoxShadow }) =>
    showBoxShadow &&
    css`
      box-shadow: 0px 3px 10px 0px rgba(53, 56, 77, 0.2);
      transition: box-shadow 200ms ease-in;
    `}
`;

const StyledHeaderContainer = styled.div<{
  isExpanded: boolean;
  hideHeaderBorder: boolean;
  removePaddingButtom: boolean;
}>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid transparent;
  flex-wrap: wrap;
  row-gap: 10px;
  cursor: pointer;

  ${({ isExpanded, hideHeaderBorder, removePaddingButtom }) =>
    isExpanded &&
    css`
      padding-bottom: ${removePaddingButtom ? "0px" : "10px"};
      border-bottom: ${!hideHeaderBorder && `1px solid ${Color.blue20}`};
      transition: 200ms padding-bottom ease-in, 200ms border-bottom ease-in;
    `}

  span {
    font-weight: bold;
  }
`;

const StyledClickableHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  user-select: none;
  flex: 1;
`;

const StyledRightContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const StyledSkeletonContainer = styled.div`
  height: 18px;
`;

const StyledChildrenContainer = styled.div<{ isExpanded: boolean }>`
  opacity: ${({ isExpanded }) => (isExpanded ? 1 : 0)};
  max-height: ${({ isExpanded }) => (isExpanded ? "100000px" : "0px")};
  overflow: ${({ isExpanded }) => (isExpanded ? "visible" : "hidden")};
  cursor: default;
  transition: ${({ isExpanded }) =>
    isExpanded ? "opacity 200ms ease-in, max-height 200ms ease-in" : "max-height"};
`;
