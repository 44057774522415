import React, { createContext, useEffect, useState } from "react";
import { isEqual } from "lodash";
import {
  fetchRecentTopicsResourceFile,
  getInitialTopicResources,
  getResources,
  getResourceFiles,
} from "./helpers";

import { getTypedObjectKeys } from "utils/keys";
import { Resources } from "ts/resources";

const resourcesFiles = getResourceFiles();

export const ResourcesContext = createContext({ resources: {} as Resources });

export const ResourcesProvider = ({ children }) => {
  const [topicResources, setTopicResources] = useState<Resources>(getInitialTopicResources);

  useEffect(() => {
    if (Object.keys(topicResources.Eng).length === 0) {
      getTypedObjectKeys(resourcesFiles).forEach(async (lang) => {
        const recentTopicsResourceFile = await fetchRecentTopicsResourceFile(lang);

        // Check if topic translations in the state are outdated vs. blob file.
        if (!isEqual(topicResources[lang], recentTopicsResourceFile)) {
          localStorage.setItem(`topics_${lang}`, JSON.stringify(recentTopicsResourceFile));
          setTopicResources((prevTopicResources) => ({
            ...prevTopicResources,
            [lang]: recentTopicsResourceFile,
          }));
        }
      });
    }
  }, [topicResources]);

  return (
    <ResourcesContext.Provider value={{ resources: getResources(topicResources) }}>
      {children}
    </ResourcesContext.Provider>
  );
};
