import React from "react";
import styled from "styled-components";

import { Icon, IconType } from "components/_icons/Icon";
import { Text } from "components/Text";

type Props = {
  closeHandler: () => void;
  titleKey?: string;
};

export const CloseButton = ({ closeHandler, titleKey }: Props) => {
  return (
    <StyledCloseButton onClick={closeHandler}>
      <Text resource={titleKey} />
      <Icon
        type={IconType.xCircle}
        size={15}
        style={{ marginLeft: 6, position: "relative", top: 1 }}
      />
    </StyledCloseButton>
  );
};

const StyledCloseButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
  font-size: 14px;
  font-weight: bold;
`;
