import { RecommendationType } from "@explorance/mly-types";
import { CategorizedTopicPill } from "components/_pills/CategorizedTopicPill";
import React from "react";
import styled from "styled-components";
import { CategorizedTopic } from "ts/enums/categorizedTopic";
import { humanize } from "utils/humanize";
import { flatten } from "lodash";

type Props = {
  recommendations: RecommendationType[];
  categorizedRecommendations: Record<RecommendationType, string[]>;
  commentId: number;
  style?: Record<string, number | string>;
};

export const AllRecommendationsPillList = ({
  recommendations,
  categorizedRecommendations,
  commentId,
  style,
}: Props) => {
  const topicPillData = flatten(
    Object.keys(categorizedRecommendations).map((key) =>
      categorizedRecommendations[key].map((topic) => {
        return { label: topic, type: key as CategorizedTopic };
      })
    )
  );
  return (
    <StyledPillListContainer style={style}>
      {topicPillData.map((props, index) => (
        <CategorizedTopicPill key={index} {...props} />
      ))}
      {recommendations.map((topic) => (
        <CategorizedTopicPill
          key={`${topic}-${commentId}`}
          type={topic as any}
          label={humanize(topic)}
          isNonCatRec={true}
        />
      ))}
    </StyledPillListContainer>
  );
};

const StyledPillListContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 4px;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;
