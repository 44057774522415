import React, { useEffect } from "react";
import styled from "styled-components";
import { useAppSelector } from "store";
import { useUserProfile } from "context/UserProfileContext";

import { StyledUserEditorBlock } from "./StyledUserEditorBlock";
import { UserProfileEditorField } from "components/UserProfileEditorField";
import { CollapsibleSection } from "components/CollapsibleSection";
import { ToggleSwitch } from "components/_inputs/ToggleSwitch";
import { SsoMappingField } from "./SsoMappingField";

import { useResource } from "hooks/useResource";

import { SSOMapping } from "ts/user";
import { RoleType } from "@explorance/mly-types";

type Props = {
  validateAuth: () => boolean;
};

export const AuthenticationBlock = ({ validateAuth }: Props) => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);

  const { fields, setField } = useUserProfile();
  const { getResource } = useResource();

  // Used for the UI to reflect the new SSO ID validation when the user clicks the toggle switch, making the field required.
  useEffect(() => {
    validateAuth();
  }, [fields.ssoMappings]); // eslint-disable-line

  return (
    <StyledUserEditorBlock>
      <CollapsibleSection
        titleLeftResource={"userProfile.auth.title"}
        hideBoxShadow
        hideHeaderBorder
      >
        <StyledExpandedSection>
          <StyledSSOProviderFieldContainer>
            <UserProfileEditorField
              label={getResource("userProfile.auth.basicAuth.label")}
              value={getResource("userProfile.auth.basicAuth.placeholder")}
              isDisabled
              handleChangeFieldValue={() => undefined}
            />
            <ToggleSwitch
              checked={fields.basicAuth}
              labelKey=""
              isDisabled={currentUser.roleType === RoleType.Viewer}
              onChange={(value) => setField("basicAuth", value)}
            />
          </StyledSSOProviderFieldContainer>
          {fields.ssoMappings.map((ssoMapping: SSOMapping, index) => (
            <SsoMappingField
              key={index}
              ssoMapping={ssoMapping}
              index={index}
              handleBlur={validateAuth}
            />
          ))}
        </StyledExpandedSection>
      </CollapsibleSection>
    </StyledUserEditorBlock>
  );
};

export const StyledSSOProviderFieldContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledExpandedSection = styled.div`
  padding: 8px 8px 0 8px;
`;
