import React from "react";
import styled from "styled-components";
import { Skeleton } from "components/Skeleton";
import { Color } from "ts/enums/color";
import { Icon, IconType } from "components/_icons/Icon";
import { TopicsListView } from "@explorance/mly-types";
import { Text } from "components/Text";

type Props = {
  view?: TopicsListView;
};

export const TopCommentsPlaceholder = () => {
  return (
    <>
      <Skeleton width={451} height={18} borderRadius="5px" />

      {[...Array(4)].map((p, i) => {
        return (
          <StyledCommentsSkeletonWrapper key={i}>
            <Skeleton
              width={900}
              height={12}
              borderRadius="5px"
              backgroundColor={Color.neutral10}
            />
            <Skeleton
              width={450}
              height={12}
              borderRadius="5px"
              backgroundColor={Color.neutral10}
            />
          </StyledCommentsSkeletonWrapper>
        );
      })}
    </>
  );
};

export const StatSectionSkeleton = ({ view }: Props) => {
  return (
    <StyledStatSkeletonWrapper>
      <StyledIconSkeleton>
        <Icon type={IconType.positive} color={Color.black} />
      </StyledIconSkeleton>

      <StyledViewSkeleton>
        <div>
          <Text
            resource={{
              key: view ? `statTab.${view}` : "modal.relevantTopics.widget.skeleton.placeholder",
            }}
          />
        </div>
        <Skeleton width={220} height={33} borderRadius="5px" backgroundColor={Color.neutral10} />
      </StyledViewSkeleton>
    </StyledStatSkeletonWrapper>
  );
};

export const BannerSkeleton = () => {
  return (
    <StyledBannerSkeletonWrapper>
      <StyledLeftBannerSkeleton>
        {[...Array(3)].map((p, i) => {
          return <Skeleton width={141} height={12} backgroundColor={Color.blue20} key={i} />;
        })}
      </StyledLeftBannerSkeleton>
      <Skeleton width={141} height={12} backgroundColor={Color.blue20} />
    </StyledBannerSkeletonWrapper>
  );
};

const StyledCommentsSkeletonWrapper = styled.div`
  height: 58px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const StyledStatSkeletonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 275px;
  font-size: 14px;
  font-weight: bold;
`;

const StyledViewSkeleton = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledIconSkeleton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: ${Color.neutral10};
  border-radius: 5px;
`;

const StyledBannerSkeletonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 12px;
  width: 948px;
  padding-right: 24px;
`;

const StyledLeftBannerSkeleton = styled.div`
  display: flex;
  justify-content: space-between;
  width: 475px;
`;
