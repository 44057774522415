import React from "react";
import styled from "styled-components";
import { Color } from "ts/enums/color";

export const DotsMenuButton = (props: React.HTMLAttributes<HTMLButtonElement>) => {
  return (
    <StyledDotsMenuButton {...props}>
      <span className="dot" />
      <span className="dot" />
      <span className="dot" />
    </StyledDotsMenuButton>
  );
};

const StyledDotsMenuButton = styled.button`
  display: inline-block;
  text-align: center;
  width: 24px;
  height: 24px;
  background-color: ${Color.white};
  cursor: pointer;
  border-radius: 2px;
  border: none;
  padding: 0;

  .dot {
    height: 4px;
    width: 4px;
    background: ${Color.gray30};
    border-radius: 50%;
    display: inline-block;
    margin: 10px 1px 5px 1px;
  }
`;
