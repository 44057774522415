// library
import React, { useState, useEffect, useContext, ReactNode } from "react";
import styled from "styled-components";
import { lowerFirst } from "lodash";

// Context
import { AnalysisContext } from "context/AnalysisContext";
import {
  setThreshold,
  setSelectedTopicNodes,
  setSelectedDemographicFilters,
  setLastSelectedTopicNodes,
  setSelectedColumnFilters,
  setSelectedAlertRange,
  setAvailableDemographicFilters,
} from "context/AnalysisContext/actions";

// models & enums
import { FilterType } from "ts/filters/filterType";
import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { FilterPillStyle } from "ts/enums/filterPillStyle";
import {
  PillStyle,
  defaultTopicsPillStyle,
  outlineBluePillStyle,
  outlineIndigoPillStyle,
} from "ts/filters/pillStyle";
import { DEFAULT_ALERT_RANGE } from "assets/constants/defaultAlertRange";

// functions
import useClickOutside from "hooks/useClickOutside";
import { useResource } from "hooks/useResource";

// components
import { Pill, PillSize } from "components/_pills/Pill";
import { FilterPillCount } from "./FilterPillCount";
import { DemographicsMenuContainer } from "./DemographicsMenuContainer";
import { TopicsMenu } from "./TopicsMenu";
import { ThresholdMenu } from "./ThresholdMenu";
import { ColumnMenu } from "./ColumnMenu";
import { AlertRangeMenu } from "./AlertRangeMenu";
import { TopicFilterPill } from "./TopicFilterPill";
import { StyledDropdownTransition } from "components/DropdownMenu/StyledDropdownTransition";
import { getDemographicById } from "services/demographics";
import { DEMOGRAPHIC_LIST_LIMIT } from "assets/constants/demographicLimit";
import { getUpdatedDemographicFilters } from "../helpers";

type Props = {
  filterType: FilterType;
  styleType: FilterPillStyle;
  filterCount?: number | string;
  demographicFilterName?: string;
  demographicFilterId?: number;
  renderAltMenu?: (resetLocalState, onSubmit) => ReactNode;
  deletePill?: () => void;
};

export const FilterPillContainer = ({
  filterType,
  styleType,
  filterCount,
  demographicFilterName,
  demographicFilterId,
  renderAltMenu,
  deletePill,
}: Props) => {
  // hooks
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [menuTop, setMenuTop] = useState<string>();
  const [resetLocalState, setResetLocalState] = useState<boolean>(false);

  const filterPillContainerRef = useClickOutside(() => setShowMenu(false));

  const [state, dispatch] = useContext(AnalysisContext);

  const { getResource } = useResource();

  // variables
  const styling: PillStyle =
    styleType === FilterPillStyle.outlineBlue
      ? outlineBluePillStyle
      : styleType === FilterPillStyle.outlineIndigo
      ? outlineIndigoPillStyle
      : defaultTopicsPillStyle;

  // function
  const getPillTop = () => {
    if (filterPillContainerRef && filterPillContainerRef.current) {
      const pillTop = filterPillContainerRef.current.getBoundingClientRect().top + 30;
      setMenuTop(`${pillTop}px`);
    }
  };

  // useEffects
  useEffect(() => {
    if (filterPillContainerRef) document.addEventListener("scroll", getPillTop, true);
    return () => document.removeEventListener("scroll", getPillTop);
  });

  useEffect(() => {
    if (filterPillContainerRef) getPillTop();
  });

  useEffect(() => {
    if (!showMenu) {
      setResetLocalState(true);
      setTimeout(() => setResetLocalState(false), 200);
    }
  }, [showMenu]);

  // functions
  const onDeletePill = () => {
    if (deletePill) return deletePill();

    switch (filterType) {
      case FilterType.Threshold:
        dispatch(setThreshold(null));
        break;
      case FilterType.Topics:
        dispatch(setSelectedTopicNodes([]));
        dispatch(setLastSelectedTopicNodes([]));
        break;
      case FilterType.Demographic:
        dispatch(
          setSelectedDemographicFilters(
            state.selectedDemographicFilters.filter((sdf) => sdf.id !== demographicFilterId)
          )
        );
        break;
      case FilterType.Feedback:
        dispatch(setSelectedColumnFilters([]));
        break;
      case FilterType.Alerts:
        dispatch(setSelectedAlertRange(DEFAULT_ALERT_RANGE));
        break;
      default:
        return;
    }
  };

  // Returns the menu component depending on the filter type
  const AnalysisFilterMenu = {
    Topics: TopicsMenu,
    Threshold: ThresholdMenu,
    Alerts: AlertRangeMenu,
    Feedback: ColumnMenu,
    Demographic: DemographicsMenuContainer,
  }[filterType];

  const showPillCount = (): boolean => {
    if (typeof filterCount === "string") return !!filterCount;
    if (typeof filterCount === "number") return filterCount >= 0;
  };

  const pillContent = (
    <StyledPillContent>
      <StyledFilterPillText>
        {demographicFilterName ?? getResource(`filterPill.${lowerFirst(filterType.toString())}`)}
      </StyledFilterPillText>
      {showPillCount() && (
        <StyledFilterPillCountContainer>
          <FilterPillCount
            count={filterCount}
            backgroundColor={styling.color}
            isActive={showMenu}
          />
        </StyledFilterPillCountContainer>
      )}
    </StyledPillContent>
  );

  const handleClickDemographicMenu = async () => {
    setShowMenu((showMenu) => !showMenu);
    if (
      state.availableDemographicFilters.find((d) => d.id === demographicFilterId)?.listValues
        .length !== 0
    ) {
      return;
    }

    const { data } = await getDemographicById(demographicFilterId, 1, DEMOGRAPHIC_LIST_LIMIT);
    const updatedFilters = getUpdatedDemographicFilters(state.availableDemographicFilters, data);

    dispatch(setAvailableDemographicFilters(updatedFilters));
  };

  return (
    <StyledFilterPillContainer
      tabIndex={0}
      ref={filterPillContainerRef}
      key={filterType === FilterType.Demographic ? demographicFilterName : filterType}
    >
      {filterType === FilterType.Topics ? (
        <TopicFilterPill
          isDefault={state.selectedTopicNodes.length === 0}
          filterCount={filterCount as number}
          inverted={showMenu}
          handleDelete={onDeletePill}
          handleClick={() => setShowMenu((showMenu) => !showMenu)}
        />
      ) : (
        <Pill
          backgroundColor={styling.backgroundColor}
          hoverColor={styling.hoverBackgroundColor}
          color={styling.color}
          border={styling.border}
          size={PillSize.sm}
          inverted={showMenu}
          deleteButtonHoverBackgroundColor={styling.deleteButtonHoverBackgroundColor}
          style={{ fontSize: "14px" }}
          onClick={handleClickDemographicMenu}
          onDeletePill={onDeletePill}
        >
          {pillContent}
        </Pill>
      )}
      <StyledDropdownMenuContainer
        menuTop={menuTop}
        show={!!(showMenu && (renderAltMenu || AnalysisFilterMenu))}
      >
        {renderAltMenu ? (
          renderAltMenu(resetLocalState, () => setShowMenu(false))
        ) : (
          <AnalysisFilterMenu
            demographicFilterId={demographicFilterId}
            resetLocalState={resetLocalState}
            onSubmit={() => setShowMenu(false)}
          />
        )}
      </StyledDropdownMenuContainer>
    </StyledFilterPillContainer>
  );
};

const StyledFilterPillContainer = styled.div`
  position: relative;
  max-width: 215px;
  cursor: pointer;
  display: inline-table;
`;

const StyledDropdownMenuContainer = styled(StyledDropdownTransition)<{
  menuTop: string;
  show: boolean;
}>`
  position: fixed;
  top: ${({ menuTop }) => menuTop};
  background-color: ${Color.white};
  box-shadow: 0px 2px 15px #0000001a;
  border-radius: 5px;
  z-index: ${ZIndexStackingContext.low};
  cursor: default;
`;

const StyledFilterPillText = styled.div`
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
`;

const StyledFilterPillCountContainer = styled.div`
  margin-left: 7px;
`;

const StyledPillContent = styled.div`
  display: flex;
  align-items: center;
  max-width: 155px;
`;
