import { useResource } from "hooks/useResource";
import { getLocalTime } from "utils/getLocalTime";
import { getMonthDay } from "utils/getMonthDay";

export const useTimeStamp = () => {
  const { getResource } = useResource();
  return (lastUpdated: Date): string => {
    const oneDayMs = 86400000;
    // convert offset to MS and make negative so the timezone difference is correctly considered
    const timezoneOffset = lastUpdated.getTimezoneOffset() * -60000;
    const currentDateInLocalTime = Date.now() + timezoneOffset;
    const lastUpdatedInLocalTime = lastUpdated.getTime();

    const [localTimeMonth, localTimeDay] = getMonthDay(getLocalTime(new Date()));
    const [lastUpdatedMonth, lastUpdatedDay] = getMonthDay(getLocalTime(lastUpdated));
    const diffDays = Math.abs((currentDateInLocalTime - lastUpdatedInLocalTime) / oneDayMs);

    if (diffDays < 1 && localTimeMonth === lastUpdatedMonth && localTimeDay === lastUpdatedDay) {
      return getResource("timeAgo.today");
    }
    if (
      diffDays < 2 &&
      localTimeMonth === lastUpdatedMonth &&
      (localTimeDay - lastUpdatedDay === 1 || localTimeDay - lastUpdatedDay < 0)
    ) {
      return `${getResource("timeAgo.yesterday")}`;
    }
    if (diffDays < 7) {
      return getResource("timeAgo.daysAgo", Math.ceil(diffDays));
    }
    if (diffDays >= 7 && diffDays < 14) {
      return getResource("timeAgo.weekAgo");
    }
    return getResource("timeAgo.weeksAgo");
  };
};
