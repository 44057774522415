import React, { useState } from "react";
import styled from "styled-components";
import { UserAvatar } from "components/UserAvatar.tsx";
import { SharingUser } from "ts/sharing";
import { Tooltip } from "components/Tooltip";

type Props = {
  user: SharingUser;
};

export const FirstNameCell = ({ user }: Props) => {
  const [showFirstNameTooltip, setShowFirstNameTooltip] = useState<boolean>();

  const handleHoverModelName = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) setShowFirstNameTooltip(true);
  };

  return (
    <>
      <StyledFirstNameCell key={user.id}>
        <UserAvatar firstName={user.firstname} lastName={user.lastname} userId={user.id} />
        <span
          onMouseOver={handleHoverModelName}
          onMouseOut={() => setShowFirstNameTooltip(false)}
          data-tip
          data-for={`${user.id}-firstname-tooltip`}
        >
          {user.firstname}
        </span>
      </StyledFirstNameCell>
      {showFirstNameTooltip && (
        <Tooltip tooltipId={`${user.id}-firstname-tooltip`} content={user.firstname} />
      )}
    </>
  );
};

const StyledFirstNameCell = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  > span {
    font-weight: bold;
    font-size: 15px;
    max-width: 70%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
