import { round } from "lodash";

export const formatStat = (value, type) => {
  if (type === "count") return value;
  if (type === "percentage") {
    if (value > 0 && value < 0.01) {
      return "<1%";
    }
    return `${round(value * 100)}%`;
  }
};
