import React from "react";
import styled from "styled-components";
import { Color } from "ts/enums/color";

type StackedBarChartPiece = {
  portion: number;
  color: Color;
};

type Props = {
  chartPieces: StackedBarChartPiece[];
  handleBarClick?: (key: number) => void;
  activeBarIndex?: number;
  isClickable?: boolean;
};

export const StackedBarChart = ({
  chartPieces,
  handleBarClick,
  activeBarIndex,
  isClickable,
}: Props) => {
  return (
    <StyledStackedBarChartContainer>
      {chartPieces.map(
        (cp, i) =>
          cp.portion > 0 && (
            <StyledStackedBarChartPiece
              key={i}
              color={cp.color}
              widthPercentage={cp.portion * 100}
              barIndex={activeBarIndex}
              isClickable={isClickable}
              onClick={isClickable && i !== activeBarIndex ? () => handleBarClick(i) : undefined}
              index={i}
            />
          )
      )}
    </StyledStackedBarChartContainer>
  );
};

const StyledStackedBarChartContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

const StyledStackedBarChartPiece = styled.div<{
  color: string;
  widthPercentage: number;
  isClickable: boolean;
  index: number;
  barIndex: number;
}>`
  background-color: ${({ color }) => color};
  width: ${({ widthPercentage }) => widthPercentage}%;
  height: 12px;
  opacity: ${({ index, barIndex, isClickable }) =>
    isClickable ? (index === barIndex ? 1 : 0.2) : "inherit"};

  transition: ${({ isClickable }) => isClickable && "opacity .5s ease-in-out"};
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "default")};
`;
