import React, { useContext, useMemo, ReactNode, useState } from "react";
import styled from "styled-components";

import { AnalysisContext } from "context/AnalysisContext";
import { useResource } from "hooks/useResource";
import { getTitleFilterCount } from "utils/filters";

import { WidgetFilter } from "ts/widget";
import { Color } from "ts/enums/color";
import { ApiTopicNode } from "ts/topic";

import { Pill, PillSize } from "components/_pills/Pill";
import { Tooltip } from "components/Tooltip";
import { Text } from "components/Text";
import { RecommendationType, SentimentType } from "@explorance/mly-types";

type WidgetFilterPillsProps = {
  filters: WidgetFilter;
};

export const WidgetFilterPills = ({ filters }: WidgetFilterPillsProps) => {
  const [state] = useContext(AnalysisContext);
  const [showCustomModelTooltip, setShowCustomModelTooltip] = useState<boolean>(false);
  const { getResource } = useResource();

  const customModelName = state.analysisModels?.find(
    (am) => am.modelId === filters.modelId && am.isVirtual
  )?.modelName;

  const demographicPaths = useMemo(() => {
    const arr = [];
    filters.demographics.forEach((demographic) => {
      demographic.values.forEach((value) => {
        arr.push(`${demographic.name} > ${value}`);
      });
    });
    return arr;
  }, [filters]);

  const nbActiveFilters =
    (typeof filters.threshold === "number" ? 1 : 0) +
    (filters.alertRange ? 1 : 0) +
    demographicPaths.length +
    filters.topics.length +
    filters.statistics?.length +
    (filters.selectedColumns ? filters.selectedColumns.length : 0);

  const tooltipId = Math.random().toString();

  const getTopicFilterName = (topic: ApiTopicNode) => {
    const splitTopicPath = topic.fullPath?.split(">");

    return (
      <StyledTopicNameContainer key={topic.id}>
        {splitTopicPath.map((t, i) => (
          <>
            <span>{filters.isVirtual ? t : getResource(`ML.topic.[${t}]`)}</span>
            {i < splitTopicPath.length - 1 && <span>{" > "}</span>}
          </>
        ))}
      </StyledTopicNameContainer>
    );
  };

  const handleHoverCustomModelName = (e: React.MouseEvent<HTMLSpanElement>) => {
    setShowCustomModelTooltip(e.currentTarget.offsetWidth < e.currentTarget.scrollWidth);
  };

  return (
    <StyledWidgetFilterPillsContainer>
      {customModelName && (
        <Pill size={PillSize.sm} color={Color.indigo50} inverted applyGridMargin>
          <StyledCustomModelNamePill
            data-tip
            data-for={`${customModelName}-tooltip`}
            onMouseOver={handleHoverCustomModelName}
            onMouseOut={() => setShowCustomModelTooltip(false)}
          >
            {customModelName}
          </StyledCustomModelNamePill>
          {showCustomModelTooltip && (
            <Tooltip tooltipId={`${customModelName}-tooltip`} content={customModelName} />
          )}
        </Pill>
      )}

      <Pill
        size={PillSize.sm}
        color={Color.indigo50}
        data-tip="filter-tip"
        data-for={tooltipId}
        applyGridMargin
      >
        {nbActiveFilters > 0 ? (
          <>
            <Text
              resource={{
                key:
                  nbActiveFilters === 1 ? "widget.activeFilters.singular" : "widget.activeFilters",
                args: [`${nbActiveFilters}`],
              }}
            />
            <StyledInfoIcon>i</StyledInfoIcon>
          </>
        ) : (
          <Text resource="widget.noFilters" />
        )}
      </Pill>
      <StyledTooltip
        tooltipId={tooltipId}
        backgroundColor={Color.gray50}
        arrowColor={Color.gray50}
        fontSize="0.785em"
        disable={!nbActiveFilters}
      >
        <TooltipSection
          title={getResource("filter.alertRange.title")}
          body={getResource(
            "filter.alertRange.value",
            filters.alertRange?.min?.toString(),
            filters.alertRange?.max?.toString()
          )}
          more={0}
          show={!!filters.alertRange}
        />
        <TooltipSection
          title={getResource("filter.topics.label") + getTitleFilterCount(filters.topics)}
          body={filters.topics.slice(0, 5).map((topic) => getTopicFilterName(topic))}
          more={filters.topics.slice(5, filters.topics.length).length}
          show={filters.topics?.length > 0}
        />
        <TooltipSection
          title={getResource("filter.demographics.label") + getTitleFilterCount(demographicPaths)}
          body={demographicPaths.slice(0, 5).map((path) => (
            <div key={path}>{path}</div>
          ))}
          more={demographicPaths.slice(5, demographicPaths.length).length}
          show={filters?.demographics?.length > 0}
        />
        <TooltipSection
          title={
            getResource("filter.sentimentType.label") +
            getTitleFilterCount(filters?.statistics ?? [])
          }
          body={filters?.statistics?.slice(0, 5).map((sentiment) => (
            <div key={sentiment}>
              <Text resource={`sentimentPill.${sentiment}`} />
            </div>
          ))}
          more={filters?.statistics?.slice(5, filters.statistics.length).length}
          show={
            filters.statistics?.length > 0 &&
            Object.values(SentimentType).includes(filters.statistics[0] as SentimentType)
          }
        />
        <TooltipSection
          title={
            getResource("filter.recommendationType.label") +
            getTitleFilterCount(filters?.statistics ?? [])
          }
          body={filters?.statistics?.slice(0, 5).map((recommendation) => (
            <div key={recommendation}>
              <Text resource={`recommendationPill.${recommendation}`} />
            </div>
          ))}
          more={filters?.statistics?.slice(5, filters.statistics.length).length}
          show={
            filters.statistics?.length > 0 &&
            Object.values(RecommendationType).includes(filters.statistics[0] as RecommendationType)
          }
        />
        <TooltipSection
          title={getResource("filter.threshold.label")}
          body={filters.threshold}
          more={0}
          show={filters?.threshold !== null}
        />
        <TooltipSection
          title={
            getResource("sideDrawer.filters.feedback") +
            getTitleFilterCount(filters?.selectedColumns?.length > 0 && filters.selectedColumns)
          }
          body={filters.selectedColumns?.slice(0, 5).map((sc) => (
            <div key={sc}>{sc}</div>
          ))}
          more={filters.selectedColumns?.slice(5, filters.selectedColumns.length).length}
          show={filters?.selectedColumns?.length > 0}
        />
      </StyledTooltip>
    </StyledWidgetFilterPillsContainer>
  );
};

type TooltipSectionProps = {
  title: ReactNode;
  body: ReactNode;
  more: number;
  show: boolean;
};

const TooltipSection = ({ title, body, show, more }: TooltipSectionProps) => {
  if (!show) return null;
  return (
    <>
      <StyledTooltipHeader>{title}</StyledTooltipHeader>
      <StyledTooltipBody>{body}</StyledTooltipBody>
      {more > 0 && (
        <StyledTooltipMore>
          <Text resource={{ key: `widget.filterTooltip.more`, args: [`${more}`] }} />
        </StyledTooltipMore>
      )}
    </>
  );
};

const StyledWidgetFilterPillsContainer = styled.div`
  display: flex;
`;

const StyledCustomModelNamePill = styled.span`
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledTooltip = styled(Tooltip)`
  min-width: 150px;
  max-width: 420px;
  font-size: 12px;
  padding: 10px 10px 4px 10px;
`;

const StyledInfoIcon = styled.div`
  background-color: ${Color.indigo50};
  color: ${Color.indigo10};
  border-radius: 50%;
  height: 12px;
  width: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: "Times New Roman", Times, serif;
  border: 1px solid;
  font-size: 10px;
  line-height: 10px;
  margin-left: 6px;
`;

const StyledTooltipHeader = styled.div`
  color: ${Color.gray20};
  margin: 6px 0;
  font-weight: bold;
`;

const StyledTooltipBody = styled.div`
  color: ${Color.white};
  margin-bottom: 8px;
`;

const StyledTopicNameContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledTooltipMore = styled.div`
  color: ${Color.white};
  margin: 8px 0 12px 0;
`;
