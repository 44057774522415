import { Model, ModelSelectionDropdownData, ModelSelectionOptions } from "ts/models";

export const getAvailableModelOptions = (
  modelOptionsDropdownData: ModelSelectionDropdownData,
  selectedEnv: string
): ModelSelectionOptions => {
  const filteredModelOptions = JSON.parse(JSON.stringify(modelOptionsDropdownData)); //Deep copy
  const availableOptionsModels = Object.keys(filteredModelOptions.options?.models); //Model type (EEC, ELC, SCE)

  filteredModelOptions.options.models = availableOptionsModels.reduce((acc, option) => {
    acc[option] = filteredModelOptions.options?.models[option].filter(
      (model) => model.env === selectedEnv
    );
    return acc;
  }, {});

  return filteredModelOptions.options;
};

export const getAvailableCustomModelOptions = (
  baseModel: Model,
  customModelOptions: ModelSelectionOptions
) => {
  if (!baseModel || !baseModel?.categorizationType) return [];
  const filteredCustomModels = JSON.parse(JSON.stringify(customModelOptions)); //Deep copy
  // Filter custom models by categorization type of base model
  filteredCustomModels.customModels = Object.keys(customModelOptions.customModels).reduce(
    (acc, option) => {
      acc[option] = customModelOptions.customModels[option].filter(
        (model) => model.categorizationType === baseModel.categorizationType
      );
      return acc;
    },
    {}
  );

  return filteredCustomModels;
};
