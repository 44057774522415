import { allowedFileExtensions } from "assets/constants/allowedFileFormats";

export const stripFileExtension = (fileName: string) => {
  for (const extension of allowedFileExtensions) {
    const dotExtension = `.${extension}`;
    if (fileName.endsWith(dotExtension)) {
      return fileName.replace(new RegExp(dotExtension + "$"), "");
    }
  }
  return fileName;
};
