import { LicensePeriod, ModelStatistics } from "ts/licensing";
import { SelectOption } from "ts/select";
import { GetResourceFunction } from "hooks/useResource";
import { upperFirst } from "lodash";

export const typeLicensePeriodsForDropdown = (
  licensePeriod: LicensePeriod,
  getResource: GetResourceFunction
): SelectOption => {
  const dateFormat = new Intl.DateTimeFormat(undefined, {
    month: "long",
    year: "numeric",
  });
  return {
    label: getResource(
      "licensing.periodDropdown.label",
      dateFormat.format(licensePeriod.startDate),
      dateFormat.format(licensePeriod.endDate)
    ),
    value: licensePeriod.id.toString(),
    data: licensePeriod,
  };
};

export const getModelStatisticsFromData = (
  licenseStat: any,
  getModelName: (model: any) => string
): ModelStatistics => ({
  ...licenseStat,
  analyzedCount: new Intl.NumberFormat().format(licenseStat.analyzedCount),
  name: getModelName(
    licenseStat.modelFamily
      ? {
          languages: licenseStat.languages.map((l) => upperFirst(l)),
          isPowerset: licenseStat.isPowerset,
        }
      : null
  ),
});
