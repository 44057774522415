import React, { useState, useEffect, useRef } from "react";
import { useAppSelector, useAppDispatch } from "store";
import styled from "styled-components";
import { cloneDeep } from "lodash";
import { useParams } from "react-router-dom";

import {
  ShareStatus,
  ShareableUsersSortingParameter,
  SortingOrder,
  DemographicFilterMethod,
} from "@explorance/mly-types";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { UserDemographic } from "context/UserProfileContext";

import { FlexTable, FlexTableSorting } from "components/FlexTable";
import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { Header } from "./Header";
import { EmptyState } from "components/EmptyState";
import { FirstNameCell } from "./FlexTableCells/FirstNameCell";
import { EmailCell } from "./FlexTableCells/EmailCell";
import { LastNameCell } from "./FlexTableCells/LastNameCell";

import { useResource } from "hooks/useResource";
import { useSharingContext } from "context/SharingContext";
import { GetShareableUsersParams } from "services/analysis/sharing";
import { formatSearchForFetchRequest } from "utils/formatters";
import { FlexTableType } from "ts/enums/flexTable";
import { setSearchTerm } from "store/search/searchbarSlice";
import { Text } from "components/Text";

const SHARE_TO_USERS_HEADERS = [
  { columnNameKey: "firstName", sortingParameter: ShareableUsersSortingParameter.FirstName },
  { columnNameKey: "lastName", sortingParameter: ShareableUsersSortingParameter.LastName },
  { columnNameKey: "email", sortingParameter: ShareableUsersSortingParameter.Email },
  { columnNameKey: "role", sortingParameter: ShareableUsersSortingParameter.RoleType },
];

export const UserSelection = () => {
  const searchTerm = useAppSelector((state) => state.search.searchTerm);
  const dispatch = useAppDispatch();
  const [sortingColumn, setSortingColumn] = useState<ShareableUsersSortingParameter>(
    ShareableUsersSortingParameter.FirstName
  );
  const [sortOrder, setSortOrder] = useState<SortingOrder>(SortingOrder.ASC);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isUserDemographicFiltersReset, setIsUserDemographicFiltersReset] =
    useState<boolean>(false);

  const {
    sharingState,
    updateSharingState,
    refetchSharingUsersData,
    handleUserSharingDraft,
    validateUsersShown,
  } = useSharingContext();
  const { getResource } = useResource();
  const firstRender = useRef<boolean>(true);
  const analysisId = parseInt(useParams<{ analysisId: string }>().analysisId);

  // reset all filters when view is changed
  useEffect(() => {
    if (sharingState.view && !firstRender.current) {
      clearAllFilters();
    }
    firstRender.current = false;
  }, [sharingState.view]); // eslint-disable-line

  useEffect(() => {
    refetch(null, 1);
  }, [sortOrder, sortingColumn, searchTerm]); // eslint-disable-line

  const toggleSelectUser = (selectedUserId: number) => {
    //Make shareableUsersApiData reflect isSelected change
    const apiData = cloneDeep(sharingState.shareableUsersApiData);
    const apiUserIndex = apiData.users.findIndex((c) => c.id === selectedUserId);
    const isApiUserSelected = apiData.users[apiUserIndex].isSelected;

    if (apiUserIndex !== -1) {
      const apiUserIndex = apiData.users.findIndex((c) => c.id === selectedUserId);
      apiData.users.splice(apiUserIndex, 1, {
        ...apiData.users[apiUserIndex],
        isSelected: !isApiUserSelected,
      });
    }
    updateSharingState("shareableUsersApiData", apiData);

    const copy = cloneDeep(sharingState.selectedUsers);
    const selectedUserIndex = copy.findIndex((c) => c.id === selectedUserId);
    if (isApiUserSelected) {
      if (selectedUserIndex !== -1) {
        copy.splice(selectedUserIndex, 1);
      }
    } else {
      copy.push(sharingState.shareableUsersApiData.users.find((u) => u.id === selectedUserId));
    }
    updateSharingState("selectedUsers", validateUsersShown(copy));
    handleUserSharingDraft([selectedUserId], isApiUserSelected);
  };

  const toggleSelectAllUsers = () => {
    const allSelected = sharingState.shareableUsersApiData.users.every((u) => u.isSelected);
    const apiData = cloneDeep(sharingState.shareableUsersApiData);
    const selectedUsers = cloneDeep(sharingState.selectedUsers);
    apiData.users.forEach((u) => {
      u.isSelected = !allSelected;
    });
    updateSharingState("shareableUsersApiData", apiData);
    if (allSelected) {
      const updatedSelectedUsers = selectedUsers.filter(
        (user) => !apiData.users.some((u) => u.id === user.id)
      );
      updateSharingState("selectedUsers", validateUsersShown(updatedSelectedUsers));
    } else {
      const nonSelectedApiDataUsers = apiData.users.filter(
        (u) => !selectedUsers.some((su) => su.id === u.id)
      );
      const updatedSelectedUsers = [...selectedUsers, ...nonSelectedApiDataUsers];
      updateSharingState("selectedUsers", validateUsersShown(updatedSelectedUsers));
    }
    const uniqueUserIds = sharingState.shareableUsersApiData.users.reduce((acc, u) => {
      if (u.isSelected === allSelected) {
        acc.push(u.id);
      }
      return acc;
    }, []);
    handleUserSharingDraft(uniqueUserIds, allSelected);
  };

  const refetch = (demographicFilters?: UserDemographic[], updatedPageValue?: number) => {
    const fetchParams: GetShareableUsersParams = {
      analysisId,
      page: updatedPageValue ?? currentPage,
      search: formatSearchForFetchRequest(searchTerm),
      sort_column: sortingColumn,
      sort_order: sortOrder,
    };
    const filterArray = demographicFilters ?? sharingState.selectedUserDemographicFilters;
    const demographics = filterArray.map((sf) => ({
      ...sf,
      method: DemographicFilterMethod.List,
    }));
    refetchSharingUsersData(fetchParams, demographics);
  };

  const updateSorting = (updatedSorting: FlexTableSorting) => {
    const { columnIndex, order } = updatedSorting;
    setSortingColumn(SHARE_TO_USERS_HEADERS[columnIndex].sortingParameter);
    setSortOrder(order);
    setCurrentPage(1);
  };

  const updatePage = (updatedPage: number) => {
    setCurrentPage(updatedPage);
    refetch(null, updatedPage);
  };

  const clearAllFilters = () => {
    setSortingColumn(ShareableUsersSortingParameter.FirstName);
    setSortOrder(SortingOrder.ASC);
    dispatch(setSearchTerm(""));
    setIsUserDemographicFiltersReset(true);
    updateSharingState("selectedUserDemographicFilters", []);
    setTimeout(() => {
      setIsUserDemographicFiltersReset(false);
    }, 500);
    refetchSharingUsersData({ analysisId }, []);
  };

  return (
    <>
      <Header
        pageTitle={getResource("userList.title")}
        searchCountDataType="searchBar.dataType.userList"
        currentPage={currentPage}
        showUserDemographicFilters={true}
        isUserDemographicFiltersReset={isUserDemographicFiltersReset}
        updatePage={updatePage}
        refetchList={refetch}
      />
      {sharingState.shareableUsersApiData.users.length === 0 ? (
        <EmptyState
          type={EmptyStateType.noSearchResults}
          customStyles={{ marginTop: "85px", marginBottom: "85px" }}
          handleClickCaptionLink={clearAllFilters}
        />
      ) : (
        <FlexTable
          type={FlexTableType.Card}
          data={{
            headers: SHARE_TO_USERS_HEADERS.map((header) =>
              getResource(`sharing.table.${header.columnNameKey}`)
            ),
            rows: sharingState.shareableUsersApiData.users.map((user) => ({
              isSelected: user.isSelected,
              contentId: user.id,
              data: [
                <FirstNameCell key={`${user.id}-firstname}`} user={user} />,
                <LastNameCell key={`${user.id}-lastname}`} user={user} />,
                <EmailCell key={`${user.id}-email}`} user={user} />,
                <StyledRoleCell key={user.id}>
                  <StyledRole>
                    <Text resource={`roleType.${user.roleType}`} />
                  </StyledRole>
                  {user.shareStatus === ShareStatus.Shared && user.isSelected ? (
                    <StyledOverrideSettingsPill>
                      <Text resource="sharing.pill.overrideSettings" />
                    </StyledOverrideSettingsPill>
                  ) : user.shareStatus === ShareStatus.Shared ? (
                    <StyledAlreadySharedPill>
                      <Text resource="sharing.pill.alreadyShared" />
                    </StyledAlreadySharedPill>
                  ) : (
                    <div />
                  )}
                  <Button
                    variant={ButtonVariant.secondary}
                    size={ButtonSize.sm}
                    style={{ justifySelf: "right" }}
                    onClick={() => toggleSelectUser(user.id)}
                  >
                    <Icon
                      type={user.isSelected ? IconType.xCircle : IconType.plusCircle}
                      size={14}
                      style={{ marginRight: 5 }}
                    />
                    <Text
                      resource={{
                        key: user.isSelected ? "button.deselectUser" : "button.selectUser",
                      }}
                    />
                  </Button>
                </StyledRoleCell>,
              ],
            })),
            columnWidths: ["17.5%", "17.5%", "20%", "45%"],
          }}
          isSelectable
          maxHeight="400px"
          initialSorting={{
            columnIndex: SHARE_TO_USERS_HEADERS.findIndex(
              (h) => h.sortingParameter === sortingColumn
            ),
            order: sortOrder,
          }}
          onSelectionChange={toggleSelectUser}
          handleSelectAll={toggleSelectAllUsers}
          onSortingChange={updateSorting}
        />
      )}
    </>
  );
};

const StyledRole = styled.div`
  justify-self: start;
`;

const StyledAlreadySharedPill = styled.div`
  display: inline;
  padding: 6px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background-color: ${Color.indigo20};
  font-size: 14px;
  text-align: center;
  justify-self: center;
`;

const StyledRoleCell = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: 0.5fr 1fr 1fr;
  gap: 10px;
  align-items: center;
`;

const StyledOverrideSettingsPill = styled.div`
  display: inline;
  padding: 6px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background-color: ${Color.indigo45};
  color: ${Color.white};
  font-size: 14px;
  text-align: center;
  justify-self: center;
`;
