import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "store";
import { routes } from "routes";

import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";

import { useResource } from "hooks/useResource";
import { getModelLicenseList, synchronizeModels } from "services/licensing";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { ModelLicense } from "ts/licensing";
import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { RoleType } from "@explorance/mly-types";
import { showToastError, showToastSuccess } from "store/toast/toastSlice";
import { FlexTable } from "components/FlexTable";
import { FlexTableType } from "ts/enums/flexTable";
import { CategorizationTypeInfo } from "components/CategorizationTypeInfo";
import { getInternationalDateFormat } from "utils/getInternationalDateFormat";
import { getDayMonthYearFromDate } from "utils/formatters";
import { ModelToggleSwitch } from "./ModelToggleSwitch";
import { TableFilterDropdown } from "components/TableFilterDropdown";
import { Text } from "components/Text";

const TABLE_HEADERS = [
  "name",
  "version",
  "env",
  "creationDate",
  "lastUpdated",
  "deprecatesOn",
  "status",
];

export const ModelManagementPage = () => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const [modelLicenses, setModelLicenses] = useState<ModelLicense[]>();
  const [filteredModelLicenses, setFilteredModelLicenses] = useState<ModelLicense[]>();
  const [isSynchronizing, setIsSynchronizing] = useState<boolean>(false);
  const [currentEnvFilters, setCurrentEnvFilters] = useState<string[]>([]);
  const [allEnvFilters, setAllEnvFilters] = useState<string[]>([]);

  const { getResource } = useResource();
  const { push } = useHistory();

  const handleSynchronizeModelList = async () => {
    setIsSynchronizing(true);

    try {
      const { data } = await synchronizeModels();

      if (data) {
        setModelLicenses(data.models);
        dispatch(showToastSuccess("toast.synchronizationSuccessful"));
      }
    } catch {
      dispatch(showToastError("toast.defaultError"));
    }
    setIsSynchronizing(false);
  };

  const filterModels = () => {
    if (!currentEnvFilters.length) {
      setFilteredModelLicenses(modelLicenses);
      return;
    }
    setFilteredModelLicenses(
      modelLicenses.filter((model) => currentEnvFilters.includes(model?.env))
    );
  };

  const updateFilters = (env: string) => {
    if (!currentEnvFilters.includes(env)) {
      const copy = [...currentEnvFilters];
      copy.push(env);
      setCurrentEnvFilters(copy);
    } else {
      setCurrentEnvFilters(currentEnvFilters.filter((f) => f !== env));
    }
  };

  const navigateBackToLicensing = () => push(routes.licensingPage);

  useEffect(() => {
    if (currentUser.roleType !== RoleType.SuperAdmin) push(routes.homePage);
  }, [currentUser, push]);

  useEffect(() => {
    if (modelLicenses) {
      filterModels();
    }
  }, [currentEnvFilters, modelLicenses]); // eslint-disable-line

  useEffect(() => {
    getModelLicenseList()
      .then(({ data }) => {
        setModelLicenses(data.models);
        const uniqueEnv = new Set<string>();
        Object.values(data.models as ModelLicense[]).forEach((model) => {
          model.env && uniqueEnv.add(model.env);
        });
        setAllEnvFilters(Array.from(uniqueEnv));
        setFilteredModelLicenses(data.models);
      })
      .catch(console.error);
  }, []);

  return (
    <div className="fade-in">
      <StyledPageTop>
        <Button
          variant={ButtonVariant.secondary}
          size={ButtonSize.md}
          onClick={navigateBackToLicensing}
        >
          <StyledButtonContent>
            <Icon type={IconType.chevronLeft} size={14} />
            <span>
              <Text resource="modelManagement.button.backToLicensing" />
            </span>
          </StyledButtonContent>
        </Button>
        <StyledPageHeader>
          <h1>
            <Text resource="modelManagement.title" />
          </h1>
          <Button
            variant={ButtonVariant.secondary}
            size={ButtonSize.md}
            onClick={handleSynchronizeModelList}
            disabled={isSynchronizing}
          >
            <StyledButtonContent>
              <Icon type={IconType.refresh} size={14} />
              <span>
                <Text resource="licensing.button.synchronize" />
              </span>
            </StyledButtonContent>
          </Button>
        </StyledPageHeader>
      </StyledPageTop>
      <TableFilterDropdown
        currentFilters={currentEnvFilters}
        allFilters={allEnvFilters}
        updateFilters={updateFilters}
        deleteFilters={() => setCurrentEnvFilters([])}
        nameKey={"button.filter.byEnv"}
        customStyles={{
          zIndex: ZIndexStackingContext.medium,
        }}
      />
      <FlexTable
        type={FlexTableType.Table}
        data={{
          headers: TABLE_HEADERS.map((header) => getResource(`licensing.table.${header}`)),
          rows: filteredModelLicenses?.map((model) => ({
            contentId: model.modelId,
            data: [
              <StyledNameSection key={model.modelId}>
                {model.family && (
                  <CategorizationTypeInfo
                    isPowerset={model.isPowerset}
                    family={model.family}
                    languages={model.languages}
                  />
                )}
                <div>
                  <b>
                    <Text resource="licensing.table.originalName" />:
                  </b>
                  {model.name}
                </div>
                <div>
                  <b>
                    <Text resource="licensing.table.description" />:
                  </b>
                  {model.description}
                </div>
              </StyledNameSection>,
              <span key={model.modelId}>{model.version}</span>,
              <span key={model.modelId}>{model?.env}</span>,
              <span key={model.modelId}>
                {getInternationalDateFormat(getDayMonthYearFromDate(model.createdDate.toString()))}
              </span>,
              <span key={model.modelId}>
                {getInternationalDateFormat(getDayMonthYearFromDate(model.lastUpdated.toString()))}
              </span>,
              <span key={model.modelId}>
                {model.deprecatesOn &&
                  getInternationalDateFormat(
                    getDayMonthYearFromDate(model.deprecatesOn.toString())
                  )}
              </span>,
              <ModelToggleSwitch model={model} key={model.modelId} setModels={setModelLicenses} />,
            ],
          })),
          columnWidths: ["32%", "8%", "11%", "12.5%", "12.5%", "12.5%", "11.5%"],
        }}
        isLoading={modelLicenses?.length === 0}
        disableSorting={true}
        customStyles={{
          rows: {
            padding: "10px",
            backgroundColor: Color.white,
            rowHeight: "250px",
          },
        }}
      />
    </div>
  );
};

const StyledPageTop = styled.div`
  position: sticky;
  top: 0;
  padding: 24px 0 20px 0;
  background-color: ${Color.white};
  z-index: ${ZIndexStackingContext.low};

  h1 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
  }
`;

const StyledPageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 13px;
`;

const StyledButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const StyledNameSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 95%;
`;
