import React from "react";
import styled from "styled-components";
import { cloneDeep } from "lodash";

import { Select } from "components/_inputs/Select";
import { Icon, IconType } from "components/_icons/Icon";

import { SelectOption } from "ts/select";
import { CustomConditionDemographics } from "ts/sharing";
import { Color } from "ts/enums/color";
import { DemographicFilterHeader } from "ts/filters/demographicFilter";
import { UserDemographic } from "context/UserProfileContext";

import { useResource } from "hooks/useResource";
import { ButtonVariant } from "ts/enums/button";
import { Text } from "components/Text";

type Props = {
  index: number;
  customConditionDemographics: CustomConditionDemographics;
  savedCustomConditions: CustomConditionDemographics[];
  analysisDemographicHeaders: DemographicFilterHeader[];
  userDemographics: UserDemographic[];
  showCustomConditionError: boolean;
  updateCustomConditions: (updatedCustomConditions: CustomConditionDemographics[]) => void;
  handleHideCustomConditionError: () => void;
};

export const CustomCondition = ({
  index,
  customConditionDemographics,
  savedCustomConditions,
  analysisDemographicHeaders,
  userDemographics,
  showCustomConditionError,
  updateCustomConditions,
  handleHideCustomConditionError,
}: Props) => {
  const { getResource } = useResource();

  // variables
  const defaultCommentersDemographicOption: SelectOption = {
    label: getResource("sharing.permissionForm.customCondition.defaultCommentersDemographic.label"),
    value: null,
  };

  const selectedCommentersDemographicOption: SelectOption =
    customConditionDemographics.analysisDemographic
      ? {
          label: customConditionDemographics.analysisDemographic,
          value: customConditionDemographics.analysisDemographic,
        }
      : defaultCommentersDemographicOption;

  const defaultUserDemographicOption: SelectOption = {
    label: getResource("sharing.permissionForm.customCondition.defaultUserDemographic.label"),
    value: null,
  };

  const selectedUserDemographicOption: SelectOption = customConditionDemographics.userDemographic
    ? {
        label: customConditionDemographics.userDemographic,
        value: customConditionDemographics.userDemographic,
      }
    : defaultUserDemographicOption;

  const commentsDemographicHasError =
    showCustomConditionError &&
    !selectedCommentersDemographicOption.value &&
    selectedUserDemographicOption.value;

  const userDemographicHasError =
    showCustomConditionError &&
    !selectedUserDemographicOption.value &&
    selectedCommentersDemographicOption.value;

  // functions
  const selectCommentersDemographicOption = (name: string) => {
    const selectedHeader = analysisDemographicHeaders.find((adh) => adh.name === name);
    const copy = cloneDeep(savedCustomConditions);
    copy[index].analysisDemographic = selectedHeader.name;
    updateCustomConditions(copy);
    validateConditions(copy);
  };

  const selectUserDemographicOption = (name: string) => {
    const selectedUserDemographic = userDemographics.find((aud) => aud.name === name);
    const copy = cloneDeep(savedCustomConditions);
    copy[index].userDemographic = selectedUserDemographic.name;
    updateCustomConditions(copy);
    validateConditions(copy);
  };

  const deleteCondition = () => {
    const copy = cloneDeep(savedCustomConditions);
    copy.splice(index, 1);
    updateCustomConditions(copy);
    validateConditions(copy);
  };

  const validateConditions = (updatedCustomConditions: CustomConditionDemographics[]) => {
    if (showCustomConditionError) {
      const hasError = updatedCustomConditions.some(
        (cc) => (cc.analysisDemographic.length === 0) !== (cc.userDemographic.length === 0)
      );
      !hasError && handleHideCustomConditionError();
    }
  };

  return (
    <StyledCustomCondition>
      <span>
        <Text
          resource={{
            key:
              index === 0
                ? "sharing.permissionForm.customCondition.firstCondition.phrase"
                : "sharing.permissionForm.customCondition.subsequentConditions.phrase",
          }}
        />
      </span>
      <Select
        selectedOption={selectedCommentersDemographicOption}
        options={analysisDemographicHeaders.map((adh) => ({
          label: adh.name,
          value: adh.name,
        }))}
        handleChange={selectCommentersDemographicOption}
        dropdownWidth="100%"
        buttonStyle={{ borderRadius: "2px", width: "100%" }}
        buttonVariant={
          commentsDemographicHasError ? ButtonVariant.error : ButtonVariant.outlineGray
        }
        dropdownMaxHeight="260px"
      />
      <span>
        <Text resource="sharing.permissionForm.customCondition.equalsTo.phrase" />
      </span>
      <Select
        selectedOption={selectedUserDemographicOption}
        options={userDemographics.map((aud) => ({
          label: aud.name,
          value: aud.name,
        }))}
        handleChange={selectUserDemographicOption}
        dropdownWidth="100%"
        buttonStyle={{ borderRadius: "2px", width: "100%" }}
        buttonVariant={userDemographicHasError ? ButtonVariant.error : ButtonVariant.outlineGray}
        dropdownMaxHeight="260px"
      />
      <StyledDeleteContainer onClick={deleteCondition}>
        <Icon type={IconType.xCircle} color={Color.gray30} size={14} />
      </StyledDeleteContainer>
    </StyledCustomCondition>
  );
};

const StyledCustomCondition = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  span {
    font-size: 15px;
    color: ${Color.gray50};
  }

  /* setting the widths of Select dropdowns */
  @media (max-width: 1365px) {
    :first-child {
      > :nth-child(2),
      > :nth-child(4) {
        width: 28%;
      }
    }

    :not(:first-child) {
      > :nth-child(2),
      > :nth-child(4) {
        width: 34.1%;
      }
    }
  }

  @media (min-width: 1366px) {
    :first-child {
      > :nth-child(2),
      > :nth-child(4) {
        width: 33.4%;
      }
    }

    :not(:first-child) {
      > :nth-child(2),
      > :nth-child(4) {
        width: 38%;
      }
    }
  }
`;

const StyledDeleteContainer = styled.span`
  cursor: pointer;
`;
