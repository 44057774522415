import React from "react";
import styled from "styled-components";
import { Color } from "ts/enums/color";

type Props = {
  count: number | string;
  backgroundColor: string;
  isActive: boolean;
};

export const FilterPillCount = ({ count, backgroundColor, isActive }: Props) => (
  <StyledFilterPillCount backgroundColor={backgroundColor} isActive={isActive}>
    {count}
  </StyledFilterPillCount>
);

type StyledProps = {
  backgroundColor: string;
  isActive: boolean;
};

const StyledFilterPillCount = styled.div<StyledProps>`
  background-color: ${(props) => (props.isActive ? Color.white : props.backgroundColor)};
  color: ${(props) => (props.isActive ? props.backgroundColor : Color.white)};
  padding: 2px 6px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.785em;
  font-weight: bold;
`;
