import styled from "styled-components";

export const DEFAULT_DROPDOWN_TRANSITION_TIME = 200;

export const StyledDropdownTransition = styled.div<{ show: boolean }>`
  transition: ${DEFAULT_DROPDOWN_TRANSITION_TIME}ms opacity,
    ${DEFAULT_DROPDOWN_TRANSITION_TIME}ms visibility,
    pointer-events ${DEFAULT_DROPDOWN_TRANSITION_TIME}ms;
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
  opacity: ${({ show }) => (show ? 1 : 0)};
`;
