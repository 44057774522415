import { precisionSelectionRange } from "assets/constants/precisionSelection";

export const findPrecisionLevel = (precision: number) => {
  const foundRange = precisionSelectionRange.find((obj) => obj.value === precision);
  const rangeValue = foundRange.label;
  if (rangeValue === "highRecommended") {
    return "high";
  }
  return rangeValue;
};

export const findPrecisionNumber = (precision: string) => {
  let precisionWord = precision;
  if (precisionWord === "high") {
    precisionWord = "highRecommended";
  }
  const foundRange = precisionSelectionRange.find((obj) => obj.label === precisionWord);
  const rangeValue = foundRange.value;
  return rangeValue;
};
