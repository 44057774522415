import React from "react";
import store from "store";
import { Provider as StoreProvider } from "react-redux";
import { createRoot } from "react-dom/client";
import "index.css";
import { App } from "app";
import { Router, Redirect, Route, Switch } from "react-router-dom";
import Modal from "react-modal";
import { AuthenticationManager } from "config/authenticationManager";
import { PostLoginPage } from "pages/post-login";
import { PostRefreshPage } from "pages/post-refresh";
import { ErrorHandler } from "components/ErrorHandler";
import { createBrowserHistory } from "history";

// Handle redirect after login
const currentPath = window.location.pathname;
const fullPath = currentPath + window.location.search;
if (!["/post-login", "/post-refresh"].includes(currentPath)) {
  localStorage.setItem("redirect", fullPath);
}

// Setup authentication manager
export const authenticationManager = new AuthenticationManager();

// Setup react-modal
Modal.setAppElement("#root");

// Setup history
const history = createBrowserHistory();

// Setup react root
const container = document.querySelector("#root");
const root = createRoot(container);

root.render(
  <StoreProvider store={store}>
    <Router history={history}>
      <ErrorHandler>
        <Switch>
          <Route path="/post-login">
            <PostLoginPage />
          </Route>
          <Route path="/post-refresh">
            <PostRefreshPage />
          </Route>
          <Route path="/">
            <App />
          </Route>
          <Redirect from="/index.html" to="/" />
        </Switch>
      </ErrorHandler>
    </Router>
  </StoreProvider>
);
