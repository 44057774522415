import React, { useContext, RefObject, forwardRef } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";

import { AnalysisContext } from "context/AnalysisContext";
import { useSharingContext } from "context/SharingContext";
import { useResource } from "hooks/useResource";
import { routes } from "routes";
import { addUsersToNewSharingDraft } from "services/analysis/sharing";
import { getSharingMethodFromView } from "utils/getSharingMethodFromSharingView";
import { getUrlQueryString } from "utils/getUrlQueryString";
import { setSelectedModelId } from "context/AnalysisContext/actions";

import { ButtonVariant } from "ts/enums/button";
import { ShareStep, SharingView } from "ts/enums/sharing";
import { Color } from "ts/enums/color";
import { Text } from "components/Text";

type ShareToButtonProps = {
  type: SharingView;
  handleGoBack: () => void;
  handleNavigateToPreview: () => void;
  onSave: (type: SharingView) => void;
  isSaving: boolean;
  groupNameButtonRef: RefObject<HTMLInputElement>;
};

const ShareToButtons = forwardRef<HTMLInputElement, ShareToButtonProps>(function ShareToButtons(
  {
    type,
    handleGoBack,
    handleNavigateToPreview,
    onSave,
    isSaving,
    groupNameButtonRef,
  }: ShareToButtonProps,
  ref
) {
  return (
    <StyledShareToButtonContainer ref={ref}>
      <Button variant={ButtonVariant.light} onClick={handleGoBack} disabled={isSaving}>
        <Text resource="button.backToUserSelection" />
      </Button>
      <Button variant={ButtonVariant.outline} onClick={handleNavigateToPreview} disabled={isSaving}>
        <Text resource="button.previewDashboard" />
      </Button>
      <div ref={groupNameButtonRef}>
        <Button onClick={() => onSave(type)} loading={isSaving}>
          <Text
            resource={{
              key:
                type === SharingView.ShareToGroups ? "button.shareToGroup" : "button.shareAnalysis",
            }}
          />
        </Button>
      </div>
    </StyledShareToButtonContainer>
  );
});

// Main component
type Props = {
  onSave: (type: SharingView) => void;
  isSaving: boolean;
  groupNameInputRef?: RefObject<HTMLInputElement>;
  groupNameButtonRef?: RefObject<HTMLInputElement>;
};

export const SharingActionsSection = ({
  onSave,
  isSaving,
  groupNameInputRef,
  groupNameButtonRef,
}: Props) => {
  const { sharingState, updateSharingState } = useSharingContext();
  const [analysisState, dispatch] = useContext(AnalysisContext);
  const { getResource } = useResource();
  const history = useHistory();

  const handleGoToPermissionLevelSettings = async () => {
    try {
      await addUsersToNewSharingDraft({
        analysisId: analysisState.analysisDetails.id,
        sharingMethod: getSharingMethodFromView(sharingState.view),
        userIds: sharingState.selectedUsers.map((u) => u.id),
      });
      // Redirect to permission level settings by setting query params
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("step", ShareStep.PermissionSettings);
      queryParams.delete("search");
      history.push(`${window.location.pathname}?${queryParams.toString()}`);
      updateSharingState("step", ShareStep.PermissionSettings);
    } catch (error: any) {
      console.error(error.message);
    }
  };

  const handleNavigateToPreview = () => {
    // reset custom model id if not currently selected for preview page
    if (
      analysisState.analysisDetails.selectedModel?.customModelId &&
      analysisState.selectedModelId !== analysisState.analysisDetails.selectedModel.customModelId
    ) {
      dispatch(setSelectedModelId(analysisState.analysisDetails.selectedModel.customModelId));
    }

    history.push(
      routes.overviewPage(
        analysisState.analysisDetails.id,
        getUrlQueryString({
          sharingPreview: sharingState.view,
          step: sharingState.step,
        })
      )
    );
  };

  const handleGoBack = () => {
    updateSharingState("step", ShareStep.UserSelection);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("step", ShareStep.UserSelection);
    history.push(`${window.location.pathname}?${queryParams.toString()}`);
    if (sharingState.showCustomConditionError || sharingState.showGroupNameError) {
      updateSharingState("showCustomConditionError", false);
      updateSharingState("showGroupNameError", false);
      groupNameInputRef.current.value = getResource("sharing.groupName.default");
      updateSharingState(
        "permissionFields",
        {
          ...sharingState.permissionFields,
          groupName: getResource("sharing.groupName.default"),
        },
        { save: true }
      );
    }
  };

  return (
    <StyledSharingActionsSection>
      {sharingState.step === ShareStep.UserSelection ? (
        <Button
          variant={ButtonVariant.outlineBlue}
          disabled={sharingState.selectedUsers.length === 0}
          onClick={handleGoToPermissionLevelSettings}
        >
          <Text resource="button.goToPermissionLevelSettings" />
        </Button>
      ) : (
        <StyledShareToSection>
          {sharingState.showCustomConditionError && (
            <StyledErrorContainer>
              <Icon type={IconType.info} color={Color.red50} size={12} />
              <span>
                <Text resource="sharing.permissionForm.customCondition.error" />
              </span>
            </StyledErrorContainer>
          )}
          {sharingState.showGroupNameError && (
            <StyledErrorContainer>
              <Icon type={IconType.info} color={Color.red50} size={12} />
              <span>
                <Text resource="sharing.permission.groupName.emptyFieldError" />
              </span>
            </StyledErrorContainer>
          )}
          <ShareToButtons
            type={sharingState.view}
            handleNavigateToPreview={handleNavigateToPreview}
            handleGoBack={handleGoBack}
            onSave={onSave}
            isSaving={isSaving}
            groupNameButtonRef={groupNameButtonRef}
          />
        </StyledShareToSection>
      )}
    </StyledSharingActionsSection>
  );
};

const StyledSharingActionsSection = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 12px;
  padding: 24px 0;
  height: 100%;
`;

const StyledShareToButtonContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const StyledShareToSection = styled.div`
  width: max-content;
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

const StyledErrorContainer = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
  gap: 6px;

  span {
    color: ${Color.red50};
    font-size: 15px;
  }
`;
