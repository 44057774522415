import React, { useEffect, useState } from "react";
import styled from "styled-components";

import useClickOutside from "hooks/useClickOutside";
import { getStorageConfig } from "config/configProviders";
import { useSocket } from "context/SocketContext";

import { StyledDropdownTransition } from "components/DropdownMenu/StyledDropdownTransition";
import { Button } from "components/_buttons/Button";
import { FileDropCard } from "components/FileDropCard";
import { Select } from "components/_inputs/Select";
import { Icon, IconType } from "components/_icons/Icon";

import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { ButtonVariant } from "ts/enums/button";
import { ApplicationStatus, SocketEvent, SpreadsheetImportMethod } from "@explorance/mly-types";
import { showToastError } from "store/toast/toastSlice";
import { useAppSelector, useAppDispatch } from "store";
import { fetchUserList } from "store/users/thunks";
import {
  clearState,
  handleCancelUpload,
  setImportStatus,
  setIsPreimporting,
  setSelectedAppendReplaceOption,
  setShowDropdown,
} from "store/users/importUsersSlice";
import { Text } from "components/Text";
import { importUsers } from "services/users";

export const ImportUsers = () => {
  const state = useAppSelector((state) => state.importUsers);
  const { isUserImportInProgress } = useAppSelector((state) => state.userList);
  const { currentUser } = useAppSelector((state) => state.auth);
  const [usersFile, setUsersFile] = useState<File | null>(null);
  const dispatch = useAppDispatch();

  const socket = useSocket();

  const dropdownRef = useClickOutside(() => dispatch(setShowDropdown(false)));

  const storageConfig = getStorageConfig();
  const downloadSampleFileUrl = `${storageConfig.account}${
    storageConfig.usersSampleFile[currentUser.preferredLanguage]
  }`;

  const appendReplaceSelectOptions = [
    {
      label: <Text resource="importUsers.dropdown.append" />,
      value: SpreadsheetImportMethod.Append,
      data: {
        description: <Text resource="importUsers.dropdown.append.description" />,
        icon: undefined,
      },
    },
    {
      label: <Text resource="importUsers.dropdown.replace" />,
      value: SpreadsheetImportMethod.Replace,
      data: {
        description: <Text resource="importUsers.dropdown.replace.description" />,
        icon: IconType.warning,
      },
    },
  ];

  const uploadUsersFile = async () => {
    dispatch(setIsPreimporting(true));
    dispatch(setShowDropdown(false));

    await importUsers(
      usersFile,
      state.selectedAppendReplaceOption.value as SpreadsheetImportMethod
    ).then((res) => {
      dispatch(setIsPreimporting(false));
      setUsersFile(null);
      if (res.status >= 400) {
        dispatch(showToastError("toast.defaultError"));
      }
    });
  };

  useEffect(() => {
    dispatch(setImportStatus(isUserImportInProgress));
    dispatch(
      setSelectedAppendReplaceOption(
        appendReplaceSelectOptions.find((o) => o.value === SpreadsheetImportMethod.Append)
      )
    );

    return () => {
      dispatch(clearState());
    };
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    if (!socket) return;

    const handleChangedUserImportStatus = (value: { status: ApplicationStatus }) => {
      dispatch(setImportStatus(value.status));
      if (value.status === ApplicationStatus.Stopped && !state.isPreimporting) {
        dispatch(fetchUserList());
      }
    };

    socket.on(SocketEvent.ChangedUserListImportStatus, handleChangedUserImportStatus);

    return () => {
      socket.off(SocketEvent.ChangedUserListImportStatus, handleChangedUserImportStatus);
    };
  }, [socket, dispatch]); // eslint-disable-line

  return (
    <StyledImportUsers ref={dropdownRef}>
      <Button
        style={{ marginLeft: "auto" }}
        onClick={() => dispatch(setShowDropdown(!state.showDropdown))}
        loading={state.isPreimporting || state.importStatus === ApplicationStatus.Started}
      >
        <Text resource="button.importUsers" />
      </Button>
      <StyledImportUsersDropdown show={state.showDropdown}>
        <StyledDropdownHeader>
          <Text resource="importUsers.title" />
        </StyledDropdownHeader>
        <StyledDropdownDescription>
          <Text resource="importUsers.description" />{" "}
          <StyledSampleFileLink href={downloadSampleFileUrl}>
            <Text resource="importUsers.description.link" />
          </StyledSampleFileLink>
        </StyledDropdownDescription>
        {usersFile ? (
          <StyledCurrentFile>
            <div>
              <StyledCurrentFileButton>
                <Icon type={IconType.import} size={12} color={Color.gray50} />
              </StyledCurrentFileButton>
              <span>{usersFile.name}</span>
              <StyledDeleteCurrentFileButton onClick={() => setUsersFile(null)}>
                <Icon type={IconType.trash} size={12} color={Color.gray50} />
              </StyledDeleteCurrentFileButton>
            </div>
          </StyledCurrentFile>
        ) : (
          <FileDropCard
            onDropFile={async (file) => {
              setUsersFile(file);
            }}
            showInfoTooltip={false}
          />
        )}
        <StyledAppendReplaceSection>
          <Select
            options={appendReplaceSelectOptions}
            selectedOption={state.selectedAppendReplaceOption}
            handleChange={(option) => {
              dispatch(
                setSelectedAppendReplaceOption(
                  appendReplaceSelectOptions.find((o) => o.value === option)
                )
              );
            }}
            dropdownWidth="120px"
            buttonVariant={ButtonVariant.outline}
          />

          {state.selectedAppendReplaceOption.data.icon && (
            <Icon type={state.selectedAppendReplaceOption.data.icon} color={Color.red50} />
          )}
          <p>{state.selectedAppendReplaceOption.data.description}</p>
        </StyledAppendReplaceSection>
        <StyledBottomSection>
          <Button
            variant={ButtonVariant.light}
            style={{ marginRight: 12 }}
            onClick={() =>
              dispatch(
                handleCancelUpload(
                  appendReplaceSelectOptions.find((o) => o.value === SpreadsheetImportMethod.Append)
                )
              )
            }
          >
            <Text resource="button.cancel" />
          </Button>
          <Button variant={ButtonVariant.primary} onClick={uploadUsersFile} disabled={!usersFile}>
            <Text resource="button.upload" />
          </Button>
        </StyledBottomSection>
      </StyledImportUsersDropdown>
    </StyledImportUsers>
  );
};

const StyledImportUsers = styled.div`
  position: relative;
`;

const StyledCurrentFile = styled.div`
  height: 193px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Color.indigo20};
  border-radius: 5px;
  padding: 10px;

  > div {
    display: flex;
    border-radius: 5px;
    background-color: ${Color.neutral10};
    width: 100%;
    padding: 12px;

    span {
      font-size: 15px;
      padding: 0 8px;
      color: ${Color.gray50};
      width: 90%;
      word-wrap: break-word;
      flex-basis: content;
    }
  }
`;

const StyledCurrentFileButton = styled.div`
  display: flex;
  padding-top: 4px;
  flex-grow: unset;
`;

const StyledDeleteCurrentFileButton = styled.button`
  border: none;
  background: none;
  transition: 0.15s opacity;
  margin-left: auto;
  &:hover {
    opacity: 0.8;
  }
`;

const StyledImportUsersDropdown = styled(StyledDropdownTransition)`
  position: absolute;
  background-color: ${Color.white};
  right: 0;
  top: calc(100% + 2px);
  padding: 24px;
  z-index: ${ZIndexStackingContext.medium};
  width: 500px;
  box-shadow: 0px 3px 10px 0px rgba(53, 56, 77, 0.2);
  border-radius: 5px;
`;

const StyledDropdownHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
`;

const StyledDropdownDescription = styled.div`
  font-size: 15px;
  font-weight: normal;
  margin-bottom: 16px;
`;

const StyledSampleFileLink = styled.a`
  color: ${Color.blue50};
  text-decoration: underline;
  font-weight: bold;
`;

const StyledAppendReplaceSection = styled.div`
  margin-top: 6px;
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    font-size: 15px;
  }
`;

const StyledBottomSection = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > div {
    display: flex;
    justify-content: space-between;
  }
`;
