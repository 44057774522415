import { arrayMove } from "@dnd-kit/sortable";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { saveWidgetOrdering } from "./thunks";
import { Widget, WidgetGroup } from "ts/widget";
import { WidgetType } from "@explorance/mly-types";

type WidgetOrderingState = {
  currentlyDragging: {
    title: string;
    id: number;
    isGroupInModalExpanded?: boolean;
    widgets?: Widget[];
    widgetType?: WidgetType;
  };
  showManageWidgetsModal: boolean;
  groups: WidgetGroup[];
};

const initialState: WidgetOrderingState = {
  currentlyDragging: null,
  showManageWidgetsModal: false,
  groups: [],
};

const widgetOrderingSlice = createSlice({
  name: "widgetOrdering",
  initialState,
  reducers: {
    setCurrentlyDragging: (
      state,
      action: PayloadAction<WidgetOrderingState["currentlyDragging"]>
    ) => {
      state.currentlyDragging = action.payload;
    },
    setShowManageWidgetsModal: (state, action: PayloadAction<boolean>) => {
      state.showManageWidgetsModal = action.payload;
    },
    setGroups: (state, action: PayloadAction<WidgetGroup[]>) => {
      state.groups = action.payload;
    },
    handleDragEnd: (state, action: PayloadAction<any>) => {
      const { activeId, overId } = action.payload as {
        activeId: number;
        overId: number;
      };

      state.currentlyDragging = null;
      if (activeId === overId || !activeId || !overId) return;

      state.groups = (function () {
        //CASE DRAG OF SECTION
        const activeIndex = state.groups.findIndex((section) => section.id === activeId);
        const overIndex = state.groups.findIndex((section) => section.id === overId);

        if (activeIndex !== -1) {
          return arrayMove(state.groups, activeIndex, overIndex);
        }

        //CASE DRAG WIDGET
        const activeGroupIndex = state.groups.findIndex((section) =>
          section.widgets.some((widget) => widget.id === activeId)
        );

        const oldIndex = state.groups[activeGroupIndex].widgets.findIndex(
          (widget) => widget.id === activeId
        );

        //CASE DRAG WIDGET TO OTHER SECTION
        if (overIndex !== -1) {
          if (state.groups[activeGroupIndex].id === overId) return state.groups;
          const newGroups = [...state.groups];
          const [movedWidget] = newGroups[activeGroupIndex].widgets.splice(oldIndex, 1);
          newGroups.forEach((section) => {
            section.widgets = section.widgets.filter((widget) => widget.id !== activeId);
          });
          newGroups.forEach((section) => {
            if (section.id === overId) {
              section.widgets.push(movedWidget);
            }
          });
          return newGroups;
        }

        //CASE DRAG WIDGET IN SAME SECTION
        const newIndex = state.groups[activeGroupIndex].widgets.findIndex(
          (widget) => widget.id === overId
        );
        return state.groups.map((section) => {
          if (section.id === state.groups[activeGroupIndex].id) {
            return {
              ...section,
              widgets: arrayMove(section.widgets, oldIndex, newIndex),
            };
          }
          return section;
        });
      })();
    },
    handleDragStart: (state, action: PayloadAction<any>) => {
      //CASE DRAG SECTION
      const groupId = state.groups.findIndex((section) => section.id === action.payload.active.id);

      if (groupId !== -1) {
        state.currentlyDragging = {
          title: state.groups[groupId].title,
          id: action.payload.active.id,
          isGroupInModalExpanded: state.groups[groupId].isGroupInModalExpanded,
          widgets: state.groups[groupId].widgets,
        };
        return;
      }
      // CASE DRAG WIDGET
      const draggedWidget = state.groups
        .filter((group) =>
          group?.widgets.some((widget) => widget.id === action.payload.active.id)
        )[0]
        .widgets.filter((widget) => widget.id === action.payload.active.id)[0];

      state.currentlyDragging = {
        title: draggedWidget.title,
        id: action.payload.active.id,
        widgetType: draggedWidget.type,
      };
    },
    moveCardToDifferentSection: (state, action: PayloadAction<{ groupId: number; id: number }>) => {
      const widgetGroupIndex = state.groups.findIndex((section) =>
        section.widgets.some((widget) => widget.id === action.payload.id)
      );
      const widgetToMove = state.groups[widgetGroupIndex].widgets.find(
        (widget) => widget.id === action.payload.id
      );
      //REMOVE FROM SECTION
      state.groups[widgetGroupIndex].widgets = state.groups[widgetGroupIndex].widgets.filter(
        (widget) => widget.id !== action.payload.id
      );
      //ADD TO SECTION
      state.groups[
        state.groups.findIndex((group) => group.id === action.payload.groupId)
      ].widgets.push(widgetToMove);
    },
    moveCardSameSection: (
      state,
      action: PayloadAction<{ direction: number; id: number | string }>
    ) => {
      const groupIndex = state.groups.findIndex((group) =>
        group.widgets.some((widget) => widget.id === action.payload.id)
      );
      const widgetIndex = state.groups[groupIndex].widgets.findIndex(
        (widget) => widget.id === action.payload.id
      );
      const newGroups = [...state.groups];
      const newWidgetIndex = widgetIndex - action.payload.direction;
      if (newWidgetIndex < 0 || newWidgetIndex >= state.groups[groupIndex].widgets.length) {
        return;
      }
      const [movedWidget] = newGroups[groupIndex].widgets.splice(widgetIndex, 1);
      newGroups[groupIndex].widgets.splice(newWidgetIndex, 0, movedWidget);
      state.groups = newGroups;
    },
    changeWidgetName: (state, action: PayloadAction<{ title: string; id: number }>) => {
      const groupIndex = state.groups.findIndex((groups) =>
        groups.widgets.some((widget) => widget.id === action.payload.id)
      );
      const widgetIndex = state.groups[groupIndex].widgets.findIndex(
        (widget) => widget.id === action.payload.id
      );
      const newGroups = [...state.groups];
      newGroups[groupIndex].widgets[widgetIndex].title = action.payload.title;
      state.groups = newGroups;
    },
    moveContainer: (state, action: PayloadAction<{ direction: number; id: string | number }>) => {
      const groupIndex = state.groups.findIndex((section) => section.id === action.payload.id);
      if (
        (groupIndex === 0 && action.payload.direction === 1) ||
        (groupIndex === state.groups.length - 1 && action.payload.direction === -1)
      ) {
        return;
      }
      const newGroups = arrayMove(state.groups, groupIndex, groupIndex - action.payload.direction);
      state.groups = newGroups;
    },
    changeSectionName: (state, action: PayloadAction<{ title: string; id: number }>) => {
      const groupIndex = state.groups.findIndex((section) => section.id === action.payload.id);
      const newGroups = [...state.groups];
      newGroups[groupIndex].title = action.payload.title;
      state.groups = newGroups;
    },
    handleSectionExpand: (state, action: PayloadAction<{ id: number; isExpanded: boolean }>) => {
      const groupIndex = state.groups.findIndex((section) => section.id === action.payload.id);
      const newGroups = [...state.groups];
      newGroups[groupIndex].isGroupInModalExpanded = action.payload.isExpanded;
      state.groups = newGroups;
    },
    onClose: () => initialState,
    onSave: (state) => {
      state.showManageWidgetsModal = false;
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(saveWidgetOrdering.fulfilled, (state) => {
      state.showManageWidgetsModal = false;
    });
  },
});

export const {
  setCurrentlyDragging,
  setShowManageWidgetsModal,
  setGroups,
  handleDragEnd,
  handleDragStart,
  moveCardToDifferentSection,
  moveCardSameSection,
  changeWidgetName,
  moveContainer,
  changeSectionName,
  handleSectionExpand,
  onClose,
  onSave,
  clearState,
} = widgetOrderingSlice.actions;

export default widgetOrderingSlice.reducer;
