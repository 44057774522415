import React from "react";
import styled, { css } from "styled-components";

import { Icon, IconType } from "components/_icons/Icon";
import { FilterPillCount } from "./FilterPillCount";

import { Color } from "ts/enums/color";
import { Text } from "components/Text";

type Props = {
  isDefault: boolean;
  filterCount: number;
  inverted: boolean;
  handleDelete: () => void;
  handleClick: () => void;
};

export const TopicFilterPill = ({
  isDefault,
  filterCount,
  inverted,
  handleDelete,
  handleClick,
}: Props) => {
  const handleDeleteClick = (e) => {
    e.stopPropagation();
    handleDelete();
  };

  return (
    <StyledTopicFilterPill isDefault={isDefault} inverted={inverted} onClick={handleClick}>
      <StyledContentContainer>
        <StyledPillText isBold={!isDefault}>
          <Text resource="filterPill.topics" />
        </StyledPillText>
        {filterCount && (
          <StyledCountContainer>
            <FilterPillCount
              count={filterCount}
              backgroundColor={Color.blue40}
              isActive={inverted}
            />
          </StyledCountContainer>
        )}
      </StyledContentContainer>
      {isDefault ? (
        <StyledIconContainer>
          <Icon
            type={IconType.chevronDown}
            color={inverted ? Color.white : Color.gray20}
            size={7}
          />
        </StyledIconContainer>
      ) : (
        <StyledDeletePillButton inverted={inverted} onClick={handleDeleteClick}>
          <Icon type={IconType.x} size={12} color={inverted ? Color.white : Color.gray50} />
        </StyledDeletePillButton>
      )}
    </StyledTopicFilterPill>
  );
};

const StyledTopicFilterPill = styled.div<{ isDefault: boolean; inverted: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  font-size: 14px;
  padding: 4px 6px;
  text-align: center;
  transition: 0.15s background-color;
  gap: 4px;

  background-color: ${({ isDefault, inverted }) =>
    inverted ? Color.blue40 : isDefault ? Color.white : Color.sky15};
  color: ${({ isDefault, inverted }) =>
    inverted ? Color.white : isDefault ? Color.gray20 : Color.blue40};
  border: ${({ isDefault, inverted }) =>
    inverted || !isDefault ? `1px solid ${Color.blue40}` : `1px solid ${Color.gray20}`};

  ${({ inverted, isDefault }) =>
    !inverted &&
    css`
      :hover {
        background-color: ${isDefault ? Color.sky15 : Color.sky30};
        color: ${isDefault && Color.blue40};
        border: ${isDefault && `1px solid ${Color.blue40}`};
      }
    `}
`;

const StyledContentContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledPillText = styled.div<{ isBold: boolean }>`
  font-weight: ${({ isBold }) => isBold && "700"};
`;

const StyledCountContainer = styled.div`
  margin-left: 7px;
`;

const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  padding-right: 2px;
  margin-top: 2px;
`;

const StyledDeletePillButton = styled.button<{ inverted: boolean }>`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  margin-top: 1px;
  margin-right: 0px;
  border-radius: 2px;

  :hover {
    background: ${({ inverted }) => !inverted && Color.blue20};
  }
`;
