import React from "react";
import styled from "styled-components";
import { camelCase } from "lodash";
import parseHTML from "html-react-parser";
import { Color } from "ts/enums/color";
import { CategorizedTopic } from "ts/enums/categorizedTopic";
import { Icon, IconType } from "components/_icons/Icon";
import { Pill, PillSize } from "components/_pills/Pill";
import { ConditionalWrapper } from "components/ConditionalWrapper";
import { RecommendationType } from "@explorance/mly-types";
import { useResource } from "hooks/useResource";

type Props = {
  type: CategorizedTopic | RecommendationType;
  label: string;
  style?: Record<string, number | string>;
  isNonCatRec?: boolean;
  isCustomModel?: boolean;
};

export const CategorizedTopicPill = ({
  type,
  label,
  style = {},
  isNonCatRec = false,
  isCustomModel = false,
}: Props) => {
  const { getResource } = useResource();

  const pillStyles = {
    [CategorizedTopic.positive]: {
      background: Color.green30,
      color: Color.green50,
      iconType: null,
    },
    [CategorizedTopic.negative]: {
      background: Color.red10,
      iconType: null,
      color: Color.red50,
    },
    [CategorizedTopic.neutral]: {
      background: Color.sky30,
      iconType: null,
      color: Color.blue55,
    },
    [CategorizedTopic.notExplicit]: {
      background: Color.orange20,
      iconType: null,
      color: Color.orange60,
    },
    [CategorizedTopic.mixed]: {
      background: Color.indigo20,
      iconType: null,
      color: Color.indigo60,
    },
    [CategorizedTopic.generalRecommendation]: {
      background: Color.indigo10,
      iconType: IconType.recommendation,
      color: Color.gray50,
    },
    [CategorizedTopic.recommendation]: {
      background: Color.sky20,
      iconType: IconType.recommendation,
      color: Color.gray50,
    },
    [CategorizedTopic.doMore]: isNonCatRec
      ? {
          background: Color.blue40,
          color: Color.white,
          iconType: IconType.doMore,
        }
      : {
          background: Color.sky20,
          color: Color.blue40,
          iconType: IconType.doMore,
        },
    [CategorizedTopic.doLess]: isNonCatRec
      ? {
          background: Color.indigo50,
          color: Color.white,
          iconType: IconType.doLess,
        }
      : {
          background: Color.indigo20,
          color: Color.indigo50,
          iconType: IconType.doLess,
        },
    [CategorizedTopic.start]: isNonCatRec
      ? {
          background: Color.green50,
          color: Color.white,
          iconType: IconType.start,
        }
      : {
          background: Color.green10,
          color: Color.green50,
          iconType: IconType.start,
        },
    [CategorizedTopic.stop]: isNonCatRec
      ? {
          background: Color.red40,
          color: Color.white,
          iconType: IconType.stop,
        }
      : {
          background: Color.red10,
          color: Color.red40,
          iconType: IconType.stop,
        },
    [CategorizedTopic.continue]: isNonCatRec
      ? {
          background: Color.blue90,
          color: Color.white,
          iconType: IconType.continue,
        }
      : {
          background: Color.blue20,
          color: Color.blue90,
          iconType: IconType.continue,
        },
    [CategorizedTopic.change]: isNonCatRec
      ? {
          background: Color.orange60,
          color: Color.white,
          iconType: IconType.change,
        }
      : {
          background: Color.orange10,
          color: Color.orange60,
          iconType: IconType.change,
        },
  };

  const iconHasBackground = isNonCatRec && type !== CategorizedTopic.recommendation;

  const getLabel = (label: string) => {
    const topicArray = label
      .split(">")
      .map(
        (t) =>
          `<span>${
            isNonCatRec
              ? getResource(`recommendationPill.${camelCase(t)}`)
              : isCustomModel
              ? t
              : getResource(`ML.topic.[${t}]`).replaceAll("/", " / ")
          }</span>`
      );
    const iconToString = `<svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 8 4"
    width="6px"
    height="6px"
    style="transform: rotate(-90deg); margin: 1px 4px 0px 4px;"
  >
    <path
      id="Subtraction_68"
      data-name="Subtraction 68"
      d="M4,4,4,4,0,0H8L4,4Z"
      transform="translate(0)"
      fill=${pillStyles[type]?.color}
    />
  </svg>`;
    return (
      <StyledLabel color={pillStyles[type]?.color}>
        {parseHTML(topicArray.join(iconToString))}
      </StyledLabel>
    );
  };

  return (
    <Pill
      size={PillSize.sm}
      color={pillStyles[type]?.color}
      backgroundColor={pillStyles[type]?.background}
      style={{
        padding: "4px 8px",
        ...(type === CategorizedTopic.recommendation && { position: "relative", top: 2 }),
        ...style,
      }}
    >
      <>
        <ConditionalWrapper
          condition={iconHasBackground}
          wrapper={(children) => <StyledNCIconWrapper>{children}</StyledNCIconWrapper>}
        >
          {pillStyles[type]?.iconType && (
            <Icon
              type={pillStyles[type].iconType}
              size={type === CategorizedTopic.recommendation ? 10 : 6}
              style={{ marginRight: iconHasBackground ? 0 : 5 }}
            />
          )}
        </ConditionalWrapper>
        {getLabel(label)}
      </>
    </Pill>
  );
};

const StyledLabel = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  font-size: 12px;
  text-align: left;
`;

const StyledNCIconWrapper = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 2px;
  margin-right: 5px;
  background-color: ${Color.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;
