import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AnalysisDataSource } from "ts/analysis";
import { fetchDataSources } from "./thunks";

type DataSourceState = {
  analysisDataSource: AnalysisDataSource | null;
  showFileUploadingModal: boolean;
};

const initialState: DataSourceState = {
  analysisDataSource: null,
  showFileUploadingModal: false,
};

const dataSourceSlice = createSlice({
  name: "dataSource",
  initialState,
  reducers: {
    setAnalysisDataSource: (state, action: PayloadAction<AnalysisDataSource>) => {
      state.analysisDataSource = action.payload;
    },
    setShowFileUploadingModal: (state, action: PayloadAction<boolean>) => {
      state.showFileUploadingModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDataSources.fulfilled, (state, action) => {
      state.analysisDataSource = action.payload;
    });
  },
});

export const { setAnalysisDataSource, setShowFileUploadingModal } = dataSourceSlice.actions;

export default dataSourceSlice.reducer;
