import { User } from "oidc-client";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { authenticationManager } from "..";
import { routes } from "routes";
import { getUser, updateUserLastLogin } from "services/users";
import { parseWindowLocationHash } from "utils/parseWindowLocationHash";

export const PostLoginPage = () => {
  const history = useHistory();

  useEffect(() => {
    const updateLoginDate = async (user: User) => {
      const userByExternalId = await getUser(user.profile.sub, true);
      updateUserLastLogin(userByExternalId.data.id);
    };

    const parsedHash = parseWindowLocationHash(window.location.hash);

    if (parsedHash["id_token"] && parsedHash["access_token"]) {
      authenticationManager.completeLogin().then((user) => {
        updateLoginDate(user as User);

        const redirectUrl = localStorage.getItem("redirect");
        if (redirectUrl) localStorage.removeItem("redirect");

        history.push(redirectUrl || routes.homePage);
      });
    } else {
      authenticationManager.login();
    }
  }, [history]);

  return <></>;
};
