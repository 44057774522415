import React, { useMemo } from "react";
import styled from "styled-components";
import { Color } from "ts/enums/color";
import { formatSearchTerms } from "utils/comments";

type Props = {
  comment: string;
  searchField: string;
};

export const CommentText = ({ comment, searchField }: Props) => {
  const searchRegExp = useMemo(() => {
    if (searchField && searchField.length > 0) {
      const formattedSearch = formatSearchTerms(searchField);
      return new RegExp(`(${formattedSearch})`, "gi");
    }
  }, [searchField]);

  const renderCommentParts = () => {
    const parts = comment.split(searchRegExp);

    return parts.map((part, i) => (
      <span key={i} className={searchRegExp.test(part) ? "highlight" : undefined}>
        {part}
      </span>
    ));
  };

  if (!comment) return null;

  return <StyledCommentText>{searchField ? renderCommentParts() : comment}</StyledCommentText>;
};

const StyledCommentText = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: ${Color.gray50};
  margin: 0;
  white-space: break-spaces;

  .highlight {
    background: ${Color.blue40};
    color: ${Color.white};
    // keep this value here to prevent highlight of extra characters in Chrome and Edge (to see, comment out this value and search for "of")
    font-weight: 500;
  }
`;
