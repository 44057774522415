import { pull } from "lodash";

import { CommentsView } from "@explorance/mly-types";
import { countsSchema } from "./countsSchema";
import { StatType } from "ts/enums/statsMenu";
import { AvailableResults } from "ts/analysis";

const getStatTabValue = (apiData, cv, stat) => {
  if (!apiData[cv] || apiData[cv]?.total === 0) return 0;

  const value = apiData[cv]?.[stat.name];
  if (stat.type === StatType.Percentage) {
    return value / apiData[cv]?.total;
  }
  if (cv === CommentsView.alerts) {
    return apiData[cv]?.requiresAttention;
  }
  return value;
};

export const mapCommentStatisticsForMenu = (apiData): any => {
  const schemaWithValues = {};
  Object.keys(CommentsView).forEach((cv) => {
    schemaWithValues[cv] = countsSchema[cv].map((stat) => {
      return { ...stat, value: getStatTabValue(apiData, cv, stat) };
    });
  });
  return schemaWithValues;
};

// @TODO: migrate this logic so into getCommentsByAnalysisId endpoint call, so we directly get the correct data
export const getAvailableCommentViews = (availableResults: AvailableResults) => {
  const availableCommentViews = Object.values(CommentsView);

  if (!availableResults.alerts) {
    pull(availableCommentViews, CommentsView.alerts);
  }
  if (!availableResults.topics) {
    pull(
      availableCommentViews,
      CommentsView.categorizedComments,
      CommentsView.categorizedRecommendations,
      CommentsView.notCategorizedComments,
      CommentsView.notCategorizedRecommendations
    );
  }
  if (!availableResults.recommendations) {
    pull(
      availableCommentViews,
      CommentsView.allRecommendations,
      CommentsView.categorizedRecommendations,
      CommentsView.notCategorizedRecommendations
    );
  }

  return availableCommentViews;
};
