import React, { createRef, useEffect, useState } from "react";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "store";
import { showToastError } from "store/toast/toastSlice";

import window from "assets/images/window.svg";

import { getStorageConfig } from "config/configProviders";

import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { FileUploadInput } from "components/_inputs/FileUpload";
import { Tooltip } from "components/Tooltip";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { Text } from "components/Text";
type Props = {
  onDropFile: (file: File) => Promise<string | void>;
  showHelpExtension?: boolean;
  showInfoTooltip?: boolean;
};

export const FileDropCard = ({ onDropFile, showHelpExtension, showInfoTooltip = true }: Props) => {
  const dispatch = useAppDispatch();

  const [isImportError, setIsImportError] = useState<boolean>(false);
  const [hasDraggedFile, setHasDraggedFile] = useState<boolean>(false);

  const inputFileRef = createRef<HTMLInputElement>();
  const { currentUser } = useAppSelector((state) => state.auth);

  const storageConfig = getStorageConfig();
  const downloadSampleFileUrl = `${storageConfig.account}${
    storageConfig.commentsSampleFile[currentUser.preferredLanguage]
  }`;

  useEffect(() => {
    if (isImportError) {
      setHasDraggedFile(false);
      setIsImportError(false);
    }
  }, [isImportError]);

  const handleSelectFile = async (file: File) => {
    const toastErrorLabelKey = await onDropFile(file);
    setIsImportError(!!toastErrorLabelKey);
    return toastErrorLabelKey;
  };

  const handleDropFile = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setHasDraggedFile(false);
    const toastErrorLabelKey = await handleSelectFile(e.dataTransfer.files[0]);
    toastErrorLabelKey && dispatch(showToastError(toastErrorLabelKey));
  };

  const handleClickDropZone = (e) => {
    if (e.target.localName !== "a") {
      inputFileRef.current.click();
    }
  };

  const handleDragFile = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setHasDraggedFile((hasFile) => !hasFile);
  };

  return (
    <>
      <StyledFileDropCard
        onDragEnter={handleDragFile}
        onDragLeave={handleDragFile}
        onDrop={handleDropFile}
        hasDraggedFile={hasDraggedFile}
      >
        <StyledMainSection hasDraggedFile={hasDraggedFile}>
          <div onClick={handleClickDropZone}>
            {showInfoTooltip && (
              <StyledInfoToolTipContainer data-tip data-for={"import-file-info-tooltip"}>
                <Icon type={IconType.info} color={Color.gray30} size={14} />
                <Tooltip
                  tooltipId={"import-file-info-tooltip"}
                  className="info-tooltip"
                  font-size="0.75em"
                >
                  <Text resource="fileDropZone.tooltip" />
                </Tooltip>
              </StyledInfoToolTipContainer>
            )}

            <StyledFileDropContent className="fade-in">
              <img src={window} alt="window" />
              <StyledFileDropText>
                <StyledImportTitle>
                  <Text resource="fileDropZone.title" />
                </StyledImportTitle>
                <StyledImportDescription>
                  <Text resource="fileDropZone.description" />
                </StyledImportDescription>
                <Button
                  variant={ButtonVariant.primaryPurple}
                  style={{
                    marginLeft: "auto",
                    gap: "8px",
                  }}
                  size={ButtonSize.ml}
                >
                  <Icon
                    type={IconType.import}
                    size={12}
                    color={Color.white}
                    secondaryColor={Color.blue50}
                  />
                  <span>
                    <Text resource="button.importData" />
                    ...
                  </span>
                </Button>
              </StyledFileDropText>
            </StyledFileDropContent>
          </div>
        </StyledMainSection>
        {showHelpExtension && (
          <StyledHelpExtension>
            <StyledImportHelpText>
              <Text resource="fileDropZone.extension.needHelp" />
            </StyledImportHelpText>
            <StyledButtonContainer>
              <Button
                href={downloadSampleFileUrl}
                style={{
                  backgroundColor: Color.sky20,
                  color: Color.gray50,
                  marginLeft: "auto",
                  gap: "8px",
                }}
                size={ButtonSize.sm}
                data-click="prevent-upload"
              >
                <Icon type={IconType.download} size={12} color={Color.blue50} />
                <Text resource="fileDropZone.button.downloadSampleFile" />
              </Button>
            </StyledButtonContainer>
          </StyledHelpExtension>
        )}
      </StyledFileDropCard>
      <FileUploadInput
        inputFileRef={inputFileRef}
        onSelectFile={handleSelectFile}
        resetFileRef={isImportError}
      />
    </>
  );
};

const StyledFileDropCard = styled.div<{ hasDraggedFile: boolean }>`
  display: flex;
  flex-direction: column;
  border: 2px dashed ${({ hasDraggedFile }) => (hasDraggedFile ? Color.blue50 : Color.indigo20)};
  border-radius: 5px 5px 5px 5px;

  ${({ hasDraggedFile }) =>
    !hasDraggedFile &&
    `&:hover {
    background-color: ${Color.sky30};
  }`}
`;

const StyledHelpExtension = styled.div`
  background-color: ${Color.sky15};
  height: 49px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid ${Color.indigo10};
  border-radius: 0px 0px 5px 5px;
  padding: 8px 8px 8px 12px;
  gap: 18px;
`;

const StyledImportHelpText = styled.div`
  font-size: 14px;
  color: ${Color.gray50};
`;

const StyledButtonContainer = styled.div`
  svg:not(.is-stroke) * {
    fill: ${Color.blue50};
  }
`;

const StyledMainSection = styled.div<{ hasDraggedFile: boolean }>`
  border-radius: 5px 5px 0px 0px;
  background-color: ${({ hasDraggedFile }) => (hasDraggedFile ? Color.sky40 : Color.white)};
  cursor: pointer;
  padding: 1em 1em 0.5em 0;
  transition: 0.2s background-color, 0.2s border-color;
  ${({ hasDraggedFile }) =>
    !hasDraggedFile &&
    `&:hover {
    background-color: ${Color.sky30};
    border-color: transparent;
  }`}
`;

const StyledFileDropContent = styled.div`
  display: flex;
  align-items: center;
  margin-top: -16px;
  padding-left: 10px;
  @media (min-width: 1366px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const StyledFileDropText = styled.div`
  width: 178px;
  margin-left: 12px;
  position: relative;
  left: -8px;
  @media (max-width: 1366px) {
    position: relative;
    left: 16px;
  }
`;

const StyledImportTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${Color.gray50};
`;

const StyledImportDescription = styled.div`
  font-size: 0.875em;
  margin: 4px 0 16px 0;
  color: ${Color.gray30};
  font-weight: normal;
`;

const StyledInfoToolTipContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 0.2em;
  position: relative;
  z-index: ${ZIndexStackingContext.low};

  .info-tooltip {
    box-shadow: 0px 2px 4px #00000026;
    text-align: center;
  }

  @media (min-width: 960px) {
    margin-left: 370px;
  }

  @media (min-width: 1366px) {
    margin-left: 315px;
  }
`;
