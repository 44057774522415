import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { useAppSelector, useAppDispatch } from "store";
import {
  getCreateUserRequestBody,
  getUpdateUserRequestBody,
  useUserProfile,
} from "context/UserProfileContext";

import { isPasswordValid, validateEmail } from "utils/validation";
import { useResource } from "hooks/useResource";
import { sendUserInvite } from "services/users";
import { isAnyAdmin } from "utils/isAnyAdmin";

import { BackButton } from "components/_buttons/BackButton";
import { Checkbox } from "components/_inputs/Checkbox";
import { Button } from "components/_buttons/Button";
import { ResetPasswordBlock } from "common-layouts/UserProfileEditor/ResetPasswordBlock";
import { BasicUserInfoBlock } from "./BasicUserInfoBlock";
import { AuthenticationBlock } from "./AuthenticationBlock";
import { MainDemographicsBlock } from "./MainDemographicsBlock";
import { AdditionalDemographicsBlock } from "./AdditionalDemographicsBlock";
import { ErrorMessage } from "./ErrorMessage";

import { SSOMapping, User } from "ts/user";
import { ButtonVariant } from "ts/enums/button";
import { FormType } from "ts/enums/formType";
import { showToastError, showToastSuccess } from "store/toast/toastSlice";
import { Text } from "components/Text";

type Props = {
  user?: User;
  formType: FormType;
  handleSaveUser: (
    args
  ) => Promise<{ isError: boolean; isUsernameDuplicate: boolean; isRoleQuotaReached: boolean }>;
};

export const UserProfileEditor = ({ user, formType, handleSaveUser }: Props) => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  // errors and loading state
  const [showErrorMessages, setShowErrorMessages] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  // hooks
  const { userId } = useParams<{ userId: string }>();
  const userIdToEdit = parseInt(userId);
  const history = useHistory();
  const { getResource } = useResource();

  const {
    fields,
    setField,
    mainDemographics,
    additionalDemographics,
    fieldErrors,
    setFieldError,
    resetFields,
  } = useUserProfile();

  const errorMessages = Array.from(
    new Set(Object.values(fieldErrors).filter((fe) => fe.length > 0))
  );

  const showPasswordBlock = formType === FormType.Edit && userIdToEdit === currentUser.id;

  useEffect(() => {
    if (
      (fieldErrors.noAuthMethodCurrentUser && fields.basicAuth) ||
      fields.ssoMappings.some((sso) => sso.activated)
    ) {
      setFieldError("noAuthMethodCurrentUser", "");
    }
  }, [fields.basicAuth, fields.ssoMappings]); // eslint-disable-line

  // functions
  const handleCreateUpdateUser = async () => {
    if (!isUserFormValid()) {
      return setShowErrorMessages(true);
    }

    setIsSaving(true);

    try {
      if (formType === FormType.Create) {
        const requestBody = getCreateUserRequestBody(fields);
        const { isUsernameDuplicate, isError, isRoleQuotaReached } = await handleSaveUser(
          requestBody
        );

        if (!isError) resetFields();
        if (isUsernameDuplicate) {
          setFieldError("username", getResource("userProfile.existsUsername.message"));
          setShowErrorMessages(true);
        } else if (isRoleQuotaReached) {
          setFieldError(
            "roleType",
            getResource(
              "userProfile.roleQuotaReached.message",
              getResource(`roleType.${fields.roleType}`)
            )
          );
          setShowErrorMessages(true);
        } else {
          setTimeout(() => setFieldError("ssoMappings", [""]), 50);
          setShowErrorMessages(false);
        }
      } else if (formType === FormType.Edit) {
        const requestBody = getUpdateUserRequestBody(fields);
        const { isRoleQuotaReached } = await handleSaveUser(requestBody);
        if (isRoleQuotaReached) {
          setFieldError(
            "roleType",
            getResource(
              "userProfile.roleQuotaReached.message",
              getResource(`roleType.${fields.roleType}`)
            )
          );
          setShowErrorMessages(true);
        } else {
          setTimeout(() => setFieldError("ssoMappings", [""]), 50);
          setShowErrorMessages(false);
        }
      }
    } catch (err: any) {
      console.error(err);
      dispatch(showToastError("toast.defaultError"));
    } finally {
      setIsSaving(false);
    }
  };

  const handleResendUserInvite = (userId: number) => {
    sendUserInvite(userId)
      .then(() => dispatch(showToastSuccess("userProfile.invitationSuccess.message")))
      .catch(() => dispatch(showToastError("toast.defaultError")));
  };

  const isUserFormValid = (): boolean => {
    let isFormValid = true;

    if (isBasicUserInfoValid() === false) isFormValid = false;
    if (isAuthValid() === false) isFormValid = false;
    if (showPasswordBlock && isPasswordInfoValid() === false) isFormValid = false;

    return isFormValid;
  };

  const isBasicUserInfoValid = (): boolean => {
    let isMissingValues = false;
    let isUsernameInvalid = false;
    const requiredFields = {
      username: fields.username,
      firstname: fields.firstname,
      roleType: fields.roleType,
      lastname: fields.lastname,
      preferredLanguage: fields.preferredLanguage,
    };

    Object.keys(requiredFields).forEach((f) => {
      if (requiredFields[f].length === 0 || requiredFields[f].trim() === "") {
        isMissingValues = true;
        setFieldError(f, getResource("userProfile.missingRequiredField.message"));
      } else if (f === "username" && !validateEmail(requiredFields[f])) {
        isUsernameInvalid = true;
        setFieldError(f, getResource("userProfile.invalidUsername.message"));
      } else {
        setFieldError(f, "");
      }
    });
    return !isMissingValues && !isUsernameInvalid;
  };

  const isAuthValid = (): boolean => {
    let isValid = true;

    // ensure user cannot deactivate their own profile
    if (
      currentUser.id === userIdToEdit &&
      !fields.ssoMappings.some((auth) => auth.activated) &&
      !fields.basicAuth
    ) {
      setFieldError("noAuthMethodCurrentUser", getResource("userProfile.auth.missing"));
      isValid = false;
    }

    fields.ssoMappings.forEach((ssoMappingField: SSOMapping, index) => {
      if (ssoMappingField.activated && !ssoMappingField.username) {
        isValid = false;
        const newSsoMappingErrors = fieldErrors.ssoMappings;
        newSsoMappingErrors[index] = getResource("userProfile.auth.message");
        setFieldError("ssoMappings", newSsoMappingErrors);
      } else {
        const newSsoMappingErrors = fieldErrors.ssoMappings;
        newSsoMappingErrors[index] = "";
        setFieldError("ssoMappings", newSsoMappingErrors);
      }
    });
    return isValid;
  };

  const isPasswordInfoValid = (): boolean => {
    const passwordFields = {
      oldPassword: fields.oldPassword,
      newPassword: fields.newPassword,
      confirmPassword: fields.confirmPassword,
    };

    if (Object.keys(passwordFields).every((pf) => passwordFields[pf].length === 0)) return true;

    const fieldsWithErrors = new Set(
      Object.keys(passwordFields).filter((k) => passwordFields[k].length === 0)
    );

    const isNewPasswordInvalid =
      !isPasswordValid(passwordFields.newPassword) ||
      passwordFields.newPassword !== passwordFields.confirmPassword;
    const isConfirmPasswordInvalid = passwordFields.newPassword !== passwordFields.confirmPassword;

    if (isNewPasswordInvalid) fieldsWithErrors.add("newPassword");
    if (isConfirmPasswordInvalid) fieldsWithErrors.add("confirmPassword");

    Object.keys(passwordFields).forEach((f) => {
      if (fieldsWithErrors.has(f)) {
        setFieldError(f, getResource("userProfile.passwordError.message"));
      } else {
        setFieldError(f, "");
      }
    });

    return !(fieldsWithErrors.size > 0);
  };

  return (
    <div className="fade-in">
      {/* HEADER */}
      <StyledHeader>
        <BackButton resourceKey={"button.backToPreviousPage"} onClick={() => history.goBack()} />
      </StyledHeader>
      <StyledUserProfileForm>
        {/* BLOCKS */}
        <BasicUserInfoBlock
          isCreation={formType === FormType.Create}
          savedUserFirstName={formType === FormType.Edit && user?.firstname}
          savedUserLastName={formType === FormType.Edit && user?.lastname}
          validateBasicUserInfo={isBasicUserInfoValid}
        />

        <AuthenticationBlock validateAuth={isAuthValid} />
        {!isEmpty(mainDemographics) && <MainDemographicsBlock />}
        {!isEmpty(additionalDemographics) && <AdditionalDemographicsBlock />}

        {showPasswordBlock && (
          <ResetPasswordBlock handleValidatePasswordFields={isPasswordInfoValid} />
        )}

        {/* BOTTOM */}
        <StyledBottomContainer>
          {formType === FormType.Create && (
            <StyledSendEmail>
              <Checkbox
                checked={fields.sendEmailNotification}
                disabled={false}
                onChange={() => setField("sendEmailNotification", !fields.sendEmailNotification)}
              />
              <span
                onClick={() => setField("sendEmailNotification", !fields.sendEmailNotification)}
              >
                <Text resource="userProfile.notifyField.label" />
              </span>
            </StyledSendEmail>
          )}
          {formType === FormType.Edit &&
            isAnyAdmin(currentUser.roleType) &&
            currentUser.id !== user.id && (
              <Button
                variant={ButtonVariant.outlineBlue}
                onClick={() => handleResendUserInvite(user.id)}
              >
                <Text resource="button.resendInvitation" />
              </Button>
            )}
          {showErrorMessages && (
            <StyledErrorMessagesContainer>
              {errorMessages.map((message: any, index) => (
                <ErrorMessage key={index} message={message} index={index} />
              ))}
            </StyledErrorMessagesContainer>
          )}
          <Button
            loading={isSaving}
            style={{ marginLeft: "auto" }}
            onClick={handleCreateUpdateUser}
          >
            <Text
              resource={{
                key: formType === FormType.Create ? "button.createUser" : "button.update",
              }}
            />
          </Button>
        </StyledBottomContainer>
      </StyledUserProfileForm>
    </div>
  );
};

const StyledHeader = styled.div`
  padding: 36px 0 34px 0;
`;

const StyledUserProfileForm = styled.div`
  width: 792px;
  margin: 0 auto;
`;

const StyledSendEmail = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.875em;
  margin-left: 4px;
  margin-right: 20px;

  input {
    display: none;
  }

  span {
    margin-left: 10px;
    cursor: pointer;
  }
`;

const StyledBottomContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 22px 0;
  gap: 10px;
`;

const StyledErrorMessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
