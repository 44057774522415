import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CreateUpdateVariableMappingBody,
  createVariableMapping,
  getVariableMapping,
  updateVariableMapping,
} from "services/variableMappings";
import { AppState } from "store/appReducer";

import { VariableMappingGroup } from "ts/variableMappings";

export const fetchVariableMapping = createAsyncThunk<
  VariableMappingGroup,
  void,
  { state: AppState }
>(
  "editVariableMapping/fetchVariableMapping",
  async (_, { getState }): Promise<VariableMappingGroup> => {
    const state = getState();
    const editVariableMappingState = state.editVariableMapping;

    const { data: variableMappingData } = await getVariableMapping(
      editVariableMappingState.mappingId
    );

    if (!variableMappingData) {
      throw new Error("Failed to fetch variable mapping data");
    }
    return variableMappingData;
  }
);

export const createVariableMappingThunk = createAsyncThunk<
  any,
  CreateUpdateVariableMappingBody,
  { state: AppState }
>("editVariableMapping/createNewVariableMapping", async (body, { getState }): Promise<any> => {
  const state = getState();
  const editVariableMappingState = state.editVariableMapping;

  if (editVariableMappingState.showErrors) {
    throw new Error("Please correct the error fields");
  }

  const { data: newVariableMapping } = await createVariableMapping(body);

  return newVariableMapping;
});

export const updateVariableMappingThunk = createAsyncThunk<
  any,
  CreateUpdateVariableMappingBody,
  { state: AppState }
>("editVariableMapping/updateVariableMapping", async (body, { getState }): Promise<any> => {
  const state = getState();
  const editVariableMappingState = state.editVariableMapping;

  if (editVariableMappingState.showErrors) {
    throw new Error("Please correct the error fields");
  }

  const { data: updatedVarMapping } = await updateVariableMapping(
    editVariableMappingState.mappingId,
    body
  );
  return { ...updatedVarMapping, updateSuccessful: true };
});
