import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getCustomModel, SaveModelValues, updateCustomModel } from "services/customModels";
import { CustomModelBuilder } from "common-layouts/CustomModelBuilder";
import { ErrorScreen } from "components/ErrorScreen";
import { PageErrorType } from "ts/enums/pageErrorType";
import { useAppDispatch } from "store";
import { showToastError, showToastSuccess } from "store/toast/toastSlice";

export const CustomModelEditPage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { customModelId } = useParams<{ customModelId: string }>();
  const [existingModel, setExistingModel] = useState<any>(null);
  const [apiErrorCode, setApiErrorCode] = useState<number>(null);

  // Get existing custom model
  useEffect(() => {
    if (!existingModel) {
      getCustomModel(customModelId)
        .then(({ data }) => setExistingModel(data))
        .catch((err) => setApiErrorCode(JSON.parse(JSON.stringify(err))?.status));
    }
  }, [customModelId, location.search, existingModel]); //eslint-disable-line

  const handleSave = async (values: SaveModelValues) => {
    try {
      await updateCustomModel(customModelId, values);
      dispatch(showToastSuccess("toast.customModelSaved"));
      return true;
    } catch (error) {
      dispatch(showToastError("toast.defaultError"));
      return false;
    }
  };

  if (apiErrorCode) {
    return (
      <ErrorScreen
        errorType={
          apiErrorCode === 403
            ? PageErrorType.CustomModelInsufficientPermission
            : PageErrorType.Other
        }
      />
    );
  }

  if (!existingModel) return null;

  return (
    <CustomModelBuilder
      initialValues={{
        name: existingModel.name,
        modelId: existingModel.graphId,
        topics: existingModel.topics,
      }}
      onSave={handleSave}
    />
  );
};
