import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "store";
import { routes, Routes } from "routes";

import { getCurrentUserId } from "hooks/useGetUserId";
import { getUser } from "services/users";

import { LoadingDots } from "components/LoadingDots";
import { ScreenSizeNotSupported } from "components/_screens/ScreenSizeNotSupported";
import Sidebar from "components/Sidebar";

import { SocketProvider } from "context/SocketContext";
import { NotificationsProvider } from "context/NotificationsContext";

import { Color } from "ts/enums/color";
import { AnalysisPageType } from "ts/enums/analysisPageType";
import { authenticationManager } from "index";
import { ResourcesProvider } from "context/ResourcesContext";
import { APP_CSS } from "assets/constants/appCss";
import { setCurrentUser } from "store/auth/authSlice";
import { Toast } from "components/Toast";
import { LoadingDotsScreen } from "components/_screens/LoadingDotsScreen";
import { getTypedObjectKeys } from "utils/keys";
import {
  fetchRecentTopicsResourceFile,
  getResourceFiles,
  getResources,
} from "context/ResourcesContext/helpers";
import { isEqual } from "lodash";
import { setResources, setTopicResources } from "store/resources/resourceSlice";

export const App = () => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);
  const resourcesState = useAppSelector((state) => state.resourcesSlice);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { pathname } = useLocation();
  const history = useHistory();

  const mainContentRef = useRef<HTMLDivElement>();
  const resourcesFiles = getResourceFiles();

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === "PUSH" || action === "REPLACE") {
        localStorage.setItem("lastVisitedPath", location.pathname);
      }
    });

    return () => unlisten();
  }, [history]);

  useEffect(() => {
    const initUser = async () => {
      setIsLoading(true);

      const isLoggedIn = await authenticationManager.isLoggedIn();

      if (!isLoggedIn) {
        authenticationManager.login();
        return;
      }

      const userId = await getCurrentUserId();

      if (userId) {
        const { data } = await getUser(userId);
        dispatch(setCurrentUser(data));
      }

      setIsLoading(false);
    };

    initUser();
  }, [dispatch]);

  //initialize resources
  useEffect(() => {
    if (
      currentUser &&
      (Object.keys(resourcesState.topicResources.Eng).length === 0 || !resourcesState.resources)
    ) {
      getTypedObjectKeys(resourcesFiles).forEach(async (lang) => {
        const recentTopicsResourceFile = await fetchRecentTopicsResourceFile(lang);

        // Check if topic translations in the state are outdated vs. blob file.
        if (!isEqual(resourcesState.topicResources[lang], recentTopicsResourceFile)) {
          localStorage.setItem(`topics_${lang}`, JSON.stringify(recentTopicsResourceFile));
          const resources = getResources({
            ...resourcesState.topicResources,
            [lang]: recentTopicsResourceFile,
          });
          dispatch(
            setTopicResources({
              ...resourcesState.topicResources,
              [lang]: recentTopicsResourceFile,
            })
          );
          dispatch(setResources(resources));
        }
      });
    } else if (!resourcesState.resources) {
      const resources = getResources(resourcesState.topicResources);
      dispatch(setResources(resources));
    }
  }, [currentUser, resourcesState.topicResources, dispatch]); //eslint-disable-line

  // reset scroll position for navigating between analysis pages
  useEffect(() => {
    const analysisPages = Object.keys(AnalysisPageType).map((apt) => apt.toLowerCase());

    if (analysisPages.some((ap) => pathname.includes(ap)) && mainContentRef.current) {
      mainContentRef.current.scrollIntoView();
    }
  }, [pathname]);

  if (!currentUser) return <LoadingDotsScreen />;

  const isGrayBackground: boolean =
    pathname.startsWith(routes.updateProfilePath) ||
    pathname === routes.createProfilePage ||
    pathname === routes.homePage ||
    pathname.includes("overview") ||
    pathname.includes("dashboard");

  return (
    <ResourcesProvider>
      <SocketProvider>
        {isLoading && (
          <StyledLoadingContainer>
            <div className="loading-dots">
              <LoadingDots />
            </div>
          </StyledLoadingContainer>
        )}
        <StyledMasterPage>
          <ScreenSizeNotSupported />
          <NotificationsProvider>
            <Sidebar />
          </NotificationsProvider>
          <div className={`main-body ${isGrayBackground ? "bg-gray" : ""}`}>
            <div ref={mainContentRef} className="main-content">
              <Routes />
            </div>
          </div>
        </StyledMasterPage>
      </SocketProvider>
      <Toast />
    </ResourcesProvider>
  );
};

const StyledLoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .loading-dots {
    margin: auto;
  }
`;

const StyledMasterPage = styled.div`
  display: flex;
  padding: 0px;
  margin: 0px;
  height: 100%;
  width: 100%;
  flex-direction: column;
  color: ${Color.gray50}; // Default text color through the app

  .main-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    transition: background 100ms ease-in-out 50ms;
    /* Prevents scrollbar from pushing content */
    scrollbar-gutter: stable;
    -webkit-scrollbar: 10px;
    -webkit-scrollbar-position: inside;
  }

  .bg-gray {
    background: ${Color.neutral10};
  }

  .main-content {
    margin: 0px auto;
    position: relative;
    left: ${APP_CSS["[.main-content].left"]};
  }

  @media (max-width: 1079px) {
    div:not(.notSupported, .notSupported div) {
      display: none;
    }
  }
`;

export default App;
