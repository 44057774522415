import { mlyApi, getHeaders } from "config/mlyApi";

type PostQuickAnalysisBody = {
  comment: string;
  graphId?: number;
  customModelId?: number;
  precision?: string;
  env: string;
};

export async function runQuickAnalysis(body: PostQuickAnalysisBody) {
  const requestConfig = await getHeaders();
  const url = "/quick-analysis";
  return mlyApi.post(url, body, requestConfig);
}

export async function getQuickAnalysisModels() {
  const requestConfig = await getHeaders();
  const url = "/quick-analysis/models";
  return mlyApi.get(url, requestConfig);
}
