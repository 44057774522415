import { createAsyncThunk } from "@reduxjs/toolkit";

import { WidgetGroup } from "ts/widget";
import { saveWidgetOrdering as handleWidgetOrderSave } from "services/widgets";
import { AppState } from "store/appReducer";

export const saveWidgetOrdering = createAsyncThunk<WidgetGroup[], number, { state: AppState }>(
  "widgetOrdering/saveWidgetOrdering",
  async (analysisId, { getState }): Promise<WidgetGroup[]> => {
    const state = getState();
    const widgetOrderingState = state.widgetOrdering;
    const orderedGroups = widgetOrderingState.groups.map((group, groupIndex) => {
      const orderedWidgets = group.widgets.map((widget, widgetIndex) => {
        return {
          ...widget,
          displayOrder: widgetIndex + 1,
        };
      });
      return {
        ...group,
        displayOrder: groupIndex + 1,
        widgets: orderedWidgets,
      };
    });

    const response = await handleWidgetOrderSave(analysisId, orderedGroups);

    if (!response) {
      throw new Error("Failed to save widget ordering");
    }

    return orderedGroups;
  }
);
