export enum QuickAnalysisState {
  edit = "edit",
  results = "results",
  loading = "loading",
}

export enum QuickAnalysisInterpretationType {
  comment = "comment",
  topicInsight = "topicInsight",
  recommendation = "recommendation",
  alertNothingToReport = "alertNothingToReport",
}
