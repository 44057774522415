import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { isEqual } from "lodash";

import { DropdownMenu } from "components/DropdownMenu";
import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { Tooltip } from "components/Tooltip";

import useClickOutside from "hooks/useClickOutside";
import { getRandomNumber } from "utils/getRandomNumber";

import { DropdownPosition } from "ts/dropdown";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { SelectOption } from "ts/select";
import { Color } from "ts/enums/color";
import { Text } from "components/Text";
import { useResource } from "hooks/useResource";

type LabelStyle = {
  fontWeight: string;
  color: Color;
};

type SelectStyle = {
  margin?: string;
  padding?: string;
  width?: string;
};

type Props = {
  selectedOption: SelectOption;
  options: SelectOption[];
  handleChange: (arg) => void;
  selectTitleKey?: string;
  dropdownWidth?: string;
  buttonVariant?: ButtonVariant;
  buttonSize?: ButtonSize;
  buttonStyle?: Record<string, string | number>;
  labelStyle?: LabelStyle;
  dropdownPosition?: DropdownPosition;
  flex?: string;
  dropdownMaxHeight?: string;
  hideDropdown?: boolean;
  iconColor?: Color;
  addNoneOption?: boolean;
  selectStyle?: SelectStyle;
};

export const Select = ({
  selectedOption,
  options,
  handleChange,
  selectTitleKey,
  dropdownWidth,
  buttonVariant = ButtonVariant.outlineGray,
  buttonSize = ButtonSize.md,
  buttonStyle: buttonStyleProp,
  iconColor,
  labelStyle,
  dropdownPosition = {},
  flex = "none",
  dropdownMaxHeight = null,
  addNoneOption,
  selectStyle,
}: Props) => {
  const { getResource } = useResource();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [localSelectOptions, setLocalSelectOptions] = useState<SelectOption[]>(options);
  const selectContainerRef = useClickOutside(() => setMenuOpen(false));

  const buttonLabelRef = useRef<HTMLDivElement>();

  const randomId = getRandomNumber();

  useEffect(() => {
    if (addNoneOption) {
      setLocalSelectOptions((prev) => [
        { label: getResource("dropdown.none"), value: null },
        ...prev,
      ]);
    } else {
      setLocalSelectOptions(options);
    }
  }, [addNoneOption, options]); // eslint-disable-line

  useEffect(() => {
    if (buttonLabelRef.current) {
      setShowTooltip(
        buttonLabelRef.current.offsetWidth < buttonLabelRef.current.scrollWidth ||
          buttonLabelRef.current.offsetHeight < buttonLabelRef.current.scrollHeight
      );
    }
    setLocalSelectOptions((prev) =>
      prev.map((o) => ({
        ...(o.label === null ? { label: getResource("dropdown.none"), value: null } : o),
        isActive: isEqual(selectedOption, o),
      }))
    );
  }, [selectedOption]); // eslint-disable-line react-hooks/exhaustive-deps

  const buttonStyle = {
    display: "flex",
    justifyContent: "space-between",
    height: 34,
    ...buttonStyleProp,
  };

  if (iconColor) {
    buttonStyle["color"] = iconColor;
  }

  return (
    <StyledSelectContainer ref={selectContainerRef} flex={flex} selectStyle={selectStyle}>
      {selectTitleKey && (
        <StyledSelectionTitle>
          <Text resource={selectTitleKey} />
        </StyledSelectionTitle>
      )}
      <Button
        style={buttonStyle}
        variant={buttonVariant}
        size={buttonSize}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <StyledButtonLabel
          ref={buttonLabelRef}
          data-tip
          data-for={randomId}
          labelStyle={labelStyle}
        >
          {selectedOption.label}
          {showTooltip && (
            <Tooltip tooltipId={randomId.toString()} content={selectedOption.label} />
          )}
        </StyledButtonLabel>
        <Icon type={IconType.triangleFilledDown} size={8} style={{ marginLeft: 8 }} />
      </Button>
      {menuOpen && (
        <DropdownMenu
          position={{ zIndex: ZIndexStackingContext.high, ...dropdownPosition }}
          items={localSelectOptions}
          show={menuOpen}
          onChange={(value) => {
            setMenuOpen(false);
            handleChange && handleChange(value);
          }}
          width={dropdownWidth}
          maxHeight={dropdownMaxHeight}
        />
      )}
    </StyledSelectContainer>
  );
};

const StyledSelectContainer = styled.div<{ flex: string; selectStyle: SelectStyle }>`
  position: relative;
  flex: ${({ flex }) => flex};
  margin: ${({ selectStyle }) => selectStyle?.margin};
  padding: ${({ selectStyle }) => selectStyle?.padding};
  width: ${({ selectStyle }) => selectStyle?.width};
`;

const StyledButtonLabel = styled.div<{ labelStyle?: LabelStyle }>`
  text-overflow: ellipsis;
  overflow: hidden;
  max-lines: 1;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: left;
  font-weight: ${({ labelStyle }) => labelStyle?.fontWeight};
  color: ${({ labelStyle }) => labelStyle?.color};
`;

const StyledSelectionTitle = styled.div`
  font-size: 12px;
  color: ${Color.gray20};
  font-weight: bold;
  margin-bottom: 8px;
`;
