import React, { useState } from "react";
import styled from "styled-components";

import { CategorizationType } from "@explorance/mly-types";
import { CategorizationTypeSymbol } from "components/_icons/CategorizationTypeSymbol";
import { BaseModelPill } from "components/_pills/BaseModelPill";
import { useGetModelName } from "hooks/useGetModelName";
import { Tooltip } from "components/Tooltip";
import { Text } from "components/Text";

type Props = {
  languages: string[];
  isPowerset: boolean;
  family?: CategorizationType;
  isOutdated?: boolean;
};

export const CategorizationTypeInfo = ({ languages, isPowerset, family, isOutdated }: Props) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const getModelName = useGetModelName();

  const modelName = getModelName({ languages, isPowerset });

  const handleModelNameHover = (e: React.MouseEvent<HTMLSpanElement>) => {
    setShowTooltip(e.currentTarget.offsetWidth < e.currentTarget.scrollWidth);
  };

  return (
    <StyledCategorizationTypeInfo>
      {family ? (
        <>
          <CategorizationTypeSymbol categorizationType={family} />
          <StyledModelNameDetails>
            <span
              data-tip
              data-for={modelName}
              onMouseOver={handleModelNameHover}
              onMouseOut={() => setShowTooltip(false)}
            >
              {modelName}
              {isOutdated && <Text resource="analysisSettings.outdatedModel.label" />}
            </span>
            {showTooltip && <Tooltip tooltipId={modelName} content={modelName} />}
          </StyledModelNameDetails>
        </>
      ) : (
        <BaseModelPill />
      )}
    </StyledCategorizationTypeInfo>
  );
};

const StyledCategorizationTypeInfo = styled.div`
  display: flex;
  align-items: center;
`;

const StyledModelNameDetails = styled.span`
  margin-left: 6px;
  display: inline-flex;
  max-width: 69%;
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
