import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getInitialTopicResources } from "context/ResourcesContext/helpers";
import { Resources } from "ts/resources";

type ResourceState = {
  resources: Resources;
  topicResources: Resources;
};

const initialState: ResourceState = {
  resources: null,
  topicResources: getInitialTopicResources(),
};

const resourcesSlice = createSlice({
  name: "resourcesSlice",
  initialState,
  reducers: {
    setResources: (state, action: PayloadAction<Resources>) => {
      state.resources = action.payload;
    },
    setTopicResources: (state, action: PayloadAction<Resources>) => {
      state.topicResources = action.payload;
    },
  },
});

export const { setResources, setTopicResources } = resourcesSlice.actions;

export default resourcesSlice.reducer;
