import React, { useState } from "react";
import Tree, {
  mutateTree,
  moveItemOnTree,
  RenderItemParams,
  ItemId,
  TreeSourcePosition,
  TreeDestinationPosition,
  TreeItem,
} from "@atlaskit/tree";

import { Row } from "./Row";

import { createChild, deleteItem, deleteMappedTopicFromItem } from "./helpers";

import { CustomModelBuilderMode } from "ts/enums/customModelBuilderMode";
import { AnalysisModel } from "ts/filters/analysisModel";
import { CustomModelTopicUpdateMethod } from "ts/enums/customModelTopicUpdateMethod";
import { ApiTopicNode } from "ts/topic";
import { useCustomModelBuilder } from "..";

type Props = {
  topicsSideDrawerOpen: boolean;
  onClickMapTopics: (item: TreeItem, rowName: string) => void;
  updateBaseModelTopics: (
    baseModel: AnalysisModel,
    selectedTopics: ApiTopicNode[],
    type: CustomModelTopicUpdateMethod
  ) => void;
  onTreeChange: () => void;
};

export const CustomTopicsTree = ({
  topicsSideDrawerOpen,
  onClickMapTopics,
  updateBaseModelTopics,
  onTreeChange,
}: Props) => {
  const [currentlyDraggedItemId, setCurrentlyDraggedItemId] = useState<ItemId>(null);

  const ctx = useCustomModelBuilder();

  const renderItem = ({ item, onExpand, onCollapse, provided, depth }: RenderItemParams) => (
    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
      <Row
        item={item}
        onExpand={onExpand}
        onCollapse={onCollapse}
        depth={depth}
        topicsSideDrawerOpen={topicsSideDrawerOpen}
        onCreateChild={(item) => {
          const childId = createChild(item, ctx.tree, ctx.setTree);
          onTreeChange();
          ctx.setFocusedId(childId);
        }}
        onDeleteItem={onDeleteItem}
        onClickMapTopics={onClickMapTopics}
        onChangeInput={(item) => {
          ctx.setTree((prev) => mutateTree(prev, item.id, item));
          onTreeChange();
        }}
        onTreeChange={onTreeChange}
        handleDeleteMappedTopic={handleDeleteMappedTopic}
      />
    </div>
  );

  const onExpand = (itemId: ItemId) => {
    ctx.setTree((prev) => mutateTree(prev, itemId, { isExpanded: true }));
  };

  const onCollapse = (itemId: ItemId) => {
    ctx.setTree((prev) => mutateTree(prev, itemId, { isExpanded: false }));
  };

  const onDragEnd = (source: TreeSourcePosition, destination?: TreeDestinationPosition) => {
    if (!destination || isNaN(destination.index)) {
      return;
    }
    const newTree = moveItemOnTree(ctx.tree, source, destination);
    (newTree.items[currentlyDraggedItemId] as any).parentId = destination.parentId;
    ctx.setTree(newTree);
    onTreeChange();
  };

  const onDeleteItem = (item: TreeItem) => {
    deleteItem(item, ctx.tree, ctx.setTree, ctx.updatedBaseModel, updateBaseModelTopics);
    onTreeChange();
  };

  const handleDeleteMappedTopic = (item: TreeItem, deletedTopic: ApiTopicNode) => {
    deleteMappedTopicFromItem(
      item,
      ctx.tree,
      deletedTopic,
      ctx.setTree,
      ctx.updatedBaseModel,
      updateBaseModelTopics
    );
    onTreeChange();
  };

  return (
    <Tree
      tree={ctx.tree}
      offsetPerLevel={0}
      renderItem={renderItem}
      onExpand={onExpand}
      onCollapse={onCollapse}
      onDragStart={setCurrentlyDraggedItemId}
      onDragEnd={onDragEnd}
      isDragEnabled={ctx.mode === CustomModelBuilderMode.edit}
    />
  );
};
