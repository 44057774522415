import { isEmpty, differenceWith, isEqual, find, uniqWith, intersectionWith } from "lodash";
import {
  recursiveFindAllTopicNodesWithChildren,
  deepFindOneTopic,
  recursiveFindAllTopicNodes,
  recursiveFindAllParents,
} from "utils/topics";
import { TopicFilter } from "ts/filters/topicFilter";
import { TopicTreeNode, ApiTopicNode } from "ts/topic";

export const isChildTopic = (topicNode) => {
  return topicNode.fullPath.includes(">");
};

export const onSelectTopicFilter = (
  selectedNode: ApiTopicNode,
  topicsTreeData: TopicFilter[],
  localSelectedTopicNodes: ApiTopicNode[],
  setLocalSelectedTopicNodes: (selectedTopics: ApiTopicNode[]) => void,
  includeParentsForInsert?: boolean
) => {
  const selectedTopicNode = deepFindOneTopic(topicsTreeData, selectedNode);
  const allChildrenNodes = recursiveFindAllTopicNodes(selectedTopicNode);
  const allEmptyChildrenNodes = allChildrenNodes.filter((node) =>
    isEmpty(deepFindOneTopic(topicsTreeData, node).topics)
  );

  // If the topic has no children AND is already selected, uncheck it and remove it from the selection id array
  if (isEmpty(selectedTopicNode.topics) && find(localSelectedTopicNodes, selectedNode)) {
    const updatedFilters = localSelectedTopicNodes.filter((c) => !isEqual(c, selectedNode));
    deepFindOneTopic(topicsTreeData, selectedNode)["isSelected"] = false;
    setLocalSelectedTopicNodes(updatedFilters);
    // If includeParentsForInsert AND all or some siblings are absent, remove the parent from array
    if (includeParentsForInsert) {
      const parent = recursiveFindAllTopicNodesWithChildren(topicsTreeData).find(
        (t) => t.id === selectedTopicNode.parentId
      );
      const allSiblings = recursiveFindAllTopicNodes(parent);
      if (
        allSiblings.length !== updatedFilters.filter((tn) => tn.parentId === parent.id).length ||
        intersectionWith(updatedFilters, allSiblings, isEqual).length === 0
      ) {
        setLocalSelectedTopicNodes(updatedFilters.filter((t) => t.id !== parent.id));
      }
    }
    return;
  }

  // If the topic has no children AND is not selected, add it to the selection array and mark it as selected
  if (isEmpty(selectedTopicNode.topics) && !find(localSelectedTopicNodes, selectedNode)) {
    selectedTopicNode["isSelected"] = true;
    setLocalSelectedTopicNodes([...localSelectedTopicNodes, selectedNode]);
    return;
  }

  // If the topic has children AND is not selected, recursively add all children to the array + mark them as selected
  if (!selectedTopicNode["isSelected"]) {
    selectedTopicNode["isSelected"] = true;
    const alreadySelectedTopics = [];
    allChildrenNodes.forEach((node) => {
      if (includeParentsForInsert && deepFindOneTopic(topicsTreeData, node)["isHidden"]) {
        alreadySelectedTopics.push(node);
      }
      deepFindOneTopic(topicsTreeData, node)["isSelected"] = true;
    });
    allChildrenNodes.forEach((node) => {
      if (includeParentsForInsert && alreadySelectedTopics.some((x) => x.parentId === node.id)) {
        alreadySelectedTopics.push(node);
      }
    });
    setLocalSelectedTopicNodes(
      uniqWith(
        [
          ...localSelectedTopicNodes,
          ...(includeParentsForInsert
            ? // need all topics for insert regardless of whether it is a parent
              allChildrenNodes.filter(
                (n) =>
                  !alreadySelectedTopics.some(
                    (x) => x.id === n.id && x.parentId === n.parentId && x.fullPath === n.fullPath
                  )
              )
            : allEmptyChildrenNodes),
          // insert selectedNode for insert functionality
          includeParentsForInsert && alreadySelectedTopics.length === 0 && selectedNode,
        ],
        isEqual
      ).filter(Boolean)
    );

    return;
  }

  // If all above conditions are false, i.e:
  // If the topic has children AND is already selected, recursively remove all children from the array + unmark them as selected
  // And remove all parents if DND
  selectedTopicNode["isSelected"] = false;
  allChildrenNodes.forEach((node) => {
    deepFindOneTopic(topicsTreeData, node)["isSelected"] = false;
  });
  const withoutChildren = differenceWith(localSelectedTopicNodes, allEmptyChildrenNodes, isEqual);
  const updatedFilters = withoutChildren.filter((c) => !isEqual(c, selectedNode));
  setLocalSelectedTopicNodes(updatedFilters);

  if (includeParentsForInsert) {
    const selectedTopicNodeWithChildren = recursiveFindAllTopicNodesWithChildren(
      topicsTreeData
    ).find((topic) => topic.id === selectedTopicNode.id);

    // in order to treat the parents needed for insert, this function simultaneously rolls up and drills down to find all parents for a topic
    const findAllParents = () => {
      const parents = recursiveFindAllParents(selectedTopicNode, topicsTreeData);
      const findChildParents = () => {
        const allNodesInTopic = recursiveFindAllTopicNodes(selectedTopicNode);
        return allNodesInTopic.filter((nodeInTopic) =>
          allNodesInTopic.some(
            (topic) =>
              topic.parentId === nodeInTopic.id && topic.fullPath.includes(nodeInTopic.fullPath)
          )
        );
      };
      const childParents = findChildParents();
      return parents.concat(childParents);
    };
    // if the topic node selected is a child
    if (isChildTopic(selectedTopicNodeWithChildren)) {
      const allParents = findAllParents();
      const newUpdatedFilters = [];
      updatedFilters.forEach((f) => {
        if (!allParents.find((p) => f.fullPath === p.fullPath)) {
          newUpdatedFilters.push(f);
        }
      });
      setLocalSelectedTopicNodes(newUpdatedFilters);
    } else {
      const allParents = findAllParents();
      const newUpdatedFilters = [];
      updatedFilters.forEach((f) => {
        if (!allParents.find((p) => f.fullPath === p.fullPath)) {
          newUpdatedFilters.push(f);
        }
      });

      setLocalSelectedTopicNodes(newUpdatedFilters);
    }
  }

  return;
};

export const handleSelectAll = (topicsTreeData, isAllSelected, setLocalSelectedTopicNodes) => {
  const selectAllArray = [];
  const addToSelection = (topics: TopicTreeNode[]) => {
    topics.forEach((item) => {
      if (item.topics.length === 0) {
        item["isSelected"] = true;
        selectAllArray.push({ id: item.id, parentId: item.parentId, fullPath: item.fullPath });
      }
      addToSelection(item.topics);
    });
    return topics;
  };
  addToSelection(topicsTreeData);
  setLocalSelectedTopicNodes(isAllSelected ? [] : selectAllArray);
};
