import React from "react";

import { Button } from "../Button";
import { ButtonVariant, ButtonSize } from "ts/enums/button";
import { Text } from "components/Text";

type Props = {
  handleClick: () => void;
};

export const ApplyFiltersButton = ({ handleClick }: Props) => {
  return (
    <Button
      variant={ButtonVariant.primary}
      style={{ marginLeft: "auto" }}
      size={ButtonSize.sm}
      onClick={handleClick}
    >
      <Text resource="button.apply" />
    </Button>
  );
};
