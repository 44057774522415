import React from "react";
import styled from "styled-components";

import { CheckboxField } from "components/_inputs/CheckboxField";
import { useResource } from "hooks/useResource";
import { CheckboxCheckedAppearance } from "ts/enums/checkboxCheckedAppearance";
import { SelectOption } from "ts/select";

type Props = {
  options: SelectOption[];
  currentFilters: SelectOption[];
  restrictedAccess?: boolean;
  selectAllDisabled?: boolean;
  updateFilters: (newFilters: SelectOption[]) => void;
};

export const ColumnFilterSection = ({
  options,
  currentFilters,
  restrictedAccess,
  selectAllDisabled,
  updateFilters,
}: Props) => {
  const { getResource } = useResource();

  const handleSelectAll = () => {
    if (options.length === currentFilters.length) return updateFilters([]);
    updateFilters(options);
  };

  const handleSelectFilter = (selectedFilter: SelectOption) => {
    const filterArray = [...currentFilters];
    const updatedFilters = currentFilters.some((cf) => cf.value === selectedFilter.value)
      ? filterArray.filter((f) => f.value !== selectedFilter.value)
      : [...filterArray, selectedFilter];
    updateFilters(updatedFilters);
  };

  return (
    <StyledColumnFilterSection>
      {!selectAllDisabled && (
        <CheckboxField
          checked={currentFilters.length > 0}
          variant={
            options.length === currentFilters.length
              ? CheckboxCheckedAppearance.Default
              : CheckboxCheckedAppearance.Partial
          }
          disabled={restrictedAccess}
          onChange={handleSelectAll}
          label={getResource("columnFilterSection.selectAll")}
        />
      )}
      {options.map((o, index) => (
        <CheckboxField
          key={index}
          disabled={restrictedAccess}
          checked={currentFilters.some((cf) => cf.value === o.value)}
          variant={CheckboxCheckedAppearance.Default}
          onChange={() => handleSelectFilter(o)}
          label={o.label}
        />
      ))}
    </StyledColumnFilterSection>
  );
};

const StyledColumnFilterSection = styled.div`
  .checkbox input {
    margin-left: 0px;
  }
`;
